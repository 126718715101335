import { mergeStyleSets, getTheme } from "@fluentui/react";
import { IPrimaryButtonProps } from "./primary-button";
import { BorderRadius } from "../../../../utils/constants/styles";

interface IPrimaryButtonClassNames {
  'button': string;
}

export const getClassNames = (props: IPrimaryButtonProps): IPrimaryButtonClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    'button': {
      width: props.width && props.width,
      height: props.height,
      borderRadius: BorderRadius,
      color: props.color && props.color,
      padding: props.padding && `0 ${props.padding}px`,
      transition: 'background-color 500ms',

      ...(!props.disabled && {
        '&:hover': {
          backgroundColor: props.background && theme.palette.themeLight,
          borderColor: props.background && theme.palette.themeLight,
          color: props.color && props.color,
        }
      })
    }
  });
}