// import react
import { useState } from "react";
import { useAppDispatch } from "../../../../store/store";
import { cleanLessonToEdit } from "../../../../store/slices/courses";

const useLearningDetailsPage = () => {
  const dispatch = useAppDispatch();

  const [showConfigureModal, setShowConfigureModal] = useState<boolean>(false);
  const [showScheduleModal, setShowScheduleModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showQuizModal, setShowQuizModal] = useState<boolean>(false);

  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [deleteType, setDeleteType] = useState<'Learning' | 'Lesson'>();

  const [showGenerateLessonModal, setShowGenerateLessonModal] = useState<boolean>(false);

  const onConfigureModalClick = () => setShowConfigureModal(true);
  const onCloseConfigure = () => setShowConfigureModal(false);

  const onScheduleModalClick = () => setShowScheduleModal(true);
  const onCloseSchedule = () => setShowScheduleModal(false);

  const onEditLessonClick = () => {
    setShowEditModal(true);
  };
  const onCloseEditModal = async () => {
    setShowEditModal(false);
    setTimeout(() => dispatch(cleanLessonToEdit()), 250);
  }

  const onQuizModalClick = () => setShowQuizModal(true);
  const onCloseQuizModal = () => setShowQuizModal(false);

  const onDeleteClick = (type: 'Learning' | 'Lesson') => {
    setDeleteType(type);
    setShowDeleteDialog(true);
  };
  const onCloseDelete = () => {
    setShowDeleteDialog(false);
  };

  const onGenerateLessonClick = () => {
    setShowGenerateLessonModal(true);
  };
  const onCloseGenerateLessonModal = async () => {
    setShowGenerateLessonModal(false);
  };

  return {
    editModalActions: {
      showEditModal,
      onEditLessonClick,
      onCloseEditModal,
    },

    configureModalActions: {
      showConfigureModal,
      onConfigureModalClick,
      onCloseConfigure,
    },

    scheduleModalActions: {
      showScheduleModal,
      onScheduleModalClick,
      onCloseSchedule,
    },

    quizModalActions: {
      showQuizModal,
      onQuizModalClick,
      onCloseQuizModal,
    },

    deleteDialogActions: {
      showDeleteDialog,
      onDeleteClick,
      onCloseDelete,
      deleteType
    },

    generateLessonModalActions: {
      showGenerateLessonModal,
      onGenerateLessonClick,
      onCloseGenerateLessonModal,
    },
  }
};

export default useLearningDetailsPage;