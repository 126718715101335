import { getClassNames } from "./NoPermission.classNames";

export default function NoPermission() {
  const classes = getClassNames();

  return (
    <div className={classes.noPermissionContainer}>
      <div className={classes.noPermissionMessageContainer}>
        <span>You don't have the required permissions to access the app...</span>
      </div>
    </div>
  )
}