import { mergeStyleSets, getTheme } from "@fluentui/react";
import { BorderRadius, IFadeClassNames, FADE_SCALE } from "../../../../../utils/constants/styles";

interface IDeleteDialogClassNames extends IFadeClassNames{
  dialogBackground: string;
  dialog: string;
}

export const getClassNames = (): IDeleteDialogClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    dialogBackground: {
      position: 'fixed',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      zIndex: 10,
      background: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },

    dialog: {
      width: 400,
      // height: 150,
      padding: '20px 30px',
      background: theme.palette.white,
      borderRadius: BorderRadius,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      gap: 20,
    },

    ...FADE_SCALE.classNames,
  })
};