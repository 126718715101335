import { mergeStyleSets } from '@fluentui/react';
import { getTheme } from '@fluentui/react';

interface IAppLayoutClassNames {
  appLayout: string;
  main: string;

  menuIcon: string;
}

export const getClassNames = (): IAppLayoutClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    appLayout: {
      display: 'flex',
      backgroundColor: theme.palette.white,
    },

    main: {
      overflow: 'hidden',
      position: 'relative',
      flex: 1,
    },

    menuIcon: {
      fontSize: theme.fonts.xLarge.fontSize,
      cursor: 'pointer',
      position: 'absolute',
      top: 10,
      left: 10,
      zIndex: 2,
    },
  })
}