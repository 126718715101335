import { mergeStyleSets } from "@fluentui/react";

interface IBulletPointsLessonClassNames {
  bulletPointsLessonContainer: string;
  bulletPointsLessonScrollContainer: string;
  pointList: string;
  point: string;
}

export const getClassNames = (): IBulletPointsLessonClassNames => {
  return mergeStyleSets({
    bulletPointsLessonContainer: {
      overflow: 'hidden',
      flex: 1,
      paddingBottom: 10,
      margin: '0px 20px',
    },

    bulletPointsLessonScrollContainer: {
      overflow: 'auto',
      width: '100%',
      height: '100%',
      paddingRight: 10,
    },

    pointList: {
      marginLeft: 20,
    },

    point: {
      ':not(:last-child)': {
        marginBottom: 10,
      }
    },
  });
}