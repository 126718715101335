import { getClassNames } from './Buttons.classNames';
import PrimaryButton from '../../../../../../common/components/Buttons/PrimaryButton/primary-button';
import DefaultButton from '../../../../../../common/components/Buttons/DefaultButton/default-button';
import { useAppDispatch } from '../../../../../../store/store';
import { deleteCourseInError } from '../../../../../../store/slices/courses';

interface IButtonsProps {
  onCancelClick: () => void;
  // type: 'Learning' | 'Lesson' | 'LearningError' | undefined;
  courseIdToDelete: string;
}

const Buttons = (props: IButtonsProps) => {
  const dispatch = useAppDispatch();
  const classes = getClassNames();

  async function onDeleteCourse() {
    try {
      props.onCancelClick();
      await dispatch(deleteCourseInError({courseIdToDelete: props.courseIdToDelete}));
    } catch(err) {
      console.error(err);
    }
  }

  function onCancelClick() {
    props.onCancelClick();
  }

  return (
    <div className={classes.buttonsContainer}>
      <DefaultButton onClick={onCancelClick}>Cancel</DefaultButton>
      <PrimaryButton 
        onClick={onDeleteCourse}
      >Delete</PrimaryButton>
    </div>
  )
}

export default Buttons;