import { FormEvent, createRef } from "react";
import { getTheme, IDropdown, IRefObject } from "@fluentui/react";
import { getClassNames } from "./dropdown.classNames";
import { Dropdown as FluentUIDropdown, IDropdownOption, IDropdownStyles } from "@fluentui/react";
import { BorderRadius } from "../../../utils/constants/styles";
import { DropdownThemes } from "../../../utils/constants/dropdown-themes";
import { Icons } from "../../../utils/constants/icons";
import { IconNames } from "../../models/Icons/icon-names";
import SelectedOption from "../../../assets/Icons/Dropdown/SelectedOption/selected-option";
import CaretDown from "../../../assets/Icons/Dropdown/CaretDown/caret-down";

export interface IDropdownProps {
  isGroup?: boolean;
  height?: number,
  itemHeight?: number;
  title?: string;
  options: IDropdownOption[];
  isMultiSelect?: boolean;
  theme: DropdownThemes;
  placeholder?: string;
  showAlwaysPlaceholder?: boolean;
  iconName?: IconNames;
  onChange: (e: FormEvent<HTMLDivElement>, option?: IDropdownOption) => void;
  onRenderTitle?: (options: any) => JSX.Element;
  selectedKey?: string | number | string[] | number[] | null | undefined;
  selectedKeys?: string[] | number[] | null | undefined;
  caretWidth?: string;
  disabled?: boolean;
  fontWeight?: 400 | 700;
}

const Dropdown = (props: IDropdownProps) => {
  const theme = getTheme();
  const classes = getClassNames(props);
  const dropdownRef: IRefObject<IDropdown> | undefined = createRef();

  const getStyles = (): Partial<IDropdownStyles> => {
    switch (props.theme) {
      case DropdownThemes.LIGHT: return {
        root: {
          width: '100%',
        },

        title: {
          height: props.height || '100%',
          color: theme.palette.black,
          fontWeight: props.fontWeight || theme.fonts.xLarge.fontWeight,
          border: `1px solid ${theme.palette.neutralQuaternary}`,
          borderRadius: BorderRadius,
          paddingLeft: 10,
          paddingRight: 0,

          '&:hover': {
            borderColor: theme.palette.neutralQuaternary,
          }
        },
        
        callout: {
          borderTop: `2px solid ${theme.palette.themePrimary}`,
          borderRadius: `0 0 ${BorderRadius} ${BorderRadius}`,
          '.ms-Callout-main': {
            borderRadius: BorderRadius,
          },
          '*::-webkit-scrollbar': {
            width: 6,
          },
        },

        caretDownWrapper: {
          width: props.caretWidth || '18%',
          height: '100%',
          right: 0,
        },

        dropdown: {
          '&:focus::after': {
            borderRadius: BorderRadius
          },

          '&:hover': {
            borderColor: theme.palette.neutralQuaternary,
            '.ms-Dropdown-title': {
              borderColor: theme.palette.neutralQuaternary,
            }
          }
        },

        dropdownItem: {
          minHeight: 'unset',
          height: 'fit-content',
          padding: '8px 8px'
        },

        dropdownItemSelected: {
          backgroundColor: '#fff',
          minHeight: 'unset',
          height: 'fit-content',
          padding: '8px 8px'
        },
      };

      case DropdownThemes.DARK: return {
        root: {
          width: '100%',
          borderRadius: BorderRadius,
        },

        title: {
          height: props.height,
          border: 'none',
          background: props.disabled ? theme.palette.neutralLighter : theme.palette.neutralLighterAlt,
          color: (props.disabled && theme.palette.neutralTertiary) || 'unset',
          borderRadius: BorderRadius,
          paddingLeft: 10,
          paddingRight: 0,
        },

        callout: {
          borderTop: `2px solid ${theme.palette.themePrimary}`,
          borderRadius: `0 0 ${BorderRadius} ${BorderRadius}`,
          '.ms-Callout-main': {
            borderRadius: BorderRadius,
          },
          '*::-webkit-scrollbar': {
            width: 6,
          },
        },

        caretDownWrapper: {
          width: props.caretWidth || '14%',
          height: '100%',
          right: 0,
          padding: '0 5px'
        },

        caretDown: {
          fill: props.disabled ? theme.palette.red : theme.palette.neutralLighterAlt,

          '*': {
            fill: props.disabled ? theme.palette.red : theme.palette.neutralLighterAlt,
          }
        },

        dropdownItem: {
          minHeight: 'unset',
          height: 'fit-content',
          padding: '8px 8px !important',
        },
        
        dropdownItemSelected: {
          backgroundColor: '#fff',
          minHeight: 'unset',
          height: 'fit-content',
          padding: '8px 8px !important',
        },

        dropdown: {
          '&:focus::after': {
            borderRadius: BorderRadius
          }
        },
      };

      case DropdownThemes.COLORED: return {
        root: {
          width: '100%',
        },

        title: {
          height: props.height || '100%',
          color: theme.palette.white,
          background: props.disabled ? theme.palette.neutralTertiary : theme.palette.themePrimary,
          fontWeight: theme.fonts.xLarge.fontWeight,
          border: props.disabled ? `1px solid ${theme.palette.neutralTertiary}` : `1px solid ${theme.palette.themePrimary}`,
          borderRadius: BorderRadius,
          paddingLeft: 10,
          paddingRight: 0,

          '&:hover': {
            borderColor: theme.palette.themePrimary,
          }
        },
        
        callout: {
          borderTop: `2px solid ${theme.palette.themePrimary}`,
          borderRadius: `0 0 ${BorderRadius} ${BorderRadius}`,
          '.ms-Callout-main': {
            borderRadius: BorderRadius,
          },
          '*::-webkit-scrollbar': {
            width: 6,
          },
        },

        caretDownWrapper: {
          width: '13%',
          height: '100%',
          right: 0,
        },

        dropdown: {
          '&:active': {
            color: theme.palette.white,
            '.ms-Dropdown-title': {
              color: theme.palette.white,
            }
          },

          '&:focus': {
            color: theme.palette.white,
            '.ms-Dropdown-title': {
              color: theme.palette.white,
            }
          },

          '&:focus::after': {
            borderRadius: BorderRadius
          },

          '&:hover': {
            borderColor: theme.palette.themePrimary,
            '.ms-Dropdown-title': {
              color: theme.palette.white,
              borderColor: theme.palette.themePrimary,
            }
          }
        },

        dropdownItem: {
          minHeight: 'unset',
          height: 'fit-content',
          padding: '8px 8px'
        },

        dropdownItemSelected: {
          backgroundColor: '#fff',
          minHeight: 'unset',
          height: 'fit-content',
          padding: '8px 8px'
        },
      }
    }
  };

  const Icon = props.iconName && Icons[props.iconName];

  const onRenderTitle = (options: any) => {
    if (props.placeholder && props.showAlwaysPlaceholder) return (
      <div className={classes['placeholder-container']}>
        {Icon && <Icon />}
        <span className={classes['placeholder']}>{props.placeholder}</span>
      </div>
    );
    if (options.length) return (
      <div className={classes['title-container']}>
        {Icon && <Icon />}
        <span className={classes['placeholder']}>{options[0].text}</span>
      </div>
    );
    return <div></div>;
  };

  const onRenderPlaceholder = (options: any) => {
    if (props.placeholder) return (
      <div className={classes['placeholder-container']}>
        {Icon && <Icon />}
        <span className={classes['placeholder']}>{props.placeholder}</span>
      </div>
    );
    if (options.length) return (
      <div className={classes['title-container']}>
        {Icon && <Icon />}
        <span className={classes['placeholder']}>{options[0].text}</span>
      </div>
    );
    return <div></div>;
  };

  const onRenderOption = (option: any) => {
    if (props.isMultiSelect) return <span className={classes['option']} style={{padding: '8px 0px'}}>{option.text}</span>;
    if (dropdownRef && dropdownRef.current && dropdownRef.current.selectedOptions && dropdownRef.current.selectedOptions.length > 0) {
      if (option.key === dropdownRef.current.selectedOptions[0].key) return (
        <div className={classes['selected-option-container']}>
          <SelectedOption />
          <span className={classes['option']}>{option.text}</span>
        </div>
      );
      else return <span className={classes['option']}>{option.text}</span>;
    };
    return <span className={classes['option']}>{option.text}</span>;
  };

  const onRenderCaretDown = () => {
    return <div className={classes['caret-down-container']}><CaretDown theme={props.theme} size={props.theme === DropdownThemes.COLORED ? 24 : 20} /></div>
  };

  const fluentUIDropdown = <FluentUIDropdown
    className={classes['dropdown']}
    componentRef={dropdownRef}
    styles={getStyles()} 
    multiSelect={props.isMultiSelect} 
    options={props.options} 
    onChange={props.onChange}
    onRenderTitle={props.onRenderTitle || onRenderTitle}
    onRenderPlaceholder={props.onRenderTitle || onRenderPlaceholder}
    placeholder={props.placeholder}
    selectedKey={props.selectedKey}
    selectedKeys={props.selectedKeys}
    onRenderOption={onRenderOption}
    onRenderCaretDown={onRenderCaretDown}
    calloutProps={{ directionalHintFixed: true }}
  />;

  const dropdown = props.isGroup ? (
    <div className={classes.groupInput}>
      <h5 className={classes.titleLabel}>{props.title}</h5>
      {fluentUIDropdown}
    </div>
  ) : (
    fluentUIDropdown
  )
 
  return dropdown
}

export default Dropdown;