import { mergeStyleSets } from '@fluentui/react';
import { getTheme } from '@fluentui/react';
import { BorderRadius } from '../../../../utils/constants/styles';

interface ICardClassNames{
  card: string;
  cardTop: string;
  iconContainer: string;
  cardBottom: string;
  btnUpload: string;
  divider: string;
  description: string;
}

export const getClassNames = (): ICardClassNames => {
  const theme = getTheme();

    return mergeStyleSets({
      card: {
        width: 400,
        height: 480,
        background: getTheme().palette.white,
        borderTopLeftRadius: BorderRadius,
        borderTopRightRadius: BorderRadius,
        borderBottom: `3px solid ${theme.palette.themePrimary}`,
        display: 'flex',
        flexDirection: 'column',
      },

      cardTop: {
        flex: '0 0 50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },

      iconContainer: {
        width: 90,
        aspectRatio: '1 / 1',
        padding: 15,
        borderRadius: 10,
        background: theme.palette.themeTertiary,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      },
      
      cardBottom: {
        background: theme.palette.neutralLight,
        flex: '0 0 50%',
        padding: 25,
        display: 'flex',
        flexDirection: 'column',
        gap: 25,
      },
      
      btnUpload: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 16,
      },

      divider: {
        borderTop: `1px solid ${theme.palette.themePrimary}`,
      },

      description: {
        fontSize: 18,
      }
    });
};