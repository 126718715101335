import { BaseCourseDto } from "../../api-client";
import { IBaseCourse } from "../../common/models/Learnings/course";
import { IAuthor } from "../../common/models/Learnings/author";

export const CourseListMapper = (courses: BaseCourseDto[], authors: IAuthor[]): IBaseCourse[] => {
  const data: IBaseCourse[] = [];
  courses.forEach((course) => {
    console.log('course', course);
    const author = authors.find(el => el.username === course.createdBy);
    if (!author) return;

    data.push({
      id: course.id || '',
      title: course.title || '',
      category: course.category?.categoryTranslations?.find(categoryTranslation => categoryTranslation.language === 'it-IT')?.value || '',
      created: course.created || '',
      createdBy: author,
      status: course.status || 'Draft',
      generationProgress: course.generationProgress || 0,
      statusInfo: course.statusInfo || { justGenerated: false },
      errors: course.errors || [],
    });
  });
  return data;
};