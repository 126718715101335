import { getTheme } from "@fluentui/react";

export default function Pause() {
  const theme = getTheme();

  return (
    <svg width="100%" height="100%" viewBox="0 0 32 36" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.75 0C1.67893 0 0 1.67893 0 3.75V32.25C0 34.3211 1.67893 36 3.75 36H10.25C12.3211 36 14 34.3211 14 32.25V3.75C14 1.67893 12.3211 0 10.25 0H3.75ZM2.5 3.75C2.5 3.05964 3.05964 2.5 3.75 2.5H10.25C10.9404 2.5 11.5 3.05964 11.5 3.75V32.25C11.5 32.9404 10.9404 33.5 10.25 33.5H3.75C3.05964 33.5 2.5 32.9404 2.5 32.25V3.75ZM21.75 0C19.6789 0 18 1.67893 18 3.75V32.25C18 34.3211 19.6789 36 21.75 36H28.25C30.3211 36 32 34.3211 32 32.25V3.75C32 1.67893 30.3211 0 28.25 0H21.75ZM20.5 3.75C20.5 3.05964 21.0596 2.5 21.75 2.5H28.25C28.9404 2.5 29.5 3.05964 29.5 3.75V32.25C29.5 32.9404 28.9404 33.5 28.25 33.5H21.75C21.0596 33.5 20.5 32.9404 20.5 32.25V3.75Z" fill={theme.palette.neutralLight}/>
    </svg>
  )
}