import { IDropdownOption } from "@fluentui/react";
import { LessonTypeDto } from "../../api-client";

export const lessonTypesOptions: IDropdownOption[] = [
    {
        key: LessonTypeDto.Text,
        text: "Text"
    },
    {
        key: LessonTypeDto.BulletPoints,
        text: "Bullet Points"
    },
    {
        key: LessonTypeDto.Quiz,
        text: "Quiz"
    },
    {
      key: LessonTypeDto.FlashCard,
      text: "Flash Card"
  },
];