import { getTheme, mergeStyleSets } from "@fluentui/react";
import { BorderRadius } from "../../../../../../../../utils/constants/styles";

interface IFlashLessonClassNames {
  flashCardLessonContainer: string;
  flashCardContainer: string;
  answer: string;
}

export const getClassNames = (): IFlashLessonClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    flashCardLessonContainer: {
      overflow: 'auto',
      flex: 1,
      paddingBottom: 10,
      margin: '0px 20px',
    },

    flashCardContainer: {
      overflow: 'auto',
      width: '100%',
      height: '100%',
      paddingRight: 10,
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
    },

    answer: {
      padding: 10,
      background: theme.palette.themeLight,
      borderRadius: BorderRadius,
      display: 'flex',
      alignItems: 'center',
      gap: 10,
    },

    hr: {
      borderTop: `1px solid ${theme.palette.neutralLight}`
    }
  });
}