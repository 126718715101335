import { useRef, useEffect } from "react";

const useTextAreaRef = () => {
  const title = useRef<HTMLTextAreaElement>(null);
  const question = useRef<HTMLTextAreaElement>(null);
  const answer = useRef<HTMLTextAreaElement>(null);
  const explanation = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (title.current && question.current) {
      title.current.style.height = `${title.current.scrollHeight}px`;
      question.current.style.height = `${question.current.scrollHeight}px`;
    }
    if (question.current) {
      question.current.style.height = `${question.current.scrollHeight}px`;
    }
    if (answer.current) {
      answer.current.style.height = `${answer.current.scrollHeight}px`;
    }
    if (explanation.current) {
      explanation.current.style.height = `${explanation.current.scrollHeight}px`;
    }
  }, [title, question, answer, explanation]);

  return {
    title,
    question,
    answer,
    explanation
  }
};

export default useTextAreaRef;