// import styles
import { getClassNames } from "./header.classNames";

// import components
import Title from "./Title/title";
import Options from "./Options/options";
import Banners from "./Banners/Banners";

import { useSelector } from 'react-redux';
import { RootState } from "../../../../../store/store";
import { StatusNames } from "../../../../../common/models/Learnings/status";

interface IHeaderProps {
  onConfigureModalClick: () => void;
  onScheduleModalClick: () => void;
  onQuizModalClick: () => void;
  onDeleteClick: (type: 'Learning' | 'Lesson') => void;
  onEditLessonClick: () => void;
  onGenerateLessonClick: () => void;
  showGenerateFromAI: boolean;
}

const Header = (props: IHeaderProps) => {
  const title = useSelector((state: RootState) => state.courses.course?.title);
  const status = useSelector((state: RootState) => state.courses.course?.status);
  const justGenerated = useSelector((state: RootState) => state.courses.course?.statusInfo?.justGenerated);
  const errors = useSelector((state: RootState) => state.courses.course?.errors);

  const classes = getClassNames();

  return (
    <div className={classes.header}>
      <Title title={title || ''} status={status || StatusNames.GenError} />

      <Banners
        status={status}
        errors={errors}
        justGenerated={!!justGenerated}
      />  
        
      <Options
        onConfigureModalClick={props.onConfigureModalClick}
        onScheduleModalClick={props.onScheduleModalClick}
        onQuizModalClick={props.onQuizModalClick}
        onDeleteClick={props.onDeleteClick}
        onEditLessonClick={props.onEditLessonClick}
        onGenerateLessonClick={props.onGenerateLessonClick}
        showGenerateFromAI={props.showGenerateFromAI}
      />
    </div>
  )
};

export default Header;