import { useRef, useState } from "react";
import Modal from "../../../../../../../../../../common/components/Modal/modal";
import Input from "../../../../../../../../../../common/components/Inputs/Input/input";
import { CSSTransition } from "react-transition-group";
import { ANIMATION_DURATION } from "../../../../../../../../../../utils/constants/styles";
import { getClassNames } from "./EmbedForm.classNames";
import Buttons from "./components/Buttons/buttons";

interface IEmbedFormProps {
  showEmbedForm: boolean;
  closeEmbedForm: () => void;
  saveEmbedContent: (data: string) => void;
}

export default function EmbedForm(props: IEmbedFormProps) {
  const [embed, setEmbed] = useState<string>('');
  const [error, setError] = useState<string>('');
  const modalRef = useRef<HTMLDivElement>(null);
  const classes = getClassNames();

  function validate(): boolean {
    if (embed.startsWith('<iframe') && embed.endsWith('</iframe>')) return true;    
    if (embed.startsWith('<') && embed.endsWith('>')) return true;
    if (embed.startsWith('http')) return true;
    return false;
  }

  return (
    <CSSTransition
      nodeRef={modalRef}
      timeout={ANIMATION_DURATION}
      in={props.showEmbedForm}
      classNames={{
        enter: classes.enter,
        enterActive: classes.enterActive,
        exit: classes.exit,
        exitActive: classes.exitActive
      }}
      unmountOnExit
    >
      <Modal
        title=""
        nodeRef={modalRef}
        animation={props.showEmbedForm}
        width={500}
        onModalClose={() => {
          props.closeEmbedForm();
          setEmbed('');
          setError('');
        }}
      >
        <form
          className={classes.form}
          onSubmit={(e) => e.preventDefault()}
          onKeyDown={(e) => {
            if(e.key === 'Enter') {
              e.preventDefault();
            }
          }}
        >
          <Input
            id="embed"
            name="embed"
            title="Insert an iFrame or a URL"
            value={embed}
            onChange={(e) => {
              if (error) setError('');
              setEmbed(e.target.value);
            }}
          />
          <p className={classes.error}>{error}</p>
          <Buttons
            onSave={() => {
              if (!validate()) {
                setError('Please enter a valid iFrame or URL.');
                return;
              };
              props.saveEmbedContent(embed);
              props.closeEmbedForm();
              setEmbed('');
              setError('');
            }}
            onCancel={() => {
              props.closeEmbedForm();
              setEmbed('');
              setError('');
            }}
          />
        </form>
      </Modal>
    </CSSTransition>
  )
}