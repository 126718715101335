// import react
import { Dispatch } from "react";

// import styles
import { getClassNames } from "./date-time.classNames";

// import models and data
import { DropdownThemes } from "../../../../../../../../../../utils/constants/dropdown-themes";

// import components
import Dropdown from "../../../../../../../../../../common/components/Dropdown/dropdown";
import DatePicker from "./DatePicker/date-picker";
import TimePicker from "./TimePicker/time-picker";
import { IDropdownOption } from "@fluentui/react";
import { ActionTypes, IAction, IFormData } from "../../../../hooks/useForm";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../../../../store/store";

interface IDateTimeProps {
  formData: IFormData;
  dispatch: Dispatch<IAction>;
}

const DateTime = (props: IDateTimeProps) => {
  const classes = getClassNames();
  const { timeZones } = useSelector((state: RootState) => state.settings);

  return (
      <div className={classes.dateTimeContainer}>
        <div className={classes.dateGroupInput}>
          <h5 className={classes.titleLabel}>Start</h5>
          <DatePicker value={props.formData.startDate} dispatch={props.dispatch} />
        </div>
        <div className={classes.timeGroupInput}>
          <h5 className={classes.titleLabel}>Time</h5>
          <TimePicker dispatch={props.dispatch} time={props.formData.time} />
        </div>
        <div className={classes.timeZoneGroupInput}>
          <h5 className={classes.titleLabel}>Recipient Time Zone</h5>
          <Dropdown
            theme={DropdownThemes.LIGHT}
            options={timeZones.map(timeZone => ({key: timeZone.standardName || '', text: timeZone.displayName || '' }))}
            selectedKey={props.formData.timeZone}
            onChange={(e: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any>) => 
              props.dispatch({ type: ActionTypes.SET_TIME_ZONE, payload: { option: option?.key.toString() || '' } })}
            height={35}
            caretWidth="32px"
            fontWeight={400}
          />
        </div>
      </div>
  )
};

export default DateTime;