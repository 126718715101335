// import react
import { useRef, DragEvent, useContext } from "react";

import Body from "./Body/body";
import { getClassNames } from "./lesson.classNames";
import Header from "./Header/header";
import Footer from "./Footer/footer";
import AudioPlayer from "./AudioPlayer/audio-player";
import { LessonDto, LessonTypeDto } from "../../../../../../api-client";
import { RootState, useAppDispatch } from "../../../../../../store/store";
import { setLessonToDelete, setLessonToEdit } from "../../../../../../store/slices/courses";
import { useSelector } from "react-redux";
import { InfoContext } from "../../../../../../contexts/info-context";

interface ILessonProps {
  lesson: LessonDto;
  index: number;
  onPlayAudioLessonId: string;
  setAudioLessonId: (id: string) => void;
  onDragStart: (e: DragEvent<HTMLElement>) => void;
  onDrop: (e: DragEvent<HTMLElement>) => void;
  onDragEnd: (e: DragEvent<HTMLElement>) => void;
  onDragOver: (e: DragEvent<HTMLElement>) => void;
  onEditLessonClick: () => void;
  onDeleteClick: (type: 'Learning' | 'Lesson') => void;
}

const Lesson = (props: ILessonProps) => {
  const { info: { modifiedLessons } } = useContext(InfoContext);
  const dispatch = useAppDispatch();
  const hasAudio = useSelector((state: RootState) => state.courses.course?.hasAudio);
  const classes = getClassNames();

  const lessonContainerRef = useRef<HTMLDivElement>(null);

  return (
    <div 
      className={classes.lessonContainer}
      ref={lessonContainerRef}
      data-lesson-id={props.lesson.id}
      draggable
      onDragStart={props.onDragStart}
      onDragEnd={props.onDragEnd}
      onDragOver={props.onDragOver}
      onDrop={props.onDrop}
      >      
      <Header
        title={props.lesson.title || ''}
        lastModified={props.lesson.modified || ''}
        isModified={(props.lesson.id && modifiedLessons.includes(props.lesson.id)) || false}
        index={props.index}
      />
      <div className={classes.body}>
        <Body lesson={props.lesson} />
        <hr className={classes.hr} />
        {(props.lesson.type !== LessonTypeDto.Quiz && props.lesson.type !== LessonTypeDto.FlashCard) && hasAudio && (
          <>
            <AudioPlayer
              lesson={props.lesson}
              onPlayAudioLessonId={props.onPlayAudioLessonId}
              setAudioLessonId={props.setAudioLessonId}
            />
            <hr className={classes.hr} />
          </>
        )}
        <Footer
          onEditLessonClick={() => { 
            dispatch(setLessonToEdit(props.lesson));
            props.onEditLessonClick();
          }}
          onDeleteClick={() => {
            dispatch(setLessonToDelete(props.lesson));
            props.onDeleteClick('Lesson');
          }}
        />
      </div>
    </div>

  )
}

export default Lesson;