import { getTheme, mergeStyleSets } from "@fluentui/react";
import { BorderRadius, IFadeClassNames, FADE_HEIGHT_ERROR_DETAILS } from "../../../../../../utils/constants/styles";

export interface IFileDetailsClassNames extends IFadeClassNames {
  errorDetailsContainer: string;
    showDetails: string;
    chevronDownMed: string;
    errorContainer: string;
    groupInput: string;
    textInput: string;
    titleLabel: string;
    enterDone: string;
    exitDone: string;
}

export const getClassNames = (showDetails: boolean): IFileDetailsClassNames => {
    const theme = getTheme();

    return mergeStyleSets({
      errorDetailsContainer:{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
      },

      showDetails: {
          width: 'fit-content',
          display: 'flex',
          gap: 10,
          alignItems: 'flex-end',
          cursor: 'pointer',
          userSelect: 'none'
      },

      chevronDownMed: {
        fontSize: 12,
        marginBottom: 3,
        transform: showDetails ? 'rotate(-180deg)' : 'rotate(0)',
        transition: 'all 400ms',  
      },

      errorContainer: {
        overflow: 'hidden',
        display: 'flex',
        gap: 10,
      },

      groupInput: {
          display: 'flex',
          flexDirection: 'column',
          gap: 5,
          flex: 1,
      },

      textInput: {
          width: '100%',
          border: 'none',
          background: theme.palette.neutralLighterAlt,
          borderRadius: BorderRadius,
          fontSize: theme.fonts.large.fontSize,
          padding: '5px 10px',
          outline: 'none'
      },

      titleLabel: {
          color: theme.palette.neutralSecondary,
          fontWeight: theme.fonts.medium.fontWeight
      },

      ...FADE_HEIGHT_ERROR_DETAILS.classNames,
    });
};