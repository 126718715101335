// import react
import { Dispatch, useState } from "react";

// import styles
import { getClassNames } from "./add-schedule.classNames";

// import components
import PrimaryButton from "../../../../../../../../common/components/Buttons/PrimaryButton/primary-button";
import DefaultButton from "../../../../../../../../common/components/Buttons/DefaultButton/default-button";

// import data
import Recipient from "./components/Recipient/recipient";
import DateTime from "./components/DateTime/date-time";
import Frequency from "./components/Frequency/frequency";
import { IFormData, IAction, ActionTypes } from "../../hooks/useForm";
import { useAppDispatch } from "../../../../../../../../store/store";
import { addSchedule, editSchedule } from "../../../../../../../../store/slices/courses";
import { ScheduleDto, ScheduleStatusDto } from "../../../../../../../../api-client";
import { windowsId2Iana } from "../../../../../../../../utils/helpers/helpers";

interface IAddScheduleProps {
  addScheduleRef: React.RefObject<HTMLDivElement>;
  formData: IFormData;
  dispatch: Dispatch<IAction>;
  onCloseNewScheduleClick: () => void;
}

const AddSchedule = (props: IAddScheduleProps) => {
  const dispatch = useAppDispatch();
  const classes = getClassNames();
  const [isEdit] = useState<boolean>(!!props.formData.recipient);

  async function onAddClick() {
    const newSchedule: ScheduleDto = {
      recipient: {
        id: props.formData.recipient?.key?.toString() || '',
        displayName: props.formData.recipient?.text,
        type: props.formData.recipient?.userType,
        profilePicture: ''
      },
      startDate: props.formData.startDate?.set({hour: props.formData.time?.getHours(), minute: props.formData.time?.getMinutes()})
        .setZone(windowsId2Iana(props.formData.timeZone)).toUTC().toISO()?.toString() || '',
      frequency: props.formData.frequency || 0,
      status: ScheduleStatusDto.Draft,
      timeZoneId: props.formData.timeZone,
    };

    try {
      isEdit ? await dispatch(editSchedule(newSchedule)) : await dispatch(addSchedule(newSchedule));
      props.dispatch({ type: ActionTypes.SET_INITIAL_STATE, payload: {} });
      props.onCloseNewScheduleClick();
    } catch (err) {
      console.error(err);
    }
  }

  function onCancelClick() {
    props.onCloseNewScheduleClick();
    props.dispatch({ type: ActionTypes.SET_INITIAL_STATE, payload: {} })
  }

  return (
    <div ref={props.addScheduleRef} className={classes.addSchedule}>
      <h4 className={classes.title}>Add scheduling</h4>
      <div className={classes.addScheduleFormContainer}>
        <Recipient
          formData={props.formData}
          dispatch={props.dispatch}
        />
        <DateTime
          formData={props.formData}
          dispatch={props.dispatch}
        />
        <Frequency
          formData={props.formData}
          dispatch={props.dispatch}
        />
      </div>
      <div className={classes.buttons}>
        <DefaultButton type="button" onClick={onCancelClick}>Cancel</DefaultButton>
        <PrimaryButton
          type="button"
          disabled={!props.formData.recipient || !props.formData.startDate || !props.formData.time || !props.formData.timeZone || !props.formData.frequency}
          // onClick={isEdit ? props.onEditScheduleClick : props.onAddNewScheduleClick}
          onClick={onAddClick}
        >
            {isEdit ? 'Edit' : 'Add'}
        </PrimaryButton>
      </div>
    </div>
  )
};

export default AddSchedule;