import { IIconProps } from "../../../../common/models/Icons/icon-props";

const ChangeLanguage = (props: IIconProps): JSX.Element => {
  return (
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.49927 0.996094C7.22313 0.996094 6.99927 1.21995 6.99927 1.49609C6.99927 1.77224 7.22312 1.99609 7.49927 1.99609L11.0001 1.9961V3.86451C11.0001 5.0307 9.83153 5.99976 8.50011 5.99976C8.22396 5.99976 8.00011 6.22361 8.00011 6.49976C8.00011 6.7759 8.22396 6.99976 8.50011 6.99976C10.2095 6.99976 12.0001 5.7425 12.0001 3.86451V1.4961C12.0001 1.21996 11.7763 0.9961 11.5001 0.996099L7.49927 0.996094ZM5.9617 4.30749C5.88408 4.12114 5.70203 3.99975 5.50017 3.99973C5.2983 3.99972 5.11623 4.12109 5.03859 4.30743L0.038592 16.3074C-0.0676166 16.5623 0.0529221 16.8551 0.307823 16.9613C0.562723 17.0675 0.85546 16.9469 0.961669 16.692L2.49881 13.0029H8.50009L10.0386 16.6967C10.1447 16.9516 10.4375 17.0722 10.6924 16.966C10.9473 16.8599 11.0679 16.5671 10.9617 16.3122L9.29949 12.3214C9.29673 12.3143 9.29381 12.3073 9.29074 12.3003L5.9617 4.30749ZM8.08359 12.0029H2.91547L5.50004 5.79995L8.08359 12.0029ZM13.5013 0.996128C13.7775 0.996128 14.0013 1.21999 14.0013 1.49613V5.00144H15.4999C15.776 5.00144 15.9999 5.2253 15.9999 5.50144C15.9999 5.77758 15.776 6.00144 15.4999 6.00144H14.0013V12.4961C14.0013 12.7723 13.7775 12.9961 13.5013 12.9961C13.2252 12.9961 13.0013 12.7723 13.0013 12.4961V1.49613C13.0013 1.21999 13.2252 0.996128 13.5013 0.996128Z" fill="#232323"/>
  </svg>
  )
};

export default ChangeLanguage;