// react
import { useState, useCallback, useEffect, PropsWithChildren } from "react";

// microsoft teams
import * as MsTeams from "@microsoft/teams-js";

// msal
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../../utils/settings/msal";

// hocs
import Teams from "../Teams/Teams";
import Web from "../Web/Web";


export default function Authentication(props: PropsWithChildren) {
  const [isTeams, setIsTeams] = useState<boolean>();
  const [msalInstance, setMsalInstance] = useState<PublicClientApplication>();

  const isMsTeams = useCallback(async () => {
    try {
      await MsTeams.app.initialize();
      const context = await MsTeams.app.getContext();
      setIsTeams(!!context);
    }
    catch (err) {
      console.error(err);
      setIsTeams(false);
      const instance = new PublicClientApplication(msalConfig);
      await instance.initialize();
      setMsalInstance(instance);
    }
  }, []);

  useEffect(() => {
    isMsTeams();
  }, [isMsTeams]);

  return (
    <>
      { isTeams ? 
          <Teams {...props} /> : 
        msalInstance && (
          <MsalProvider instance={msalInstance}>
            <Web {...props} />
          </MsalProvider>
        )
      }
    </>
  )
}