// import react
import { FormEvent, useState } from "react";

// import types
import { ScratchCourseDto } from "../../../../../../api-client";
import { ICreateFromScratchForm } from "../../../../../../common/models/Create/form-data";

// import hooks
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store/store";
import { FromScratchValidation } from "../../../../../../utils/middlewares/validations/from-scratch-validations";
import { AxiosError, isAxiosError } from "axios";
import { timeout } from "../../../../../../utils/helpers/helpers";

const useGenerate = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const coursesClientApi = useSelector((state: RootState) => state.clients.clients.coursesClientApi);
  const navigate = useNavigate();

  // const mockUploadCourse = () => new Promise(resolve => setTimeout(resolve, 4000));

  const generateCourse = async (e: FormEvent, state: ICreateFromScratchForm): Promise<void> => {
    e.preventDefault();
    setErrorMessage('');

    try {
      if (!coursesClientApi) return;

      const fromScratchValidation = new FromScratchValidation(state);
      fromScratchValidation.validate();
      
      setLoadingMessage('Creating the course...');
      setIsLoading(true);

      await coursesClientApi.addCourseFromScratch(state as ScratchCourseDto);

      setLoadingMessage('Redirecting to the learnings page...');
      await timeout(3000);
      navigate('/learnings');
    } catch (err) {
      console.error(err);

      if (isAxiosError(err)) {
        setErrorMessage(((err as AxiosError).response?.data as any).Detail);
      } else {
        setErrorMessage((err as Error).message);
      }
      
    } finally {
      setIsLoading(false);
      setLoadingMessage('');
    }
  };

  return { generateCourse, isLoading, loadingMessage, errorMessage };
};

export default useGenerate;