import { mergeStyleSets } from "@fluentui/react"
import { IFadeClassNames, FADE } from "../../../../../../utils/constants/styles";

interface IEditModalClassNames extends IFadeClassNames {
  form: string;
  leftContainer: string;
  editLessonContainer: string;
}

export const getClassNames = (): IEditModalClassNames => {
  return mergeStyleSets({
    form: {
      overflow: 'hidden',
      width: '100%',
      flex: 1,
      display: 'flex',
      gap: 20,
    },

    leftContainer: {
      flex: 1,
      paddingTop: 22,
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
    },

    editLessonContainer: {
      overflow: 'hidden',
      flex: 1,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      gap: 20,
    },

    ...FADE.classNames,
  });
};