import { mergeStyleSets, getTheme } from "@fluentui/react";

interface IRecipientClassNames {
  recipientContainer: string;
  titleLabel: string;
}

export const getClassNames = (): IRecipientClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    recipientContainer: {
      flex: 2,
      display: 'flex',
      flexDirection: 'column',
      gap: 5,
    },

    titleLabel: {
      color: theme.palette.neutralSecondary,
      fontWeight: theme.fonts.medium.fontWeight
    },
  });
}