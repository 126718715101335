import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import { RoutePaths } from "../../utils/settings/router";
import AppLayout from "../../common/layouts/AppLayout/app-layout";
import { Create } from "../../pages/Create";
import { Learnings } from "../../pages/Learnings/Learnings";
import { Learning } from "../../pages/Learnings/Learning";
import { Settings } from "../../pages/Settings";

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <AppLayout />,
      children: [
        {
          path: '/',
          element: <Navigate to={RoutePaths.LEARNINGS} replace />
        },
        {
          path: RoutePaths.CREATE,
          element: <Create />,
        },
        {
          path: RoutePaths.LEARNINGS,
          element: <Learnings />,
          children: [

          ]
        },
        {
          path: RoutePaths.LEARNINGS,
          element: <Learning />,
          children: [
            {
              path: `${RoutePaths.LEARNINGS}/:id/:lang?`,
              element: <Learning />
            },
          ]
        },
        {
          path: RoutePaths.SETTINGS,
          element: <Settings />,
        },
      ]
    }
  ]
);

export default function Router() {
  return <RouterProvider router={router} />
};