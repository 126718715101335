import { mergeStyleSets, getTheme } from '@fluentui/react';
import { BorderRadius } from '../../../../../../../utils/constants/styles';

interface IDetailsClassNames{
  courseDetailsContainer: string;
  courseOptionsContainer: string;
  groupInput: string;
  groupInputLessonsType: string;
  textInput: string;
  textArea: string;
  dropdown: string;
  titleLabel: string;
  lessonsType: string;
}

export const getClassNames = (): IDetailsClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    courseDetailsContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
    },

    courseOptionsContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '1fr 1fr',
      gap: 10
    },

    groupInput: {
      display: 'flex',
      flexDirection: 'column',
      gap: 5
    },

    groupInputLessonsType: {
      flex: 1,
    },

    textInput: {
      width: '100%',
      border: 'none',
      background: theme.palette.neutralLighterAlt,
      borderRadius: BorderRadius,
      fontSize: theme.fonts.large.fontSize,
      padding: '5px 10px',
      outline: 'none'
    },

    textArea: {
      resize: 'none',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderBottom: `3px solid ${theme.palette.themePrimary}`,
    },

    dropdown: {
      border: 'none',
      background: theme.palette.neutralLighterAlt,
    },

    titleLabel: {
      color: theme.palette.neutralSecondary,
      fontWeight: theme.fonts.medium.fontWeight
    },

    lessonsType: {
      gridColumn: '1 / -1',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 10,
    }
  })
}