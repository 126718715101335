import { useEffect, useState, useRef } from 'react';
import Buttons from "./Buttons/buttons";
import { getClassNames } from "./confirmation-dialog.classNames";
import { CSSTransition } from 'react-transition-group';
import { ANIMATION_DURATION } from '../../../../../utils/constants/styles';

interface IConfirmationDialogProps {
  nodeRef: React.RefObject<HTMLDivElement>;
  animation: boolean;
  type: 'Close' | 'Generate';
  onClose: () => void;
  onCancel: () => void;
}

const ConfirmationDialog = (props: IConfirmationDialogProps) => {
  const [animation, setAnimation] = useState<boolean>(false);
  const classes = getClassNames();

  const message = props.type === 'Close' ? 
    'Attention: all the unsaved changes will be lost.' : 
    'Do you want to generate the final quiz?';

  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setAnimation(props.animation);
  }, [props.animation]);

  return (
    <div ref={props.nodeRef} className={classes.dialogBackground}>
      <CSSTransition
        nodeRef={modalRef}
        timeout={ANIMATION_DURATION}
        in={animation}
        classNames={{
          enter: classes.enter,
          enterActive: classes.enterActive,
          exit: classes.exit,
          exitActive: classes.exitActive
        }}
        mountOnEnter
        unmountOnExit
      >
        <div className={classes.dialog}>
          <span>{message}</span>
          <Buttons onClose={props.onClose} onCancel={props.onCancel} type={props.type} />
        </div>
      </CSSTransition>
    </div>
  )
};

export default ConfirmationDialog;