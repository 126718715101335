// import styles
import { getClassNames } from "./header.classNames";

// import components
import PrimaryButton from "../../../../../../../../common/components/Buttons/PrimaryButton/primary-button";
import Search from "../../../../../../../../assets/Icons/Controls/Search/search";
import Filter from "../../../../../../../../assets/Icons/Controls/Filter/filter";

// import utils
import { IconNames } from "../../../../../../../../common/models/Icons/icon-names";
import { ChangeEvent } from "react";

interface IHeaderProps {
  onShowNewScheduleClick: () => void;
  searchSchedule: string;
  updateSearchSchedule: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Header = (props: IHeaderProps) => {
  const classes = getClassNames();

  return (
    <div className={classes.headerContainer}>
      <div className={classes.filterContainer}>
        <Filter />
        Filter
      </div>
      <div className={classes.searchBarContainer}>
        <input
          type="text"
          className={classes.searchBar}
          placeholder="Search"
          value={props.searchSchedule}
          onChange={props.updateSearchSchedule}  
        />
        <Search />
      </div>
      <PrimaryButton
        type="button"
        iconName={IconNames.ADD_SCHEDULE}
        onClick={props.onShowNewScheduleClick}
      >
        Add scheduling
      </PrimaryButton>
    </div>
  )
};

export default Header;