// import react
import { ChangeEvent, Dispatch } from 'react';

// import models
import Input from '../../../../../../../../common/components/Inputs/Input/input';
import { ActionTypes, IAction } from '../../../FromScratchModal';
import { CreateFormKeys } from '../../../../../../../../common/models/Create/form-data';

interface ICourseDetailsProps {
  details: { title: string; description: string; }
  dispatch: Dispatch<IAction>;
}

const CourseDetails = ({ details, dispatch }: ICourseDetailsProps) => {
  const onInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch({ type: ActionTypes.UPDATE_INPUT, payload: { name: e.currentTarget.name, value: e.currentTarget.value }, })
  };

  return (
    <>
      <Input 
        id={CreateFormKeys.TITLE}
        name={CreateFormKeys.TITLE}
        value={details.title}
        onChange={onInputChange}
        title='Title'
      />

      <Input
        id={CreateFormKeys.DESCRIPTION}
        name={CreateFormKeys.DESCRIPTION}
        value={details.description}
        onChange={onInputChange}
        title='Description'
        isTextArea
      />
    </>
  )
};

export default CourseDetails;