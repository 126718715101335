// import react
import { useReducer } from "react";

// import models
import { LanguageStatusDto, SupportedLanguageDto } from "../../../../../../../api-client";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../store/store";

export interface ILanguagesForm {
  languageCollection: SupportedLanguageDto[];
  languageToAdd: {key: string, text: string} | undefined;
  isDropdownDisabled: boolean;
}

export enum ActionTypes {
  UPDATE_DROPDOWN = 'UPDATE_DROPDOWN',
  ADD_LANGUAGE = 'ADD_LANGUAGE',
  DELETE_LANGUAGE = 'DELETE_LANGUAGE',
  CHECK_TOGGLE = 'CHECK_TOGGLE',
}

interface ILanguageDropdownPayload {
  languageToAdd: {key: string, text: string};
}

export interface IAction {
  type: ActionTypes;
  payload?: ILanguageDropdownPayload | number;
}

function reducer(state: ILanguagesForm, action: IAction): ILanguagesForm {
  switch (action.type) {
    case (ActionTypes.UPDATE_DROPDOWN): return {
      ...state,
      languageToAdd: (action.payload as ILanguageDropdownPayload).languageToAdd,
    };

    case (ActionTypes.ADD_LANGUAGE): {
      const newLanguageCollection = [...state.languageCollection];
      newLanguageCollection.push({ isPublishable: false, language: state.languageToAdd?.key, status: LanguageStatusDto.Draft });
      return { 
        ...state,
        languageCollection: newLanguageCollection,
        languageToAdd: undefined,
        isDropdownDisabled: true,
      }
    };

    case (ActionTypes.DELETE_LANGUAGE): {
      const newLanguageCollection = [...state.languageCollection];
      newLanguageCollection.splice((action.payload as number), 1);
      return {
        ...state,
        languageCollection: newLanguageCollection,
        isDropdownDisabled: !(newLanguageCollection[action.payload as number] === state.languageToAdd),
      }
    };

    case (ActionTypes.CHECK_TOGGLE): {
      const newLanguageCollection = [...state.languageCollection];
      newLanguageCollection[(action.payload as number)] = {
        isPublishable: !newLanguageCollection[(action.payload as number)].isPublishable,
        language: newLanguageCollection[(action.payload as number)].language,
        status: newLanguageCollection[(action.payload as number)].status,
      };
      return {
        ...state,
        languageCollection: newLanguageCollection,
      }
    };

    default: return state;
  } 
}

const useLanguagesForm = () => {
  const supportedLanguages = useSelector((state: RootState) => state.courses.course?.supportedLanguages);

  const initialState: ILanguagesForm = {
    languageCollection: supportedLanguages || [],
    languageToAdd: undefined,
    isDropdownDisabled: false,
  };

  const [languagesForm, languagesFormDispatch] = useReducer(reducer, initialState);

  const isLanguageCollectionChanged = (): boolean => {
    if(languagesForm.languageCollection.length !== initialState.languageCollection.length) return true;
    let result: boolean = false;
    languagesForm.languageCollection.forEach((lang, i) => {
      const initLang = initialState.languageCollection[i];
      result = result || (lang.isPublishable !== initLang.isPublishable || lang.language !== initLang.language);
    });
    return result;
  };

  return {
    languagesForm,
    languagesFormDispatch,
    isLanguageCollectionChanged
  }
  
  // const [languageCollection, setLanguageCollection] = useState<SupportedLanguageDto[]>(initialLanguageCollection);
  // const [languageToAdd, setLanguageToAdd] = useState<{ key: string, text: string } | null>(null);

  // const isChanged = (): boolean => {
  //   if(languageCollection.length !== initialLanguageCollection.length) return true;
  //   let result: boolean = false;
  //   languageCollection.forEach((lang, i) => {
  //     const initLang = initialLanguageCollection[i];
  //     result = result || (lang.isPublishable !== initLang.isPublishable || lang.language !== initLang.language);
  //   });
  //   return result;
  // };

  // const onLanguageDropdownChange = (event: FormEvent<HTMLDivElement>, option?: IDropdownOption) => 
  //   option && option.key && setLanguageToAdd({key: option.key.toString(), text: option.text});
  
  // const onAddLanguage = () => {
  //   if (!languageToAdd) return;
    
  //   const newLanguageCollection = [...languageCollection];
  //   newLanguageCollection.push({isPublishable: false, language: languageToAdd.key, status: LanguageStatusDto.Draft});
  //   setLanguageCollection(newLanguageCollection);
  //   setLanguageToAdd(null);
  // };

  // const onCheckChange = (event: React.MouseEvent<HTMLElement, MouseEvent>, index: number) => {
  //   const newLanguageCollection = [...languageCollection];
  //   newLanguageCollection[index] = {
  //     isPublishable: !newLanguageCollection[index].isPublishable,
  //     language: newLanguageCollection[index].language,
  //     status: newLanguageCollection[index].status,
  //   };
  //   setLanguageCollection(newLanguageCollection);
  // };

  // const onLanguageDelete = (index: number) => {
  //   const newLanguageCollection = [...languageCollection];
  //   newLanguageCollection.splice(index, 1);
  //   setLanguageCollection(newLanguageCollection);
  // };

  // // this will be an async function that will post the data on the server
  // const onSubmit = (e: FormEvent) => {
  //   e.preventDefault();
  //   saveData(formData.languageCollection, formData.languages);
  // };
}

export default useLanguagesForm;