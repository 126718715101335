// import react
import { useReducer, useRef } from "react";

// import styles
import { getClassNames } from "./UploadModal.classNames";
import { CSSTransition } from "react-transition-group";

// import components
import Modal from "../../../../../common/components/Modal/modal";
import Loading from "../../../../../common/components/Loading/Loading";
import Buttons from "./components/Buttons/buttons";
import ChooseFile from "./components/ChooseFile/ChooseFile";
import Details from "./components/Details/details";
import FileDetails from "./components/FileDetails/FileDetails";
import ErrorMessage from "./components/ErrorMessage/ErrorMessage";

// import types
import { CreateFormKeys, ICreateFromUploadForm } from "../../../../../common/models/Create/form-data";

// import hooks
import useGenerate from "./hooks/useGenerate";
import { ANIMATION_DURATION } from "../../../../../utils/constants/styles";
import { getFileType } from "../../../../../utils/helpers/helpers";

export enum ActionTypes {
  UPDATE_INPUT = 'UPDATE_INPUT',
  UPDATE_FILE = 'UPDATE_FILE',
}

export interface IInputPayload {
  name: string;
  value: string | string[] | number | boolean | undefined;
}

export interface IAction {
  type: ActionTypes;
  payload: string | File | IInputPayload | null;
}

export function reducer(state: ICreateFromUploadForm, action: IAction): ICreateFromUploadForm {
  switch (action.type) {
    case (ActionTypes.UPDATE_FILE): return {...state, file: action.payload as File };
    case (ActionTypes.UPDATE_INPUT): return {
      ...state,
      createForm: {
        ...state.createForm,
        [(action.payload as IInputPayload).name]: (action.payload as IInputPayload).value,
      }
    };

    default: return state;
  } 
}

interface IUploadModalProps {
  onUploadModalCloseClick: () => void;
  nodeRef: React.RefObject<HTMLDivElement>;
  animation: boolean;
}

// form initial state
const initialFormDataState: ICreateFromUploadForm = {
  createForm: {
    title: '',
    description: '',
    categoryId: '',
    lessonsNumber: 0,
    documentLanguage: '',
    pages: '',
    chapterHeaderPrefix: '',
    typeOfLessons: [],
    generateAudio: true,
  },
  file: undefined,
};

const UploadModal = (props: IUploadModalProps) => {
  const [state, dispatch] = useReducer(reducer, initialFormDataState);
  const { generateCourse, isLoading, loadingMessage, errorMessage } = useGenerate();
  const classes = getClassNames();

  const loadingRef = useRef<HTMLDivElement>(null);

  return (
    <Modal
      nodeRef={props.nodeRef}
      animation={props.animation}
      width={580}
      title="Upload file"
      onModalClose={props.onUploadModalCloseClick}
    >
      <form className={classes['form']} onSubmit={(e) => generateCourse(e, state)}>
      <CSSTransition
        nodeRef={loadingRef}
        timeout={ANIMATION_DURATION}
        in={isLoading}
        classNames={{
          enter: classes.enter,
          enterActive: classes.enterActive,
          exit: classes.exit,
          exitActive: classes.exitActive
        }}
        unmountOnExit
      >
        <Loading
          nodeRef={loadingRef}
          animation={isLoading}
          label={loadingMessage}  
        />
      </CSSTransition>
        <ChooseFile dispatch={dispatch} />
        <Details formData={state.createForm} dispatch={dispatch} />
        <FileDetails
          pages={state.createForm[CreateFormKeys.PAGES]}
          chapterHeaderPrefix={state.createForm[CreateFormKeys.CHAPTER_HEADER_PREFIX]}
          fileType={state.file && getFileType(state.file)}
          dispatch={dispatch}
        />
        <ErrorMessage errorMessage={errorMessage} />
        <Buttons onCancelClick={props.onUploadModalCloseClick} />
      </form>
    </Modal>
  )
};

export default UploadModal;