// import react
import { ChangeEvent, useState } from 'react';

// import styles
import { getClassNames } from "./attachment.classNames";

// import models
import { IAttachment } from '../../../../../../../../common/models/Learnings/attachment';
import { MIMETypes } from '../../../../../../../../utils/constants/file-types';

// import components
import Loading from '../../../../../../../../common/components/Loading/Loading';
import EmbedForm from './components/EmbedForm/EmbedForm';
import { Icon } from '@fluentui/react';
import PrimaryButton from '../../../../../../../../common/components/Buttons/PrimaryButton/primary-button';
import VideoPlayer from './components/VideoPlayer/VideoPlayer';

interface IAttachmentProps {
  isLoading: boolean;
  attachment: IAttachment | null;
  onChooseFileClick: () => void;
  inputFileRef: React.RefObject<HTMLInputElement>;
  onFileChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onAttachmentClear: () => void;
  onAddEmbedContent: (data: string) => void;
}

const Attachment = (props: IAttachmentProps) => {
  const [showEmbedForm, setShowEmbedForm] = useState<boolean>(false);
  const classes = getClassNames(props.attachment?.data);

  function getEmbeddedContent() {
    if (props.attachment && props.attachment.contentType === 'embedding' && props.attachment.data) {
      if (props.attachment.data.includes('<iframe')) { // is an iFrame
        if (props.attachment.data.includes('vimeo.com') && props.attachment.data.includes('position:absolute')) {
          return (
            <div className={classes.embedVimeoResponsiveContent} dangerouslySetInnerHTML={{__html: props.attachment.data}} />
          )
        } else {
          return (
            <div className={classes.embedContentContainer}>
              <div className={classes.embedContent} dangerouslySetInnerHTML={{__html: props.attachment.data}} />
            </div>
          )
        }
      } else {
        return (
          <div className={classes.embedContentContainer}>
            <div className={classes.embedUrlContent}>
              <iframe title='embedding' src={props.attachment.data} />
            </div>
          </div>
        )
      }
    } 
  }

  return (
    <div className={classes.uploadFileContainer}>
      {props.attachment && <Icon className={classes.closeIcon} iconName="ChromeClose" onClick={props.onAttachmentClear} />}
      {props.isLoading && <Loading label="Loading attachment..." noBackground={true} />}
     
      {props.attachment && props.attachment.contentType === 'embedding' && props.attachment.data && getEmbeddedContent()}

      {props.attachment && props.attachment.contentType === MIMETypes.MP4 && (
        <VideoPlayer attachment={props.attachment} />
      )}

      {props.attachment && 
        (props.attachment.contentType === MIMETypes.JPEG || props.attachment.contentType === MIMETypes.PNG) && (
          <img className={classes.image} src={props.attachment.src} alt='' />
        )
      }

      {(!props.isLoading && !props.attachment) && (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 10,}}>
          <PrimaryButton width={140} height={60} onClick={props.onChooseFileClick}>Upload a photo or video</PrimaryButton>
          <p>or</p>
          <PrimaryButton width={140} height={40} onClick={() => setShowEmbedForm(true)}>Embed a content</PrimaryButton>
          {/* <p>
            <span className={classes.uploadLabel} onClick={props.onChooseFileClick}>Upload a file</span>
            <span> or </span>
            <span className={classes.uploadLabel} onClick={() => setShowEmbedForm(true)}>Embed a content</span>
          </p> */}
          <input type="file" accept={`.jpg,.jpeg,.png,${MIMETypes.JPEG},${MIMETypes.PNG},.mp4,${MIMETypes.MP4}`} ref={props.inputFileRef} style={{display: 'none'}} onChange={props.onFileChange}/>
        </div>
      )}

      <EmbedForm
        showEmbedForm={showEmbedForm}
        closeEmbedForm={() => setShowEmbedForm(false)}
        saveEmbedContent={props.onAddEmbedContent}
      />
    </div>
  )
};

export default Attachment;