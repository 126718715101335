import { mergeStyleSets } from '@fluentui/react';

interface ILessonsClassNames{
  lessons: string;
}

export const getClassNames = (): ILessonsClassNames => {
    return mergeStyleSets({
      lessons: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, 340px)',
        justifyContent: 'space-between',
        gap: 30,
        overflowY: 'auto',
        padding: '0px 40px 35px',
      }
    });
};