import { getClassNames } from './buttons.classNames';
import DefaultButton from '../../../../../../../../../common/components/Buttons/DefaultButton/default-button';

interface IButtonsProps {
  action: () => void;
}

const Buttons = (props: IButtonsProps) => {
  const classes = getClassNames();

  return (
    <div className={classes.buttonsContainer}>
      <DefaultButton onClick={props.action}>Ok</DefaultButton>
    </div>
  )
}

export default Buttons;