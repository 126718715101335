import { Icon, Spinner, SpinnerSize, getTheme } from "@fluentui/react";
import { getClassNames } from "./Banners.classNames";
import { CourseStatusDto, MLErrorDto } from "../../../../../../api-client";
import { clearError, clearJustGenerated } from "../../../../../../store/slices/courses";
import { useAppDispatch } from "../../../../../../store/store";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { useRef, useState } from "react";

interface IErrorBannerProps {
  status: CourseStatusDto | null | undefined;
  errors: MLErrorDto[] | null | undefined;
  justGenerated: boolean;
}

export default function Banners({ status, errors, justGenerated }: IErrorBannerProps) {
  const dispatch = useAppDispatch();
  const classes = getClassNames();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const bannerRef = useRef<HTMLDivElement>(null);
  const errorRef = useRef<HTMLDivElement>(null);
  const justGeneratedRef = useRef<HTMLDivElement>(null);

  const nodeRef = (status === CourseStatusDto.GenError && errorRef) || (justGenerated && justGeneratedRef) || null;
  const key = (status === CourseStatusDto.GenError && 'error') || (justGenerated && 'justGenerated') || null;

  const message = (status === CourseStatusDto.GenError && errors && errors.length > 0 && errors[errors.length - 1].description) || 'The content generated by the AI may not be correct, check the output of the pills generated from your document';


  async function onCancelClick() {
    try {
      setIsLoading(true);
      if (status === CourseStatusDto.GenError) {
        await dispatch(clearError()).unwrap();
      } else if (justGenerated) {
        await dispatch(clearJustGenerated()).unwrap();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <CSSTransition
      nodeRef={bannerRef}
      key='banner'
      timeout={500}
      in={status === CourseStatusDto.GenError || justGenerated}
      classNames={{
        enter: classes.enter,
        enterActive: classes.enterActive,
        exit: classes.exit,
        exitActive: classes.exitActive,
        appear: classes.enter,
        appearActive: classes.enterActive
      }}
      appear
      unmountOnExit
    >
      <div ref={bannerRef} className={classes.banner}>
        <SwitchTransition>
          <CSSTransition
            nodeRef={nodeRef}
            key={key}
            timeout={500}
            classNames={{
              enter: classes.enter,
              enterActive: classes.enterActive,
              exit: classes.exit,
              exitActive: classes.exitActive,
            }}>
              <div
                ref={nodeRef}
                className={status === CourseStatusDto.GenError ? classes.errorBannerContainer : classes.justGeneratedBannerContainer}>
                <p className={classes.message} title={message}>{message}</p>
                {isLoading ? 
                  <Spinner size={SpinnerSize.small} styles={{ 
                    circle: {
                      borderColor: `${getTheme().palette.neutralTertiary} transparent transparent transparent`,
                    },
                   }} />: 
                  <Icon iconName="ChromeClose" className={classes.closeIcon} onClick={onCancelClick} />}
              </div>
          </CSSTransition>
        </SwitchTransition>
      </div>
    </CSSTransition>
   

  // <TransitionGroup
  //   style={{
  //     position: 'relative',
  //     flex: 1,
  //     // display: 'flex',
  //     // flexDirection: 'column',
  //     // gap: 10,
  //     // overflow: 'visible',
  //     // zIndex: 1,
  //     // maxHeight: 100,
  //   }}>
  //   {banners.map((banner, index) => (
  //     <CSSTransition
  //       nodeRef={errorRefs[index]}
  //       key={index}
  //       timeout={200}
  //       classNames={{
  //         enter: classes.enter,
  //         enterActive: classes.enterActive,
  //         exit: classes.exit,
  //         exitActive: classes.exitActive
  //       }}
  //     >
  //       <div
  //         ref={errorRefs[index]}
  //         className={banner.isJustGenerated ? classes.justGeneratedBannerContainer : classes.errorBannerContainer}>
  //         <p className={classes.message}>{banner.message}</p>
  //         <Icon iconName="ChromeClose" className={classes.closeIcon} onClick={onCancelClick} />
  //       </div>
  //     </CSSTransition>
  //   ))}
  // </TransitionGroup>





    // <CSSTransition
    //   nodeRef={errorBannerRef}
    //   timeout={200}
    //   in={status === CourseStatusDto.GenError}
    //   classNames={{
    //     enter: classes.enter,
    //     enterActive: classes.enterActive,
    //     exit: classes.exit,
    //     exitActive: classes.exitActive
    //   }}
    //   unmountOnExit
    // >
    //   <div ref={errorBannerRef} className={classes.bannerContainer}>
    //     <Icon iconName="ChromeClose" className={classes.closeIcon} onClick={onCancelClick} />
    //     <p className={classes.errorMessage}>{message}</p>
    //   </div>
    // </CSSTransition>
  );
}

// Il contenuto generato dall'AI potrebbe non essere corretto, verifica l'output delle pillole generate a partire dal tuo documento
// The content generated by the AI ​​may not be correct, check the output of the pills generated from your document