import { mergeStyleSets } from "@fluentui/react";
import { FADE_HEIGHT, IFadeClassNames } from '../../../../../../utils/constants/styles';

interface IScheduleModalClassNames extends IFadeClassNames {
  form: string;
  buttons: string;
  enterDone: string;
  exitDone: string;
}

export const getClassNames = (): IScheduleModalClassNames => {
  return mergeStyleSets({
    form: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },

    buttons: {
      width: '100%',
      display:'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: 10,
      marginTop: 20,
    },

    ...FADE_HEIGHT.classNames,
  });
}