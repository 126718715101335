import { mergeStyleSets, getTheme } from '@fluentui/react';

interface IErrorMessageClassNames{
  errorMessageContainer: string;
  errorMessage: string;
}

export const getClassNames = (): IErrorMessageClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    errorMessageContainer: {
      width: '100%',
      flex: '0 0 20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    errorMessage: {
      fontSize: theme.fonts.small.fontSize,
      fontWeight: theme.fonts.xLarge.fontWeight,
      color: theme.palette.themePrimary,
    }
  })
}