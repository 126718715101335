// import styles
import { Dispatch } from "react";
import Input from "../../../../../../../../../../common/components/Inputs/Input/input";
import { ActionTypes, IAction, IFormData } from "../../../../hooks/useForm";
import { getClassNames } from "./frequency.classNames";

interface IFrequencyProps {
  formData: IFormData;
  dispatch: Dispatch<IAction>;
  // setFrequency: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const Frequency = (props: IFrequencyProps) => {
  const classes = getClassNames();

  const getValue = () => {
    if (props.formData.frequency === undefined || props.formData.frequency === 0) return '';
    else return props.formData.frequency.toString();
  }

  return (
    <div className={classes.frequencyContainer}>
      <Input
        id="frequency"
        name="frequency"
        value={getValue()}
        onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => 
          props.dispatch({ type: ActionTypes.SET_FREQUENCY, payload: { value: e.target.value } })}
        title="Frequency"
        flex
        fontSize={14}
        height={36}
      />
      <span className={classes.daysLabel}>days</span>
    </div>
  )
};

export default Frequency;