import { useEffect, useState, useRef } from 'react';
import Buttons from "./Buttons/buttons";
import { getClassNames } from "./error-dialog.classNames";
import { CSSTransition } from 'react-transition-group';
import { ANIMATION_DURATION } from '../../../../../utils/constants/styles';

interface IDeleteDialogProps {
  nodeRef: React.RefObject<HTMLDivElement>;
  animation: boolean;
}

const ErrorDialog = (props: IDeleteDialogProps) => {
  const [animation, setAnimation] = useState<boolean>(false);
  const classes = getClassNames();

  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setAnimation(props.animation);
  }, [props.animation]);

  return (
    <div ref={props.nodeRef} className={classes.dialogBackground}>
      <CSSTransition
        nodeRef={modalRef}
        timeout={ANIMATION_DURATION}
        in={animation}
        classNames={{
          enter: classes.enter,
          enterActive: classes.enterActive,
          exit: classes.exit,
          exitActive: classes.exitActive
        }}
        mountOnEnter
        unmountOnExit
      >
        <div className={classes.dialog}>
          <span>Something went wrong...</span>
          <Buttons />
        </div>
      </CSSTransition>
    </div>
  )
};

export default ErrorDialog;