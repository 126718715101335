// import react
import { Dispatch } from "react";

// import styles
import { getClassNames } from "./language-options.classNames";

// import components
import Collection from "./components/Collection/collection";
import Languages from "./components/Languages/languages";
import { IAction, ILanguagesForm } from "../../hooks/useLanguagesForm";

interface ILanguageOptionsProps {
  languagesForm: ILanguagesForm;
  dispatch: Dispatch<IAction>;
}

const LanguageOptions = (props: ILanguageOptionsProps) => {
  const classes = getClassNames();

  return (
    <div className={classes.languagesOptionsContainer}>
      <Collection
        languagesForm={props.languagesForm}
        dispatch={props.dispatch}
      />

      <hr className={classes.divider} />

      <Languages
        languagesForm={props.languagesForm}
        dispatch={props.dispatch}
      />
    </div>
  )
};

export default LanguageOptions;