import { useRef, useEffect } from "react";

const useTextAreaRef = (formBulletPoints?: string[] | null | undefined) => {
  const title = useRef<HTMLTextAreaElement>(null);
  const introduction = useRef<HTMLTextAreaElement>(null);
  const bulletPoints = useRef<HTMLTextAreaElement[]>([]);
  const newBulletPoint = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (title.current && introduction.current) {
      title.current.style.height = `${title.current.scrollHeight}px`;
      introduction.current.style.height = `${introduction.current.scrollHeight}px`;
    }

    if (bulletPoints.current) {
      bulletPoints.current.forEach(bulletPoint => {
        bulletPoint.style.height = `auto`;
        bulletPoint.style.height = `${bulletPoint.scrollHeight}px`;
      })
    }

    if (newBulletPoint.current) {
      newBulletPoint.current.style.height = `auto`;
      newBulletPoint.current.style.height = `${newBulletPoint.current.scrollHeight}px`;
    }

  }, [title, introduction, bulletPoints, formBulletPoints]);

  return {
    title,
    introduction,
    bulletPoints,
    newBulletPoint
  }
};

export default useTextAreaRef;