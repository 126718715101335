// import styles
import { getClassNames } from "./list-item.classNames";

// import models
import { StatusNames } from "../../../../../../common/models/Learnings/status";

// import utils
import { Status } from "../../../../../../utils/constants/status";
import { Persona } from "@fluentui/react";
import { PersonaSize, getTheme } from "@fluentui/react";

export interface IListItemProps {
  item: any;
  columnKey: string; 
  statusInfo: StatusNames;
  generationProgress?: number;
}

const ListItem = (props: IListItemProps) => {
  const classes = getClassNames(props);

  const getItem = () => {
    if (props.columnKey === 'Status') {
      if (props.item[props.columnKey] === StatusNames.Generating) {
        console.log('props.generationProgress', props.generationProgress);
        const statusLabel = props.generationProgress === 0 ? 'Starting' : props.generationProgress === 100 ? 'Finalizing' : 'Generating';
        return (
          <div className={classes.generatingContainer} title={`${props.generationProgress}%`}>
            {Status[props.statusInfo]?.icon()}
            <span className={classes.statusSpanLabel}>{statusLabel}</span>
            <div className={classes.generationProgress}/>
          </div>
        )
      } else return (
        <div className={classes.statusContainer}>
          {Status[props.statusInfo]?.icon()}
          <span className={classes.statusSpanLabel}>{props.item[props.columnKey] === StatusNames.GenError ? 'Error' : props.item[props.columnKey]}</span>
        </div>
      )
    };

    if (props.columnKey === 'Created') return (
      <div className={classes.status}>
        <span>{new Date(props.item[props.columnKey]).toLocaleDateString('en-US', { day: '2-digit', month: 'long', year: 'numeric' })}</span>
      </div>
    );

    if (props.columnKey === 'Author') { 
      return (
        <div className={classes.status}>
          <Persona
            imageUrl={props.item.Author.profilePicture ? `data:image/png;base64,${props.item.Author.profilePicture}` : ''}
            size={PersonaSize.size32}
            text={props.item.Author.displayName || props.item.Author.username}
            initialsColor={getTheme().palette.themePrimary}
            imageAlt={props.item.Author.displayName ? props.item.Author.displayName : ''}
            // className={persona}
          />

        </div>
      );}

    return (
    <div className={classes.status}>
      <span>{props.item[props.columnKey]}</span>
    </div>  
    )
  }

  return (
    getItem()
  )
}

export default ListItem;