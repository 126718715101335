import { mergeStyleSets, keyframes } from '@fluentui/react';

interface IListClassNames{
  listContainer: string;
  list: string;
  row: string;
}

export const getClassNames = (): IListClassNames => {
  const fadeIn = keyframes({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  });

  return mergeStyleSets({
    listContainer: {
      overflow: 'hidden',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',

      animationName: fadeIn,
      animationDuration: '500ms',
      // animationFillMode: 'forwards',
    },

    list: {
      overflow: 'hidden',
      width: '100%',
    },

    row: {
      cursor: 'pointer',
      fontSize: 14,
    },
  });
};