import { getClassNames } from './Buttons.classNames';
import PrimaryButton from '../../../../../../../../../../common/components/Buttons/PrimaryButton/primary-button';
import DefaultButton from '../../../../../../../../../../common/components/Buttons/DefaultButton/default-button';

interface IButtonsProps {
  onClick: () => Promise<void>;
  action: () => void;
}

const Buttons = (props: IButtonsProps) => {
  const classes = getClassNames();

  return (
    <div className={classes.buttonsContainer}>
      <DefaultButton type='button' onClick={props.action}>Cancel</DefaultButton>
      <PrimaryButton type='button' onClick={() => {
        props.onClick();
        props.action();
      }}>Export</PrimaryButton>
    </div>
  )
}

export default Buttons;