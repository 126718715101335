import { mergeStyleSets, getTheme } from "@fluentui/react";
import { IFadeClassNames, FADE_HEIGHT } from "../../../../../../../../../../utils/constants/styles";

interface ILanguagesClassNames extends IFadeClassNames {
  languagesContainer: string;
  dropdownContainer: string;
  errorLabel: string;
  enterDone: string;
  exitDone: string;
}

export const getClassNames = (): ILanguagesClassNames => {
  const theme = getTheme();
  
  return mergeStyleSets({
    languagesContainer: {
      flex: '0 0 fit-content',
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
      marginBottom: 20,
    },

    dropdownContainer: {
      width: '100%',
      display: 'flex',
      gap: 20,
    },

    errorLabel: {
      fontSize: theme.fonts.small.fontSize,
      fontWeight: theme.fonts.xLarge.fontWeight,
      color: theme.palette.themePrimary,
      display: 'flex',
      flexDirection: 'column',
    },

    ...FADE_HEIGHT.classNames,
  });
}