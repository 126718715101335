// import styles
import { getClassNames } from "./input.classNames";

export interface IInputProps {
  isTextArea?: boolean;
  flex?: boolean;
  width?: number;
  id: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  title: string;
  fontSize?: number;
  height?: number;
}

const Input = (props: IInputProps) => {
  const classes = getClassNames(props);

  const input = props.isTextArea ? (
    <textarea 
      className={`${classes.textInput} ${classes.textArea}`}
      id={props.id}
      name={props.name}
      rows={5}
      value={props.value || ''} 
      onChange={props.onChange} 
    />
  ) : (
    <input 
      className={classes.textInput} 
      type="text" 
      id={props.id} 
      name={props.name} 
      onChange={props.onChange}
      value={props.value || ''}
    />
  );

  return (
    <div className={classes.groupInput}>
      <h5 className={classes.titleLabel}>{props.title}</h5>
      {input}
    </div>
  )
};

export default Input;