import { useRef } from 'react';

// import styles
import { getClassNames } from "./schedule-modal.classNames";

// import components
import Modal from "../../../../../../common/components/Modal/modal";
import Header from "./components/Header/header";
import AddSchedule from "./components/AddSchedule/add-schedule";
import List from "./components/List/list";
import DefaultButton from "../../../../../../common/components/Buttons/DefaultButton/default-button";

// import hooks
import useForm from "./hooks/useForm";
import useScheduleModal from "./hooks/useScheduleModal";
import useSearchSchedule from "./hooks/useSearchSchedule";
import { CSSTransition } from "react-transition-group";
import { ANIMATION_DURATION } from "../../../../../../utils/constants/styles";

interface IScheduleProps {
  onCloseSchedule: () => void;
  nodeRef: React.RefObject<HTMLDivElement>;
  animation: boolean;
}

const ScheduleModal = (props: IScheduleProps) => {
  const { formData, formDispatch } = useForm();
  const { searchSchedule, updateSearchSchedule } = useSearchSchedule();
  const { showNewSchedule, onShowNewScheduleClick, onCloseNewScheduleClick } = useScheduleModal();
  const classes = getClassNames();

  const addScheduleRef = useRef<HTMLDivElement>(null);

  return (
    <Modal
      nodeRef={props.nodeRef}
      animation={props.animation}
      title="Schedule"
      width={1200}
      onModalClose={props.onCloseSchedule}
    >
      <form className={classes.form}>
        <Header
          onShowNewScheduleClick={onShowNewScheduleClick}
          searchSchedule={searchSchedule}
          updateSearchSchedule={updateSearchSchedule}
        />
        <CSSTransition
          nodeRef={addScheduleRef}
          timeout={ANIMATION_DURATION}
          in={showNewSchedule}
          classNames={{
            enter: classes.enter,
            enterActive: classes.enterActive,
            enterDone: classes.enterDone,
            exit: classes.exit,
            exitActive: classes.exitActive,
            exitDone: classes.exitDone,
          }}
          unmountOnExit
        >
        <AddSchedule
            addScheduleRef={addScheduleRef}
            formData={formData}
            dispatch={formDispatch}
            onCloseNewScheduleClick={onCloseNewScheduleClick}
          />
        </CSSTransition>
        <List
          dispatch={formDispatch}
          onShowNewScheduleClick={onShowNewScheduleClick}
          searchSchedule={searchSchedule}
        />
        <div className={classes.buttons}>
          <DefaultButton type="button" onClick={props.onCloseSchedule}>Close</DefaultButton>
        </div>
      </form>
    </Modal>
  )
};

export default ScheduleModal;