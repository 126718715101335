import { mergeStyleSets, getTheme, keyframes } from "@fluentui/react";
import { BorderRadius } from "../../../../../../../../utils/constants/styles";

interface IUploadPhotoClassNames {
  uploadFileContainer: string;
  closeIcon: string;
  chooseFileContainer: string;
  fileContainer: string;
  deleteIcon: string;
  fileInfoContainer: string;
  fileName: string;
  uploadLabel: string;
  image: string;
  embedForm: string;
  video: string;

  embedContentContainer: string;
  embedVimeoResponsiveContent: string;
  embedUrlContent: string;
  embedContent: string;
}

export const getClassNames = (embedData: string | undefined): IUploadPhotoClassNames => {
  const theme = getTheme();

  const fadeIn = keyframes({
    from : {
      opacity: 0,
    },
    to: {
      opacity: 1,
    }
  });

  return mergeStyleSets({
    uploadFileContainer: {
      position: 'relative',
      height: '100%',
      background: theme.palette.neutralLighterAlt,
      borderRadius: BorderRadius,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    closeIcon: {
      position: 'absolute',
      top: 10,
      right: 10,
      cursor: 'pointer',
    },

    chooseFileContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      color: theme.palette.themePrimary,
      background: theme.palette.neutralLighterAlt,
      borderRadius: BorderRadius,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',

      animationName: fadeIn,
      animationFillMode: 'forwards',
      animationDuration: '500ms',
    },

    fileContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.black,
      background: theme.palette.white,
      borderRadius: BorderRadius,
      boxShadow: '0 0 2px 2px rgba(0, 0, 0, 0.1)'
    },

    deleteIcon: {
      position: 'absolute',
      top: 10,
      right: 10,
      fontSize: 16,
    },

    fileInfoContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: 20,
    },

    fileName: {
      fontWeight: theme.fonts.xLarge.fontWeight,
    },

    uploadLabel: {
      textDecoration: 'underline',
      cursor: 'pointer',
      animationName: fadeIn,
      animationFillMode: 'forwards',
      animationDuration: '500ms',
    },

    image: {
      width: '100%',
    },

    embedForm: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '80%',
      height: 200,
      borderRadius: BorderRadius,
      backgroundColor: theme.palette.white,
      transform: 'translate(-50%, -50%)',
      // padding: 20,
      display: 'flex',
      alignItems: 'center',
    },

    video: {
      width: '100%',
    },

    embedContentContainer: {
      width: '100%',
      height: '100%',
      padding: '40px 10px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },

    embedVimeoResponsiveContent: {
      position: 'relative',
      overflow: 'auto',
      width: '100%',
      height: '80%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',

      'iframe': {
        ...(embedData && !embedData.includes('width') && { width: '100%' }),
        ...(embedData && !embedData.includes('height') && { height: '100%' }),
        maxWidth: '100%',
        maxHeight: '100%',
        border: 'none',
        overflow: 'hidden',
      },
    },

    embedUrlContent: {
      width: '100%',
      height: '100%',

      'iframe': {
        ...(embedData && !embedData.includes('width') && { width: '100%' }),
        ...(embedData && !embedData.includes('height') && { height: '100%' }),
        maxWidth: '100%',
        maxHeight: '100%',
        border: 'none',
        overflow: 'hidden',
      },
    },

    embedContent: {
      overflow: 'auto',

      'iframe': {
        ...(embedData && !embedData.includes('width') && { width: '100%' }),
        ...(embedData && !embedData.includes('height') && { height: '100%' }),
        maxWidth: '100%',
        maxHeight: '100%',
        border: 'none',
        overflow: 'hidden',
      },
    }
  });
};