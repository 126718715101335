import { createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { IBaseCourse } from '../../common/models/Learnings/course';
import { IAuthor } from '../../common/models/Learnings/author';
import { CourseListMapper } from '../../utils/mappers/course-list';
import { BulletPointLessonDto, CourseDto, CourseStatusDto, FinalQuizLessonDto, GenerateLessonRequestDto, LessonDto, LessonTypeDto, QuizLessonDto, SafeQuizLessonDto, ScheduleDto, ScheduleStatusDto, SupportedLanguageDto, TextLessonDto, TranslateRequestDto } from '../../api-client';
import { RootState } from '../store';
import { IOptionsForm } from '../../pages/Learnings/Learning/components/Modals/ConfigureModal/hooks/useOptionsForm';
import { IQuizForm } from '../../pages/Learnings/Learning/components/Modals/ConfigureModal/hooks/useQuizForm';
import { DateTime } from 'luxon';
import { IAttachment } from '../../common/models/Learnings/attachment';

export enum ApiStatus {
  SUCCESS = 'SUCCESS',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  IDLE = 'IDLE',
}

export interface ICoursesState {
  courses: IBaseCourse[] | undefined;
  course: CourseDto | undefined;
  lessonToEdit: LessonDto | undefined;
  lessonToDelete: LessonDto | undefined;
  apiStatus: {
    startingStatus: {
      api: ApiStatus;
      loadingMessage: string;
    };
    courseStatus: {
      api: ApiStatus;
      loadingMessage: string,
    };
  };
}

export const getCourses = createAsyncThunk<IBaseCourse[], void>('/courses', async (_, thunkAPI) => {
  try {
    const { coursesClientApi, usersClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesClientApi || !usersClientApi) throw new Error();

    const stateCourses  = (thunkAPI.getState() as RootState).courses.courses;
    if (!!stateCourses) return stateCourses;


    // fetching the courses
    const courseResponse = await coursesClientApi.getCourses();
    const courses = courseResponse.data;

    // fetching the authors
    const promises: any = [];
    const uniqueAuthors = courses.filter((value, index, self) => 
      index === self.findIndex((t) => (
          t.createdBy === value.createdBy
      ))
    ).map(el => ({ createdBy: el.createdBy }));

    uniqueAuthors.forEach(el => promises.push(usersClientApi.apiTenantUsersUpnOrIdGet(el.createdBy || '')));
    const authorResponse = await Promise.allSettled(promises);
    const authors: IAuthor[] = authorResponse.map((result, index) => {
      console.log('uniqueAuthors[index]', uniqueAuthors[index]);
      if (result.status === "fulfilled") {
        return {...result.value.data, username: uniqueAuthors[index].createdBy};
      } else {
        console.warn(`Failed to fetch data for author: ${uniqueAuthors[index].createdBy}`);
        return {
          username: uniqueAuthors[index].createdBy,
        }
      }
    });

    console.log('authors', authors);

    // mapping and returning the courses
    return CourseListMapper(courses, authors);
  } catch (err) {
    console.error(err);
    throw err;
  }
});

export const getProfilePhotos = createAsyncThunk<IBaseCourse[], void>('/profilePhotos', async (_, thunkAPI) => {
  try {
    const { usersClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!usersClientApi) throw new Error();

    const { courses } = (thunkAPI.getState() as RootState).courses;
    if (!courses) throw new Error();

    // fetching the authors
    const promises: any = [];
    const uniqueAuthors = courses.filter((value, index, self) => 
      index === self.findIndex((t) => (
          t.createdBy?.id === value.createdBy?.id
      ))
    ).map(el => el.createdBy);
    uniqueAuthors.forEach(author => promises.push(usersClientApi.apiTenantUsersUpnOrIdPhotoGet(author?.username || '')));
    const photoResponses = await Promise.all(promises);
    
    const authorsWithPhotos: IAuthor[] = photoResponses.map((res, index) => {
      return {
        ...uniqueAuthors[index],
        profilePicture: res.data,
      };
    });

    return [...courses].map((course) => ({
      ...course,
      createdBy: authorsWithPhotos.find(el => el.id === course.createdBy?.id) || null,
    }));
  } catch (err) {
    console.error(err);
    throw err;
  }
});

export const getCourse = createAsyncThunk<CourseDto, {id: string, lang: string | undefined}>('/course', async ({ id, lang }, thunkAPI) => {
  try {
    const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesClientApi) throw new Error();
    
    const response = lang ? await coursesClientApi.getCourseByLang(id, lang) : await coursesClientApi.getCourse(id);
    if (!response.data.statusInfo) response.data.statusInfo = { justGenerated: false };
    return response.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
});

export const toggleHide = 
  createAsyncThunk<LessonDto, {lesson: LessonDto, lang: string | undefined}>('/toggleHide', async ({ lesson, lang }, thunkAPI) => {
    if (!lesson || !lesson.id) throw new Error();

    const { course } = (thunkAPI.getState() as RootState).courses;
      if (!course || !course.id || !course.documentLanguage || !course.lessons) throw new Error();

    try {
      const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
      if (!coursesClientApi) throw new Error();

      lesson.isHide ? await coursesClientApi.showLesson(course.id, lesson.id, lang || course.documentLanguage) :
        await coursesClientApi.hideLesson(course.id, lesson.id, lang || course.documentLanguage);

      const newLesson = {...lesson};
      newLesson.isHide = !newLesson.isHide;
      return newLesson;
    } catch (err) {
      console.error(err);
      throw err;
    }
  });

  export const deleteLesson = 
  createAsyncThunk<LessonDto[], {lang: string | undefined}>('/deleteLesson', async ({ lang }, thunkAPI) => {
    const { course, lessonToDelete } = (thunkAPI.getState() as RootState).courses;
    if (!course || !course.id || !course.documentLanguage || !course.lessons) throw new Error();
    if (!lessonToDelete || !lessonToDelete.id) throw new Error();

    try {
      const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
      if (!coursesClientApi) throw new Error();

      await coursesClientApi.deleteLesson(course.id, lessonToDelete.id, lang || course.documentLanguage);

      const newLessons = [...course.lessons];
      const lessonIndex = newLessons.findIndex(el => el.id === lessonToDelete.id);
      if (lessonIndex === -1) throw new Error();
      newLessons.splice(lessonIndex, 1);
      return newLessons;
    } catch (err) {
      console.error(err);
      throw err;
    }
  });

export const changeLessonOrder = createAsyncThunk<LessonDto[], { index: number, targetIndex: number }>('/changeLessonOrder',
  async ({ index, targetIndex }, thunkAPI) => {
    const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesClientApi) throw new Error();

    const { course } = (thunkAPI.getState() as RootState).courses;
    if (!course || !course.id || !course.lessons) return [];

    const newLessons = [...course.lessons];
    newLessons.splice(targetIndex, 0, newLessons.splice(index, 1)[0]);

    try {
      await coursesClientApi.changeOrder(course.id, newLessons.map(lesson => lesson.id || ''));
      return newLessons;
    } catch (err) {
      console.error(err);
      throw err;
    }
});

export const publishCourse = createAsyncThunk<void, string | undefined | null>('/publishCourse', async (learningProviderId, thunkAPI) => {
  try {
    const { course } = (thunkAPI.getState() as RootState).courses;
    if (!course || !course.id || !course.lessons) throw new Error();

    const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesClientApi) return;

    if (!learningProviderId) await coursesClientApi.publishCourseNoProvider(course.id);
    else await coursesClientApi.publishCourse(course.id, learningProviderId);
  } catch (err) {
    console.error(err);
    throw err;
  }
});

export const unpublishCourse = createAsyncThunk<void, string | undefined | null>('/unpublishCourse', async (learningProviderId, thunkAPI) => {
  try {
    const { course } = (thunkAPI.getState() as RootState).courses;
    if (!course || !course.id || !course.lessons) throw new Error();

    const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesClientApi) throw new Error();

    if (!learningProviderId) await coursesClientApi.unpublishCourseNoProvider(course.id);
    else await coursesClientApi.unpublishCourse(course.id, learningProviderId);
  } catch (err) {
    console.error(err);
    throw err;
  }
});

// export const publishCourse = createAsyncThunk<void, string | undefined | null>('/publishCourse', async (learningProviderId, thunkAPI) => {
//   if (!learningProviderId) throw new Error();

//   try {
//     const { course } = (thunkAPI.getState() as RootState).courses;
//     if (!course || !course.id || !course.lessons) throw new Error();

//     const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
//     if (!coursesClientApi) return;

//     await coursesClientApi.publishCourse(course.id, learningProviderId);
//   } catch (err) {
//     console.error(err);
//     throw err;
//   }
// });

// export const unpublishCourse = createAsyncThunk<void, string | undefined | null>('/unpublishCourse', async (learningProviderId, thunkAPI) => {
//   if (!learningProviderId) throw new Error();

//   try {
//     const { course } = (thunkAPI.getState() as RootState).courses;
//     if (!course || !course.id || !course.lessons) throw new Error();

//     const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
//     if (!coursesClientApi) throw new Error();

//     await coursesClientApi.unpublishCourse(course.id, learningProviderId);
//   } catch (err) {
//     console.error(err);
//     throw err;
//   }
// });

export const deleteCourse = createAsyncThunk<void, void>('/deleteCourse', async (_, thunkAPI) => {
  try {
    const { course } = (thunkAPI.getState() as RootState).courses;
    if (!course || !course.id) throw new Error();

    const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesClientApi) throw new Error();

    await coursesClientApi.archiveCourse(course.id);
  } catch (err) {
    console.error(err);
    throw err;
  }
});

export const deleteCourseInError = createAsyncThunk<IBaseCourse[], { courseIdToDelete: string }>('/deleteCourseInError', async ({ courseIdToDelete }, thunkAPI) => {
  try {
    const courses = (thunkAPI.getState() as RootState).courses.courses;
    if (!courses) throw new Error();
    const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesClientApi) throw new Error();

    await coursesClientApi.archiveCourse(courseIdToDelete);

    const newCourses = [...courses];
    const courseIndexToDelete = newCourses.findIndex(course => course.id === courseIdToDelete);
    if (courseIndexToDelete === -1) throw new Error();
    newCourses.splice(courseIndexToDelete, 1);
    return newCourses;
  } catch (err) {
    console.error(err);
    throw err;
  }
});

export const saveCourseOptions = createAsyncThunk<{ title: string, description: string, passingPercentage: number, isFinalQuizRepeatable: boolean }, { optionsForm: IOptionsForm, quizForm: IQuizForm, lang: string | undefined }>('/saveCourseOptions', 
  async ({ optionsForm, quizForm, lang }, thunkAPI) => {
    try {
      const { course } = (thunkAPI.getState() as RootState).courses;
      if (!course || !course.id) throw new Error();

      const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
      if (!coursesClientApi) throw new Error();

      const newCourse: CourseDto = { 
        ...course,
        title: optionsForm.title,
        description: optionsForm.description,
        passingPercentage: quizForm.passingPercentage,
        isFinalQuizRepeatable: quizForm.isFinalQuizRepeatable,
      };
      lang ? 
        await coursesClientApi.editCourseByLang(course.id, lang, newCourse) : 
        await coursesClientApi.editCourse(course.id, newCourse);
      return { ...optionsForm, ...quizForm };
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

export const saveLanguageCollection = createAsyncThunk<SupportedLanguageDto[], SupportedLanguageDto[]>('/saveLanguageCollection', 
  async (languageCollection, thunkAPI) => {
    try {
      const { course } = (thunkAPI.getState() as RootState).courses;
      if (!course || !course.id) throw new Error();

      const { coursesFunctionClientApi } = (thunkAPI.getState() as RootState).clients.clients;
      if (!coursesFunctionClientApi) throw new Error();

      const body: TranslateRequestDto = {
        courseId: course?.id || '',
        supportedLanguages: languageCollection,
      };
      await coursesFunctionClientApi.translateCourse(course?.id || '', body);
      return languageCollection;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

export const addSchedule = createAsyncThunk<ScheduleDto[], ScheduleDto>('/addSchedule', async (schedule, thunkAPI) => {
  try {
    const { course } = (thunkAPI.getState() as RootState).courses;
    if (!course || !course.id || !course.schedules) throw new Error();

    const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesClientApi) throw new Error();

    const response = await coursesClientApi.addSchedule(course.id, schedule);
    const newSchedule = response.data;
    const newSchedules = [...course.schedules];
    newSchedules.push(newSchedule);
    return newSchedules;
  } catch (err) {
    console.error(err);
    throw err;
  }
});

export const editSchedule = createAsyncThunk<ScheduleDto[], ScheduleDto>('/editSchedule', async (schedule, thunkAPI) => {
  try {
    const { course } = (thunkAPI.getState() as RootState).courses;
    if (!course || !course.id || !course.schedules) throw new Error();

    const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesClientApi) throw new Error();

    const schedules = [...course.schedules];
    const userSchedule = schedules.find(el => el.recipient.id === schedule.recipient.id && !el.archived);
    if (!userSchedule || !userSchedule.id) throw new Error();

    schedule.id = userSchedule.id;
    schedule.archived = userSchedule.archived;
    schedule.executions = userSchedule.executions;
    schedule.lessonsSent = userSchedule.lessonsSent;
    if (schedule.recipient.profilePicture === null ||
        schedule.recipient.profilePicture === undefined
    ) schedule.recipient = {...userSchedule.recipient, profilePicture: ''};

    await coursesClientApi.editSchedule(course.id, userSchedule.id, schedule);
    const newSchedules = [...course.schedules];
    const currentScheduleIndex = newSchedules.findIndex(el => el.id === userSchedule.id);
    if (currentScheduleIndex === -1) throw new Error();
    newSchedules[currentScheduleIndex] = schedule;
    return newSchedules;
  } catch (err) {
    console.error(err);
    throw err;
  }
});

export const deleteSchedule = createAsyncThunk<ScheduleDto[], ScheduleDto>('/deleteSchedule', async (schedule, thunkAPI) => {
  try {
    const { course } = (thunkAPI.getState() as RootState).courses;
    if (!course || !course.id || !course.schedules || !schedule.id) throw new Error();

    const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesClientApi) throw new Error();

    await coursesClientApi.archiveSchedule(course.id, schedule.id);
    const newSchedules = [...course.schedules];
    const deleteScheduleIndex = newSchedules.findIndex(el => ((el.id === schedule.id) && !el.archived));
    newSchedules[deleteScheduleIndex] = { ...newSchedules[deleteScheduleIndex], archived: true };
    return newSchedules;
  } catch (err) {
    console.error(err);
    throw err;
  }
});

export const sendSchedule = createAsyncThunk<ScheduleDto[], ScheduleDto>('/sendSchedule', async (schedule, thunkAPI) => {
  try {
    const { course } = (thunkAPI.getState() as RootState).courses;
    if (!course || !course.id || !course.schedules || !schedule.id) throw new Error();

    const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesClientApi) throw new Error();

    await coursesClientApi.sendSchedule(course.id, schedule.id);
    const newSchedules = [...course.schedules];
    const sendScheduleIndex = newSchedules.findIndex(el => ((el.id === schedule.id) && !el.archived));
    newSchedules[sendScheduleIndex] = { ...newSchedules[sendScheduleIndex], status: ScheduleStatusDto.Running };
    return newSchedules;
  } catch (err) {
    console.error(err);
    throw err;
  }
});

export const saveLesson = 
  createAsyncThunk<(BulletPointLessonDto | FinalQuizLessonDto | QuizLessonDto | TextLessonDto)[], { 
    lesson: Partial<TextLessonDto> | Partial<BulletPointLessonDto> | Partial<QuizLessonDto>, lang: string | undefined}>('/saveLesson', 
    async ({ lesson, lang }, thunkAPI) => {

  try {
    if (!lesson.id) throw new Error();

    const { course } = (thunkAPI.getState() as RootState).courses;
    if (!course || !course.id || !course.lessons) throw new Error();

    const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesClientApi) throw new Error();

    await coursesClientApi.editLesson(course.id, lesson.id, lang || course.documentLanguage || '', lesson);

    lesson.modified = DateTime.now().toISO();
    const newLessons = [...course.lessons];
    const lessonIndex = newLessons.findIndex(el => el.id === lesson.id);
    if (lessonIndex === -1) throw new Error();
    newLessons[lessonIndex] = lesson;
    return newLessons;
  } catch (err) {
    console.error(err);
    throw err;
  }
});

export const editAttachment = 
  createAsyncThunk<void, { 
    lessonId: string, lang: string, attachment: IAttachment | undefined | null}>('/editAttachment', 
    async ({ lessonId, lang, attachment }, thunkAPI) => {

  try {
    if (!lessonId) throw new Error();

    const { course } = (thunkAPI.getState() as RootState).courses;
    if (!course || !course.id || !course.lessons) throw new Error();

    const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesClientApi) throw new Error();

    await coursesClientApi.editLessonAttachment(course.id, lessonId, lang, {data: attachment?.data || '', contentType: attachment?.contentType || ''});

  } catch (err) {
    console.error(err);
    throw err;
  }
});

export const addLesson = 
  createAsyncThunk<(BulletPointLessonDto | FinalQuizLessonDto | QuizLessonDto | TextLessonDto)[], { 
    lesson: Partial<TextLessonDto> | Partial<BulletPointLessonDto> | Partial<QuizLessonDto>, lang: string | undefined}>('/addLesson', 
    async ({ lesson, lang }, thunkAPI) => {

  try {
    const { course } = (thunkAPI.getState() as RootState).courses;
    if (!course || !course.id || !course.lessons) throw new Error();

    const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesClientApi) throw new Error();

    const response = await coursesClientApi.addLesson(course.id, lang || course.documentLanguage || '', lesson);
    const newLessonId = (response.data as unknown) as string;
    if (!newLessonId) throw new Error();
    lesson.id = newLessonId;
    lesson.modified = DateTime.now().toISO();

    const newLessons = [...course.lessons];
    newLessons.unshift(lesson);
    return newLessons;
  } catch (err) {
    console.error(err);
    throw err;
  }
});

export const generateLesson = 
  createAsyncThunk<string, GenerateLessonRequestDto>('/generateLesson', 
    async (request, thunkAPI) => {

  try {
    const { course } = (thunkAPI.getState() as RootState).courses;
    if (!course || !course.id) throw new Error();

    const { coursesFunctionClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesFunctionClientApi) throw new Error();

    const response = await coursesFunctionClientApi.createLesson(course.id, request);
    const newLessonId = response.data;
    return newLessonId;
  } catch (err) {
    console.error(err);
    throw err;
  }
});

export const generateFinalQuiz = 
  createAsyncThunk<void, void>('/generateFinalQuiz', 
    async (_, thunkAPI) => {

  try {
    const { course } = (thunkAPI.getState() as RootState).courses;
    if (!course || !course.id) throw new Error();

    const { coursesFunctionClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesFunctionClientApi) throw new Error();

    await coursesFunctionClientApi.generateFinalQuiz(course.id);
  } catch (err) {
    console.error(err);
    throw err;
  }
});

export const cloneCourse = 
  createAsyncThunk<void, void>('/cloneCourse', 
    async (_, thunkAPI) => {

  try {
    const { course } = (thunkAPI.getState() as RootState).courses;
    if (!course || !course.id) throw new Error();

    const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesClientApi) throw new Error();

    await coursesClientApi.clone(course.id);
  } catch (err) {
    console.error(err);
    throw err;
  }
});

export const editFinalQuiz = 
  createAsyncThunk<(BulletPointLessonDto | FinalQuizLessonDto | QuizLessonDto | TextLessonDto)[], { quizes: SafeQuizLessonDto[], lang: string | undefined }>('/editFinalQuiz', 
    async ({quizes, lang}, thunkAPI) => {

  try {
    const { course } = (thunkAPI.getState() as RootState).courses;
    if (!course || !course.id || !course.lessons) throw new Error();

    const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!coursesClientApi) throw new Error();

    const finalQuiz = {...course.lessons.find(el => el.type === LessonTypeDto.FinalQuiz) as FinalQuizLessonDto};
    finalQuiz.quizes = quizes;

    if (finalQuiz.title === null || finalQuiz.quote === null) {
      finalQuiz.title = '';
      finalQuiz.quote = '';
    }
    await coursesClientApi.editFinalQuiz(course.id, lang || course.documentLanguage || '', finalQuiz);

    const lessons = [...course.lessons];
    const finalQuizIndex = lessons.findIndex(el => el.type === LessonTypeDto.FinalQuiz);
    if (finalQuizIndex === -1) return lessons;
    lessons[finalQuizIndex] = finalQuiz;
    return lessons;
  } catch (err) {
    console.error(err);
    throw err;
  }
});


export const clearError = createAsyncThunk<CourseStatusDto, void>('/clearError', async (_, thunkAPI) => {
  try {
    const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    const { course } = (thunkAPI.getState() as RootState).courses;
    if (!coursesClientApi || !course || !course.id) throw new Error();
   
    const response = await coursesClientApi.clearCourseError(course.id);
    return (response.data as unknown) as CourseStatusDto;
  } catch (err) {
    console.error(err);
    throw err;
  }
});

export const clearJustGenerated = createAsyncThunk<void, void>('/clearJustGenerated', async (_, thunkAPI) => {
  try {
    const { coursesClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    const { course } = (thunkAPI.getState() as RootState).courses;
    if (!coursesClientApi || !course || !course.id) throw new Error();
   
    await coursesClientApi.setCourseStatusInfo(course.id, { justGenerated: false });
  } catch (err) {
    console.error(err);
    throw err;
  }
});

const initialState: ICoursesState = {
  courses: undefined,
  course: undefined,
  lessonToEdit: undefined,
  lessonToDelete: undefined,
  apiStatus: {
    startingStatus: {
      api: ApiStatus.IDLE,
      loadingMessage: '',
    },
    courseStatus: {
      api: ApiStatus.IDLE,
      loadingMessage: '',
    },
  },
};

export const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    cleanCourses: (state, action: {}) => (
      {
        ...state,
        courses: undefined,
      }
    ),
    setLessonToEdit: (state, action: {type: string; payload: LessonDto | undefined}) => (
      {
        ...state,
        lessonToEdit: action.payload,
      }
    ),
    cleanLessonToEdit: (state, action: { type: string, payload: void }) => (
      {
        ...state,
        lessonToEdit: undefined,
      }
    ),
    setLessonToDelete: (state, action: {type: string; payload: LessonDto | undefined}) => (
      {
        ...state,
        lessonToDelete: action.payload,
      }
    ),
    cleanLessonToDelete: (state, action: { type: string, payload: void }) => (
      {
        ...state,
        lessonToDelete: undefined,
      }
    ),
    cancelError: (state, action: { type: string, payload: void }) => (
      {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.SUCCESS,
          },
        }
      }
    ),
    setIDLE: (state, action: { type: string, payload: void }) => (
      {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          startingStatus: {
            ...state.apiStatus.startingStatus,
            api: ApiStatus.IDLE,
            loadingMessage: '',
          },
        }
      }
    ),
    setStatus: (state, action: { type: string, payload: { status: CourseStatusDto } }) => (
      {
        ...state,
        course: {
          ...state.course,
          status: action.payload.status,
        }
      }
    )
  },
  extraReducers: (builder) => {
    // getCourses
    builder.addCase(getCourses.pending, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          startingStatus: {
            ...state.apiStatus.startingStatus,
            api: ApiStatus.LOADING,
            loadingMessage: 'Loading the courses...',
          },
        },
      }
    });
    builder.addCase(getCourses.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          startingStatus: {
            ...state.apiStatus.startingStatus,
            api: ApiStatus.SUCCESS,
            loadingMessage: '',
          },
        },
        courses: action.payload,
        course: undefined,
      }
    });
    builder.addCase(getCourses.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          startingStatus: {
            ...state.apiStatus.startingStatus,
            api: ApiStatus.ERROR,
            loadingMessage: '',
          },
        }
      }
    });

    // getProfilePhotos
    builder.addCase(getProfilePhotos.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          startingStatus: {
            ...state.apiStatus.startingStatus,
            api: ApiStatus.SUCCESS,
            loadingMessage: '',
          },
        },
        courses: action.payload
      }
    });

    // getCourse
    builder.addCase(getCourse.pending, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          startingStatus: {
            ...state.apiStatus.startingStatus,
            api: ApiStatus.LOADING,
            loadingMessage: 'Loading the course...',
          },
        },
      }
    });
    builder.addCase(getCourse.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          startingStatus: {
            ...state.apiStatus.startingStatus,
            api: ApiStatus.SUCCESS,
            loadingMessage: '',
          },
        },
        course: action.payload
      }
    });
    builder.addCase(getCourse.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          startingStatus: {
            ...state.apiStatus.startingStatus,
            api: ApiStatus.ERROR,
            loadingMessage: '',
          },
        },
      }
    });

    // changeOrder
    builder.addCase(changeLessonOrder.pending, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.LOADING,
            loadingMessage: 'Reordering lessons...',
          },
        },
      }
    });
    builder.addCase(changeLessonOrder.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.SUCCESS,
            loadingMessage: '',
          },
        },
        course: {
          ...state.course,
          lessons: action.payload
        }
      }
    });
    builder.addCase(changeLessonOrder.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.ERROR,
            loadingMessage: '',
          },
        },
      }
    });

    // toggleHide
    builder.addCase(toggleHide.pending, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.LOADING,
            loadingMessage: action.meta.arg.lesson.isHide ? 'Unhiding lessons...' : 'Hiding lesson...',
          },
        },
      }
    });
    builder.addCase(toggleHide.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.SUCCESS,
            loadingMessage: '',
          },
        },
        course: {
          ...state.course,
          lessons: state.course?.lessons?.map(el => el.id === action.payload?.id ? {...action.payload} : el),
        }
      }
    });
    builder.addCase(toggleHide.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.ERROR,
            loadingMessage: '',
          },
        },
      }
    });

    // deleteLesson
    builder.addCase(deleteLesson.pending, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.LOADING,
            loadingMessage: 'Deleting lesson',
          },
        },
      }
    });
    builder.addCase(deleteLesson.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.SUCCESS,
            loadingMessage: '',
          },
        },
        course: {
          ...state.course,
          lessons: action.payload,
        }
      }
    });
    builder.addCase(deleteLesson.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.ERROR,
            loadingMessage: '',
          },
        },
      }
    });

    // publishCourse
    builder.addCase(publishCourse.pending, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.LOADING,
            loadingMessage: 'Publishing course...',
          },
        },
      }
    });
    builder.addCase(publishCourse.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.SUCCESS,
            loadingMessage: '',
          },
        },
        course: {
          ...state.course,
          status: CourseStatusDto.Published,
        }
      }
    });
    builder.addCase(publishCourse.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.ERROR,
            loadingMessage: '',
          },
        },
      }
    });

    // unpublishCourse
    builder.addCase(unpublishCourse.pending, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.LOADING,
            loadingMessage: 'Unpublishing course...',
          },
        },
      }
    });
    builder.addCase(unpublishCourse.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.SUCCESS,
            loadingMessage: '',
          },
        },
        course: {
          ...state.course,
          status: CourseStatusDto.Draft,
        }
      }
    });
    builder.addCase(unpublishCourse.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.ERROR,
            loadingMessage: '',
          },
        },
      }
    });

    // deleteCourse
    builder.addCase(deleteCourse.pending, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.LOADING,
            loadingMessage: 'Deleting course...',
          },
        },
      }
    });
    builder.addCase(deleteCourse.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.SUCCESS,
            loadingMessage: '',
          },
        },
        course: undefined
      }
    });
    builder.addCase(deleteCourse.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.ERROR,
            loadingMessage: '',
          },
        },
      }
    });

    // deleteCourseInError
    builder.addCase(deleteCourseInError.pending, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          startingStatus: {
            ...state.apiStatus.startingStatus,
            api: ApiStatus.LOADING,
            loadingMessage: 'Deleting the course...',
          },
        }
      }
    });
    builder.addCase(deleteCourseInError.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          startingStatus: {
            ...state.apiStatus.startingStatus,
            api: ApiStatus.SUCCESS,
            loadingMessage: '',
          },
        },
        courses: action.payload,
      }
    });
    builder.addCase(deleteCourseInError.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          startingStatus: {
            ...state.apiStatus.startingStatus,
            api: ApiStatus.ERROR,
            loadingMessage: '',
          },
        }
      }
    });

    // saveCourseOptions
    builder.addCase(saveCourseOptions.pending, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.LOADING,
            loadingMessage: 'Saving options...',
          },
        },
      }
    });
    builder.addCase(saveCourseOptions.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.SUCCESS,
            loadingMessage: '',
          },
        },
        course: {
          ...state.course,
          title: action.payload?.title,
          description: action.payload?.description,
          passingPercentage: action.payload?.passingPercentage,
          isFinalQuizRepeatable: action.payload?.isFinalQuizRepeatable,
        }
      }
    });
    builder.addCase(saveCourseOptions.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.ERROR,
            loadingMessage: '',
          },
        },
      }
    });

    // saveLanguageCollection
    builder.addCase(saveLanguageCollection.pending, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.LOADING,
            loadingMessage: 'Saving languages...',
          },
        },
      }
    });
    builder.addCase(saveLanguageCollection.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.SUCCESS,
            loadingMessage: '',
          },
        },
        course: {
          ...state.course,
          supportedLanguages: action.payload,
        }
      }
    });
    builder.addCase(saveLanguageCollection.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.ERROR,
            loadingMessage: '',
          },
        },
      }
    });

    // addSchedule
    builder.addCase(addSchedule.pending, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.LOADING,
            loadingMessage: 'Saving schedule...',
          },
        },
      }
    });
    builder.addCase(addSchedule.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.SUCCESS,
            loadingMessage: '',
          },
        },
        course: {
          ...state.course,
          schedules: action.payload,
        }
      }
    });
    builder.addCase(addSchedule.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.ERROR,
            loadingMessage: '',
          },
        },
      }
    });

    // editSchedule
    builder.addCase(editSchedule.pending, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.LOADING,
            loadingMessage: 'Saving schedule...',
          },
        },
      }
    });
    builder.addCase(editSchedule.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.SUCCESS,
            loadingMessage: '',
          },
        },
        course: {
          ...state.course,
          schedules: action.payload,
        }
      }
    });
    builder.addCase(editSchedule.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.ERROR,
            loadingMessage: '',
          },
        },
      }
    });

    // deleteSchedule
    builder.addCase(deleteSchedule.pending, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.LOADING,
            loadingMessage: 'Deleting schedule...',
          },
        },
      }
    });
    builder.addCase(deleteSchedule.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.SUCCESS,
            loadingMessage: '',
          },
        },
        course: {
          ...state.course,
          schedules: action.payload,
        }
      }
    });
    builder.addCase(deleteSchedule.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.ERROR,
            loadingMessage: '',
          },
        },
      }
    });

    // sendSchedule
    builder.addCase(sendSchedule.pending, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.LOADING,
            loadingMessage: 'Sending schedule...',
          },
        },
      }
    });
    builder.addCase(sendSchedule.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.SUCCESS,
            loadingMessage: '',
          },
        },
        course: {
          ...state.course,
          schedules: action.payload,
        }
      }
    });
    builder.addCase(sendSchedule.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.ERROR,
            loadingMessage: '',
          },
        },
      }
    });

    // saveLesson
    builder.addCase(saveLesson.pending, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.LOADING,
            loadingMessage: 'Saving lesson...',
          },
        },
      }
    });
    builder.addCase(saveLesson.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.SUCCESS,
            loadingMessage: '',
          },
        },
        course: {
          ...state.course,
          lessons: action.payload,
        }
      }
    });
    builder.addCase(saveLesson.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.ERROR,
            loadingMessage: '',
          },
        },
      }
    });

    // editAttachment
    builder.addCase(editAttachment.pending, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.LOADING,
            loadingMessage: 'Saving lesson...',
          },
        },
      }
    });
    builder.addCase(editAttachment.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.SUCCESS,
            loadingMessage: '',
          },
        },
      }
    });
    builder.addCase(editAttachment.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.ERROR,
            loadingMessage: '',
          },
        },
      }
    });

    // addLesson
    builder.addCase(addLesson.pending, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.LOADING,
            loadingMessage: 'Saving lesson...',
          },
        },
      }
    });
    builder.addCase(addLesson.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.SUCCESS,
            loadingMessage: '',
          },
        },
        course: {
          ...state.course,
          lessons: action.payload,
        }
      }
    });
    builder.addCase(addLesson.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.ERROR,
            loadingMessage: '',
          },
        },
      }
    });

    // generateLesson
    builder.addCase(generateLesson.pending, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.LOADING,
            loadingMessage: 'Generating the lesson...',
          },
        },
      }
    });
    builder.addCase(generateLesson.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.SUCCESS,
            loadingMessage: '',
          },
        }
      }
    });
    builder.addCase(generateLesson.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.ERROR,
            loadingMessage: '',
          },
        },
      }
    });

    // generateFinalQuiz
    builder.addCase(generateFinalQuiz.pending, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.LOADING,
            loadingMessage: 'Generating the final quiz...',
          },
        },
      }
    });
    builder.addCase(generateFinalQuiz.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.SUCCESS,
            loadingMessage: '',
          },
        }
      }
    });
    builder.addCase(generateFinalQuiz.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.ERROR,
            loadingMessage: '',
          },
        },
      }
    });

    // cloneCourse
    builder.addCase(cloneCourse.pending, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.LOADING,
            loadingMessage: 'Cloning the course...',
          },
        },
      }
    });
    builder.addCase(cloneCourse.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.SUCCESS,
            loadingMessage: '',
          },
        }
      }
    });
    builder.addCase(cloneCourse.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.ERROR,
            loadingMessage: '',
          },
        },
      }
    });

    // editFinalQuiz
    builder.addCase(editFinalQuiz.pending, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.LOADING,
            loadingMessage: 'Editing the final quiz...',
          },
        },
      }
    });

    builder.addCase(editFinalQuiz.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.SUCCESS,
            loadingMessage: '',
          },
        },
        course: {
          ...state.course,
          lessons: action.payload
        }
      }
    });
    builder.addCase(editFinalQuiz.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.ERROR,
            loadingMessage: '',
          },
        },
      }
    });

    // // clearError
    // builder.addCase(clearError.pending, (state, action) => {
    //   return {
    //     ...state,
    //     apiStatus: {
    //       ...state.apiStatus,
    //       courseStatus: {
    //         ...state.apiStatus.courseStatus,
    //         api: ApiStatus.LOADING,
    //         loadingMessage: '',
    //       },
    //     },
    //   }
    // });

    builder.addCase(clearError.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.SUCCESS,
            loadingMessage: '',
          },
        },
        course: {
          ...state.course,
          status: action.payload,
        }
      }
    });
    builder.addCase(clearError.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.ERROR,
            loadingMessage: '',
          },
        },
      }
    });

    builder.addCase(clearJustGenerated.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.SUCCESS,
            loadingMessage: '',
          },
        },
        course: {
          ...state.course,
          statusInfo: {
            justGenerated: false,
          },
        }
      }
    });
    builder.addCase(clearJustGenerated.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: {
          ...state.apiStatus,
          courseStatus: {
            ...state.apiStatus.courseStatus,
            api: ApiStatus.ERROR,
            loadingMessage: '',
          },
        },
      }
    });
  }
});

export default coursesSlice.reducer;
export const { 
  cleanCourses,
  setLessonToEdit,
  cleanLessonToEdit,
  setLessonToDelete,
  cleanLessonToDelete,
  cancelError,
  setIDLE,
  setStatus,
} = coursesSlice.actions;