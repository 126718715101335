import { useRef, useEffect } from "react";

const useTextAreaRef = () => {
  const title = useRef<HTMLTextAreaElement>(null);
  const body = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (title.current && body.current) {
      title.current.style.height = `${title.current.scrollHeight}px`;
      body.current.style.height = `${body.current.scrollHeight}px`;
    }
  }, [title, body]);

  return {
    title,
    body,
  }
};

export default useTextAreaRef;