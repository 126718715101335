import { Dispatch, useCallback, useEffect, useState} from "react";

// import styles
import { getTheme } from "@fluentui/react";

// import models
import { 
  IPersonaProps,
  IBasePickerStyles,
  IBasePickerStyleProps, 
  IStyleFunctionOrObject,
 } from "@fluentui/react";

// import components
import { NormalPeoplePicker } from "@fluentui/react";

// import utils
import { BorderRadius } from "../../../../../../../../../../../utils/constants/styles";
import SuggestionItem from "./suggestion-item";
import { IUser } from "../../../../../../../../../../../common/models/LearningDetails/user";
import { ActionTypes, IAction } from "../../../../../hooks/useForm";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../../../../../store/store";
import { mapUsers } from "../../../../../../../../../../../utils/mappers/user";
import { IdentityTypeDto } from "../../../../../../../../../../../api-client";


interface IPeoplePickerProps {
  recipient: IUser | null | undefined;
  dispatch: Dispatch<IAction>;
}

const PeoplePicker = (props: IPeoplePickerProps) => {
  const [imageUrl, setImageUrl] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const scheduleList = useSelector((state: RootState) => state.courses.course?.schedules);
  const usersClientApi = useSelector((state: RootState) => state.clients.clients.usersClientApi);
  const theme = getTheme();

  console.log(imageUrl);

  const styles: IStyleFunctionOrObject<IBasePickerStyleProps, IBasePickerStyles> | undefined = {
    root: {
      width: '100%',
      height: 36,
      outline: 'none',
      background: 'none',
    },

    text: {
      width: '100%',
      height: 36,
      border: 'none',
      background: theme.palette.neutralLighterAlt,
      borderRadius: BorderRadius,
      fontSize: theme.fonts.large.fontSize,
      padding: '0 10px',
      outline: 'none',

      '&::after': {
        borderRadius: BorderRadius
      }
    },

    itemsWrapper: {
      height: 36,
      alignItems: 'center',
    },

    input: {
      outline: 'none',
      height: 36,
    },
  };

    async function searchUser(filter: string): Promise<IPersonaProps[]> {
      if (!filter || !usersClientApi || !scheduleList) return [];
      try {
        const response = await usersClientApi.apiTenantSearchValueGet(filter);
        return mapUsers(response.data).filter((user: IUser) => 
          !scheduleList.find(schedule => (schedule.recipient?.id === user.key && !schedule.archived)));
      } catch (e) {
        console.error(e);
        return [];
      }
    };

    const getProfilePicture = useCallback(async function(userId: string): Promise<void> {
      try {
        if (!usersClientApi) return;
  
        const response = await usersClientApi.apiTenantUsersUpnOrIdPhotoGet(userId);
        if (!props.recipient) return;
        props.recipient.imageUrl = `data:image/png;base64,${response.data}`;
        setImageUrl(true);
      } catch (err) {
        console.error(err);
      }
    }, [usersClientApi, props.recipient]);
  
    const getGroupPicture = useCallback(async function(groupId: string): Promise<void> {
      try {
        if (!usersClientApi) return;
  
        const response = await usersClientApi.apiTenantGroupsUpnOrIdPhotoGet(groupId);
        if (!props.recipient) return;
        props.recipient.imageUrl = `data:image/png;base64,${response.data}`;
        setImageUrl(true);
      } catch (err) {
        console.error(err);
      }
    }, [usersClientApi, props.recipient]);

    useEffect(() => {
      if (!props.recipient) return;

      if (props.recipient?.userType === IdentityTypeDto.User)
        getProfilePicture(props.recipient.key?.toString() || '');
      else
        getGroupPicture(props.recipient.key?.toString() || '');

      setDisabled(true);
    }, [getProfilePicture, getGroupPicture, props.recipient]);

  return (
    <NormalPeoplePicker
      styles={styles}
      onResolveSuggestions={searchUser}
      onRenderSuggestionsItem={(props, itemProps) => <SuggestionItem userProps={props as IUser} itemProps={itemProps} />}
      resolveDelay={300}
      itemLimit={1}
      onItemSelected={(selectedItem?: IPersonaProps) => {
        props.dispatch({ type: ActionTypes.SET_RECIPIENT, payload: { selectedItem } });
        return selectedItem || null;
      }}
      selectedItems={props.recipient ? [props.recipient] : [] }
      onChange={(items?: IPersonaProps[] | undefined) => props.dispatch({ type: ActionTypes.REMOVE_RECIPIENT, payload: { items } })}
      disabled={disabled}
    />
  )
};

export default PeoplePicker;