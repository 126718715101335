import { mergeStyleSets } from "@fluentui/react";

interface ILearningsIconClassNames {
  icon: string;
}

export const getClassNames = (): ILearningsIconClassNames => {
  return mergeStyleSets({
    icon: {
      transform: 'scale(1.1)',
      transition: 'all 350ms',

      '*': {
        transition: 'all 350ms',
      },
    }
  });
};