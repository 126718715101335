import { getClassNames } from './buttons.classNames';
import DefaultButton from '../../../../../../common/components/Buttons/DefaultButton/default-button';
import { useAppDispatch } from '../../../../../../store/store';
import { cancelError } from '../../../../../../store/slices/courses';

interface IButtonsProps {
}

const Buttons = (props: IButtonsProps) => {
  const dispatch = useAppDispatch();
  const classes = getClassNames();

  return (
    <div className={classes.buttonsContainer}>
      <DefaultButton onClick={() => dispatch(cancelError())}>Ok</DefaultButton>
    </div>
  )
}

export default Buttons;