import { mergeStyleSets, getTheme } from "@fluentui/react";
import { IFadeClassNames } from "../../../../../../../../../../utils/constants/styles";
import { FADE } from "../../../../../../../../../../utils/constants/styles";

interface IEmbedFormClassNames extends IFadeClassNames {
  form: string;
  error: string;
}

export const getClassNames = (): IEmbedFormClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    form: {
      overflow: 'hidden',
      width: '100%',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
    },

    error: {
      overflow: 'hidden',
      fontSize: 14,
      height: 18,
      color: theme.palette.red,
    },

    ...FADE.classNames,
  });
};