// import react
import { useState } from "react";

function useScheduleModal() {
  const [showNewSchedule, setShowNewSchedule] = useState<boolean>(false);

  function onShowNewScheduleClick(): void {
    setShowNewSchedule(true);
  }

  function onCloseNewScheduleClick(): void {
    setShowNewSchedule(false);
  }

  return {
    showNewSchedule,
    onShowNewScheduleClick,
    onCloseNewScheduleClick
  };
}

export default useScheduleModal;