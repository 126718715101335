import { useState, ChangeEvent } from "react";

function useSearchSchedule() {
  const [searchSchedule, setSearchSchedule] = useState<string>('');

  function updateSearchSchedule(e: ChangeEvent<HTMLInputElement>) {
    setSearchSchedule(e.target.value);
  }

  return {
    searchSchedule,
    updateSearchSchedule
  };
}

export default useSearchSchedule;