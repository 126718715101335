import { IIconProps } from "../../../../common/models/Icons/icon-props";

const AddLesson = (props: IIconProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.75155 0C9.13124 0 9.4451 0.282013 9.49483 0.648078L9.5017 0.749848L9.50291 8H16.756C17.1702 8 17.506 8.33579 17.506 8.75C17.506 9.1297 17.2238 9.44349 16.8578 9.49315L16.756 9.5H9.50291L9.50495 16.7491C9.50504 17.1633 9.16932 17.4993 8.7551 17.4993C8.37541 17.4993 8.06156 17.2173 8.01182 16.8512L8.00495 16.7494L8.00291 9.5H0.753906C0.339693 9.5 0.00390625 9.16421 0.00390625 8.75C0.00390625 8.3703 0.28606 8.05651 0.652136 8.00685L0.753906 8H8.00291L8.0017 0.750152C8.00162 0.335939 8.33734 0 8.75155 0Z" fill="#242424"/>
    </svg>
  )
};

export default AddLesson;