import { mergeStyleSets, getTheme } from "@fluentui/react";
import { BorderRadius } from "../../../../../../../../utils/constants/styles";

interface IHeaderClassNames {
  headerContainer: string;
  filterContainer: string;
  searchBarContainer: string;
  searchBar: string;
}

export const getClassNames = (): IHeaderClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    headerContainer: {
      width: '100%',
      flex: '0 0 50px',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: 20,
      borderBottom: `2px solid ${theme.palette.neutralLighter}`,
      marginBottom: 10,
    },

    filterContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 5,
      color: theme.palette.neutralQuaternary,
      userSelect: 'none'
    },

    searchBarContainer: {
      position: 'relative',
      borderRadius: BorderRadius,
    },

    searchBar: {
      height: 35,
      padding: '0 10px',
      border: theme.palette.neutralQuaternary,
      backgroundColor: theme.palette.neutralLighter,
      borderRadius: BorderRadius,
      '::placeholder': {
        fontSize: 14,
        color: theme.palette.neutralQuaternary
      },

      ':focus': {
        outline: 'none',
      }
    },
    
  });
}