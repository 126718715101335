import { mergeStyleSets, getTheme } from "@fluentui/react"
import { BorderRadius } from "../../../../../../../../../utils/constants/styles";

interface ITextLessonClassNames {
  editLessonContainer: string;
  dropdownContainer: string;
  titleGroupInput: string;
  titleInput: string;
  descriptionGroupInput: string;
  descriptionInput: string;
  titleLabel: string;
}

export const getClassNames = (disabled: boolean): ITextLessonClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    editLessonContainer: {
      overflow: 'auto',
      flex: 1,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      gap: 20,
    },

    dropdownContainer: {
      width: 190,
    },

    titleGroupInput: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 5
    },

    titleInput: {
      width: '100%',
      border: 'none',
      background: theme.palette.neutralLighterAlt,
      borderRadius: BorderRadius,
      fontSize: theme.fonts.large.fontSize,
      padding: '5px 10px',
      outline: 'none',
      resize: 'none',
      opacity: disabled ? 0.3 : 1,
    },

    descriptionGroupInput: {
      width: '100%',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: 5
    },

    descriptionInput: {
      width: '100%',
      border: 'none',
      background: theme.palette.neutralLighterAlt,
      borderRadius: BorderRadius,
      fontSize: theme.fonts.large.fontSize,
      padding: '5px 10px',
      outline: 'none',
      resize: 'none',
      opacity: disabled ? 0.3 : 1,
    },

    titleLabel: {
      color: theme.palette.neutralSecondary,
      fontWeight: theme.fonts.medium.fontWeight
    },
  });
};