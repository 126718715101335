import { mergeStyleSets, keyframes } from '@fluentui/react';

interface ILoginPageClassNames{
  'login-page-section': string;
  'login-page-container': string;
  'login-page-heading': string;
}

export const getClassNames = (): ILoginPageClassNames => {
    const backgroundEffect = keyframes({
      '0%': {
        transform: 'scale(1.4) perspective(400px) rotate3d(0, 1, 0, 5deg)'
      },
      '100%': {
        transform: 'scale(1.4) perspective(400px) rotate3d(0, -1, 0, 5deg)'
      },
    })

    return mergeStyleSets({
      'login-page-section': {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        userSelect: 'none',
        // animation: `${loadLoginPage} 1000ms`,
        background: `url(${require('../../assets/background-sm.jpg')})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        
        animationName: backgroundEffect,
        animationDuration: '10s',
        animationTimingFunction: 'linear',
        animationDirection: 'alternate',
        animationIterationCount: 'infinite',
      },

      'login-page-container': {
        position: 'fixed',
        top: 0,
        left: '50%',
        transform: 'translateX(-50%)',

        height: '100%',
        width: 500,
        backgroundColor: 'rgb(250, 250, 250)',
        boxShadow: '0px 0px 50px 5px #44444494',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 20,
        '& img': {
          width: 400,
          '-webkit-user-drag': 'none',
        },
      },

      'login-page-heading': {
        textTransform: 'uppercase',
        letterSpacing: 1.5,
        fontSize: 16,
      }
    });
};