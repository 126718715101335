import { getTheme, mergeStyleSets } from "@fluentui/react";
import { BorderRadius } from "../../../../../../utils/constants/styles";
import { Status } from "../../../../../../utils/constants/status";

interface IErrorBannerClassNames {
  banner: string;
  errorBannerContainer: string;
  justGeneratedBannerContainer: string;
  message: string;
  closeIcon: string;

  enter: string;
  enterActive: string;
  exit: string;
  exitActive: string;
}

export const getClassNames = (): IErrorBannerClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    banner: {
      flex: 1,
      overflow: 'hidden',
    },

    errorBannerContainer: {
      zIndex: 1,
      overflow: 'hidden',
      position: 'relative',
      maxWidth: '100%',
      padding: 10,
      borderRadius: BorderRadius,
      color: Status.GenError.color,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 20,

      border: `1px solid ${theme.palette.red}`,
      backgroundColor: '#f0c5c8',

      // '::before': {
      //   content: "''",
      //   background: Status.GenError.backgroundColor,
      //   opacity: 0.2,
      //   position: "absolute",
      //   top: 0,
      //   left: 0,
      //   width: '100%',
      //   height: '100%',
      //   borderRadius: BorderRadius, 
      // },

      // '::after': {
      //   content: "''",
      //   border: `1px solid ${Status.GenError.backgroundColor}`,
      //   position: "absolute",
      //   top: 0,
      //   left: 0,
      //   width: 'calc(100% - 1px)',
      //   height: 'calc(100% - 1px)',
      //   borderRadius: BorderRadius, 
      // },
    },

    justGeneratedBannerContainer: {
      zIndex: 1,
      position: 'relative',
      overflow: 'hidden',
      maxWidth: '100%',
      padding: 10,
      borderRadius: BorderRadius,
      color: Status.Draft.color,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 20,

      border: `1px solid ${theme.palette.yellow}`,
      backgroundColor: '#f5e7c1',

      // boxShadow: `0 4px 10px 0px rgba(0, 0, 0, 0.3)`,
      // color: theme.palette.yellow,

      // '::before': {
      //   content: "''",
      //   background: Status.Draft.backgroundColor,
      //   opacity: 0.2,
      //   position: "absolute",
      //   top: 0,
      //   left: 0,
      //   width: '100%',
      //   height: '100%',
      //   borderRadius: BorderRadius, 
      // },

      // '::after': {
      //   content: "''",
      //   border: `1px solid ${Status.Draft.backgroundColor}`,
      //   position: "absolute",
      //   top: 0,
      //   left: 0,
      //   width: 'calc(100% - 1px)',
      //   height: 'calc(100% - 1px)',
      //   borderRadius: BorderRadius, 
      // },
    },

    message: {
      userSelect: 'none',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    closeIcon: {
      fontSize: 12,
      color: theme.palette.neutralTertiary,
      cursor: 'pointer',
    },

    enter: {
      opacity: 0,
      transform: 'translateY(-100%)',
    },

    enterActive: {
      opacity: 1,
      transform: 'translateY(0)',
      transition: 'opacity 500ms, transform 500ms',
    },

    exit: {
      opacity: 1,
      transform: 'translateY(0)',
    },

    exitActive: {
      opacity: 0,
      transform: 'translateY(-100%)',
      transition: 'opacity 500ms, transform 500ms',
    },
  });
};