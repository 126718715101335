import { getTheme, mergeStyleSets } from "@fluentui/react"
import { BorderRadius } from "../../../../../../../../utils/constants/styles";

interface ISideBarClassNames {
  sideBarContainer: string;
  questionContainer: string;
  questionParagraph: string;
  selectedQuestionParagraph: string;
  newOptionContainer: string;
  newOptionInput: string;
}

export const getClassNames = (): ISideBarClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    sideBarContainer: {
      overflow: 'auto',
      paddingRight: 20,
      flex: 45,
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
    },

    questionContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 10,
    },

    questionParagraph: {
      // width: '100%',
      flex: 1,
      fontSize: theme.fonts.large.fontSize,
      background: theme.palette.neutralLighterAlt,
      padding: '5px 10px',
      margin: 0,
      borderRadius: 5,
      cursor: 'pointer',
      userSelect: 'none',
      transition: 'all 500ms',
    }, 

    selectedQuestionParagraph: {
      // width: '100%',
      flex: 1,
      fontSize: theme.fonts.large.fontSize,
      background: theme.palette.themeLighter,
      padding: '5px 10px',
      margin: 0,
      borderRadius: 5,
      userSelect: 'none',
      cursor: 'pointer',
      transition: 'all 500ms',
    },

    newOptionContainer: {
      marginTop: 20,
      paddingLeft: 34,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: 10
    },

    newOptionInput: {
      flex: 1,
      border: 'none',
      background: theme.palette.neutralLighterAlt,
      borderRadius: BorderRadius,
      fontSize: theme.fonts.large.fontSize,
      padding: '5px 10px',
      outline: 'none',
      resize: 'none',
      opacity: 1,
    },
  });
};