import { useRef, DragEvent, Dispatch } from 'react';
import { ActionTypes, IAction } from '../../../hooks/useForm';

const useDrag = (dispatch: Dispatch<IAction>) => {
  const dragIndex = useRef<number>();

  const onDragStart = (e: DragEvent<HTMLElement>, index: number) => dragIndex.current = index;
  
  const onDrop = (e: DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (dragIndex.current === undefined) return;

    const targetIndex = e.currentTarget.getAttribute('data-index');
    if (!targetIndex) return;

    dispatch({
      type: ActionTypes.CHANGE_QUESTION_ORDER,
      payload: { index: dragIndex.current, targetIndex: +targetIndex } 
    });
  };

  const onDragEnd = (e: DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.style.cursor = 'move';
    dragIndex.current = undefined;
  };

  const onDragOver = (e: DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return {
    onDragStart,
    onDragOver,
    onDrop,
    onDragEnd
  };
};

export default useDrag;