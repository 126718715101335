// import react
import { FormEvent, Dispatch } from 'react';

// import styles
import { getClassNames } from './edit-lesson.classNames';

// import models
import { BulletPointLessonDto, LessonTypeDto, TextLessonDto } from '../../../../../../../../api-client';
import { Checkbox, IDropdownOption } from '@fluentui/react';
// import { IBulletPointLessonRefs, IQuizLessonRefs } from '../../hooks/useForm';

// import components
import TextLesson from './TextLesson/text-lesson';
import BulletPointsLesson from './BulletPointsLesson/bullet-points-lesson';
import QuizLesson from './QuizLesson/quiz-lesson';
import Dropdown from '../../../../../../../../common/components/Dropdown/dropdown';
import { lessonTypesOptions } from '../../../../../../../../utils/constants/lesson-types';
import { DropdownThemes } from '../../../../../../../../utils/constants/dropdown-themes';
import { ActionTypes, IAction, IFormData } from '../../hooks/useForm';
import FlashCardLesson from './FlashCardLesson/FlashCardLesson';

interface IEditLessonProps {
  formData: IFormData;
  dispatch: Dispatch<IAction>;
}

const EditLesson = (props: IEditLessonProps) => {
  const classes = getClassNames();

  const getLesson = () => {
    switch (props.formData.lessonData.type) {
      case LessonTypeDto.Text: return (
        <TextLesson
          formData={props.formData}
          dispatch={props.dispatch}
          disabled={props.formData.lessonData.type !== props.formData.lessonType}
        />
      )

      case LessonTypeDto.BulletPoints: return (
        <BulletPointsLesson 
          formData={props.formData}
          dispatch={props.dispatch}
          disabled={props.formData.lessonData.type !== props.formData.lessonType}
        />
      )

      case LessonTypeDto.Quiz: return (
        <QuizLesson
          formData={props.formData}
          dispatch={props.dispatch}
          disabled={props.formData.lessonData.type !== props.formData.lessonType}
        />
      )

      case LessonTypeDto.FlashCard: return (
        <FlashCardLesson
          formData={props.formData}
          dispatch={props.dispatch}
          disabled={props.formData.lessonData.type !== props.formData.lessonType}
        />
      )
      default: return <></>
    }
  };

  const isAudioOnly = props.formData.lessonData.type !== LessonTypeDto.Quiz ? 
    (props.formData.lessonData as TextLessonDto | BulletPointLessonDto).isAudioOnly :
    false; 

  return (
    <div className={classes.editLessonContainer}>
      <div className={classes.lessonTypeContainer}>
        <div className={classes.dropdownContainer}>
          <h5 className={classes.dropdownLabel}>Lesson type</h5>
            <Dropdown
              options={lessonTypesOptions}
              selectedKey={props.formData.lessonType}
              theme={DropdownThemes.LIGHT}
              height={35}
              caretWidth='40px'
              onChange={(e: FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined) => 
                props.dispatch({ type: ActionTypes.UPDATE_LESSON_TYPE, payload: {lessonType: (option?.key as LessonTypeDto) } })}
            />
        </div>
        {props.formData.lessonData.type !== LessonTypeDto.Quiz && props.formData.lessonData.type !== LessonTypeDto.FlashCard &&
          <Checkbox
            className={classes.checkbox}
            label='Audio Only'
            checked={isAudioOnly}
            onChange={(ev?: FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean | undefined) => 
              props.dispatch({ type: ActionTypes.UPDATE_AUDIO_ONLY, payload: checked || false })}
          />}
      </div>
      {getLesson()}
    </div>
  )
};

export default EditLesson;