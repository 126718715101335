import { getClassNames } from "./bullet-points-lesson.classNames";

interface IBulletPointsLessonProps {
  introduction: string;
  bulletPoints: string[];
}

const BulletPointsLesson = (props: IBulletPointsLessonProps) => {
  const classes = getClassNames();

  return (
    <div className={classes.bulletPointsLessonContainer}>
      <div className={classes.bulletPointsLessonScrollContainer}>
        <p>{props.introduction}</p>
        <br />
        <ul className={classes.pointList}>
          {props.bulletPoints.map((bulletPoint: any, index: number) => <li key={index} className={classes.point}>{bulletPoint}</li>)}
        </ul>
      </div>
    </div>
  )
};

export default BulletPointsLesson;