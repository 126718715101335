// import styles
import { useSelector } from "react-redux";
import { getClassNames } from "./nav.classNames";
import { RootState } from "../../../../../../../../store/store";
import { LessonTypeDto } from "../../../../../../../../api-client";

interface INavProps {
  selectedTab: number;
  changeTab: (tab: number) => void;
}

const Nav = (props: INavProps) => {
  const hasFinalQuiz = useSelector((state: RootState) => state.courses.course?.lessons?.some(el => el.type === LessonTypeDto.FinalQuiz));
  const classes = getClassNames();
  
  return (
    <nav className={classes.navContainer}>
      <ul className={classes.listContainer}>
        <li className={props.selectedTab === 0 ? `${classes.listItemActive}` : classes.listItem} onClick={() => props.changeTab(0)}>Course options</li>
        <li className={props.selectedTab === 1 ? `${classes.listItemActive}` : classes.listItem} onClick={() => props.changeTab(1)}>Language options</li>
        {hasFinalQuiz && (
          <li className={props.selectedTab === 2 ? `${classes.listItemActive}` : classes.listItem} onClick={() => props.changeTab(2)}>Quiz options</li>
        )}
      </ul>
    </nav>
  )
};

export default Nav;