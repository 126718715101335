// import react
import { Dispatch, useState, ChangeEvent } from 'react';
import { getClassNames } from './sidebar.classNames';
import Reorder from '../../../../../../../../assets/Icons/Controls/Reorder/reorder';
import PrimaryButton from '../../../../../../../../common/components/Buttons/PrimaryButton/primary-button';
import { IconNames } from '../../../../../../../../common/models/Icons/icon-names';
import { getTheme } from '@fluentui/react';
import { ActionTypes, IAction, IFormData } from '../../hooks/useForm';
import useDrag from './hooks/useDrag';
import useTextAreaRef from './hooks/useTextAreaRef';

interface ISideBarProps {
  formData: IFormData;
  dispatch: Dispatch<IAction>;
}

const SideBar = (props: ISideBarProps) => {
  const {
    onDragStart,
    onDragOver,
    onDragEnd,
    onDrop
  } = useDrag(props.dispatch);
  const classes = getClassNames();
  const theme = getTheme();

  const { question } = useTextAreaRef(props.formData.quizes);

  const [newItem, setNewItem] = useState<string>('');

  function onNewItemChange(e: ChangeEvent<HTMLTextAreaElement>) {
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;

    setNewItem(e.target.value);
  };

  return (
    <div className={classes.sideBarContainer}>
      {props.formData.quizes?.map((quiz, index) => (
        <div
          key={quiz.id}
          data-index={index}
          className={classes.questionContainer}
          onDragStart={(e) => onDragStart(e, index)}
          onDragEnd={onDragEnd}
          onDragOver={onDragOver}
          onDrop={onDrop}  
        >
          <Reorder />
          <p
            className={props.formData.selectedQuestionIndex === index ? classes.selectedQuestionParagraph : classes.questionParagraph}
            onClick={() => props.dispatch({ type: ActionTypes.SET_QUESTION, payload: index})}>
              {quiz.question}
          </p>
          <PrimaryButton
            type='button'
            iconName={IconNames.DELETE}
            width={90} 
            padding={10}
            gap={5} 
            background={theme.palette.themeLighter}
            color={theme.palette.themePrimary}
            onClick={() => props.dispatch({ type: ActionTypes.DELETE_QUESTION, payload: index })}
          >
            Delete
          </PrimaryButton>
        </div>
      ))}
      <div className={classes.newOptionContainer}>
          <textarea
            ref={question}
            name="new-question" 
            id="new-question" 
            className={classes.newOptionInput} 
            rows={1} 
            value={newItem}
            onChange={onNewItemChange}
          />
          <PrimaryButton
            onClick={() => {
              props.dispatch({ type: ActionTypes.ADD_QUESTION, payload: newItem });
              setNewItem('');
            }}
            iconName={IconNames.ADD}
            width={90}
            color={newItem ? theme.palette.white : theme.palette.neutralTertiary}
            disabled={!newItem}
            type='button'
          >
            Add
          </PrimaryButton>
        </div>
    </div>
  );
};

export default SideBar;