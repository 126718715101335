// import react
import { useMemo, FormEvent, useState, useRef } from 'react';

// import styles
import { getClassNames } from "./options.classNames";
import { CSSTransition } from 'react-transition-group';

// import components
import DefaultButton from "../../../../../../common/components/Buttons/DefaultButton/default-button";
import Dropdown from "../../../../../../common/components/Dropdown/dropdown";

// import models
import { CourseStatusDto, LessonTypeDto, SupportedLanguageDto } from '../../../../../../api-client';
import { IDropdownOption,
  getTheme,
  IContextualMenuProps,
  IContextualMenuItemProps,
  ContextualMenuItemType,
  DirectionalHint } from '@fluentui/react';
import { IconNames } from '../../../../../../common/models/Icons/icon-names';

// import utils
import { DropdownThemes } from '../../../../../../utils/constants/dropdown-themes';
import { getLanguageFromCode, sortString } from '../../../../../../utils/helpers/helpers';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../../../store/store';
import { cloneCourse, generateFinalQuiz, publishCourse, unpublishCourse } from '../../../../../../store/slices/courses';
import { Icons } from '../../../../../../utils/constants/icons';
import { ANIMATION_DURATION } from '../../../../../../utils/constants/styles';
import ConfirmationDialog from '../../ConfirmationDialog/confirmation-dialog';
import useScorm from './hooks/useScorm';
import Loading from '../../../../../../common/components/Loading/Loading';
import ErrorDialog from './components/ErrorDialog/error-dialog';
import ScormDetails from './components/ScormDetails/ScormDetails';

interface IOptionsProps {
  onConfigureModalClick: () => void;
  onScheduleModalClick: () => void;
  onQuizModalClick: () => void;
  onDeleteClick: (type: 'Learning' | 'Lesson') => void;
  onEditLessonClick: () => void;
  onGenerateLessonClick: () => void;
  showGenerateFromAI: boolean;
}

const Options = (props: IOptionsProps) => {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);
  const [showScormDetails, setShowScormDetails] = useState<boolean>(false);

  const { settings } = useSelector((state: RootState) => state.settings);
  const course = useSelector((state: RootState) => state.courses.course);
  const dispatch = useAppDispatch();
  const { id, lang } = useParams();
  const navigate =  useNavigate();
  const { exportScorm, isExporting, scormError, clearScormError } = useScorm();

  const classes = getClassNames();

  const dialogRef = useRef<HTMLDivElement>(null);
  const loadingRef = useRef<HTMLDivElement>(null);
  const errorDialogRef = useRef<HTMLDivElement>(null);
  const scormDetailsRef = useRef<HTMLDivElement>(null);

  const onLanguageChange = (e: FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
    if(!option || !id) return;
    if(option.key === lang) return;
    if (!lang && option.key === course?.documentLanguage) return;

    let path = `/learnings/${id}/${option.key}`;
    navigate(path, {replace: true});
  };

  const languages = useMemo((): IDropdownOption[] => {
    if (!course?.supportedLanguages) return [];

    return course.supportedLanguages?.map((language: SupportedLanguageDto): IDropdownOption => {
      if (!language.language) return { key: '', text: '' };

      return {
      key: language.language?.toString() || '',
      text: getLanguageFromCode(language.language),
      };
    }).sort((a, b) => sortString(a.text, b.text));
  }, [course?.supportedLanguages]);

  const hasFinalQuiz = course?.lessons?.some(el => el.type === LessonTypeDto.FinalQuiz);

  async function onGenerateFinalQuizClick() {
    setShowConfirmationDialog(false);
    try {
      await dispatch(generateFinalQuiz()).unwrap();
      navigate('/learnings');
    } catch (err) {
      console.error(err);
    }
  }

  const theme = getTheme();
  const itemProps = {
    styles: {
      label: {
        fontWeight: theme.fonts.xLarge.fontWeight,
      }
    }
  };

  const dividerProps: Partial<IContextualMenuItemProps> = {
    styles: {
      divider: {
        backgroundColor: theme.palette.neutralQuaternaryAlt,
        width: '90%',
        margin: '0 auto',
      }
    }
  }

  const contextualMenuProps: IContextualMenuProps = {
    directionalHint: DirectionalHint.topAutoEdge,
    items: [
      { 
        key: 'newLesson',
        text: 'New lesson',
        itemProps,
        iconProps: {},
        onRenderIcon: (props: IContextualMenuItemProps | undefined) => {
          const Icon = Icons[IconNames.ADD_LESSON];
          return (
            <div className={classes.menuItemIconContainer}>
              <Icon />
            </div>
          )
        },
        subMenuProps: {
          items: [
            ...props.showGenerateFromAI ? [{
              key: 'fromDocument',
              text: 'From document',
              onClick: props.onGenerateLessonClick,
            }] : [],
            {
              key: 'fromScratch',
              text: 'From scratch',
              onClick: props.onEditLessonClick,
            },
          ]
        }
      },
      {
        key: 'configure',
        text: 'Configure',
        onClick: props.onConfigureModalClick,
        itemProps,
        iconProps: {},
        onRenderIcon: () => {
          const Icon = Icons[IconNames.CONFIGURE];
          return (
            <div className={classes.menuItemIconContainer}>
              <Icon />
            </div>
          )
        }
      },
      { 
        key: 'schedule',
        text: 'Schedule',
        onClick: props.onScheduleModalClick,
        itemProps,
        iconProps: {},
        onRenderIcon: (props: IContextualMenuItemProps | undefined) => {
          const Icon = Icons[IconNames.SCHEDULE];
          return (
            <div className={classes.menuItemIconContainer}>
              <Icon />
            </div>
          )
        },
      },
      { 
        key: 'publish',
        text: course?.status === CourseStatusDto.Published || course?.status === CourseStatusDto.Running ? 'Unpublish' : 'Publish',
        onClick(ev, item) {
          course?.status === CourseStatusDto.Published ?
            dispatch(unpublishCourse(settings?.learningProviderId)) : 
            dispatch(publishCourse(settings?.learningProviderId));
        },
        disabled: course?.status === CourseStatusDto.Running,
        itemProps,
        iconProps: {},
        onRenderIcon: (props: IContextualMenuItemProps | undefined) => {
          const Icon = 
            course?.status === CourseStatusDto.Published || 
            course?.status === CourseStatusDto.Running ? Icons[IconNames.UNPUBLISH] : Icons[IconNames.PUBLISH];
          return (
            <div className={classes.menuItemIconContainer}>
              <Icon color={course?.status === CourseStatusDto.Running ? theme.palette.neutralTertiary : '#000'} />
            </div>
          )
        },
      },
      {
        key: 'divider1',
        itemType: ContextualMenuItemType.Divider,
        itemProps: dividerProps,
      },
      { 
        key: 'finalQuiz',
        text: hasFinalQuiz ? 'Final quiz' : 'Generate final quiz',
        onClick: hasFinalQuiz ? undefined : () => setShowConfirmationDialog(true),
        itemProps,
        iconProps: {},
        onRenderIcon: (props: IContextualMenuItemProps | undefined) => {
          const Icon = Icons[IconNames.FINAL_QUIZ];
          return (
            <div className={classes.menuItemIconContainer}>
              <Icon />
            </div>
          )
        },
        ...(hasFinalQuiz && {
          subMenuProps: {
            items: [
              {
                key: 'viewFinalQuiz',
                text: 'View final quiz',
                onClick: props.onQuizModalClick,
              },
              {
                key: 'regenarateFinalQuiz',
                text: 'Regenerate final quiz',
                onClick: () => setShowConfirmationDialog(true),
              },
            ]
          }
        })
      },
      {
        key: 'divider2',
        itemType: ContextualMenuItemType.Divider,
        itemProps: dividerProps,
      },
      { 
        key: 'clone',
        text: 'Clone',
        onClick: async () => { 
          try {
            await dispatch(cloneCourse()).unwrap();
            navigate('/learnings');
          } catch (err) {
            console.error(err);
          }
         },
        itemProps,
        iconProps: {},
        onRenderIcon: (props: IContextualMenuItemProps | undefined) => {
          const Icon = Icons[IconNames.CLONE];
          return (
            <div className={classes.menuItemIconContainer}>
              <Icon />
            </div>
          )
        },
      },
      {
        key: 'scorm',
        text: 'Export in SCORM',
        onClick: async () => setShowScormDetails(true),
        itemProps,
        iconProps: {},
        onRenderIcon: () => {
          const Icon = Icons[IconNames.SCORM];
          return (
            <div className={classes.menuItemIconContainer} style={{transform: 'scale(1.15)'}}>
              <Icon />
            </div>
          )
        },
      },
      {
        key: 'divider3',
        itemType: ContextualMenuItemType.Divider,
        itemProps: dividerProps,
      },
      {
        key: 'delete',
        text: 'Delete learning',
        onClick: () => props.onDeleteClick('Learning'),
        itemProps,
        iconProps: {},
        onRenderIcon: () => {
          const Icon = Icons[IconNames.DELETE];
          return (
            <div className={classes.menuItemIconContainer}>
              <Icon
                color={course?.status === CourseStatusDto.Published || course?.status === CourseStatusDto.Running
                   ? theme.palette.neutralTertiary : '#000'}
              />
            </div>
          )
        },
        disabled: course?.status === CourseStatusDto.Published || course?.status === CourseStatusDto.Running,
      },
    ]
  };

  return (
    <div className={classes.options}>

      <div className={classes.dropdownContainer}>
        <Dropdown 
          height={35}
          theme={DropdownThemes.LIGHT} 
          onChange={onLanguageChange} 
          placeholder='Change language'
          showAlwaysPlaceholder={true}
          iconName={IconNames.CHANGE_LANGUAGE} 
          options={languages}
          selectedKey={lang || course?.documentLanguage} />
      </div>

      <div className={classes.contextualMenuContainer}>
        <DefaultButton
          width={100}
          height={35}
          menuProps={contextualMenuProps}
        >Actions</DefaultButton>
      </div>

      <CSSTransition
        nodeRef={dialogRef}
        timeout={ANIMATION_DURATION}
        in={showConfirmationDialog}
        classNames={{
          enter: classes.enter,
          enterActive: classes.enterActive,
          exit: classes.exit,
          exitActive: classes.exitActive
        }}
        unmountOnExit
      >
        <ConfirmationDialog
          nodeRef={dialogRef}
          animation={showConfirmationDialog}
          type='Generate'
          onClose={onGenerateFinalQuizClick}
          onCancel={() => setShowConfirmationDialog(false)}
        />
      </CSSTransition>

      <CSSTransition
        nodeRef={loadingRef}
        timeout={ANIMATION_DURATION}
        in={isExporting}
        classNames={{
          enter: classes.enter,
          enterActive: classes.enterActive,
          exit: classes.exit,
          exitActive: classes.exitActive
        }}
        unmountOnExit
      >
        <Loading
          nodeRef={loadingRef}
          animation={isExporting}
          label={'Exporting the SCORM package'}  
        />
      </CSSTransition>

      <CSSTransition
        nodeRef={errorDialogRef}
        timeout={ANIMATION_DURATION}
        in={scormError}
        classNames={{
          enter: classes.enter,
          enterActive: classes.enterActive,
          exit: classes.exit,
          exitActive: classes.exitActive
        }}
        unmountOnExit
      >
        <ErrorDialog
          nodeRef={errorDialogRef}
          animation={scormError}
          action={clearScormError}
        />
      </CSSTransition>

      <CSSTransition
        nodeRef={scormDetailsRef}
        timeout={ANIMATION_DURATION}
        in={showScormDetails}
        classNames={{
          enter: classes.enter,
          enterActive: classes.enterActive,
          exit: classes.exit,
          exitActive: classes.exitActive
        }}
        unmountOnExit
      >
        <ScormDetails
          nodeRef={scormDetailsRef}
          animation={showScormDetails}
          action={() => setShowScormDetails(false)}
          exportScorm={exportScorm}
        />
      </CSSTransition>
      
    </div>
  )
}

export default Options;