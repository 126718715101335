// import react
import { FormEvent, useState } from "react";

// import helpers and mappers
import { configurationDataMapper } from "../../../../../../utils/mappers/configuration-data";
import { UploadValidation } from "../../../../../../utils/middlewares/validations/upload-validations";
import { arrayBufferToBase64, getFileType, timeout } from "../../../../../../utils/helpers/helpers";

// import types
import { FileConfigurationDto, GenerateConfigurationDto, GenerateRequestDto } from "../../../../../../api-client";
import { CreateFormKeys, ICreateFromUploadForm } from "../../../../../../common/models/Create/form-data";

// import hooks
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store/store";
import { AxiosError, isAxiosError } from "axios";

const useGenerate = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const coursesFunctionClientApi = useSelector((state: RootState) => state.clients.clients.coursesFunctionClientApi);
  const navigate = useNavigate();

  const uploadFile = async (fileConfiguration: FileConfigurationDto, file: File, configurationData: GenerateConfigurationDto) => {
    try {
      if (!coursesFunctionClientApi) return;
      // // MOCK //
      // setLoadingMessage('Uploading course...');
      // setIsLoading(true);
      // // const data: string = arrayBufferToBase64(await file.arrayBuffer());
      // // const requestBody: GenerateRequestDto = {
      // //   fileConfiguration: {...fileConfiguration},
      // //   data,
      // //   configuration: {...configurationData },
      // // };
      // await timeout(2000);
      // setLoadingMessage('Redirecting to the learnings page...');
      // await timeout(1000);
      // navigate(RouterSettings.PATHS.learnings.root);


      setLoadingMessage('Uploading the course...');
      setIsLoading(true);
      const data: string = arrayBufferToBase64(await file.arrayBuffer());
      const requestBody: GenerateRequestDto = {
        fileConfiguration: {...fileConfiguration},
        data,
        configuration: {...configurationData },
      };
      await coursesFunctionClientApi.generateCourse(requestBody);
      setLoadingMessage('Redirecting to the learnings page...');
      await timeout(3000); // await a second before redirecting
      navigate('/learnings');
    } catch (err) {
      console.error(err);
      
      if (isAxiosError(err)) {
        setErrorMessage(((err as AxiosError).response?.data as any).message);
      } else {
        setErrorMessage((err as Error).message);
      }
    } finally {
      setIsLoading(false);
      setLoadingMessage('');
    }
  };

  const generateCourse = async (e: FormEvent, state: ICreateFromUploadForm): Promise<void> => {
    e.preventDefault();
    setErrorMessage('');

    try {
      const uploadValidation = new UploadValidation(state);
      uploadValidation.validate();
      const configurationData: GenerateConfigurationDto = configurationDataMapper(state.createForm);
      
      if (!state.file) return;
      const fileType = getFileType(state.file);
      if (!fileType) return;

      const fileConfiguration: FileConfigurationDto = {
        fileType,
        pages: state.createForm[CreateFormKeys.PAGES],
        chapterHeaderPrefix: state.createForm[CreateFormKeys.CHAPTER_HEADER_PREFIX],
      };

      await uploadFile(fileConfiguration, state.file, configurationData);
    } catch (err) {
      console.error(err);
      setErrorMessage((err as Error).message);
    }
  };

  return { generateCourse, isLoading, loadingMessage, errorMessage };
};

export default useGenerate;