// import react
import { createContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';

export interface IFiltersContext {
  learnings: {
    selectedFilter: number;
    updateSelectedFilter: (filter: number) => void;
  }
}

export const FiltersContext = createContext<IFiltersContext>({
  learnings: {
    selectedFilter: 0,
    updateSelectedFilter: () => {},
  }
});

export const FiltersProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const roles = useSelector((state: RootState) => state.account.roles);
  const [selectedLearningsFilter, setSelectedLearningsFilter] = useState<number>(roles.includes("Admin") ? 0 : 1);

  function updateLearningsSelectedFilter(filter: number) {
    setSelectedLearningsFilter(filter);
  }

  return (
    <FiltersContext.Provider value={{ 
      learnings: { 
        selectedFilter: selectedLearningsFilter,
        updateSelectedFilter: updateLearningsSelectedFilter,
        } 
      }}>
      {children}
    </FiltersContext.Provider>
  )
};