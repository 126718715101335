import { useState, useEffect, FormEvent, ChangeEvent, useCallback, useContext } from "react";
import { lessonTypesOptions } from "../../../../../../../utils/constants/lesson-types";
import { AddLessonSettingsDto, GenerateLessonRequestDto, LessonTypeDto } from "../../../../../../../api-client";
import { IDropdownOption } from "@fluentui/react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../../../../store/store";
import { generateLesson } from "../../../../../../../store/slices/courses";
import { useNavigate } from "react-router-dom";
import { timeout } from "../../../../../../../utils/helpers/helpers";
import { InfoContext } from "../../../../../../../contexts/info-context";

const useAddLesson = () => {
  const { info: { addModifiedLesson } } = useContext(InfoContext);
    const dispatch = useAppDispatch();
    const courseId = useSelector((state: RootState) => state.courses.course?.id);
    // const documentLanguage = useSelector((state: RootState) => state.courses.course?.documentLanguage);
    const coursesClientApi = useSelector((state: RootState) => state.clients.clients.coursesClientApi);

    const [addLessonSettings, setAddLessonSettings] = useState<AddLessonSettingsDto | null>(null);
    const [isLessonSettingsLoading, setIsLessonSettingsLoading] = useState<boolean>(false);
    const [lessonType, setLessonType] = useState<string>(lessonTypesOptions[0].key as string);
    const [chapter, setChapter] = useState<string>('');
    const [pages, setPages] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loadingMessage, setLoadingMessage] = useState<string>('');

    const navigate = useNavigate();

    const getAddLessonSettings = useCallback(async (): Promise<void> => {
      if (!coursesClientApi) return;
      if(!courseId) return;
      try{
        setIsLessonSettingsLoading(true);
        const response = await coursesClientApi.getAddLessonSettings(courseId);
        setAddLessonSettings(response.data);
        setChapter(response.data.chapters?.at(0) || '')
      }catch(err){
        console.error(err);
      } finally {
        setIsLessonSettingsLoading(false);
      }
    }, [courseId, coursesClientApi]);

    // useEffect(() => {
    //     setChapter(chapters?.at(0)?.key as string || "");
    // }, [chapters]);

    const changeChapter = (e: FormEvent<HTMLDivElement>, selectedOption?: IDropdownOption) => {
        if(selectedOption)
            setChapter(selectedOption.key as string);
    };

    const changeLessonType = (e: FormEvent<HTMLDivElement>, selectedOption?: IDropdownOption) => {
        if(selectedOption)
            setLessonType(selectedOption.key as string);
    };

    const changePages = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPages(e.target.value);
    };

    const onGenerateClick = async () => {
      try {
        setLoadingMessage('Generating the new lesson...');
        setIsLoading(true);
        const request: GenerateLessonRequestDto = {
          chapterIndex: chapter ? addLessonSettings?.chapters?.indexOf(chapter) : -1,
          lessonType: lessonType as LessonTypeDto,
          pages: pages
        };
        await dispatch(generateLesson(request)).unwrap().then(async (newLessonId) => {
          addModifiedLesson(newLessonId);
          setLoadingMessage('Redirecting to the learnings page...');
          await timeout(3000); // await a second before redirecting
          navigate('/learnings');
        });
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
        setLoadingMessage('');
      }
    };

    useEffect(() => {
      getAddLessonSettings();
    }, [getAddLessonSettings]);

    return {
        lessonType,
        chapter,
        // chapters,
        pages,
        addLessonSettings,
        isLessonSettingsLoading,
        
        changeChapter,
        changeLessonType,
        changePages,
        onGenerateClick,

        isLoading,
        loadingMessage
    }
};

export default useAddLesson;