import { getTheme } from "@fluentui/react";
import { IIconProps } from "../../../../common/models/Icons/icon-props";

const Add = (props: IIconProps) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 3C4.34315 3 3 4.34315 3 6V14C3 15.6569 4.34315 17 6 17H14C15.6569 17 17 15.6569 17 14V6C17 4.34315 15.6569 3 14 3H6ZM4 6C4 4.89543 4.89543 4 6 4H14C15.1046 4 16 4.89543 16 6V14C16 15.1046 15.1046 16 14 16H6C4.89543 16 4 15.1046 4 14V6ZM10.5 6.50021C10.5 6.22407 10.2761 6.00021 10 6.00021C9.72386 6.00021 9.5 6.22407 9.5 6.50021V9.50021H6.5C6.22386 9.50021 6 9.72407 6 10.0002C6 10.2764 6.22386 10.5002 6.5 10.5002H9.5V13.5002C9.5 13.7764 9.72386 14.0002 10 14.0002C10.2761 14.0002 10.5 13.7764 10.5 13.5002V10.5002H13.5C13.7761 10.5002 14 10.2764 14 10.0002C14 9.72407 13.7761 9.50021 13.5 9.50021H10.5V6.50021Z" fill={props.color || getTheme().palette.neutralTertiary}/>
    </svg>
  )
};

export default Add;