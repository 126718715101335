import { useState } from "react";
import { getClassNames } from "./CourseModal.classes";
import Modal from "../../../../../common/components/Modal-copy/modal";
import useModalSettings, { ActionTypes } from "./hooks/useModalSettings";
import { getTheme } from "@fluentui/react";
import PrimaryButton from "../../../../../common/components/Buttons/PrimaryButton/primary-button";
import Input from "../../../../../common/components/Inputs/Input/input";
import { useAppDispatch } from "../../../../../store/store";
import Categories from "./components/Categories/Categories";
import { updateCategories } from "../../../../../store/slices/settings";

interface IScormModalProps {
  isOpen: boolean;
  onDismiss: () => void;
}

export default function CourseModal({ isOpen, onDismiss }: IScormModalProps) {
  const [isSettingsChanged, setIsSettingsChanged] = useState<boolean>(false);
  const { courseSettingsForm, courseSettingsFormDispatch } = useModalSettings();
  const dispatch = useAppDispatch();
  const classes = getClassNames();

  function enableSaveButton () {
    setIsSettingsChanged(true);
  }

  async function saveCourseSettings() {
    try {
      await dispatch(updateCategories(courseSettingsForm.categories || [])).unwrap();
      setIsSettingsChanged(false);
    } catch (err) {
      console.error(err);
    }
  }
  
  return (
     <Modal
        isOpen={isOpen}
        width={580}
        title="Course settings"
        onSave={saveCourseSettings}
        onDismiss={() => onDismiss()}
        saveButtonEnabled={isSettingsChanged}
     >
      <div className={classes.scormSettingsContainer}>
      <Categories categories={courseSettingsForm.categories || []} dispatch={courseSettingsFormDispatch} enableSaveButton={enableSaveButton} />
      <hr className={classes.divider} />
      <div className={classes.newDomainContainer}>
        <Input
          id=""
          name=""
          onChange={(e) => courseSettingsFormDispatch({ type: ActionTypes.UPDATE_INPUT, payload: e.target.value })}
          title="New category"
          value={courseSettingsForm.categoryToAdd}
          flex
        />
        <PrimaryButton
          // iconName={IconNames.ADD}
          disabled={courseSettingsForm.categoryToAdd === ''}
          color={getTheme().palette.white}
          onClick={() => {
            courseSettingsFormDispatch({ type: ActionTypes.ADD_CATEGORY });
            enableSaveButton();
          }}
        >
          Add
        </PrimaryButton>
      </div>
    </div>
     </Modal>
  )
}
