import { IIconProps } from "../../../../common/models/Icons/icon-props";

const AddSchedule = (props: IIconProps) => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.5 0.5C12.8807 0.5 14 1.61929 14 3V7.09971C13.6832 6.93777 13.3486 6.80564 13 6.70703V4.5H1V12C1 12.8284 1.67157 13.5 2.5 13.5H6.20703C6.30564 13.8486 6.43777 14.1832 6.59971 14.5H2.5C1.11929 14.5 0 13.3807 0 12V3C0 1.61929 1.11929 0.5 2.5 0.5H11.5ZM11.5 1.5H2.5C1.67157 1.5 1 2.17157 1 3V3.5H13V3C13 2.17157 12.3284 1.5 11.5 1.5ZM16 12C16 14.4853 13.9853 16.5 11.5 16.5C9.01472 16.5 7 14.4853 7 12C7 9.51472 9.01472 7.5 11.5 7.5C13.9853 7.5 16 9.51472 16 12ZM12 10C12 9.72386 11.7761 9.5 11.5 9.5C11.2239 9.5 11 9.72386 11 10V11.5H9.5C9.22386 11.5 9 11.7239 9 12C9 12.2761 9.22386 12.5 9.5 12.5H11V14C11 14.2761 11.2239 14.5 11.5 14.5C11.7761 14.5 12 14.2761 12 14V12.5H13.5C13.7761 12.5 14 12.2761 14 12C14 11.7239 13.7761 11.5 13.5 11.5H12V10Z" fill={props.color || 'white'}/>
    </svg>
  )
};

export default AddSchedule;