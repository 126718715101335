import { useReducer } from 'react';
import { IDropdownOption } from '@fluentui/react';
import { ScormStandard } from '../../../../../../../../../api-client';

// Define the state structure
export interface IScormDetailsState {
  scormVersion: ScormStandard;
  includeAudio: boolean;
  allLanguages: boolean;
  languages: string[];
}

// Define the actions
type TScormDetailsAction =
  | { type: 'UPDATE_BOOLEANS'; payload: { name: string } }
  | { type: 'UPDATE_LANGUAGES'; payload: IDropdownOption }
  | { type: 'UPDATE_SCORM_VERSION'; payload: ScormStandard };

// Reducer function
function scormDetailsReducer(state: IScormDetailsState, action: TScormDetailsAction): IScormDetailsState {
  switch (action.type) {
    case 'UPDATE_SCORM_VERSION':
      return {
        ...state,
        scormVersion: action.payload,
      };

    case 'UPDATE_BOOLEANS':
      return {
        ...state,
        [action.payload.name]: !state[action.payload.name as keyof IScormDetailsState],
      };
    case 'UPDATE_LANGUAGES':
      const newLanguages = [...state.languages];
      if (action.payload.selected) newLanguages.push(action.payload.key.toString());
      else newLanguages.splice(newLanguages.findIndex(lang => lang === action.payload.key), 1); 

      return {
        ...state,
        languages: newLanguages,
      };
    default:
      return state;
  }
}

// Custom hook
function useScormDetails() {
  // Initial state
  const initialState: IScormDetailsState = {
    scormVersion: ScormStandard.V12,
    includeAudio: true,
    allLanguages: true,
    languages: [],
  };

  const [state, dispatch] = useReducer(scormDetailsReducer, initialState);

  return { state, dispatch };
}

export default useScormDetails;
