import './App.scss';
import { Provider } from "react-redux";
import Authentication from "./hocs/Authentication/Authentication";
import { store } from "./store/store";
import Router from "./hocs/Router/Router";
import Application from "./hocs/Application/Application";

export default function App() {
  return (
    <Provider store={store}>
      <Authentication>
        <Application>
          <Router />
        </Application>
      </Authentication>
    </Provider>
  );
}