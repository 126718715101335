import { Dispatch } from 'react';

// import styles
import { getClassNames } from "./Domains.classNames";
import { getTheme } from "@fluentui/react";

// import components
import PrimaryButton from "../../../../../../../common/components/Buttons/PrimaryButton/primary-button";

// import utils
import { IconNames } from "../../../../../../../common/models/Icons/icon-names";
import { IScormSettingsForm, ActionTypes, IAction } from '../../../../../hooks/useScormSettings';

interface IDomainsProps {
  form: IScormSettingsForm;
  dispatch: Dispatch<IAction>;
  enableSaveButton: () => void;
}

export default function Domains(props: IDomainsProps) {
  const classes = getClassNames();
  const theme = getTheme();

  return (
    <div className={classes.collectionContainer}>
      <p>Add or delete domains</p>
        <div className={classes.languagesContainer}>
          {props.form.domains?.map((domain, index) => (
            <div key={index} className={classes.languageContainer}>
              <div className={classes.languageItem}>
                <div className={classes.language}>{domain}</div>
              </div>
            <PrimaryButton
              type="button"
              onClick={() => {
                props.dispatch({ type: ActionTypes.DELETE_DOMAIN, payload: index });
                props.enableSaveButton();
              }}
              iconName={IconNames.DELETE}
              color={theme.palette.themePrimary}
              background={theme.palette.themeLighter}
              width={90} 
              padding={10}
              gap={5}
            >
              Delete
            </PrimaryButton>
            </div>
          ))}
        </div>
    </div>
  )
}