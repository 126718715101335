import { mergeStyleSets } from '@fluentui/react';
import { getTheme } from '@fluentui/react';
import { BorderRadius } from '../../../utils/constants/styles';

interface IMenuClassNames {
  menuContainer: string;
  navbarContainer: string;
  logoContainer: string;
  itemsContainer: string;
  primaryItems: string;
  secondaryItems: string;
  microlearningIcon: string;
  microlearningLabel: string;
  link: string;
  activeLink: string;

  filtersContainer: string;
  filters: string;
  filterItem: string;
  selected: string;

  navbarEnter: string;
  navbarEnterActive: string;
  navbarEnterDone: string;
  navbarExit: string;
  navbarExitActive: string;

  filtersEnter: string;
  filtersEnterActive: string;
  filtersEnterDone: string;
  filtersExit: string;
  filtersExitActive: string;
}

export const getClassNames = (showMenu: boolean, expandedMenu: boolean): IMenuClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    menuContainer: {
      zIndex: 2,
      overflow: 'hidden',
      width: showMenu ? expandedMenu ? 230 : 90 : 0,
      backgroundColor: theme.palette.white,
      boxShadow: '0px 0px 30px 0px rgba(0, 0, 0, 0.2)',
      transition: 'all 500ms',
    },

    navbarContainer: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 40,
      padding: '20px 15px',
    },

    logoContainer: {
      width: 60,
      display: 'flex',
      // justifyContent: 'center',
      alignItems: 'center',
      gap: 30,
      marginBottom: 50,
    },

    itemsContainer: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      // justifyContent: 'space-between',
      gap: 40,
      // paddingBottom: 80,
    },

    primaryItems: {
      display: 'flex',
      flexDirection: 'column',
      gap: 40,
    },

    secondaryItems: {
      display: 'flex',
      flexDirection: 'column',
      gap: 40,
    },

    microlearningIcon: {
      width: 40,
      marginLeft: 10,
    },

    microlearningLabel: {
      margin: 0,
      fontSize: 10,
      fontWeight: 900,
    },

    link: {
      fontSize: 18,
      width: 60,
      aspectRatio: '1 / 1',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textDecoration: 'none',
      color: 'black',
      backgroundColor: theme.palette.neutralLight,
      transition: 'all 350ms',
      borderRadius: BorderRadius,

    },

    activeLink: {
      fontWeight: 600,
      color: 'red',
      backgroundColor: theme.palette.themeLight,
      transition: 'all 350ms',

      '*': {
        fill: theme.palette.themePrimary,
      }
    },

    filtersContainer: {
      overflow: 'hidden',
      position: 'relative',
      width: '200px',
      padding: 10,
      marginTop: 10,
      borderLeft: `3px solid ${theme.palette.themeTertiary}`,
    },

    filters: {
      display: 'flex',
      flexDirection: 'column',
      listStyleType: 'none',
      overflow: 'hidden',
    },

    filterItem: {
      padding: '10px 10px',
      borderRadius: BorderRadius,
      textDecoration: 'none',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      cursor: 'pointer',
      transition: 'all 500ms',
    },

    selected: {
      background: theme.palette.themeLighter,
      transition: 'all 500ms',
    },

    navbarEnter: {
      maxWidth: 0,
    },
    navbarEnterActive: {
      maxWidth: 230,
      transition: 'all 500ms',
    },
    navbarEnterDone: {
      maxWidth: 230,
    },
    navbarExit: {
      maxWidth: 230,
    },
    navbarExitActive: {
      maxWidth: 0,
      transition: 'all 500ms',
    },


    filtersEnter: {
      width: '0px !important',
      maxHeight: 0,
      padding: '0px !important',
      marginTop: '0px !important',
    },
    filtersEnterActive: {
      width: '200px !important',
      maxHeight: 300,
      padding: '10px !important',
      marginTop: '10px !important',
      transition: 'all 500ms',
    },
    filtersEnterDone: {
      width: '200px !important',
      padding: '10px !important',
      marginTop: '10px !important',
    },
    filtersExit: {
      width: '200px !important',
      maxHeight: 300,
      padding: '10px !important',
      marginTop: '10px !important',
    },
    filtersExitActive: {
      width: '0px !important',
      maxHeight: 0,
      padding: '0px !important',
      marginTop: '0px !important',
      transition: 'all 500ms',
    },
  })
}