import { getTheme } from "@fluentui/react";

const InfoSolid = () => {

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12ZM13.25 6C13.25 6.69036 12.6904 7.25 12 7.25C11.3096 7.25 10.75 6.69036 10.75 6C10.75 5.30964 11.3096 4.75 12 4.75C12.6904 4.75 13.25 5.30964 13.25 6ZM12 9C12.5523 9 13 9.44772 13 10L13 18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18L11 10C11 9.44771 11.4477 9 12 9Z" fill={getTheme().palette.themeSecondary}/>
    </svg>
  )
}

export default InfoSolid;