import { mergeStyleSets, getTheme } from "@fluentui/react";
import { BorderRadius } from "../../../utils/constants/styles";

interface INoPermissionClassNames {
  noPermissionContainer: string;
  noPermissionMessageContainer: string;
}

export const getClassNames = (): INoPermissionClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    noPermissionContainer: {
      width: '100%',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    noPermissionMessageContainer: {
      width: 350,
      padding: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: theme.palette.neutralLighterAlt,
      borderRadius: `${BorderRadius} ${BorderRadius} 0 0`,
      borderBottom: `2px solid ${theme.palette.themePrimary}`
    }
  });
};