import { mergeStyleSets, getTheme } from "@fluentui/react"
import { BorderRadius } from "../../../../../../../../../utils/constants/styles";

interface IBulletPointsLessonClassNames {
  editLessonContainer: string;
  titleGroupInput: string;
  titleInput: string;
  titleLabel: string;
  introductionGroupInput: string;
  introductionInput: string;
  bulletPointsGroupInput: string;
  bulletPointGroupInput: string;
  bulletPointContainer: string;
  bulletPointInput: string;
  newBulletPointContainer: string;
  newBulletPointInput: string;
}

export const getClassNames = (disabled: boolean): IBulletPointsLessonClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    editLessonContainer: {
      overflow: 'auto',
      flex: 1,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      gap: 20,
      paddingRight: 20,
    },

    titleGroupInput: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 5
    },

    titleInput: {
      width: '100%',
      border: 'none',
      background: theme.palette.neutralLighterAlt,
      borderRadius: BorderRadius,
      fontSize: theme.fonts.large.fontSize,
      padding: '5px 10px',
      outline: 'none',
      resize: 'none',
      opacity: disabled ? 0.3 : 1,
    },

    titleLabel: {
      color: theme.palette.neutralSecondary,
      fontWeight: theme.fonts.medium.fontWeight
    },

    introductionGroupInput: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 5,
    },

    introductionInput: {
      width: '100%',
      border: 'none',
      background: theme.palette.neutralLighterAlt,
      borderRadius: BorderRadius,
      fontSize: theme.fonts.large.fontSize,
      padding: '5px 10px',
      outline: 'none',
      resize: 'none',
      opacity: disabled ? 0.3 : 1,
    },

    bulletPointsGroupInput: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 10
    },

    bulletPointGroupInput: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 5,
      ...(disabled && {
        pointerEvents: 'none'
      }),
    },

    bulletPointContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 10,
    },

    bulletPointInput: {
      flex: 1,
      border: 'none',
      background: theme.palette.neutralLighterAlt,
      borderRadius: BorderRadius,
      fontSize: theme.fonts.large.fontSize,
      padding: '5px 10px',
      outline: 'none',
      resize: 'none',
      opacity: disabled ? 0.3 : 1,
    },

    newBulletPointContainer: {
      marginTop: 20,
      paddingLeft: 34,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: 10
    },

    newBulletPointInput: {
      flex: 1,
      border: 'none',
      background: theme.palette.neutralLighterAlt,
      borderRadius: BorderRadius,
      fontSize: theme.fonts.large.fontSize,
      padding: '5px 10px',
      outline: 'none',
      resize: 'none',
      opacity: disabled ? 0.3 : 1,
    },
  });
};