import appsettings from '../../config/appsettings.json';
import appthemes from '../../config/appthemes.json';
import { env } from '../../config/env';

export class ApplicationSettings{
    public static readonly SHOW_LOG = appsettings[env].showLog;
    public static readonly APPLICATION_DEFAULT_THEME = appthemes['defaultTheme'];
    public static readonly APPLICATION_AUTHORITY = appsettings[env].authority;
    public static readonly APPLICATION_CLIENTID = appsettings[env].clientId;
    public static IAI_APP_API_URL = appsettings[env].baseApiUrl;
    public static IAI_APP_API_RESOURCE = appsettings[env].resource;
    public static IAI_APP_API_SCOPE = appsettings[env].scope;
    public static IAI_FUNCTION_APP_API_URL = appsettings[env].baseFunctionApiUrl;
    public static IAI_APP_AXIOS_TIMEOUT = appsettings[env].axiosTimeout;
}