// import react
import { Dispatch } from 'react';

// import styles
import { getClassNames } from './details.classNames';

// import models
import { ICreateForm, CreateFormKeys } from '../../../../../../../common/models/Create/form-data';

// import utils
import CourseDetails from './CourseDetails/CourseDetails';
import CourseOptions from './CourseOptions/CourseOptions';
import { IAction } from '../../UploadModal';

interface IDetailsProps {
  formData: ICreateForm;
  dispatch: Dispatch<IAction>;
}

const Details = ({formData, dispatch}: IDetailsProps) => {
  const classes = getClassNames();
  
  return (
    <div className={classes.courseDetailsContainer}>
      <p>Course details</p>
      <CourseDetails
        details={{ title: formData.title, description: formData.description }}
        dispatch={dispatch}
      />
      <CourseOptions
        options={{
          [CreateFormKeys.CATEGORY]: formData[CreateFormKeys.CATEGORY],
          [CreateFormKeys.LESSONS_NUMBER]: formData[CreateFormKeys.LESSONS_NUMBER],
          [CreateFormKeys.DOCUMENT_LANGUAGE]: formData[CreateFormKeys.DOCUMENT_LANGUAGE],
          [CreateFormKeys.TYPE_OF_LESSONS]: formData[CreateFormKeys.TYPE_OF_LESSONS],
          [CreateFormKeys.GENERATE_AUDIO]: formData[CreateFormKeys.GENERATE_AUDIO],
        }}
        dispatch={dispatch}
      />
    </div>
  )
}

export default Details;