import { mergeStyleSets, getTheme } from '@fluentui/react';

interface ICardClassNames{
  lessonContainer: string;
  header: string;
  body: string;
  hr: string;
}

export const getClassNames = (): ICardClassNames => {
  const theme = getTheme();
    return mergeStyleSets({
      lessonContainer: {
        overflow: 'hidden',
        width: 340,
        height: 460,
        background: theme.palette.white,
        borderTop: `3px solid ${theme.palette.themePrimary}`,
        borderBottom: `3px solid ${theme.palette.themePrimary}`,
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        cursor: 'move',

        '*::-webkit-scrollbar': {
          width: 6,
        },
        '*::-webkit-scrollbar-track': {
          background: theme.palette.white,
          borderRadius: '20px 20px 20px 0px',
        },
        '*::-webkit-scrollbar-thumb': {
          borderRadius: 20,
          background: theme.palette.neutralTertiary,
        },
      },

      header: {
        flex: '0 0 80px',
        padding: 20,
        color: theme.palette.themePrimary,
        background: theme.palette.themeLighterAlt
      },

      body: {
        overflow: 'hidden',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',

        '*': {
          overflowWrap: 'break-word',
        }
      },

      hr: {
        margin: '0 20px',
        borderTop: `1px solid ${theme.palette.neutralLight}`
      }
    });
};