import { getClassNames } from './buttons.classNames';
import PrimaryButton from '../../../../../../../../common/components/Buttons/PrimaryButton/primary-button';
import DefaultButton from '../../../../../../../../common/components/Buttons/DefaultButton/default-button';
import { BulletPointLessonDto, LessonTypeDto, QuizLessonDto } from '../../../../../../../../api-client';
import { IFormData } from '../../hooks/useForm';

interface IButtonsProps {
  lessonType: LessonTypeDto | null | undefined;
  formData: IFormData;
  onSaveClick: () => Promise<void>;
  onCancelClick: () => void;
}

const Buttons = (props: IButtonsProps) => {
  const classes = getClassNames();

  const isSaveDisabled = (): boolean => {
    if (props.lessonType === LessonTypeDto.Quiz) {
      const quizLesson = (props.formData as QuizLessonDto);
      if (quizLesson.options && quizLesson.options.length < 2) return true;
    }
    if (props.lessonType === LessonTypeDto.BulletPoints) {
      const bulletPointLesson = (props.formData as BulletPointLessonDto);
      if (bulletPointLesson.bulletPoints && bulletPointLesson.bulletPoints.length < 2) return true;
    }
    return false;
  }

  return (
    <div className={classes.buttonsContainer}>
      <DefaultButton onClick={props.onCancelClick}>Cancel</DefaultButton>
      <PrimaryButton onClick={props.onSaveClick} disabled={isSaveDisabled()}>Save</PrimaryButton>
    </div>
  )
}

export default Buttons;