import { Dispatch, ChangeEvent } from 'react';
import { getClassNames } from "./text-lesson.classNames";
import { TextLessonDto } from "../../../../../../../../../api-client";
import useTextAreaRef from './hooks/useTextAreaRef';
import { ActionTypes, IAction, IFormData } from '../../../hooks/useForm';

interface ITextLessonProps {
  formData: IFormData;
  dispatch: Dispatch<IAction>;
  disabled: boolean;
}

const TextLesson = (props: ITextLessonProps) => {
  const classes = getClassNames(props.disabled);
  const textAreaRefs = useTextAreaRef();

  const lessonData = props.formData.lessonData as TextLessonDto;

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;

    props.dispatch({ type: ActionTypes.UPDATE_INPUT, payload: { name: e.target.name, value: e.target.value } });
  };

  return (
    <>
     <div className={classes.titleGroupInput}>
        <h5 className={classes.titleLabel}>Title</h5>
        <textarea 
          className={classes.titleInput} 
          id='title' 
          name='title'
          rows={1}
          value={lessonData.title || ''}
          onChange={onChange}
          ref={textAreaRefs.title}
          disabled={props.disabled}
        />
      </div>
      
      <div className={classes.descriptionGroupInput}>
        <h5 className={classes.titleLabel}>Description</h5>
        <textarea 
          className={classes.descriptionInput}
          id='body' 
          name='body'
          value={lessonData.body || ''}
          onChange={onChange}
          rows={1}
          ref={textAreaRefs.body}
          disabled={props.disabled}
        />
      </div>
    </>
  )
};

export default TextLesson;