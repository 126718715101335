import { mergeStyleSets, getTheme } from "@fluentui/react";
import { IDefaultButtonProps } from "./default-button";
import { BorderRadius } from "../../../../utils/constants/styles";

interface IDefaultButtonClassNames {
  'button': string;
  'icon': string;
}

export const getClassNames = (props: IDefaultButtonProps): IDefaultButtonClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    'button': {
      width: props.width,
      height: props.height,
      border: `1px solid ${theme.palette.neutralQuaternary}`,
      borderRadius: BorderRadius,
      transition: 'background-color 500ms',
    },

    'icon': {
      marginRight: 10,
      fontSize: 18,
    }
  });
}