// import react
import { createContext, useState } from 'react';

let sessionModifiedLessons: string[] = [];
const session = sessionStorage.getItem('iai-microlearning-modifiedlessons');
if (session) sessionModifiedLessons = JSON.parse(session);
else sessionStorage.setItem('iai-microlearning-modifiedlessons', JSON.stringify([]));

export interface IInfoContext {
  info: {
    modifiedLessons: string[];
    addModifiedLesson: (lessonId: string) => void;
  };
}

export const InfoContext = createContext<IInfoContext>({
  info: {
    modifiedLessons: [],
    addModifiedLesson: () => {},
  },
});

export const InfoProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [modifiedLessons, setModifiedLessons] = useState<string[]>(sessionModifiedLessons);

  const addModifiedLesson = (lessonId: string) => {
    const newModifiedLessons = [...modifiedLessons];
    newModifiedLessons.push(lessonId);
    setModifiedLessons(newModifiedLessons);
    sessionStorage.setItem('iai-microlearning-modifiedlessons', JSON.stringify(newModifiedLessons));
  }

  return (
    <InfoContext.Provider value={{ 
      info: {
        modifiedLessons,
        addModifiedLesson
      },
      }}>
      {children}
    </InfoContext.Provider>
  )
};