import { mergeStyleSets } from '@fluentui/react';
import { getTheme } from '@fluentui/react';
import { BorderRadius, IFadeClassNames, FADE } from '../../../../../utils/constants/styles';

interface IModalClassNames extends IFadeClassNames {
  form: string;
  loadingBackground: string;
  spinnerContainer: string;
}

export const getClassNames = (): IModalClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    'form': {
      overflow: 'auto',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      gap: 20,
    },

    loadingBackground: {
      zIndex: 100,
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },

    spinnerContainer: {
      width: 150,
      height: 150,
      background: theme.palette.white,
      borderRadius: BorderRadius,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },

    ...FADE.classNames,
  })
}