import { IIconProps } from "../../../../common/models/Icons/icon-props";

const Schedule = (props: IIconProps): JSX.Element => {
  return (
    <svg width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 8C4.55228 8 5 7.55229 5 7C5 6.44771 4.55228 6 4 6C3.44772 6 3 6.44771 3 7C3 7.55229 3.44772 8 4 8ZM5 10C5 10.5523 4.55228 11 4 11C3.44772 11 3 10.5523 3 10C3 9.44771 3.44772 9 4 9C4.55228 9 5 9.44771 5 10ZM7 8C7.55229 8 8 7.55229 8 7C8 6.44771 7.55229 6 7 6C6.44771 6 6 6.44771 6 7C6 7.55229 6.44771 8 7 8ZM8 10C8 10.5523 7.55229 11 7 11C6.44771 11 6 10.5523 6 10C6 9.44771 6.44771 9 7 9C7.55229 9 8 9.44771 8 10ZM10 8C10.5523 8 11 7.55229 11 7C11 6.44771 10.5523 6 10 6C9.44771 6 9 6.44771 9 7C9 7.55229 9.44771 8 10 8ZM14 2.5C14 1.11929 12.8807 0 11.5 0H2.5C1.11929 0 0 1.11929 0 2.5V11.5C0 12.8807 1.11929 14 2.5 14H11.5C12.8807 14 14 12.8807 14 11.5V2.5ZM1 4H13V11.5C13 12.3284 12.3284 13 11.5 13H2.5C1.67157 13 1 12.3284 1 11.5V4ZM2.5 1H11.5C12.3284 1 13 1.67157 13 2.5V3H1V2.5C1 1.67157 1.67157 1 2.5 1Z" fill="#232323"/>
    </svg>
  )
};

export default Schedule;