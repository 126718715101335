import { useCallback, useRef } from 'react';

// import styles
import { getClassNames } from "./Learning.classNames";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { ANIMATION_DURATION } from "../../../utils/constants/styles";

// import components
import Loading from "../../../common/components/Loading/Loading";
import Header from "./components/Header/header";
import Lessons from "./components/Lessons/lessons";
import ConfigureModal from "./components/Modals/ConfigureModal/configure-modal";
import EditModal from "./components/Modals/EditModal/edit-modal";
import ScheduleModal from "./components/Modals/ScheduleModal/schedule-modal";

// import hooks
import useLearningDetailsPage from "./hooks/useLearningDetailsPage";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from 'react-redux';

// import redux
import { RootState, useAppDispatch } from '../../../store/store';
import { ApiStatus, cleanCourses, getCourse } from '../../../store/slices/courses';
import QuizModal from "./components/Modals/QuizModal/quiz-modal";
import Error from "../../../common/components/Error/error";
import ErrorDialog from "./components/ErrorDialog/error-dialog";
import DeleteDialog from "./components/DeleteDialog/delete-dialog";
import GenerateLessonModal from './components/Modals/GenerateLessonModal/generate-lesson-modal';
import useAddLessonSettings from './hooks/useAddLessonSettings';
import { FileTypeDto } from '../../../api-client';

const Learning = () => {
  const dispatch = useAppDispatch();
  const {courses} = useSelector((state: RootState) => state.courses);
  const apiStatus = useSelector((state: RootState) => state.courses.apiStatus);
  const classes = getClassNames();
  const {
    editModalActions,
    configureModalActions,
    scheduleModalActions,
    quizModalActions,
    deleteDialogActions,
    generateLessonModalActions
  } = useLearningDetailsPage();
  const {
    addLessonSettings,
    getAddLessonSettings
  } = useAddLessonSettings();
  const { id, lang } = useParams();

  const courseRef = useRef<HTMLDivElement>(null);
  const loadingRef = useRef<HTMLDivElement>(null);
  const errorRef = useRef<HTMLDivElement>(null);
  
  function getNodeRef() {
    if (apiStatus.startingStatus.api === ApiStatus.LOADING) return loadingRef;
    if (apiStatus.startingStatus.api === ApiStatus.ERROR) return errorRef;
    if (apiStatus.startingStatus.api === ApiStatus.SUCCESS) return courseRef;
  }

  function getNodeKey() {
    if (apiStatus.startingStatus.api === ApiStatus.LOADING) return 'loading';
    if (apiStatus.startingStatus.api === ApiStatus.ERROR) return 'error';
    if (apiStatus.startingStatus.api === ApiStatus.SUCCESS) return 'course';
  }

  const nodeRef = getNodeRef();
  const nodeKey = getNodeKey();

  const modalRef = useRef<HTMLDivElement>(null);

  const courseInit = useCallback(async () => {
    if (!id) return;
    await dispatch(getCourse({ id, lang })).unwrap();
    // if (!course) await dispatch(getCourse({ id, lang })).unwrap();
  }, [id, lang, dispatch]);

  useEffect(() => {
    courseInit();
  }, [courseInit]);

  useEffect(() => {
    if (!!courses) dispatch(cleanCourses());
  }, [courses, dispatch]);

  useEffect(() => {
    if (!addLessonSettings) getAddLessonSettings();
  }, [getAddLessonSettings, addLessonSettings]);

  return ( 
    <section className={classes.learningViewPage}>
      <SwitchTransition>
        <CSSTransition
          nodeRef={nodeRef}
          key={nodeKey}
          timeout={ANIMATION_DURATION}
          classNames={{
            enter: classes.enter,
            enterActive: classes.enterActive,
            exit: classes.exit,
            exitActive: classes.exitActive
          }}
          unmountOnExit
        >
          <div ref={nodeRef} style={{overflow: 'hidden'}}>
            {apiStatus.startingStatus.api === ApiStatus.LOADING && <Loading label='Loading course...' position='absolute' />}
            {apiStatus.startingStatus.api === ApiStatus.ERROR && <Error message='Error loading the course...' />}
            {apiStatus.startingStatus.api === ApiStatus.SUCCESS && (
              <div className={classes.courseContainer}>
                <Header
                  onConfigureModalClick={configureModalActions.onConfigureModalClick}
                  onScheduleModalClick={scheduleModalActions.onScheduleModalClick}
                  onQuizModalClick={quizModalActions.onQuizModalClick}
                  onDeleteClick={deleteDialogActions.onDeleteClick}
                  onEditLessonClick={editModalActions.onEditLessonClick}
                  onGenerateLessonClick={generateLessonModalActions.onGenerateLessonClick}
                  showGenerateFromAI={addLessonSettings?.fileType !== FileTypeDto.None && !addLessonSettings?.showError}
                />

                <CSSTransition
                  nodeRef={modalRef}
                  timeout={ANIMATION_DURATION}
                  in={apiStatus.courseStatus.api === ApiStatus.LOADING}
                  classNames={{
                    enter: classes.enter,
                    enterActive: classes.enterActive,
                    exit: classes.exit,
                    exitActive: classes.exitActive
                  }}
                  unmountOnExit
                >
                  <Loading
                    nodeRef={modalRef}
                    animation={apiStatus.courseStatus.api === ApiStatus.LOADING}
                    label={apiStatus.courseStatus.loadingMessage}  
                  />
                </CSSTransition>
                
                <CSSTransition
                  nodeRef={modalRef}
                  timeout={ANIMATION_DURATION}
                  in={apiStatus.courseStatus.api === ApiStatus.ERROR}
                  classNames={{
                    enter: classes.enter,
                    enterActive: classes.enterActive,
                    exit: classes.exit,
                    exitActive: classes.exitActive
                  }}
                  unmountOnExit
                >
                  <ErrorDialog
                    nodeRef={modalRef}
                    animation={apiStatus.courseStatus.api === ApiStatus.ERROR}
                  />
                </CSSTransition>
                
                <CSSTransition
                  nodeRef={modalRef}
                  timeout={ANIMATION_DURATION}
                  in={deleteDialogActions.showDeleteDialog}
                  classNames={{
                    enter: classes.enter,
                    enterActive: classes.enterActive,
                    exit: classes.exit,
                    exitActive: classes.exitActive
                  }}
                  unmountOnExit
                >
                  <DeleteDialog
                    nodeRef={modalRef}
                    animation={deleteDialogActions.showDeleteDialog}
                    type={deleteDialogActions.deleteType}
                    onCancelDeleteLearning={deleteDialogActions.onCloseDelete}
                  />
                </CSSTransition>

                <Lessons
                  onEditLessonClick={editModalActions.onEditLessonClick}
                  onDeleteClick={deleteDialogActions.onDeleteClick}
                />

                <CSSTransition
                  nodeRef={modalRef}
                  timeout={ANIMATION_DURATION}
                  in={generateLessonModalActions.showGenerateLessonModal}
                  classNames={{
                    enter: classes.enter,
                    enterActive: classes.enterActive,
                    exit: classes.exit,
                    exitActive: classes.exitActive
                  }}
                  unmountOnExit
                >
                  <GenerateLessonModal
                    nodeRef={modalRef}
                    onCloseGenerate={generateLessonModalActions.onCloseGenerateLessonModal}
                    animation={generateLessonModalActions.showGenerateLessonModal}
                    addLessonSettings={addLessonSettings}
                  />
                </CSSTransition>

                <CSSTransition
                  nodeRef={modalRef}
                  timeout={ANIMATION_DURATION}
                  in={configureModalActions.showConfigureModal}
                  classNames={{
                    enter: classes.enter,
                    enterActive: classes.enterActive,
                    exit: classes.exit,
                    exitActive: classes.exitActive
                  }}
                  unmountOnExit
                >
                  <ConfigureModal 
                    nodeRef={modalRef}
                    onCloseConfigure={configureModalActions.onCloseConfigure}
                    animation={configureModalActions.showConfigureModal}
                  />
                </CSSTransition>

                <CSSTransition
                  nodeRef={modalRef}
                  timeout={ANIMATION_DURATION}
                  in={scheduleModalActions.showScheduleModal}
                  classNames={{
                    enter: classes.enter,
                    enterActive: classes.enterActive,
                    exit: classes.exit,
                    exitActive: classes.exitActive
                  }}
                  unmountOnExit
                >
                  <ScheduleModal 
                    nodeRef={modalRef}
                    onCloseSchedule={scheduleModalActions.onCloseSchedule}
                    animation={scheduleModalActions.showScheduleModal}
                  />
                </CSSTransition>

                <CSSTransition
                  nodeRef={modalRef}
                  timeout={ANIMATION_DURATION}
                  in={editModalActions.showEditModal}
                  classNames={{
                    enter: classes.enter,
                    enterActive: classes.enterActive,
                    exit: classes.exit,
                    exitActive: classes.exitActive
                  }}
                  unmountOnExit
                >
                  <EditModal 
                    nodeRef={modalRef}
                    onCloseEditModal={editModalActions.onCloseEditModal}
                    animation={editModalActions.showEditModal}
                  />
                </CSSTransition> 

                <CSSTransition
                  nodeRef={modalRef}
                  timeout={ANIMATION_DURATION}
                  in={quizModalActions.showQuizModal}
                  classNames={{
                    enter: classes.enter,
                    enterActive: classes.enterActive,
                    exit: classes.exit,
                    exitActive: classes.exitActive
                  }}
                  unmountOnExit
                >
                  <QuizModal 
                    nodeRef={modalRef}
                    onCloseQuizModal={quizModalActions.onCloseQuizModal}
                    animation={quizModalActions.showQuizModal}
                  />
                </CSSTransition> 

              </div>
            )}
          </div>
        </CSSTransition>
      </SwitchTransition>
      
    </section>
  )
}

export default Learning;