import { Spinner, SpinnerSize } from '@fluentui/react';
import { Logger } from '../../utils/logger';
import { getClassNames } from './Login.classNames';

const Login = () => {
  Logger.render("Login");

  const classes = getClassNames();

  return (
    <div style={{width: '100vw', height: '100vh', overflow: 'hidden', position: 'relative',}}>
      <section className={classes['login-page-section']}>
      </section>
      <div className={classes['login-page-container']}>
        <img src='./assets/app-logo.webp' alt='' />
        <div className={classes['login-page-heading']}>Logging in...</div>
        <Spinner size={SpinnerSize.large} />
      </div>
    </div>
  )
};

export default Login;