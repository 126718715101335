import { getClassNames } from './buttons.classNames';
import PrimaryButton from '../../../../../../common/components/Buttons/PrimaryButton/primary-button';
import DefaultButton from '../../../../../../common/components/Buttons/DefaultButton/default-button';
import { useAppDispatch } from '../../../../../../store/store';
import { cleanLessonToDelete, deleteCourse, deleteLesson } from '../../../../../../store/slices/courses';
import { useNavigate, useParams } from 'react-router-dom';

interface IButtonsProps {
  onCancelClick: () => void;
  type: 'Learning' | 'Lesson' | undefined;
}

const Buttons = (props: IButtonsProps) => {
  const dispatch = useAppDispatch();
  const navigate =  useNavigate();
  const { lang } = useParams();
  const classes = getClassNames();

  async function onDeleteCourse() {
    try {
      props.onCancelClick();
      await dispatch(deleteCourse()).unwrap().then((_) => navigate('/learnings'));
    } catch(err) {
      console.error(err);
    }
  }

  async function onDeleteLesson() {
    try {
      props.onCancelClick();
      await dispatch(deleteLesson({ lang })).unwrap().then(_ => dispatch(cleanLessonToDelete()));
    } catch(err) {
      console.error(err);
    }
  }

  function onCancelClick() {
    props.onCancelClick();
    dispatch(cleanLessonToDelete());
  }

  const onDeleteClick = props.type === 'Learning' ? onDeleteCourse : onDeleteLesson;

  return (
    <div className={classes.buttonsContainer}>
      <DefaultButton onClick={onCancelClick}>Cancel</DefaultButton>
      <PrimaryButton 
        onClick={onDeleteClick}
      >Delete</PrimaryButton>
    </div>
  )
}

export default Buttons;