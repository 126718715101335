import { getClassNames } from "./lessons.classNames";
import { LessonDto, LessonTypeDto } from "../../../../../api-client";
import Lesson from "./Lesson/lesson";
import useDrag from './hooks/useDrag';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import useAudio from './hooks/useAudio';

interface ILessonsProps {
  onEditLessonClick: () => void;
  onDeleteClick: (type: 'Learning' | 'Lesson') => void;
}

const Lessons = ({  onEditLessonClick, onDeleteClick }: ILessonsProps) => {
  const lessons = useSelector((state: RootState) => state.courses.course?.lessons);

  const { 
    onDragStart,
    onDragOver,
    onDragEnd,
    onDrop
  } = useDrag(lessons || []);
  const { onPlayAudioLessonId, setAudioLessonId } = useAudio();

  const classes = getClassNames();

  return (
    <div className={classes.lessons}>
      {lessons?.filter((lesson, index) => lesson.type !== LessonTypeDto.FinalQuiz).map((lesson: LessonDto, index: number) => (
        <Lesson
          key={lesson.id}
          index={index}
          lesson={lesson}
          onEditLessonClick={onEditLessonClick}
          onDeleteClick={onDeleteClick}
          onPlayAudioLessonId={onPlayAudioLessonId}
          setAudioLessonId={setAudioLessonId}
          onDragStart={(e) => onDragStart(e, index)}
          onDrop={onDrop}
          onDragEnd={onDragEnd}
          onDragOver={onDragOver}
        />
      ))}
    </div>
  )
}

export default Lessons;