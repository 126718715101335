import { useRef, useEffect } from "react";
import { SafeQuizLessonDto } from "../../../../../../../../../api-client";

const useTextAreaRef = (questions: SafeQuizLessonDto[] | null | undefined) => {
  const question = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (question.current) {
      question.current.style.height = 'auto';
      question.current.style.height = `${question.current.scrollHeight}px`;
    }
  }, [question, questions]);

  return {
    question,
  }
};

export default useTextAreaRef;