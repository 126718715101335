import { mergeStyleSets, keyframes } from '@fluentui/react';
import { getTheme } from '@fluentui/react';
import { BorderRadius, IFadeClassNames } from '../../utils/constants/styles';
import { FADE } from '../../utils/constants/styles';

interface ICreatePageClassNames extends IFadeClassNames {
  settingsPage: string;
  settingsContainer: string;
  buttons: string;
}

export const getClassNames = (): ICreatePageClassNames => {
  const theme = getTheme();

  const fadeIn = keyframes({
    from : {
      opacity: 0,
    },
    to: {
      opacity: 1,
    }
  });

  return mergeStyleSets({
    settingsPage: {
      width: '100%',
      height: '100vh',
      background: theme.palette.themeLighter,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 100, // 50 with 3 cards

      animationName: fadeIn,
      animationFillMode: 'forwards',
      animationDuration: '500ms',
    },

    settingsContainer: {
      width: 800,
      height: 500,
      padding: 20,
      borderRadius: BorderRadius,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },

    buttons: {
      width: '100%',
      display:'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: 10,
    },

    ...FADE.classNames,
  });
};