import { IDropdownOption } from "@fluentui/react";
import Dropdown from "../../../../../../../../common/components/Dropdown/dropdown";
import { DropdownThemes } from "../../../../../../../../utils/constants/dropdown-themes";

interface IDocxProps {
  chapters: IDropdownOption<any>[];
  chapter: string;
  changeChapter: (e: React.FormEvent<HTMLDivElement>, selectedOption?: IDropdownOption<any> | undefined) => void;
}

export default function Docx(props: IDocxProps) {
  return (
    <Dropdown
      height={40}
      itemHeight={50}
      onChange={props.changeChapter}
      theme={DropdownThemes.LIGHT}
      options={props.chapters}
      caretWidth="12%"
      title="Select which chapter to use to generate the new lesson"
      isGroup={true}
      selectedKey={props.chapter}
    />
  );
}