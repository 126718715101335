// import react
import { Dispatch, useMemo, FormEvent, useRef } from "react";

// import styles
import { getClassNames } from "./languages.classNames";

// import components
import Dropdown from "../../../../../../../../../../common/components/Dropdown/dropdown";
import PrimaryButton from "../../../../../../../../../../common/components/Buttons/PrimaryButton/primary-button";

// import models
import { DropdownThemes } from "../../../../../../../../../../utils/constants/dropdown-themes";
import { IDropdownOption } from "@fluentui/react";

// import utils
import { getLanguageFromCode, sortString } from "../../../../../../../../../../utils/helpers/helpers";
import { ActionTypes, IAction, ILanguagesForm } from "../../../../hooks/useLanguagesForm";
import { CSSTransition } from "react-transition-group";
import { ANIMATION_DURATION } from "../../../../../../../../../../utils/constants/styles";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../../../../store/store";

interface ILanguagesProps {
  languagesForm: ILanguagesForm;
  dispatch: Dispatch<IAction>;
}

const Languages = (props: ILanguagesProps) => {
  const classes = getClassNames();
  const { settings } = useSelector((state: RootState) => state.settings);

  const errorLabelContainerRef = useRef<HTMLDivElement>(null);

  const languages = useMemo((): IDropdownOption[] => {
    if (!settings?.supportedLanguages) return [];

    const filteredArray = 
      settings?.supportedLanguages.filter((language) => !props.languagesForm.languageCollection.find(el => el.language === language));
    
      return filteredArray.map((language: string): IDropdownOption => ({
        key: language,
        text: getLanguageFromCode(language),
      })).sort((a, b) => sortString(a.text, b.text));
  }, [settings?.supportedLanguages, props.languagesForm.languageCollection]);

  const onRenderTitle = () => {
    if (props.languagesForm.languageToAdd) return <div>{props.languagesForm.languageToAdd.text}</div>
    return <div>Select the language...</div>
  };
  
  return (
    <div className={classes.languagesContainer}>
      <p>Add a language to your collection</p>
      <div className={classes.dropdownContainer}>
        <Dropdown 
          options={languages}
          theme={DropdownThemes.DARK} 
          onChange={(event: FormEvent<HTMLDivElement>, option?: IDropdownOption) => 
            option && props.dispatch({ type: ActionTypes.UPDATE_DROPDOWN, payload: { languageToAdd: { key: option.key.toString(), text: option.text } } })}
          placeholder="Select the language..."
          selectedKey={props.languagesForm.languageToAdd ? props.languagesForm.languageToAdd.key : null}
          onRenderTitle={onRenderTitle}
          caretWidth='10%'
          disabled={props.languagesForm.isDropdownDisabled}
          />
        <PrimaryButton
          type="button"
          onClick={() => props.dispatch({ type: ActionTypes.ADD_LANGUAGE })}
          disabled={props.languagesForm.isDropdownDisabled || !props.languagesForm.languageToAdd}
          >Add</PrimaryButton>
      </div>
      <CSSTransition
        nodeRef={errorLabelContainerRef}
        timeout={ANIMATION_DURATION}
        in={props.languagesForm.isDropdownDisabled}
        classNames={{
          enter: classes.enter,
          enterActive: classes.enterActive,
          enterDone: classes.enterDone,
          exit: classes.exit,
          exitActive: classes.exitActive,
          exitDone: classes.exitDone,
        }}
        unmountOnExit
      >
        <div ref={errorLabelContainerRef}>
          <p className={classes.errorLabel}>
            <span>* Please add one language at time.</span>
            <span>
              ** If the new language is toggled on and the course is already published, the course will be published in the new language automatically.
            </span>
          </p>
        </div>
      </CSSTransition>
    </div>
  )
}

export default Languages;