import { mergeStyleSets, getTheme } from "@fluentui/react";

interface INavNames {
  navContainer: string;
  listContainer: string;
  listItem: string;
  listItemActive: string;
}

export const getClassNames = (): INavNames => {
  const theme = getTheme();

  return mergeStyleSets({
    navContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },

    listContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 20,
    },

    listItemActive: {
      listStyle: 'none',
      borderBottom: `2px solid ${theme.palette.themePrimary}`,
      cursor: 'pointer',
    },

    listItem: {
      listStyle: 'none',
      borderBottom: '2px solid transparent',
      cursor: 'pointer',
    },
  });
}