import { getClassNames } from "./quote.classNames";

interface IQuoteProps {
  quote: string | undefined | null;
}

export default function Quote(props: IQuoteProps) {
  if (!props.quote) return null;

  const classes = getClassNames();

  return (
    <div className={classes.quoteContainer}>
      <p className={classes.paragraph}>{props.quote}</p>
    </div>
  );
}