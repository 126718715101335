// import react
import { Dispatch } from 'react';
import { getClassNames } from './navigation.classNames';
import ChevronLeft from '../../../../../../../../assets/Icons/Controls/ChevronLeft/chevron-left';
import ChevronRight from '../../../../../../../../assets/Icons/Controls/ChevronRight/chevron-right';
import { ActionTypes, IAction } from '../../hooks/useForm';

interface INavigationProps {
  questionsLength: number;  
  selectedQuestionIndex: number;
  dispatch: Dispatch<IAction>;
}

const Navigation = (props: INavigationProps) => {
  const classes = getClassNames();

  return (
    <div className={classes.navigation}>
      <ChevronLeft 
        onClick={() => 
          props.selectedQuestionIndex - 2 > -1 && 
            props.dispatch({ type: ActionTypes.SET_QUESTION, payload: props.selectedQuestionIndex - 2 })}
      />
      <p className={classes.navigationLabel}>{`Question n. ${props.selectedQuestionIndex} of ${props.questionsLength}`}</p>
      <ChevronRight
        onClick={() =>
          props.selectedQuestionIndex < props.questionsLength &&
          props.dispatch({ type: ActionTypes.SET_QUESTION, payload: props.selectedQuestionIndex })}
      />
    </div>
  );
};

export default Navigation;