import { IIconProps } from "@fluentui/react";

const Publish = (props: IIconProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1_7938)">
      <g clipPath="url(#clip1_1_7938)">
      <path d="M12 13V2C12 0.895386 11.1046 0 10 0H2C0.895447 0 0 0.895386 0 2V14C0 15.1046 0.895447 16 2 16H11.5C11.7761 16 12 15.7761 12 15.5C12 15.2239 11.7761 15 11.5 15H2C1.44775 15 1 14.5522 1 14H11C11.5523 14 12 13.5522 12 13ZM1 2C1 1.44769 1.44775 1 2 1H10C10.5523 1 11 1.44769 11 2V13H1V2Z" fill={props.color || '#000'}/>
      <path d="M10 7C10.0001 6.72383 9.77615 6.50001 9.50002 6.50001L6.50004 6.49997V3.50003C6.50004 3.22391 6.27622 3 6 3C5.72388 3 5.50001 3.22386 5.50001 3.49999V6.50001L2.50003 6.49997C2.22391 6.49997 2.00004 6.72383 2 7C2.00004 7.27617 2.22391 7.50004 2.50003 7.50004L5.50001 7.49999V10.5C5.50001 10.7761 5.72388 11 6 11C6.27617 11 6.50004 10.7761 6.50004 10.5V7.50004L9.50002 7.49999C9.77615 7.49999 10 7.27613 10 7Z" fill={props.color || '#000'}/>
      </g>
      </g>
      <defs>
        <clipPath id="clip0_1_7938">
          <rect width="12" height="16" fill={props.color || '#000'}/>
        </clipPath>
        <clipPath id="clip1_1_7938">
          <rect width="12" height="16" fill={props.color || '#000'}/>
        </clipPath>
      </defs>
    </svg>

  )
};

export default Publish;