import { mergeStyleSets, keyframes, getTheme } from "@fluentui/react";

interface ILanguageOptionsNames {
  languagesOptionsContainer: string;
  divider: string;
}

export const getClassNames = (): ILanguageOptionsNames => {
  const theme = getTheme();

  const fadeIn = keyframes({
    from : {
      opacity: 0,
    },
    to: {
      opacity: 1,
    }
  });
  
  return mergeStyleSets({
    languagesOptionsContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      gap: 20,

      animationName: fadeIn,
      animationFillMode: 'forwards',
      animationDuration: '500ms',
    },

    divider: {
      borderColor: theme.palette.whiteTranslucent40,
      marginTop: 10,
    },
  });
};