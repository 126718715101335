//import styles
import { getClassNames } from './CreateCard.classNames';

// import components
import { Text, getTheme } from '@fluentui/react';
import PrimaryButton from '../../../../common/components/Buttons/PrimaryButton/primary-button';

// import models
import { CreateCardInfo } from '../../../../common/models/Create/create-card-info';

interface ICardProps {
  cardInfo: CreateCardInfo;
}

const CreateCard = (props: ICardProps) => {
  const classes = getClassNames();

  return (
    <div className={classes.card}>
      <div className={classes.cardTop}>
        <div className={classes.iconContainer}>
          <props.cardInfo.Icon />
        </div>
      </div>
      <div className={classes.cardBottom}>
        <PrimaryButton
          height={50}
          onClick={props.cardInfo.action && props.cardInfo.action}
          disabled={!props.cardInfo.action}
          disabledBackgroundColor={getTheme().palette.neutralQuaternary}>
            <span className={classes.btnUpload}>{props.cardInfo.buttonLabel}</span>
          </PrimaryButton>
        <hr className={classes.divider} />
        <Text className={classes.description}>{props.cardInfo.description}</Text>
      </div>
    </div>
  )
}

export default CreateCard;