import { memo } from 'react';
import { getClassNames } from "./title.classNames";
import { Status } from "../../../../../../utils/constants/status";
import { CourseStatusDto } from '../../../../../../api-client';

interface ITitleProps {
  title: string;
  status: CourseStatusDto;
}

const Title = (props: ITitleProps) => {
  const classes = getClassNames(props.status);

  return (
    <div className={classes.titleContainer}>
      <h1>{props.title}</h1>
      {props.status && <div className={classes.statusContainer}>
        {Status[props.status].icon()}
        <span className={classes.statusLabel}>{props.status === CourseStatusDto.GenError ? 'Error' : props.status}</span>
      </div>}
    </div>
  )
}

export default memo(Title);