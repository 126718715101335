import { useEffect, useState, useCallback } from "react";
import { getClassNames } from "./list-item.classNames";
import DefaultButton from "../../../../../../../../../common/components/Buttons/DefaultButton/default-button";
import PrimaryButton from "../../../../../../../../../common/components/Buttons/PrimaryButton/primary-button";
import { getTheme, Persona, PersonaSize } from "@fluentui/react";
import { IconNames } from "../../../../../../../../../common/models/Icons/icon-names";
import { StatusNames } from "../../../../../../../../../common/models/Learnings/status";
import { Status } from "../../../../../../../../../utils/constants/status";
import { CourseStatusDto, IdentityTypeDto } from "../../../../../../../../../api-client";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../../../store/store";

export interface IListItemProps {
  item: any;
  courseStatus: CourseStatusDto | undefined;
  itemIndex: number;
  columnKey: string; 
  statusInfo: StatusNames;
  onDeleteClick: (item: any) => void;
  editListItem: () => void;
  onSendScheduleClick: (item: any) => void;
  onStopClick: (item: any) => void;
}

const ListItem = (props: IListItemProps) => {
  const [profilePicture, setProfilePicture] = useState<string>('');
  const usersClientApi = useSelector((state: RootState) => state.clients.clients.usersClientApi);
  const classes = getClassNames(props);
  const theme = getTheme();

  const getProfilePicture = useCallback(async function(userId: string): Promise<void> {
    try {
      if (!usersClientApi) return;

      const response = await usersClientApi.apiTenantUsersUpnOrIdPhotoGet(userId);
      setProfilePicture(`data:image/png;base64,${response.data}`);
    } catch (err) {
      console.error(err);
    }
  }, [usersClientApi]);

  const getGroupPicture = useCallback(async function(groupId: string): Promise<void> {
    try {
      if (!usersClientApi) return;

      const response = await usersClientApi.apiTenantGroupsUpnOrIdPhotoGet(groupId);
      setProfilePicture(`data:image/png;base64,${response.data}`);
    } catch (err) {
      console.error(err);
    }
  }, [usersClientApi]);

  const renderItem = () => {
    if (props.columnKey === '') {
      return (
        <div className={classes.buttonsColumn}>
          {props.statusInfo === StatusNames.Draft && (
            <>
              <PrimaryButton 
              type="button"
              iconName={IconNames.SEND}
              onClick={() => props.onSendScheduleClick(props.item)}
              disabled={props.courseStatus !== CourseStatusDto.Published && props.courseStatus !== CourseStatusDto.Running}
              color={props.courseStatus !== CourseStatusDto.Published && props.courseStatus !== CourseStatusDto.Running ? theme.palette.neutralTertiary : '#fff'}
              >
                Send
              </PrimaryButton>
              <DefaultButton
              type="button"
              iconName={IconNames.EDIT}
              onClick={props.editListItem}
              // onClick={onEditClick}
            >
              Edit
            </DefaultButton>
            <PrimaryButton 
              type="button"
              iconName={IconNames.DELETE}
              color={theme.palette.themePrimary}
              background={theme.palette.themeLighter}
              onClick={() => props.onDeleteClick(props.item)}
            >
              Delete
            </PrimaryButton>
          </>
          )}
          {props.statusInfo === StatusNames.Running && (
            <PrimaryButton 
              type="button"
              iconName={IconNames.STOP}
              onClick={() => props.onStopClick(props.item)}
              color={theme.palette.themePrimary}
              background={theme.palette.themeLighter}
            >
              Stop
            </PrimaryButton>
          )}
        </div>
      )
    };
    if (props.columnKey === 'Recipients') {
      return <div className={classes['columns']}>
        <Persona
          size={PersonaSize.size24}
          text={props.item[props.columnKey].displayName}
          imageUrl={profilePicture}
       />
        </div>;
    };
    if (props.columnKey === 'Status') return (
      <div className={classes.statusContainer}>
        {Status[props.statusInfo] && Status[props.statusInfo].icon()}
        <span className={classes.statusSpanLabel}>{props.statusInfo}</span>
      </div>
    );

    return <div className={classes.columns}>{props.item[props.columnKey]}</div>;
  };

  useEffect(() => {
    if (props.columnKey === 'Recipients') {
      if (props.item[props.columnKey].type === IdentityTypeDto.User)
        getProfilePicture(props.item[props.columnKey].id);
      else
        getGroupPicture(props.item[props.columnKey].id);
    };
  }, [getProfilePicture, getGroupPicture, props.columnKey, props.item]);

  return renderItem()
}

export default ListItem;