// import react
import { useReducer } from "react";

// import models
import { BulletPointLessonDto, FlashCardLessonDto, LessonDto, LessonTypeDto, QuizLessonDto, TextLessonDto } from "../../../../../../../api-client";
import { IAttachment } from "../../../../../../../common/models/Learnings/attachment";

export enum ActionTypes {
  UPDATE_INPUT = 'UPDATE_INPUT',

  UPDATE_LESSON_TYPE = 'UPDATE_LESSON_TYPE',
  UPDATE_AUDIO_ONLY = 'UPDATE_AUDIO_ONLY',

  UPDATE_BULLET_POINT = 'UPDATE_BULLET_POINT',
  CHANGE_BULLET_POINTS_ORDER = 'CHANGE_BULLET_POINTS_ORDER',
  ADD_BULLET_POINT = 'ADD_BULLET_POINT',
  DELETE_BULLET_POINT = 'DELETE_BULLET_POINT',

  UPDATE_OPTION = 'UPDATE_OPTION',
  CHANGE_OPTIONS_ORDER = 'CHANGE_OPTIONS_ORDER',
  ADD_OPTION = 'ADD_OPTION',
  DELETE_OPTION = 'DELETE_OPTION',

  UPDATE_ATTACHMENT = 'UPDATE_ATTACHMENT',
}

export interface IFormData {
  lessonData: Partial<TextLessonDto> | Partial<BulletPointLessonDto> | Partial<QuizLessonDto> | Partial<FlashCardLessonDto>;
  lessonType: LessonTypeDto | undefined;
  newItem?: string;
  attachment: IAttachment | undefined;
}

interface IInputPayload {
  name: string;
  value: string | string[] | number | boolean | undefined;
  index?: number
}

interface IChangeOrderPayload {
  index: number;
  targetIndex: number;
}

interface IUpdateLessonTypePayload {
  lessonType: LessonTypeDto;
}

export interface IAction {
  type: ActionTypes;
  payload: IInputPayload | IChangeOrderPayload | IUpdateLessonTypePayload | IAttachment | string | number | boolean;
}

function reducer(
  state: IFormData,
  action: IAction): IFormData {
  switch (action.type) {
    case (ActionTypes.UPDATE_INPUT): return {
      ...state,
      lessonData: {
        ...state.lessonData,
        [(action.payload as IInputPayload).name]: (action.payload as IInputPayload).value
      },
    };

    case (ActionTypes.UPDATE_LESSON_TYPE): return !!state.lessonData.quote ? {
      ...state,
      lessonType: (action.payload as IUpdateLessonTypePayload).lessonType,
    } : {
      ...state,
      lessonData: {
        ...state.lessonData,
        type: (action.payload as IUpdateLessonTypePayload).lessonType,
      },
      lessonType: (action.payload as IUpdateLessonTypePayload).lessonType,
    };

    case (ActionTypes.UPDATE_AUDIO_ONLY): return {
      ...state,
      lessonData: {
        ...state.lessonData,
        isAudioOnly: action.payload as boolean,
      }
    };

    case (ActionTypes.UPDATE_BULLET_POINT): {
      const payload = action.payload as IInputPayload;

      if (payload.index === null || payload.index === undefined) return state;
      
      const bulletPoints = (state.lessonData as BulletPointLessonDto).bulletPoints;
      if (!bulletPoints) return state;

      
      const newBulletPoints = [...bulletPoints];
      newBulletPoints[payload.index] = payload.value as string;

      return {
        ...state,
        lessonData: {
          ...state.lessonData,
          bulletPoints: newBulletPoints,
        }
      };
    };

    case (ActionTypes.CHANGE_BULLET_POINTS_ORDER): {
      const bulletPoints = (state.lessonData as BulletPointLessonDto).bulletPoints;
      if (!bulletPoints) return state;

      const newBulletPoints = [...bulletPoints];
      const payload = action.payload as IChangeOrderPayload
      newBulletPoints.splice(payload.targetIndex, 0, newBulletPoints.splice(payload.index, 1)[0]);

      return {
        ...state,
        lessonData: {
          ...state.lessonData,
          bulletPoints: newBulletPoints,
        }
      }
    };

    case (ActionTypes.ADD_BULLET_POINT): {
      let bulletPoints = (state.lessonData as BulletPointLessonDto).bulletPoints;
      if (!bulletPoints) bulletPoints = [];

      const newBulletPoints = [...bulletPoints];
      const payload = action.payload as string;
      newBulletPoints.push(payload);

      return {
        ...state,
        lessonData: {
          ...state.lessonData,
          bulletPoints: newBulletPoints,
        }
      }
    };

    case (ActionTypes.DELETE_BULLET_POINT): {
      const bulletPoints = (state.lessonData as BulletPointLessonDto).bulletPoints;
      if (!bulletPoints) return state;

      const newBulletPoints = [...bulletPoints];
      const payload = action.payload as number;
      newBulletPoints.splice(payload, 1);

      return {
        ...state,
        lessonData: {
          ...state.lessonData,
          bulletPoints: newBulletPoints,
        }
      }
    };

    case (ActionTypes.UPDATE_OPTION): {
      const payload = action.payload as IInputPayload;

      if (payload.index === null || payload.index === undefined) return state;
      
      const options = (state.lessonData as QuizLessonDto).options;
      if (!options) return state;

      
      const newOptions = [...options];
      const newOption = {
        ...newOptions[payload.index],
        response: payload.value as string,
      };
      newOptions[payload.index] = newOption;

      return {
        ...state,
        lessonData: {
          ...state.lessonData,
          options: newOptions,
        }
      };
    };

    case (ActionTypes.CHANGE_OPTIONS_ORDER): {
      const options = (state.lessonData as QuizLessonDto).options;
      if (!options) return state;

      const newOptions = [...options];
      const payload = action.payload as IChangeOrderPayload
      newOptions.splice(payload.targetIndex, 0, newOptions.splice(payload.index, 1)[0]);

      return {
        ...state,
        lessonData: {
          ...state.lessonData,
          options: newOptions,
        }
      };
    };

    case (ActionTypes.ADD_OPTION): {
      let options = (state.lessonData as QuizLessonDto).options;
      if (!options) options = [];

      const newOptions = [...options];
      const payload = action.payload as string;
      const maxId = newOptions.length === 0 ? 0 : Math.max(...newOptions.map(el => el.id !== undefined ? el.id : 0));
      newOptions.push({ id: maxId + 1, response: payload });

      return {
        ...state,
        lessonData: {
          ...state.lessonData,
          options: newOptions,
        }
      };
    };

    case (ActionTypes.DELETE_OPTION): {
      const options = (state.lessonData as QuizLessonDto).options;
      if (!options) return state;

      const newOptions = [...options];
      const payload = action.payload as number;
      newOptions.splice(payload, 1);

      return {
        ...state,
        lessonData: {
          ...state.lessonData,
          options: newOptions,
        }
      };
    };

    case (ActionTypes.UPDATE_ATTACHMENT): 
      return {
        ...state,
        attachment: action.payload as IAttachment,
      };

    default: return state;
  } 
  
}

const useForm = (lesson: LessonDto | undefined) => {
  let initialState: IFormData;

  switch (lesson?.type) {
    case LessonTypeDto.Text: { 
      initialState = {
        attachment: undefined,
        lessonData: {
          id: lesson.id,
          title: (lesson as TextLessonDto).title,
          isHide: false,
          modified: lesson.modified,
          body: (lesson as TextLessonDto).body,
          quote: lesson.quote,
          type: lesson.type,
          isAudioOnly: (lesson as TextLessonDto).isAudioOnly,
          ...((lesson as TextLessonDto).hasAudio !== undefined && {
            hasAudio: (lesson as TextLessonDto).hasAudio}
          ),
          scratch: lesson.scratch,
        },
        lessonType: lesson.type,
      };
      break;
    }

    case LessonTypeDto.BulletPoints: { 
      initialState = {
        attachment: undefined,
        lessonData: {
          id: lesson.id,
          title: (lesson as BulletPointLessonDto).title,
          isHide: false,
          modified: lesson.modified,
          introduction: (lesson as BulletPointLessonDto).introduction,
          bulletPoints: (lesson as BulletPointLessonDto).bulletPoints,
          quote: lesson.quote,
          type: lesson.type,
          isAudioOnly: (lesson as BulletPointLessonDto).isAudioOnly,
          ...((lesson as TextLessonDto).hasAudio !== undefined && {
            hasAudio: (lesson as BulletPointLessonDto).hasAudio}
          ),
          scratch: lesson.scratch,
        },
        lessonType: lesson.type,
        newItem: '',
      };
      break;
    }

    case LessonTypeDto.Quiz: { 
      initialState = {
        attachment: undefined,
        lessonData: {
          id: lesson.id,
          title: (lesson as QuizLessonDto).title,
          isHide: false,
          modified: lesson.modified,
          question: (lesson as QuizLessonDto).question,
          options: (lesson as QuizLessonDto).options,
          correctAnswerId: (lesson as QuizLessonDto).correctAnswerId,
          explanationCorrectAnswer: (lesson as QuizLessonDto).explanationCorrectAnswer || '',
          quote: lesson.quote,
          type: lesson.type,
          scratch: lesson.scratch,
        },
        lessonType: lesson.type,
        newItem: '',
      };
      break;
    }

    case LessonTypeDto.FlashCard: { 
      initialState = {
        attachment: undefined,
        lessonData: {
          id: lesson.id,
          title: (lesson as QuizLessonDto).title,
          isHide: false,
          modified: lesson.modified,
          question: (lesson as FlashCardLessonDto).question,
          answer: (lesson as FlashCardLessonDto).answer,
          explanation: (lesson as FlashCardLessonDto).explanation || '',
          quote: lesson.quote,
          type: lesson.type,
          scratch: lesson.scratch,
        },
        lessonType: lesson.type,
      };
      break;
    }

    default: initialState = {
      attachment: undefined,
        lessonData: {
          title: '',
          body: '',
          quote: '',
          modified: '',
          options: [],
          bulletPoints: [],
          isHide: false,
          isAudioOnly: false,
          type: LessonTypeDto.Text,
          scratch: false,
        },
        lessonType: LessonTypeDto.Text,
    };
  }

  const [formData, formDispatch] = useReducer(reducer, initialState);

  return {
    formData,
    formDispatch
  };
};

export default useForm;