// import react
import { useReducer } from "react";

import { v4 as uuidv4 } from 'uuid';

// import models
import { FinalQuizLessonDto, SafeQuizLessonDto } from "../../../../../../../api-client";

export enum ActionTypes {
  SET_QUESTION = 'SET_QUESTION',
  CHANGE_QUESTION_ORDER = 'CHANGE_QUESTION_ORDER',
  DELETE_QUESTION = 'DELETE_QUESTION',
  ADD_QUESTION = 'ADD_QUESTION',
  CHANGE_OPTIONS_ORDER = 'CHANGE_OPTIONS_ORDER',
  UPDATE_INPUT = 'UPDATE_INPUT',
  UPDATE_OPTION = 'UPDATE_OPTION',
  DELETE_OPTION = 'DELETE_OPTION',
  ADD_OPTION = 'ADD_OPTION',
}

export interface IFormData {
  quizes: SafeQuizLessonDto[] | undefined | null;
  selectedQuestionIndex: number;
}

interface IChangeOrderPayload {
  index: number;
  targetIndex: number;
}

interface IInputPayload {
  name: string;
  value: string | string[] | number | boolean | undefined;
  index?: number;
}

export interface IAction {
  type: ActionTypes;
  payload: number | string | IChangeOrderPayload | IInputPayload;
}

function reducer(state: IFormData, action: IAction): IFormData {
  switch (action.type) {
    case ActionTypes.SET_QUESTION: return {
      ...state,
      selectedQuestionIndex: action.payload as number,
    };

    case ActionTypes.CHANGE_QUESTION_ORDER: {
      if (!state.quizes) return state;

      const payload = action.payload as IChangeOrderPayload
      const newQuizesArray = [...state.quizes];
      const selectedQuizId = newQuizesArray[state.selectedQuestionIndex].id;
      newQuizesArray.splice(payload.targetIndex, 0, newQuizesArray.splice(payload.index, 1)[0]);
      const newSelectedQuestionIndex = newQuizesArray.findIndex(el => el.id === selectedQuizId);

      return {
        ...state,
        quizes: newQuizesArray,
        selectedQuestionIndex: newSelectedQuestionIndex,
    }};

    case ActionTypes.DELETE_QUESTION: {
      if (!state.quizes) return state;

      const newQuizes = [...state.quizes];
      newQuizes.splice(action.payload as number, 1);

      let newSelectedIndex;
      if (state.selectedQuestionIndex === (action.payload as number) && 
          state.selectedQuestionIndex === state.quizes.length - 1) newSelectedIndex = state.selectedQuestionIndex - 1;
      else newSelectedIndex = state.selectedQuestionIndex > (action.payload as number) ?
        state.selectedQuestionIndex - 1 :
        state.selectedQuestionIndex;
      
      return {
        ...state,
        quizes: newQuizes,
        selectedQuestionIndex: newSelectedIndex,
      }
    };

    case ActionTypes.ADD_QUESTION: {
      if (!state.quizes) return state;

      console.log('state.selectedQuestionIndex', state.selectedQuestionIndex);

      const newQuizesArray = [...state.quizes];
      newQuizesArray.push({
        id: uuidv4(),
        question: action.payload as string,
        options: [],
        correctAnswerId: undefined,
      });

      return {
        ...state,
        quizes: newQuizesArray,
        selectedQuestionIndex: state.quizes.length === 0 ? 0 : state.selectedQuestionIndex,
    }};

    case ActionTypes.CHANGE_OPTIONS_ORDER: {
      if (!state.quizes) return state;

      const payload = action.payload as IChangeOrderPayload;
      const newQuizesArray = [...state.quizes];
      const quiz = {...newQuizesArray[state.selectedQuestionIndex]};
      if (!quiz.options) return state;
      const options = [...quiz.options];
      options.splice(payload.targetIndex, 0, options.splice(payload.index, 1)[0]);
      quiz.options = options;
      newQuizesArray[state.selectedQuestionIndex] = quiz;

      return {
        ...state,
        quizes: newQuizesArray,
    }};

    case ActionTypes.UPDATE_INPUT: {
      if (!state.quizes) return state;

      const selectedQuizId = state.quizes[state.selectedQuestionIndex].id;

      return {
        ...state,
        quizes: state.quizes.map(el => (el.id === selectedQuizId ? {
          ...el,
          [(action.payload as IInputPayload).name]: (action.payload as IInputPayload).value,
         } : el ))
      }
    };

    case (ActionTypes.UPDATE_OPTION): {
      const payload = action.payload as IInputPayload;

      if (!state.quizes) return state;
      if (payload.index === null || payload.index === undefined) return state;
      
      const newQuizesArray = [...state.quizes];
      const quiz = { ...newQuizesArray[state.selectedQuestionIndex] };
      if (!quiz.options) return state;
      const options = [...quiz.options];
      const newOption = {
        ...options[payload.index],
        response: payload.value as string,
      };
      options[payload.index] = newOption;
      quiz.options = options;
      newQuizesArray[state.selectedQuestionIndex] = quiz;

      return {
        ...state,
        quizes: newQuizesArray,
      };
    };

    case ActionTypes.DELETE_OPTION: {
      if (!state.quizes) return state;

      const newQuizesArray = [...state.quizes];
      const quiz = { ...newQuizesArray[state.selectedQuestionIndex] };
      if (!quiz.options) return state;
      const options = [...quiz.options];
      options.splice(action.payload as number, 1);
      quiz.options = options;
      newQuizesArray[state.selectedQuestionIndex] = quiz;

      return {
        ...state,
        quizes: newQuizesArray,
      }
    };

    case ActionTypes.ADD_OPTION: {
      if (!state.quizes) return state;

      const newQuizesArray = [...state.quizes];
      const quiz = { ...newQuizesArray[state.selectedQuestionIndex] };
      if (!quiz.options) return state;
      const options = [...quiz.options];
      const payload = action.payload as string;
      const maxId = options.length === 0 ? 0 : Math.max(...options.map(el => el.id !== undefined ? el.id : 0));
      options.push({ id: maxId + 1, response: payload });
      quiz.options = options;
      newQuizesArray[state.selectedQuestionIndex] = quiz;

      return {
        ...state,
        quizes: newQuizesArray,
    }};

    default: return state;
  }
}

function useForm(finalQuiz: FinalQuizLessonDto) {
  const initialState: IFormData = {
    quizes: finalQuiz.quizes,
    selectedQuestionIndex: 0,
  };

  const [formData, formDispatch] = useReducer(reducer, initialState);

  return {
    formData,
    formDispatch
  }
}

export default useForm;