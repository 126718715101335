// import styles
import { getClassNames } from "./nav.classNames";

interface INavProps {
  selectedTab: number;
  changeTab: (tab: number) => void;
}

const Nav = (props: INavProps) => {
  const classes = getClassNames();
  
  return (
    <nav className={classes.navContainer}>
      <ul className={classes.listContainer}>
        <li className={props.selectedTab === 0 ? `${classes.listItemActive}` : classes.listItem} onClick={() => props.changeTab(0)}>Attachment</li>
        <li className={props.selectedTab === 1 ? `${classes.listItemActive}` : classes.listItem} onClick={() => props.changeTab(1)}>Quote</li>
      </ul>
    </nav>
  )
};

export default Nav;