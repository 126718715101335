import { mergeStyleSets, getTheme, keyframes } from "@fluentui/react";
import { BorderRadius } from "../../../../../../../../utils/constants/styles";

interface IQuoteClassNames {
  quoteContainer: string;
  paragraph: string;
}

export const getClassNames = (): IQuoteClassNames => {
  const theme = getTheme();

  const fadeIn = keyframes({
    from : {
      opacity: 0,
    },
    to: {
      opacity: 1,
    }
  });

  return mergeStyleSets({
    quoteContainer: {
      height: '100%',
      background: theme.palette.neutralLighterAlt,
      borderRadius: BorderRadius,
      padding: 20,
      userSelect: 'none',
      overflowY: 'auto',
      display: 'flex',
      alignItems: 'safe center',
    },

    paragraph: {
      fontSize: theme.fonts.large.fontSize,
      maxWidth: 510,
      overflowWrap: 'break-word',
      animationName: fadeIn,
      animationFillMode: 'forwards',
      animationDuration: '500ms',
    }
  })
};