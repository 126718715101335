import { IPersonaProps, ISuggestionItemProps, PeoplePickerItemSuggestion } from "@fluentui/react";
import React from "react";
import { IdentityTypeDto } from "../../../../../../../../../../../api-client";
import { IUser } from "../../../../../../../../../../../common/models/LearningDetails/user";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../../../../../store/store";

interface SuggestionItemProps {
  userProps: IUser;
  itemProps: ISuggestionItemProps<IPersonaProps>;
}

function SuggestionItem(props: SuggestionItemProps) {   
  const { usersClientApi } = useSelector((state: RootState) => state.clients.clients);
  const [propsState, setPropsState] = React.useState<IPersonaProps>(props.userProps);

  const getProfilePicture = React.useCallback(async () => {
    if (!usersClientApi) return;
    try {
      let response: any;
      if (props.userProps.userType === IdentityTypeDto.User) response = await usersClientApi.apiTenantUsersUpnOrIdPhotoGet(props.userProps.key as string);
      else response =  await usersClientApi.apiTenantGroupsUpnOrIdPhotoGet(props.userProps.key as string);
      props.userProps.imageUrl = `data:image/png;base64,${response.data}`;
      setPropsState((prevState) => ({ ...prevState, imageUrl: `data:image/png;base64,${response.data}` })) //props.userProps.imageUrl = response.data;
    } catch (e) {
      
    }
  }, [props.userProps, usersClientApi]);

  React.useEffect(() => {
    getProfilePicture();
  }, [getProfilePicture]);
  
  return <PeoplePickerItemSuggestion suggestionsProps={props.itemProps} personaProps={propsState} styles={{personaWrapper: {alignItems: 'center', height: 32}}} />
}

export default SuggestionItem;