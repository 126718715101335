import { BulletPointLessonDto, LessonDto, LessonTypeDto, QuizLessonDto, TextLessonDto, FlashCardLessonDto } from "../../../../../../../api-client";
import TextLesson from "./TextLesson/text-lesson";
import BulletPointsLesson from "./BulletPointsLesson/bullet-points-lesson";
import QuizLesson from "./QuizLesson/quiz-lesson";
import FlashCardLesson from "./FlashCardLesson/FlashCardLesson";

interface IBodyProps {
  lesson: LessonDto;
}

const Body = (props: IBodyProps) => {
  switch (props.lesson.type) {
    case LessonTypeDto.Text: return (
      <TextLesson text={(props.lesson as TextLessonDto).body || ''} />
    );
    case LessonTypeDto.BulletPoints: return (
      <BulletPointsLesson introduction={(props.lesson as BulletPointLessonDto).introduction || ''} bulletPoints={(props.lesson as BulletPointLessonDto).bulletPoints || []} />
    )
    case LessonTypeDto.Quiz: return (
      <QuizLesson question={(props.lesson as QuizLessonDto).question || ''} options={(props.lesson as QuizLessonDto).options || []} correctAnswerId={(props.lesson as QuizLessonDto).correctAnswerId} />
    )
    case LessonTypeDto.FlashCard: return (
      <FlashCardLesson
        question={(props.lesson as FlashCardLessonDto).question || ''}
        answer={(props.lesson as FlashCardLessonDto).answer || ''}
        explanation={(props.lesson as FlashCardLessonDto).explanation || ''}
      />
    )
    default: return <div>NO TYPE</div>
   }
};

export default Body;