import { useMemo } from "react";
import Modal from "../../../../../../../../common/components/Modal/modal";
import { Checkbox, IDropdownOption } from "@fluentui/react";
import Dropdown from "../../../../../../../../common/components/Dropdown/dropdown";
import { DropdownThemes } from "../../../../../../../../utils/constants/dropdown-themes";
import { getClassNames } from "./ScormDetails.classNames";
import useScormDetails, { IScormDetailsState } from "./hooks/useScormDetails";
import { getLanguageFromCode, sortString } from "../../../../../../../../utils/helpers/helpers";
import Buttons from "./components/Buttons/Buttons";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../../store/store";
import { ScormStandard } from "../../../../../../../../api-client";

interface IScormDetailsProps {
  nodeRef: React.RefObject<HTMLDivElement>;
  animation: boolean;
  action: () => void;
  exportScorm: (scormData: IScormDetailsState) => Promise<void>;
}

export default function ScormDetails(props: IScormDetailsProps) {
  // const settings = useSelector((state: RootState) => state.settings);
  const supportedLanguages = useSelector((state: RootState) => state.courses.course?.supportedLanguages);
  const { state, dispatch } = useScormDetails();
  const classes = getClassNames();

  const languages = useMemo((): IDropdownOption[] => {
    if (!supportedLanguages) return [];
    
    return supportedLanguages.map((language): IDropdownOption => ({
      key: language.language || '',
      text: getLanguageFromCode(language.language || ''),
    })).sort((a, b) => sortString(a.text, b.text));
  }, [supportedLanguages]);

  const scormVersions: IDropdownOption[] = [
    {
      key: ScormStandard.V12,
      text: '1.2',
    },
    {
      key: ScormStandard.V20043ed,
      text: '2004 3rd Edition',
    },
    {
      key: ScormStandard.V20044ed,
      text: '2004 4th Edition',
    },
  ];

  return (
    <Modal
      nodeRef={props.nodeRef}
      animation={props.animation}
      width={500}
      title="Scorm options"
      onModalClose={props.action}
    >
      <form action="" className={classes.form}>
        <div className={classes.languages}>
          <label>Scorm version</label>
          <div style={{flex: '0 0 200px'}}>
            <Dropdown 
              theme={DropdownThemes.DARK}
              selectedKey={state.scormVersion}
              options={scormVersions}
              onChange={(e: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined) => {
                if (!!option) dispatch({ type: 'UPDATE_SCORM_VERSION', payload: option.key as ScormStandard });}}
              height={35}
              caretWidth="30px"
            />
          </div>
        </div>
        <div className={classes.formSection}>
          <h4>Audio</h4>
          <Checkbox
            styles={{ text: { fontSize: 16, color: '#000' } }}
            name="includeAudio"
            label='Include audio'
            boxSide="end"
            checked={state.includeAudio}
            onChange={() => dispatch({ type: 'UPDATE_BOOLEANS', payload: { name: 'includeAudio' } })}
          />
        </div>
        
        <div className={classes.formSection}>
          <h4>Languages</h4>
          <Checkbox
            styles={{ text: { fontSize: 16, color: '#000' } }}
            name="allLanguages"
            label='Include all languages'
            boxSide="end"
            checked={state.allLanguages}
            onChange={() => dispatch({ type: 'UPDATE_BOOLEANS', payload: { name: 'allLanguages' } })}
          />
          <div className={classes.languages}>
            <label>Languages</label>
            <div style={{flex: '0 0 200px'}}>
              <Dropdown 
                theme={DropdownThemes.DARK}
                disabled={state.allLanguages}
                options={languages}
                onChange={(e: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined) => {
                  console.log('option', option);
                  if (!!option) dispatch({ type: 'UPDATE_LANGUAGES', payload: option });
                }}
                height={35}
                caretWidth="30px"
                isMultiSelect
              />
            </div>
          </div>
          
        </div>

        <Buttons onClick={async () => { await props.exportScorm(state)}} action={props.action} />
      </form>
    </Modal>
  )
}