import { useState, useEffect, useRef } from 'react';
import { Spinner, SpinnerSize } from "@fluentui/react";
import { getClassNames } from "./Loading.classNames";
import { CSSTransition } from 'react-transition-group';
import { ANIMATION_DURATION } from '../../../utils/constants/styles';

export interface ILoadingProps {
  nodeRef?: React.RefObject<HTMLDivElement>;
  animation?: boolean;
  label: string;
  position?: string;
  noBackground?: boolean;
}

const Loading = (props: ILoadingProps) => {
  const [animation, setAnimation] = useState<boolean>(false);
  const classes = getClassNames(props);

  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setAnimation(props.animation || false);
  }, [props.animation]);

  return (
    <>
      {props.animation === undefined ? (
        <div className={classes['loading-background']}>
          <div className={classes['spinner-container']}>
            <Spinner label={props.label} size={SpinnerSize.large} />
          </div>
        </div>
      ) : (
        <div ref={props.nodeRef} className={classes['loading-background']}>
          <CSSTransition
            nodeRef={modalRef}
            timeout={ANIMATION_DURATION}
            in={animation}
            classNames={{
              enter: classes.enter,
              enterActive: classes.enterActive,
              exit: classes.exit,
              exitActive: classes.exitActive
            }}
            mountOnEnter
            unmountOnExit
          >
            <div className={classes['spinner-container']}>
              <Spinner label={props.label} size={SpinnerSize.large}  />
            </div>
          </CSSTransition>
        </div>
      )}
    </>
  )
};

export default Loading;