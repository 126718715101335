import { mergeStyleSets, getTheme } from "@fluentui/react";

interface IVideoPlayerClassNames {
  videoPlayerContainer: string;
  videoPlayer: string;
}

export const getClassNames = (): IVideoPlayerClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    videoPlayerContainer: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'safe center',
      maxWidth: '100%',
      maxHeight: '90%',
      background: theme.palette.neutralLighterAlt,
      cursor: 'pointer',
    },

    videoPlayer: {
      width: '100%', // Set width to 100%
      height: 'auto', // Set height to auto
      objectFit: 'contain', // Add this line to maintain aspect ratio
    },
  });
};