// import react
import { FormEvent, useMemo, Dispatch, } from 'react';

// import styles
import { getClassNames } from "./CourseOptions.classNames";

// import models
import { IDropdownOption } from '@fluentui/react';
import { CreateFormKeys } from '../../../../../../../../common/models/Create/form-data';
import { CourseCategoryDto } from '../../../../../../../../api-client';
import { NumberOfLessons } from '../../../../../../../../common/models/Create/number-of-lessons';
import { TypeOfLessons } from '../../../../../../../../common/models/Create/type-of-lessons';

// import utils
import { getLanguageFromCode, sortString } from '../../../../../../../../utils/helpers/helpers';
import Dropdown from '../../../../../../../../common/components/Dropdown/dropdown';
import { DropdownThemes } from '../../../../../../../../utils/constants/dropdown-themes';
import { ActionTypes, IAction } from '../../../UploadModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../../store/store';

interface ICourseOptionsProps {
  dispatch: Dispatch<IAction>;
  options: { 
    [CreateFormKeys.CATEGORY]: string;
    [CreateFormKeys.LESSONS_NUMBER]: number;
    [CreateFormKeys.DOCUMENT_LANGUAGE]: string;
    [CreateFormKeys.TYPE_OF_LESSONS]: string[];
    [CreateFormKeys.GENERATE_AUDIO]: boolean;
   };
}

const CourseOptions = ({ options, dispatch }: ICourseOptionsProps) => {
  const classes = getClassNames();
  const { settings, error } = useSelector((state: RootState) => state.settings);

  const onRenderTypeOfLessonsTitle = (options: any) => {
    if (!options.length) return <div></div>
    if (options.length === 1) return <div>{`${options.length} item selected`}</div>
    return <div>{`${options.length} items selected`}</div>
  };

  const numberOfLessons = useMemo((): IDropdownOption[] => {
    const options: IDropdownOption[] = [];
    for (let key in NumberOfLessons) {
      options.push({key, text: NumberOfLessons[key as keyof typeof NumberOfLessons]});
    }
    return options;
  }, []);

  const categories = useMemo((): IDropdownOption[] => {
    if (!settings?.categories) return [];

    return settings?.categories.filter(category => !category.archived).map((category: CourseCategoryDto): IDropdownOption => ({
      key: category.id || '',
      text: category.categoryTranslations?.find(categoryTranslation => categoryTranslation.language === 'it-IT')?.value || '',
    }));
  }, [settings?.categories]);

  const languages = useMemo((): IDropdownOption[] => {
    if (!settings?.supportedLanguages) return [];
    
    return settings?.supportedLanguages.map((language: string): IDropdownOption => ({
      key: language,
      text: getLanguageFromCode(language),
    })).sort((a, b) => sortString(a.text, b.text));
  }, [settings?.supportedLanguages]);

  const typeOfLessons = useMemo((): IDropdownOption[] => {
    const options: IDropdownOption[] = [];
    for (let key in TypeOfLessons) {
    options.push({ key, text: TypeOfLessons[key as keyof typeof TypeOfLessons] });
    }
    return options;
  }, []);

  const onDropdownChange = (e: FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined, name?: CreateFormKeys) => {
    if (!option || !name) return;

    switch (name) {
      case CreateFormKeys.TYPE_OF_LESSONS: {
        if (option.key === CreateFormKeys.GENERATE_AUDIO)
          dispatch({ type: ActionTypes.UPDATE_INPUT, payload: {name: CreateFormKeys.GENERATE_AUDIO, value: !options[CreateFormKeys.GENERATE_AUDIO]} });
        else option.selected ? 
          dispatch({ type: ActionTypes.UPDATE_INPUT, payload: { name: CreateFormKeys.TYPE_OF_LESSONS, value: [...options[CreateFormKeys.TYPE_OF_LESSONS], option.key.toString()] } }) :
          dispatch({ type: ActionTypes.UPDATE_INPUT, payload: { name: CreateFormKeys.TYPE_OF_LESSONS, value: options[CreateFormKeys.TYPE_OF_LESSONS].filter(el => el !== option.key) } });
        break;
      }
      case CreateFormKeys.LESSONS_NUMBER: {
        if (option.key === 'id-1') dispatch({ type: ActionTypes.UPDATE_INPUT, payload: { name: CreateFormKeys.LESSONS_NUMBER, value: -1 } });
        else dispatch({ type: ActionTypes.UPDATE_INPUT, payload: { name: CreateFormKeys.LESSONS_NUMBER, value: +option.text } });
        break;
      }

      default: {
        dispatch({ type: ActionTypes.UPDATE_INPUT, payload: { name, value: option.key.toString() } });
      }
    };
  };

  return (
    <div className={classes.courseOptionsContainer}>
      <Dropdown
        options={categories}
        onChange={(event: FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) =>
          onDropdownChange(event, option, index, CreateFormKeys.CATEGORY)}
        theme={DropdownThemes.DARK} 
        placeholder={error && 'Error...'}
        isGroup
        title="Category"
      />

      <Dropdown
        options={numberOfLessons}
        onChange={(event: FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) =>
          onDropdownChange(event, option, index, CreateFormKeys.LESSONS_NUMBER)}
        theme={DropdownThemes.DARK} 
        placeholder={error && 'Error...'}
        isGroup
        title="Number of lessons"
      />

      <Dropdown
        options={languages}
        onChange={(event: FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) =>
          onDropdownChange(event, option, index, CreateFormKeys.DOCUMENT_LANGUAGE)}
        theme={DropdownThemes.DARK} 
        placeholder={error && 'Error...'}
        isGroup
        title="Document language"
      />

      <Dropdown
        options={typeOfLessons}
        onChange={(event: FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) =>
          onDropdownChange(event, option, index, CreateFormKeys.TYPE_OF_LESSONS)}
        theme={DropdownThemes.DARK} 
        placeholder={error && 'Error...'}
        isGroup
        title="Type of lessons"
        isMultiSelect
        onRenderTitle={onRenderTypeOfLessonsTitle}
      />
    </div>
  )
};

export default CourseOptions;