import { IIconProps } from "../../../../common/models/Icons/icon-props";

const Unpublish = (props: IIconProps): JSX.Element => {
  return (
    <svg width="16" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1_6516)">
      <path d="M12 13V2C12 0.895386 11.1046 0 10 0H2C0.895447 0 0 0.895386 0 2V14C0 15.1046 0.895447 16 2 16H11.5C11.7761 16 12 15.7761 12 15.5C12 15.2239 11.7761 15 11.5 15H2C1.44775 15 1 14.5522 1 14H11C11.5523 14 12 13.5522 12 13ZM1 2C1 1.44769 1.44775 1 2 1H10C10.5523 1 11 1.44769 11 2V13H1V2Z" fill={props.color || '#000'}/>
      <path d="M8.82845 4.17158C8.63319 3.97626 8.3166 3.97633 8.12135 4.17158L6.00002 6.29285L3.87874 4.17158C3.68349 3.97633 3.3669 3.97626 3.17159 4.17158C2.97633 4.36683 2.97633 4.68342 3.17159 4.87867L5.29292 7.00001L3.17159 9.12128C2.97633 9.31654 2.97633 9.63313 3.17159 9.82844C3.3669 10.0237 3.68349 10.0237 3.87874 9.82844L6.00002 7.7071L8.12135 9.82844C8.3166 10.0237 8.63319 10.0237 8.82845 9.82844C9.0237 9.63313 9.0237 9.31654 8.82845 9.12128L6.70717 7.00001L8.82845 4.87867C9.0237 4.68342 9.0237 4.36683 8.82845 4.17158Z" fill={props.color || '#000'}/>
      </g>
      <defs>
      <clipPath id="clip0_1_6516">
      <rect width="12" height="16" fill={props.color || '#000'}/>
      </clipPath>
      </defs>
    </svg>
  )
};

export default Unpublish;