// import react
import { useReducer, useRef } from "react";

// import styles
import { getClassNames } from "./FromScratchModal.classNames";
import { CSSTransition } from "react-transition-group";

// import components
import Modal from "../../../../../common/components/Modal/modal";
import Loading from "../../../../../common/components/Loading/Loading";
import Buttons from "./components/Buttons/buttons";
import Details from "./components/Details/details";
import ErrorMessage from "./components/ErrorMessage/ErrorMessage";

// import types
import { ICreateFromScratchForm } from "../../../../../common/models/Create/form-data";

// import hooks
import useGenerate from "./hooks/useGenerate";
import { ANIMATION_DURATION } from "../../../../../utils/constants/styles";

export enum ActionTypes {
  UPDATE_INPUT = 'UPDATE_INPUT',
  UPDATE_GENERATE_AUDIO = 'UPDATE_GENERATE_AUDIO',
}

export interface IInputPayload {
  name: string;
  value: string | string[] | number | boolean | undefined;
}

export interface IAction {
  type: ActionTypes;
  payload: string | File | IInputPayload | null;
}

export function reducer(state: ICreateFromScratchForm, action: IAction): ICreateFromScratchForm {
  switch (action.type) {
    case (ActionTypes.UPDATE_INPUT): return {
      ...state,
      [(action.payload as IInputPayload).name]: (action.payload as IInputPayload).value,
    };

    case (ActionTypes.UPDATE_GENERATE_AUDIO): return {
      ...state,
      [(action.payload as IInputPayload).name]: (action.payload as IInputPayload).value,
    };

    default: return state;
  } 
}

interface IFromScratchModalProps {
  onFromScratchModalCloseClick: () => void;
  nodeRef: React.RefObject<HTMLDivElement>;
  animation: boolean;
}

// form initial state
const initialFormDataState: ICreateFromScratchForm = {
  title: '',
  description: '',
  categoryId: '',
  documentLanguage: '',
  hasAudio: true,
};

function FromScratchModal(props: IFromScratchModalProps) {
  const [state, dispatch] = useReducer(reducer, initialFormDataState);
  const { generateCourse, isLoading, loadingMessage, errorMessage } = useGenerate();
  const classes = getClassNames();

  const loadingRef = useRef<HTMLDivElement>(null);

  return (
    <Modal
      nodeRef={props.nodeRef}
      animation={props.animation}
      width={580}
      title="From scratch"
      onModalClose={props.onFromScratchModalCloseClick}
    >
      <form className={classes['form']} onSubmit={(e) => generateCourse(e, state)}>
        <CSSTransition
          nodeRef={loadingRef}
          timeout={ANIMATION_DURATION}
          in={isLoading}
          classNames={{
            enter: classes.enter,
            enterActive: classes.enterActive,
            exit: classes.exit,
            exitActive: classes.exitActive
          }}
          unmountOnExit
        >
          <Loading
            nodeRef={loadingRef}
            animation={isLoading}
            label={loadingMessage}
          />
        </CSSTransition>
        <Details formData={state} dispatch={dispatch} />
        <ErrorMessage errorMessage={errorMessage} />
        <Buttons onCancelClick={props.onFromScratchModalCloseClick} />
      </form>
    </Modal>
  )
};

export default FromScratchModal;