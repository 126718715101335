import { IIconProps } from "../../../../common/models/Icons/icon-props";

const Download = (props: IIconProps): JSX.Element => {
  return (
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.5 14.9978C11.7761 14.9978 12 15.2216 12 15.4978C12 15.7432 11.8231 15.9474 11.5899 15.9897L11.5 15.9978H0.5C0.223858 15.9978 0 15.7739 0 15.4978C0 15.2523 0.176875 15.0482 0.410124 15.0058L0.5 14.9978H11.5ZM6.00014 0C6.2456 0 6.4497 0.177006 6.49197 0.410267L6.5 0.500145L6.496 12.294L10.1414 8.64567C10.3148 8.47194 10.5842 8.45241 10.7792 8.58722L10.8485 8.64502C11.0222 8.81842 11.0418 9.08783 10.907 9.28282L10.8492 9.35212L6.35745 13.8521C6.28495 13.9248 6.19568 13.9704 6.10207 13.9892L5.99608 13.9989C5.83511 13.9989 5.69192 13.9228 5.60051 13.8046L1.14386 9.3527C0.948459 9.15758 0.948234 8.84099 1.14336 8.64559C1.3168 8.4719 1.58621 8.45243 1.78117 8.58728L1.85046 8.64509L5.496 12.286L5.5 0.499855C5.50008 0.223712 5.724 0 6.00014 0Z" fill="#242424"/>
    </svg>
  )
};

export default Download;