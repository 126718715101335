// import react
import { Dispatch } from "react";

// import styles
import { getClassNames } from "./recipient.classNames";

// import components
import PeoplePicker from "./PeoplePicker/people-picker";
import { IAction, IFormData } from "../../../../hooks/useForm";

interface IRecipientProps {
  formData: IFormData;
  dispatch: Dispatch<IAction>;
  // setRecipient: (selectedItem?: IUser | undefined) => IUser | PromiseLike<IUser> | null;
  // removeRecipient: (items: IPersonaProps[] | undefined) => void;
  // searchUser: (filter: string) => Promise<IPersonaProps[]>;
}

const Recipient = (props: IRecipientProps) => {
  const classes = getClassNames();

  return (
    <div className={classes.recipientContainer}>
      <h5 className={classes.titleLabel}>Assignee</h5>
      <PeoplePicker
        recipient={props.formData.recipient}
        dispatch={props.dispatch}
      />
    </div>
  )
};

export default Recipient;