import { useRef, DragEvent } from 'react';
import { LessonDto } from '../../../../../../api-client';
import { useAppDispatch } from '../../../../../../store/store';
import { changeLessonOrder } from '../../../../../../store/slices/courses';

const useDrag = (lessons: LessonDto[]) => {
  const dragIndex = useRef<number>();
  const dispatch = useAppDispatch();

  const onDragStart = (e: DragEvent<HTMLElement>, index: number) => dragIndex.current = index;
  
  const onDrop = (e: DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (dragIndex.current === undefined) return;

    const targetLessonId = e.currentTarget.getAttribute('data-lesson-id');
    if (!targetLessonId) return;

    const targetIndex = lessons.findIndex(lesson => lesson.id === targetLessonId);
    if (targetIndex === -1) return;

    dispatch(changeLessonOrder({ index: dragIndex.current, targetIndex })).catch(err => console.error(err));
  };

  const onDragEnd = (e: DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.style.cursor = 'move';
    dragIndex.current = undefined;
  };

  const onDragOver = (e: DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return {
    onDragStart,
    onDragOver,
    onDrop,
    onDragEnd
  };
};

export default useDrag;