// import react
import * as React from 'react';
import { getClassNames } from './navigation.classNames';
import ChevronLeft from '../../../../../../assets/Icons/Controls/ChevronLeft/chevron-left';
import ChevronRight from '../../../../../../assets/Icons/Controls/ChevronRight/chevron-right';

interface INavigationProps {
  numberOfPages: number;
  page: number;
  nextPage: () => void;
  previousPage: () => void;
}

const Navigation = (props: INavigationProps) => {
  const classes = getClassNames();

  return (
    <div className={classes.navigation}>
      {props.numberOfPages > 0 && (
        <>
          <ChevronLeft onClick={props.previousPage} />
          <p className={classes.navigationLabel}>{`Page ${props.page + 1} of ${props.numberOfPages}`}</p>
          <ChevronRight onClick={props.nextPage} />
        </>
      )}
    </div>
  );
};

export default Navigation;