import { useCallback, useContext, useRef } from 'react';

// import styles
import { getClassNames } from './Learnings.classNames';
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { ANIMATION_DURATION } from "../../../utils/constants/styles";

// import components
import Loading from '../../../common/components/Loading/Loading';
import Error from '../../../common/components/Error/error';
import Header from './components/Header/Header';
import List from "./components/List/list";

// import hooks
import useLearningsPage from './hooks/useLearningsPage';
import { useEffect, useMemo } from 'react';

// import redux
import { RootState, useAppDispatch } from '../../../store/store';
import { useSelector } from 'react-redux';
import { ApiStatus, getCourses, getProfilePhotos } from '../../../store/slices/courses';
import DeleteDialog from './components/DeleteDialog/DeleteDialog';
import { CourseStatusDto } from '../../../api-client';
import { FiltersContext } from '../../../contexts/filters-context';

const Learnings = () => {
  const filters = useContext(FiltersContext);
  
  const {
    onMenuClick,
    search,
    updateSearch,
    deleteDialogActions
  } = useLearningsPage();

  const dispatch = useAppDispatch();
  const courses = useSelector((state: RootState) => state.courses.courses);
  const { startingStatus } = useSelector((state: RootState) => state.courses.apiStatus);
  const { username } = useSelector((state: RootState) => state.account);

  const classes = getClassNames();

  const coursesRef = useRef<HTMLDivElement>(null);
  const loadingRef = useRef<HTMLDivElement>(null);
  const errorRef = useRef<HTMLDivElement>(null);
  
  function getNodeRef() {
    if (startingStatus.api === ApiStatus.LOADING) return loadingRef;
    if (startingStatus.api === ApiStatus.ERROR) return errorRef;
    if (startingStatus.api === ApiStatus.SUCCESS) return coursesRef;
  }

  function getNodeKey() {
    if (startingStatus.api === ApiStatus.LOADING) return 'loading';
    if (startingStatus.api === ApiStatus.ERROR) return 'error';
    if (startingStatus.api === ApiStatus.SUCCESS) return 'courses';
  }

  const modalRef = useRef<HTMLDivElement>(null);

  const nodeRef = getNodeRef();
  const nodeKey = getNodeKey();

  const filteredLearnings = useMemo(() => {
    if (!courses) return [];

    if (filters.learnings.selectedFilter === 1)
      return courses.filter((course): any => course.createdBy?.username === username).filter(course => course.title?.toLowerCase().includes(search.toLowerCase())) || [];
    
    if (filters.learnings.selectedFilter === 2)
      return courses.filter((course): any => course.status === CourseStatusDto.Running).filter(course => course.title?.toLowerCase().includes(search.toLowerCase())) || [];
    
    return courses.filter(course => course.title?.toLowerCase().includes(search.toLowerCase())) || [];
  }, [courses, search, filters.learnings.selectedFilter, username]);

  const init = useCallback(async () => {
    try {
      if (!!courses) return;

      await dispatch(getCourses()).unwrap();
      await dispatch(getProfilePhotos()).unwrap();

    } catch (err) {
      console.error(err);
    }
  }, [courses, dispatch]);

  useEffect(() => {
    init();
  }, [init]);

  console.log('window', window);

  return (
    <section className={classes.learningsPage}>
      {/* <Header onMenuClick={onMenuClick} search={search} updateSearch={updateSearch} /> */}
      {/* <Menu isMenuOpened={isMenuOpened} selectedMenuItem={selectedMenuItem} onMenuItemClick={onMenuItemClick} /> */}
      <SwitchTransition>
        <CSSTransition
          nodeRef={nodeRef}
          key={nodeKey}
          timeout={ANIMATION_DURATION}
          classNames={{
            enter: classes.enter,
            enterActive: classes.enterActive,
            exit: classes.exit,
            exitActive: classes.exitActive
          }}
          unmountOnExit
        >
          <div ref={nodeRef} className={classes.content}>
            <Header onMenuClick={onMenuClick} search={search} updateSearch={updateSearch} />
            {startingStatus.api === ApiStatus.LOADING && <Loading label={startingStatus.loadingMessage} position='absolute' />}
            {startingStatus.api === ApiStatus.ERROR && <Error message='Error loading the courses...' />}
            {startingStatus.api === ApiStatus.SUCCESS && <List courses={filteredLearnings} onErrorItemClick={deleteDialogActions.onShowDeleteDialog} filter={filters.learnings.selectedFilter} />}

            <CSSTransition
              nodeRef={modalRef}
              timeout={ANIMATION_DURATION}
              in={deleteDialogActions.showDeleteDialog}
              classNames={{
                enter: classes.enter,
                enterActive: classes.enterActive,
                exit: classes.exit,
                exitActive: classes.exitActive
              }}
              unmountOnExit
            >
              <DeleteDialog
                nodeRef={modalRef}
                animation={deleteDialogActions.showDeleteDialog}
                // type={'LearningError'}
                onCancelDeleteLearning={deleteDialogActions.onCloseDeleteDialog}
                courseIdToDelete={deleteDialogActions.courseIdToDelete}
              />
            </CSSTransition>
          </div>
        </CSSTransition>
      </SwitchTransition>
    </section>
  )
}

export default Learnings;