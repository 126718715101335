import { mergeStyleSets, getTheme } from "@fluentui/react";
import { IModalProps } from "./modal";
import { BorderRadius, IFadeClassNames, FADE, FADE_SCALE, IFadeScaleClassNames } from "../../../utils/constants/styles-copy";

interface IModalClassNames extends IFadeClassNames, IFadeScaleClassNames {
  'modal-background': string;
  'modal': string;
  modalContent: string;
  'header': string;
  'close-icon': string;
  buttons: string;
}

export const getClassNames = (props: IModalProps): IModalClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    'modal-background': {
      position: 'fixed',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      zIndex: 10,
      background: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },

    'modal': {
      position: 'relative',
      width: props.width,
      maxHeight: '90%',
      overflow: 'auto',
      padding: '20px 30px',
      background: theme.palette.white,
      borderRadius: BorderRadius,
    },

    modalContent: {
      height: props.height,
      display: 'flex',
      flexDirection: 'column',
      gap: props.gap ? props.gap : 40,
      transition: 'all 300ms',
    },

    'header': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    'close-icon': {
      cursor: 'pointer',
    },

    buttons: {
      width: '100%',
      display:'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: 10,
    },

    ...FADE.classNames,
    ...FADE_SCALE.classNames
  });
}