export enum StatusNames {
  Generating = 'Generating',
  Draft = 'Draft',
  Published = 'Published',
  Running = 'Running',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  GenError = 'GenError'
}

export interface IStatusInfo {
  icon: () => JSX.Element;
  color: string;
  backgroundColor: string;
}


