import { useReducer } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../store/store";

export interface IQuizForm {
  passingPercentage: number;
  isFinalQuizRepeatable: boolean;
}

export enum ActionTypes {
  UPDATE_INPUT = 'UPDATE_INPUT',
  UPDATE_REPEATABLE = 'UPDATE_REPEATABLE',
}

interface IInputPayload {
  name: string;
  value: string | string[] | number | boolean | undefined;
}

interface IRepeatablePayload {
  checked: boolean;
}

export interface IAction {
  type: ActionTypes;
  payload: IInputPayload | IRepeatablePayload;
}

function reducer(state: IQuizForm, action: IAction): IQuizForm {
  switch (action.type) {
    case (ActionTypes.UPDATE_REPEATABLE): return {
      ...state,
      isFinalQuizRepeatable: (action.payload as IRepeatablePayload).checked,
    };

    case (ActionTypes.UPDATE_INPUT): return {
      ...state,
      [(action.payload as IInputPayload).name]: (action.payload as IInputPayload).value,
    };

    default: return state;
  } 
}

function useQuizForm() {
  const passingPercentage = useSelector((state: RootState) => state.courses.course?.passingPercentage);
  const isFinalQuizRepeatable = useSelector((state: RootState) => state.courses.course?.isFinalQuizRepeatable);

  const initialState: IQuizForm = {
    passingPercentage: passingPercentage || 0,
    isFinalQuizRepeatable: isFinalQuizRepeatable || false,
  };

  const [quizForm, quizFormDispatch] = useReducer(reducer, initialState);

  const isQuizChanged = (): boolean => {
    return (
      quizForm.isFinalQuizRepeatable !== initialState.isFinalQuizRepeatable || 
      quizForm.passingPercentage !== initialState.passingPercentage
    );
  };

  return {
    quizForm,
    quizFormDispatch,
    isQuizChanged
  }
}

export default useQuizForm;