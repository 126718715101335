import { useState, useCallback } from "react";

const useAudio = () => {
  const [onPlayAudioLessonId, setOnPlayAudioLessonId] = useState<string>('');

  const setAudioLessonId = useCallback((id: string) => setOnPlayAudioLessonId(id), []);
  
  return {
    onPlayAudioLessonId,
    setAudioLessonId
  };
};

export default useAudio;