import { PropsWithChildren } from 'react'
import { DefaultButton as FluentUIDefaultButton, IButtonStyles, IContextualMenuProps } from "@fluentui/react";
import { getClassNames } from "./default-button.classNames";
import { getTheme } from '@fluentui/react';
import { Icons } from '../../../../utils/constants/icons';
import { IconNames } from '../../../models/Icons/icon-names';

export interface IDefaultButtonProps {
  width?: number;
  height?: number;
  onClick?: () => void;
  type?: string;
  disabled?: boolean;
  iconName?: IconNames;
  showBorder?: boolean;
  color?: string;
  menuProps?: IContextualMenuProps;
}

const DefaultButton = (props: PropsWithChildren<IDefaultButtonProps>) => {
  const classes = getClassNames(props);

  const theme = getTheme();
  const buttonStyles: IButtonStyles = {
    rootDisabled: {
      background: theme.palette.neutralLight,
      color: theme.palette.neutralTertiary,
      fontWeight: theme.fonts.xLarge.fontWeight
    },
    root: {
      color: props.color && props.color,
      fontWeight: theme.fonts.xLarge.fontWeight,
    },
    flexContainer: {
      gap: !props.menuProps && 10
    },
    menuIcon: {
      marginLeft: 10,
      marginTop: 2,
      marginRight: -4
    }
  };

  const Icon = props.iconName && Icons[props.iconName];

  return (
    <FluentUIDefaultButton
      className={classes['button']}
      styles={buttonStyles}
      onClick={props.onClick}
      type={props.type}
      menuProps={props.menuProps}
      disabled={props.disabled}
    >
      {Icon && <Icon color={props.disabled ? theme.palette.neutralTertiary : props.color} />}
      {props.children}
    </FluentUIDefaultButton>
  )
}

export default DefaultButton;