import { mergeStyleSets, getTheme } from "@fluentui/react";

interface IControlsClassNames {
  controlsContainer: string;
  progressBar: string;
  buttonControl: string;
  muteControl: string;
  progressTime: string;

  fadeEnter: string;
  fadeEnterActive: string;
  fadeExit: string;
  fadeExitActive: string;
}

export function getClassNames(currentTime: number, duration: number): IControlsClassNames {
  const theme = getTheme();

  return mergeStyleSets({
    controlsContainer: {
      position: 'absolute',
      bottom: 10,
      left: '50%',
      transform: 'translateX(-50%)',
      width: '90%',
      padding: '8px 12px',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      backdropFilter: 'blur(10px)',
      borderRadius: 5,
      display: 'flex',
      alignItems: 'center',
      gap: 10
    },

    progressBar: {
      flex: 1,
      display: 'flex',
      marginTop: 2,

      'input[type="range"]': {
        '--range-progress': 0,
        '-webkit-appearance': 'none',
        position: 'relative',
        background: theme.palette.neutralTertiary,
        width: '100%',
        height: 3,
        borderRadius: 2,
        cursor: 'pointer',
      },

      'input[type="range"]::before': {
        content: '""',
        height: 3,
        background: theme.palette.themePrimary,
        width: currentTime && duration && `${(currentTime / duration) * 100}%`,
        // width: 50,
        borderBottomLeftRadius: 2,
        borderTopLeftRadius: 2,
        position: 'absolute',
        top: 0,
        left: 0,
      },

      'input[type="range"]::-webkit-slider-thumb': {
        '-webkit-appearance': 'none',
        height: 10,
        width: 10,
        borderRadius: '50%',
        border: 'none',
        background: theme.palette.themePrimary,
        cursor: 'pointer',
        position: 'relative',
      },

      'input[type="range"]:active::-webkit-slider-thumb': {
        transform: 'scale(1.2)',
      },
    },

    progressTime: {
      color: theme.palette.neutralLight,
      fontSize: 13,
    },

    buttonControl: {
      height: 12,
      aspectRatio: '1 / 1',
      display: 'flex',
    },

    muteControl: {
      height: 14,
      aspectRatio: '1 / 1',
      display: 'flex',
    },

    fadeEnter: {
      opacity: 0,
    },

    fadeEnterActive: {
      opacity: 1,
      transition: 'opacity 300ms',
    },

    fadeExit: {
      opacity: 1,
    },

    fadeExitActive: {
      opacity: 0,
      transition: 'opacity 300ms',
    },
  });
}






// import { mergeStyleSets, getTheme, keyframes } from "@fluentui/react";

// interface IPlayButtonClassNames {
//   controlsContainer: string;
//   timeBar: string;
//   buttonsContainer: string;
//   buttonControl: string;
//   volume: string;
//   loader: string;
//   timeProgressContainer: string;

//   fadeEnter: string;
//   fadeEnterActive: string;
//   fadeExit: string;
//   fadeExitActive: string;
// }

// export function getClassNames(currentTime: number, duration: number): IPlayButtonClassNames {
//   const theme = getTheme();

//   const rotation = keyframes({
//     '0%': {
//       transform: 'rotate(0deg)',
//     },

//     '100%': {
//         transform: 'rotate(360deg)',
//     }
//   });
  
//   return mergeStyleSets({
//     controlsContainer: {
//       position: 'absolute',
//       bottom: 10,
//       left: '50%',
//       transform: 'translateX(-50%)',
//       width: '90%',
//       padding: '8px 12px',
//       backgroundColor: 'rgba(0, 0, 0, 0.5)',
//       backdropFilter: 'blur(10px)',
//       borderRadius: 5,
//       display: 'flex',
//       alignItems: 'center',
//       gap: 10
//     },

//     timeBar: {
//       flex: 1,
//       display: 'flex',
//       marginTop: 2,

//       'input[type="range"]': {
//         '--range-progress': 0,
//         '-webkit-appearance': 'none',
//         position: 'relative',
//         background: theme.palette.neutralTertiary,
//         width: '100%',
//         height: 3,
//         borderRadius: 2,
//         cursor: 'pointer',
//       },

//       'input[type="range"]::before': {
//         content: '""',
//         height: 3,
//         background: theme.palette.themePrimary,
//         width: currentTime && duration && `${(currentTime / duration) * 100}%`,
//         // width: 50,
//         borderBottomLeftRadius: 2,
//         borderTopLeftRadius: 2,
//         position: 'absolute',
//         top: 0,
//         left: 0,
//       },

//       'input[type="range"]::-webkit-slider-thumb': {
//         '-webkit-appearance': 'none',
//         height: 10,
//         width: 10,
//         borderRadius: '50%',
//         border: 'none',
//         background: theme.palette.themePrimary,
//         cursor: 'pointer',
//         position: 'relative',
//       },

//       'input[type="range"]:active::-webkit-slider-thumb': {
//         transform: 'scale(1.2)',
//       },
//     },

//     buttonsContainer: {
//       display: 'flex',
//       gap: 10,
//       height: 14,
//     },

//     buttonControl: {
//       width: '100%',
//       height: '100%',
//       color: theme.palette.neutralLight,
//       fontSize: 15,
//     },

//     volume: {
//       width: '100%',
//       height: '100%',
//       color: theme.palette.neutralLight,
//       fontSize: 14    },

//     loader: {
//       height: '100%',
//       aspectRatio: '1 / 1',
//       // border: `2px solid ${theme.palette.themeLight}`,
//       border: '2px solid transparent',
//       borderTopColor: theme.palette.neutralLight,
//       borderRadius: '50%',
//       display: 'inline-block',
//       animationName: rotation,
//       animationDuration: '1.3s',
//       animationIterationCount: 'infinite',
//       animationTimingFunction: 'cubic-bezier(0.53, 0.21, 0.29, 0.67)',
//     },

//     timeProgressContainer: {
//       display: 'flex',
//       fontSize: 14,
//       color: theme.palette.neutralLight,
//     },

//     fadeEnter: {
//       opacity: 0,
//       // backdropFilter: 'blur(0px)',
//     },

//     fadeEnterActive: {
//       opacity: 1,
//       // backdropFilter: 'blur(5px)',
//       // transition: "opacity 300ms, backdrop-filter 300ms",
//       transition: 'opacity 300ms',
//     },

//     fadeExit: {
//       opacity: 1,
//       // backdropFilter: 'blur(5px)',
//     },

//     fadeExitActive: {
//       opacity: 0,
//       // backdropFilter: 'blur(0px)',
//       // transition: "opacity 300ms, backdrop-filter 300ms",
//       transition: 'opacity 300ms',
//     },
//   });
// }