import { ApplicationSettings } from './application';
import { CoursesApi, Configuration, TenantApi, SettingsApi, LmsApi } from "../../api-client";

const baseAPIUrl = ApplicationSettings.IAI_APP_API_URL;
const baseFunctionAPIUrl = ApplicationSettings.IAI_FUNCTION_APP_API_URL;

export const CoursesApiClient = async (token: string) => {
  return new CoursesApi({
    basePath: baseAPIUrl,
    baseOptions: {
      headers: {
        Authorization: `Bearer ${token}`
      },
      timeout: ApplicationSettings.IAI_APP_AXIOS_TIMEOUT
    },
    isJsonMime: Configuration.prototype.isJsonMime
  })
}

export const UsersApiClient = async (token: string) => {
  return new TenantApi({
    basePath: baseAPIUrl,
    baseOptions: {
      headers: {
        Authorization: `Bearer ${token}`
      },
      timeout: ApplicationSettings.IAI_APP_AXIOS_TIMEOUT
    },
    isJsonMime: Configuration.prototype.isJsonMime
  })
}

export const SettingsApiClient = async (token: string) => {
  return new SettingsApi({
    basePath: baseAPIUrl,
    baseOptions: {
      headers: {
        Authorization: `Bearer ${token}`
      },
      timeout: ApplicationSettings.IAI_APP_AXIOS_TIMEOUT
    },
    isJsonMime: Configuration.prototype.isJsonMime
  })
}

export const CoursesFunctionApiClient = async (token: string) => {
  return new CoursesApi({
    basePath: baseFunctionAPIUrl,
    baseOptions: {
      headers: {
        Authorization: `Bearer ${token}`
      },
      timeout: ApplicationSettings.IAI_APP_AXIOS_TIMEOUT
    },
    isJsonMime: Configuration.prototype.isJsonMime
  })
}

export const LMSApiClient = async (token: string) => {
  return new LmsApi({
    basePath: baseAPIUrl,
    baseOptions: {
      headers: {
        Authorization: `Bearer ${token}`
      },
      timeout: ApplicationSettings.IAI_APP_AXIOS_TIMEOUT
    },
    isJsonMime: Configuration.prototype.isJsonMime
  })
}