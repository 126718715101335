import { Dispatch } from "react";
import { getClassNames } from "./list.classNames";
import { DetailsList, CheckboxVisibility, IColumn, getTheme, IDetailsHeaderProps, IRenderFunction, DetailsListLayoutMode } from "@fluentui/react";
import ListItem from "./ListItem/list-item";
import { StatusNames } from "../../../../../../../../common/models/Learnings/status";
import { DateTime } from "luxon";
import { CourseStatusDto, ScheduleDto, ScheduleStatusDto } from "../../../../../../../../api-client";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../../../../../store/store";
import { deleteSchedule, editSchedule, sendSchedule, setStatus } from "../../../../../../../../store/slices/courses";
import { ActionTypes, IAction } from "../../hooks/useForm";

interface IListProps {
  dispatch: Dispatch<IAction>;
  onShowNewScheduleClick: () => void;
  searchSchedule: string;
}

const List = (props: IListProps) => {
  const scheduleList = useSelector((state: RootState) => state.courses.course?.schedules);
  const courseStatus = useSelector((state: RootState) => state.courses.course?.status);
  const dispatch = useAppDispatch();
  const classes = getClassNames();

  const [selectedColumn, setSelectedColumn] = useState<string>('Start');
  const [isSortedDescending, setIsSortedDescending] = useState<boolean>(false);

  const items = useMemo(() => {
    if (!scheduleList) return [];
    return scheduleList.filter(
      el => el.archived === false && el.recipient.displayName?.toLowerCase().includes(props.searchSchedule))
      .map((el: ScheduleDto): any => (
      {
        Recipients: el.recipient,
        Start: DateTime.fromISO(el.startDate || '').toLocaleString({weekday: "short", month: "short", day: "2-digit", year: "numeric"}, {locale: "en"}),
        Frequency: el.frequency,
        Status: el.status,
        '': ''
      }
    )).sort((a, b) => {
      if (selectedColumn === 'Recipients')
        return (isSortedDescending ? a[selectedColumn].displayName < b[selectedColumn].displayName : a[selectedColumn].displayName > b[selectedColumn].displayName) ? 1 : -1;
      if (selectedColumn === 'Start')
        return (isSortedDescending ? new Date(a[selectedColumn]) < new Date(b[selectedColumn]) : 
          new Date(a[selectedColumn]) > new Date(b[selectedColumn])) ? 1 : -1;
      return (isSortedDescending ? a[selectedColumn] < b[selectedColumn] : a[selectedColumn] > b[selectedColumn]) ? 1 : -1;
    })
  }, [selectedColumn, isSortedDescending, scheduleList, props.searchSchedule]);

  const onColumnClick = (ev: React.MouseEvent<HTMLElement, MouseEvent>, column: IColumn) => {
    if (column.key === '') return;

    if (selectedColumn === column.key) {
      column.isSortedDescending = !column.isSortedDescending;
      setIsSortedDescending(column.isSortedDescending);
    }
    setSelectedColumn(column.key);
  };

  const getColumns = () => {
    const columns: IColumn[] = [
      { key: 'Recipients', name: 'Recipients', minWidth: 200, maxWidth: 250, onColumnClick, isSortedDescending, isSorted: selectedColumn === 'Recipients' },
      { key: 'Start', name: 'Start', minWidth: 200, onColumnClick, isSortedDescending, isSorted: selectedColumn === 'Start' },
      { key: 'Frequency', name: 'Frequency', minWidth: 0, maxWidth: 80, onColumnClick, isSortedDescending, isSorted: selectedColumn === 'Frequency' },
      { key: 'Status', name: 'Status', minWidth: 140, maxWidth: 200, onColumnClick, isSortedDescending, isSorted: selectedColumn === 'Status' },
      { key: '', name: '', minWidth: 300 }
    ];
    return columns;
  };

  function onEditClick (item: any) {
    const scheduleItem = scheduleList?.find(el => el.recipient.id === item.Recipients.id && !el.archived);
    if (!scheduleItem) return;

    props.dispatch({ type: ActionTypes.SET_EDIT_STATE, payload: { scheduleItem } });
    props.onShowNewScheduleClick();
  };

  async function onDeleteClick(item: any) {
    const schedule = 
      scheduleList?.filter(el => el.archived === false).find(el => el.recipient.id === item.Recipients.id);
    if (!schedule) return;
    try {
      await dispatch(deleteSchedule(schedule));
    } catch (err) {
      console.error(err);
    }
  }

  async function onSendClick(item: any) {
    const schedule = scheduleList?.find(el => el.recipient.id === item.Recipients.id);
    if (!schedule) return;

    try {
      await dispatch(sendSchedule(schedule)).unwrap();
      if (courseStatus !== 'Running') {
        dispatch(setStatus({ status: CourseStatusDto.Running}));
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function onStopClick(item: any) {
    const schedule = scheduleList?.find(el => el.recipient.id === item.Recipients.id);
    if (!schedule) return;

    try {
      const draftSchedule = {...schedule, status: ScheduleStatusDto.Draft};
      await dispatch(editSchedule(draftSchedule)).unwrap();
      if (scheduleList?.some(el => el.id !== draftSchedule.id && el.status === ScheduleStatusDto.Running)) return;
      else dispatch(setStatus({ status: CourseStatusDto.Published }));
    } catch (err) {
      console.error(err);
    }
  }

  const renderItem = (item?: any, index?: number | undefined, column?: IColumn | undefined) => {
    if (!column || index === undefined) return;
    if (items.length > 0)
      return (
        <ListItem
          item={item}
          courseStatus={courseStatus}
          itemIndex={index}
          columnKey={column.key}
          statusInfo={items[index].Status as StatusNames}
          editListItem={() => onEditClick(item)}
          onDeleteClick={() => onDeleteClick(item)}
          onSendScheduleClick={() => onSendClick(item)}
          onStopClick={() => onStopClick(item)}
        />
      )
    else return <div></div>;
  }

  const renderDetailsHeader = (details?: IDetailsHeaderProps, defaultRender?: IRenderFunction<IDetailsHeaderProps>) => {
    if (!details || !defaultRender) return null;

    return defaultRender({
      ...details, styles: {
        root: {
          '.ms-DetailsHeader-cellName': {
            fontSize: 12,
            color: getTheme().palette.neutralTertiary
          },
        },
      }
    })
  };

  return (
    <div className={classes.listContainer}>
      <DetailsList
        items={items}
        columns={getColumns()}
        onRenderDetailsHeader={renderDetailsHeader}
        checkboxVisibility={CheckboxVisibility.hidden}
        onRenderItemColumn={renderItem}
        layoutMode={DetailsListLayoutMode.fixedColumns}
      />
    </div>
  )
};

export default List;