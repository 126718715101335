import { PropsWithChildren, useEffect, useRef, useState } from "react";
import { getClassNames } from "./modal.classNames";
import { Icon } from "@fluentui/react";
import { CSSTransition } from "react-transition-group";
import { ANIMATION_DURATION } from "../../../utils/constants/styles";

export interface IModalProps {
  nodeRef: React.RefObject<HTMLDivElement>;
  animation: boolean;
  width: number;
  height?: number;
  title: string;
  onModalClose: () => void;
  gap?: number;
}

const Modal = (props: PropsWithChildren<IModalProps>) => {
  const [animation, setAnimation] = useState<boolean>(false);
  const classes = getClassNames(props);

  const modalRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   document.body.style.overflow = 'hidden';
  //   document.body.style.paddingRight = '10px'
  //   return () => { 
  //     document.body.style.overflow = 'auto'; 
  //     document.body.style.paddingRight = '0px'
  //   }
  // }, []);

  useEffect(() => {
    setAnimation(props.animation);
  }, [props.animation]);

  return ( 
    <div ref={props.nodeRef} className={classes['modal-background']}>
      <CSSTransition
        nodeRef={modalRef}
        timeout={ANIMATION_DURATION}
        in={animation}
        classNames={{
          enter: classes.enter,
          enterActive: classes.enterActive,
          exit: classes.exit,
          exitActive: classes.exitActive
        }}
        mountOnEnter
        unmountOnExit
      >
        <div ref={modalRef} className={classes['modal']}>
          <div className={classes.modalContent}>
            <div className={classes['header']}>
              <h1>{props.title}</h1>
              <Icon className={classes['close-icon']} iconName="ChromeClose" onClick={props.onModalClose} />
            </div>
            {props.children}
          </div>
        </div>
      </CSSTransition>
    </div>
  )
}

export default Modal;