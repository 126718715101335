// import react
import { FormEvent, useContext, useEffect, useRef, useState } from 'react';

// import styles
import { getClassNames } from "./edit-modal.classNames";
import { CSSTransition } from 'react-transition-group';

import _ from 'lodash';

// import components
import Modal from "../../../../../../common/components/Modal/modal";
import Attachment from "./components/Attachment/attachment";
import EditLesson from "./components/EditLesson/edit-lesson";
import Buttons from './components/Buttons/buttons';

// import hooks
import useAttachment from './hooks/useAttachment';
import useForm, { ActionTypes } from './hooks/useForm';
import { useParams } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../../../../store/store';
import { addLesson, saveLesson } from '../../../../../../store/slices/courses';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Quote from './components/Quote/quote';
import Nav from './components/Nav/nav';
import useNav from './hooks/useNav';
import { ANIMATION_DURATION } from '../../../../../../utils/constants/styles';
import ConfirmationDialog from '../../ConfirmationDialog/confirmation-dialog';
import { timeout } from '../../../../../../utils/helpers/helpers';
import Loading from '../../../../../../common/components/Loading/Loading';
import { InfoContext } from '../../../../../../contexts/info-context';

interface IEditModalProps {
  onCloseEditModal: () => void;
  nodeRef: React.RefObject<HTMLDivElement>;
  animation: boolean;
}

const EditModal = (props: IEditModalProps) => {
  const { info: { modifiedLessons, addModifiedLesson } } = useContext(InfoContext);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>('');

  const coursesFunctionClientApi = useSelector((state: RootState) => state.clients.clients.coursesFunctionClientApi);
  const courseId = useSelector((state: RootState) => state.courses.course?.id);
  const lessonToEdit = useSelector((state: RootState) => state.courses.lessonToEdit);
  const dispatch = useAppDispatch();
  const classes = getClassNames();
  
  // hooks
  const { formData, formDispatch } = useForm(lessonToEdit);
  const attachment = useAttachment();
  const { lang } = useParams();
  const navigate = useNavigate();
  const { changeTab, selectedTab } = useNav();

  const dialogRef = useRef<HTMLDivElement>(null);
  const loadingRef = useRef<HTMLDivElement>(null);

  async function onSaveClick() {
    try {
      if (formData.lessonData.type !== formData.lessonType) {
        if (!coursesFunctionClientApi || !courseId || !formData.lessonData.id) return;
        setLoadingMessage('Generating the new lesson...');
        setIsLoading(true);
        await coursesFunctionClientApi.changeLessonType(courseId, formData.lessonData.id, {
          courseId,
          lessonId: formData.lessonData.id,
          type: formData.lessonType,
        });
        if (!modifiedLessons.includes(formData.lessonData.id || '')) addModifiedLesson(formData.lessonData.id || '');
        setLoadingMessage('Redirecting to the learnings page...');
        await timeout(3000); // await a second before redirecting
        navigate('/learnings');
        return;
      }

      let newLessonId: string | undefined;
      if (lessonToEdit) await dispatch(saveLesson({lesson: formData.lessonData, lang})).unwrap();
      else {
        const newLessons = await dispatch(addLesson({lesson: formData.lessonData, lang})).unwrap();
        newLessonId = newLessons[0].id; // no need
      }
      
      if (attachment.uploadAttachment) {
        await attachment.saveAttachment(newLessonId || formData.lessonData.id || '');
      }

      if (attachment.clearAttachment) {
        await attachment.deleteAttachment(newLessonId || formData.lessonData.id || '');
      }

      
      if (!modifiedLessons.includes(formData.lessonData.id || '')) addModifiedLesson(formData.lessonData.id || '');
      props.onCloseEditModal();
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
      setLoadingMessage('');
    }
  }

  useEffect(() => {
    if (attachment.attachment) {
      formDispatch({ type: ActionTypes.UPDATE_ATTACHMENT, payload: attachment.attachment });
    }
  }, [attachment.attachment, formDispatch]);

  return (
    <Modal
      nodeRef={props.nodeRef}
      animation={props.animation}
      width={1200}
      height={800}
      title={lessonToEdit ? "Edit lesson" : "New Lesson"}
      onModalClose={() => {
        if (lessonToEdit) {
          if (_.isEqual(lessonToEdit, formData.lessonData)) {
            if (attachment.isLoading || formData.attachment === undefined || _.isEqual(attachment.attachment, formData.attachment)) 
              props.onCloseEditModal();
            else setShowConfirmationDialog(true);
          } 
          else setShowConfirmationDialog(true);
        } else setShowConfirmationDialog(true);
      }}
      gap={10}
    >
      <form className={classes.form} onSubmit={(e: FormEvent) => { e.preventDefault(); }}>
        <div className={classes.leftContainer}>

          {!!formData.lessonData.quote ? (
            <>
              <Nav changeTab={changeTab} selectedTab={selectedTab} />

              {selectedTab === 0 && (
                <Attachment
                  isLoading={attachment.isLoading}
                  attachment={attachment.attachment}
                  onChooseFileClick={attachment.onChooseFileClick}
                  inputFileRef={attachment.inputFileRef}
                  onFileChange={attachment.onFileChange}
                  onAttachmentClear={attachment.onAttachmentClear}
                  onAddEmbedContent={attachment.onAddEmbedContent}
                />
              )}
      
              {selectedTab === 1 && (
                <Quote quote={formData.lessonData.quote} />
              )}
            </>
          ) : (
            <Attachment
              isLoading={attachment.isLoading}
              attachment={attachment.attachment}
              onChooseFileClick={attachment.onChooseFileClick}
              inputFileRef={attachment.inputFileRef}
              onFileChange={attachment.onFileChange}
              onAttachmentClear={attachment.onAttachmentClear}
              onAddEmbedContent={attachment.onAddEmbedContent}
            />
          )}
        </div>
        
        <div className={classes.editLessonContainer}>
          <EditLesson
            formData={formData}
            dispatch={formDispatch}
          />
          <Buttons 
            lessonType={formData.lessonType} 
            formData={formData} 
            onCancelClick={() => {
              if (lessonToEdit) {
                if (_.isEqual(lessonToEdit, formData.lessonData)) {
                  if (attachment.isLoading || formData.attachment === undefined ||  _.isEqual(attachment.attachment, formData.attachment)) 
                    props.onCloseEditModal();
                  else setShowConfirmationDialog(true);
                } 
                else setShowConfirmationDialog(true);
              } else setShowConfirmationDialog(true);
            }}
            onSaveClick={onSaveClick} 
          />
        </div>
      </form>

      <CSSTransition
        nodeRef={dialogRef}
        timeout={ANIMATION_DURATION}
        in={showConfirmationDialog}
        classNames={{
          enter: classes.enter,
          enterActive: classes.enterActive,
          exit: classes.exit,
          exitActive: classes.exitActive
        }}
        unmountOnExit
      >
        <ConfirmationDialog
          nodeRef={dialogRef}
          animation={showConfirmationDialog}
          type='Close'
          onClose={props.onCloseEditModal}
          onCancel={() => setShowConfirmationDialog(false)}
        />
      </CSSTransition>

      <CSSTransition
        nodeRef={loadingRef}
        timeout={ANIMATION_DURATION}
        in={isLoading}
        classNames={{
          enter: classes.enter,
          enterActive: classes.enterActive,
          exit: classes.exit,
          exitActive: classes.exitActive
        }}
        unmountOnExit
      >
        <Loading
          nodeRef={loadingRef}
          animation={isLoading}
          label={loadingMessage}  
        />
      </CSSTransition>

    </Modal>
  )
};

export default EditModal;