import { getClassNames } from './buttons.classNames';
import PrimaryButton from '../../../../../../../../common/components/Buttons/PrimaryButton/primary-button';
import DefaultButton from '../../../../../../../../common/components/Buttons/DefaultButton/default-button';

interface IButtonsProps {
  onSaveQuiz: () => void;
  onCloseClick: () => void;
}

const Buttons = (props: IButtonsProps) => {
  const classes = getClassNames();

  return (
    <div className={classes.buttonsContainer}>
      <DefaultButton type='button' onClick={props.onCloseClick}>Cancel</DefaultButton>
      <PrimaryButton type='button' onClick={props.onSaveQuiz}>Save</PrimaryButton>
    </div>
  )
}

export default Buttons;