import { getClassNames } from "./learnings.classNames"

export default function LearningsIcon() {
  const classes = getClassNames();

  return (
    <svg className={classes.icon} width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0.75C0 0.335786 0.335786 0 0.75 0H19.25C19.6642 0 20 0.335786 20 0.75C20 1.16421 19.6642 1.5 19.25 1.5H0.75C0.335786 1.5 0 1.16421 0 0.75ZM0 14.75C0 14.3358 0.335786 14 0.75 14H17.25C17.6642 14 18 14.3358 18 14.75C18 15.1642 17.6642 15.5 17.25 15.5H0.75C0.335786 15.5 0 15.1642 0 14.75ZM0.75 7C0.335786 7 0 7.33579 0 7.75C0 8.16421 0.335786 8.5 0.75 8.5H21.25C21.6642 8.5 22 8.16421 22 7.75C22 7.33579 21.6642 7 21.25 7H0.75Z" fill="#242424"/>
    </svg>
  )
}