// import react
import { Dispatch, ChangeEvent } from 'react';

// import styles
import { getClassNames } from './quiz-options.classNames';
import { Toggle } from '@fluentui/react';
import { ActionTypes, IAction, IQuizForm } from '../../hooks/useQuizForm';

interface IQuizOptionsProps {
  quizForm: IQuizForm;
  dispatch: Dispatch<IAction>;
}

const QuizOptions = (props: IQuizOptionsProps) => {
  const classes = getClassNames();

  return (
    <div className={classes.quizOptionsContainer}>
      <div className={classes.percentageContainer}>
        <p>Passing score</p>
        <div className={classes.groupPercentage}>
          <input
            id='passingPercentage'
            className={classes.percentageInput}
            name='passingPercentage'
            width={50}
            value={props.quizForm.passingPercentage}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              if (e.target.value === '' || !isNaN(+e.target.value)) {
                let value;
                if (+e.target.value > 100) value = 100;
                else value = +e.target.value;
                props.dispatch({ type: ActionTypes.UPDATE_INPUT, payload: { name: e.target.name, value } });
              }
            }}
          />
          <p>%</p>
        </div>
      </div>
      <div className={classes.attemptsContainer}>
        <p>Multiple attempts</p>
        <div className={classes.toggleContainer}>
          <Toggle styles={{root: { margin: 0 }}}
            checked={props.quizForm.isFinalQuizRepeatable}
            onChange={(e: React.MouseEvent<HTMLElement>, checked?: boolean) => 
              props.dispatch({ type: ActionTypes.UPDATE_REPEATABLE, payload: { checked: checked || false } })} />
        </div>
      </div>
    </div>
  );
};

export default QuizOptions;