import { mergeStyleSets } from '@fluentui/react';
import { getTheme } from '@fluentui/react';
import { ILoadingProps } from './Loading';
import { BorderRadius, IFadeClassNames, FADE_SCALE } from "../../../utils/constants/styles";

interface IModalClassNames extends IFadeClassNames {
  'loading-background': string;
  'spinner-container': string;
}

export const getClassNames = (props: ILoadingProps): IModalClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    'loading-background': {
      ...(!props.noBackground && {
        zIndex: 100,
        position: props.position ? props.position : 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      })
    },

    'spinner-container': {
      width: 200,
      height: 200,
      padding: 20,
      background: theme.palette.white,
      borderRadius: BorderRadius,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },

    ...FADE_SCALE.classNames,
  })
}