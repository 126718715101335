import { mergeStyleSets } from '@fluentui/react';
import { IListItemProps } from './list-item';
import { Status } from '../../../../../../utils/constants/status';

interface IListItemClassNames{
  status: string;
  statusContainer: string;
  statusSpanLabel: string;
  profilePic: string;
  generatingContainer: string;
  generationProgress: string;
}
export const getClassNames = (props: IListItemProps): IListItemClassNames => {
    return mergeStyleSets({
      status: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: 5,
      },
      statusContainer: {
        position: 'relative',
        width: 'fit-content',
        padding: '4px 10px',
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        marginTop: 2,
        // borderRadius: '100px 0 0 100px', 

        '::before': {
          content: "''",
          background: Status[props.statusInfo]?.backgroundColor,
          opacity: 0.2,
          position: "absolute",
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          borderRadius: 100, 
        },

        '::after': {
          content: "''",
          border: `1px solid ${Status[props.statusInfo]?.backgroundColor}`,
          position: "absolute",
          top: 0,
          left: 0,
          width: 'calc(100% - 1px)',
          height: 'calc(100% - 1px)',
          borderRadius: 100, 
        },

        '> svg': {
          marginTop: 1
        }
      },

      statusSpanLabel: {
        color: Status[props.statusInfo]?.color,
      },

      profilePic: {
        width: 32,
        height: 32,
        borderRadius: '50%'
      },

      generatingContainer: {
        overflow: 'hidden',
        position: 'relative',
        border: `1px solid ${Status[props.statusInfo]?.backgroundColor}`,
        backgroundColor: '#c2daeb',
        width: 'fit-content',
        height: 'fit-content',
        padding: '4px 10px',
        borderRadius: 100,
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        marginTop: 2,
      },

      generationProgress: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: `${props.generationProgress}%`,
        height: '100%',
        background: Status[props.statusInfo]?.backgroundColor,
        opacity: 0.2,
      }
    });
};