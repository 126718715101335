import { PropsWithChildren, useRef } from "react";
import { getClassNames } from "./modal.classNames";
import { Icon } from "@fluentui/react";
import { CSSTransition } from "react-transition-group";
import { ANIMATION_DURATION } from "../../../utils/constants/styles";
import DefaultButton from "../Buttons/DefaultButton/default-button";
import PrimaryButton from "../Buttons/PrimaryButton/primary-button";

export interface IModalProps {
  isOpen: boolean;
  width: number;
  height?: number;
  title: string;
  onSave: () => Promise<void>;
  onDismiss: () => void;
  gap?: number;
  saveButtonEnabled?: boolean;
}

const Modal = (props: PropsWithChildren<IModalProps>) => {
  const classes = getClassNames(props);
  const backgroundRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  return ( 
      <CSSTransition
        nodeRef={backgroundRef}
        timeout={ANIMATION_DURATION}
        in={props.isOpen}
        classNames={{
          enter: classes.fadeEnter,
          enterActive: classes.fadeEnterActive,
          exit: classes.fadeExit,
          exitActive: classes.fadeExitActive
        }}
        unmountOnExit
      >
        <div ref={backgroundRef} className={classes["modal-background"]}>
          <CSSTransition
            nodeRef={modalRef}
            timeout={ANIMATION_DURATION}
            in={props.isOpen}
            classNames={{
              appear: classes.fadeScaleEnter,
              appearActive: classes.fadeScaleEnterActive,
              exit: classes.fadeScaleExit,
              exitActive: classes.fadeScaleExitActive
            }}
            appear
            unmountOnExit
          >
            <div ref={modalRef} className={classes['modal']}>
              <div className={classes.modalContent}>
                <div className={classes['header']}>
                  <h1>{props.title}</h1>
                  <Icon className={classes['close-icon']} iconName="ChromeClose" onClick={props.onDismiss} />
                </div>
                {props.children}
                <div className={classes.buttons}>
                  <DefaultButton type="button" onClick={props.onDismiss}>Cancel</DefaultButton>
                  <PrimaryButton
                    type="button"
                    onClick={async () => {
                      try {
                        await props.onSave();
                        props.onDismiss();
                      } catch(err) {
                        throw err;
                      }
                    }}
                    disabled={props.saveButtonEnabled === undefined ? false : !props.saveButtonEnabled}
                  >Save</PrimaryButton>
                </div>
              </div>
            </div>
          </CSSTransition>
        </div>
      </CSSTransition>
  )
}

export default Modal;