import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../store/store";
import saveAs from 'file-saver';
import { IScormDetailsState } from "../components/ScormDetails/hooks/useScormDetails";
import { AdvancedExportScormSettingsDto } from "../../../../../../../api-client";

export default function useScorm() {
  const courseId = useSelector((state: RootState) => state.courses.course?.id);
  const lmsClientApi = useSelector((state: RootState) => state.clients.clients.lmsClientApi);
  // const settings = useSelector((state: RootState) => state.settings.settings);
  const supportedLanguages = useSelector((state: RootState) => state.courses.course?.supportedLanguages);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [scormError, setScormError] = useState<boolean>(false);

  async function exportScorm(scormData: IScormDetailsState) {
    const courseLanguages = supportedLanguages?.map(supportedLanguage => supportedLanguage.language || '');
    if (!courseId || !courseLanguages) return;

    try {
      setIsExporting(true);
      const body: AdvancedExportScormSettingsDto = { 
        includeAudio: scormData.includeAudio,
        specifiedLanguages: scormData.allLanguages ? courseLanguages : scormData.languages, 
      }
      // await timeout(3000);
      // console.log('body', body);
      const lmsResponse = await lmsClientApi?.exportScormAdvanced(courseId, scormData.scormVersion, body, {
          responseType: 'arraybuffer'
        });
      if (!lmsResponse) return;
  
      const blob = new Blob([(lmsResponse.data as unknown as ArrayBuffer)]);
      const filename = `${courseId}.zip`;
      saveAs(blob, filename);
    } catch(err) {
      console.error(err);
      setScormError(true);
    } finally {
      setIsExporting(false);
    }
  }

  function clearScormError() {
    setScormError(false);
  }

  return {
    exportScorm,
    isExporting,
    scormError,
    clearScormError,
  };
}