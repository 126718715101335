import { mergeStyleSets } from "@fluentui/react"
import { IFadeClassNames, FADE } from "../../../../../../utils/constants/styles";

interface IGenerateLessonModalClassNames extends IFadeClassNames {
  form: string;
  bodyContent: string;
}

export const getClassNames = (): IGenerateLessonModalClassNames => {
  return mergeStyleSets({
    form: {
      overflow: 'hidden',
      width: '100%',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      gap: 20,
      padding: '0 20px',
    },

    bodyContent: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 20,
      gap: 20,
    },

    ...FADE.classNames,
  });
};