import { Dispatch } from 'react';

// import styles
import { getClassNames } from "./Categories.classNames";
import { getTheme } from "@fluentui/react";

// import components
import PrimaryButton from "../../../../../../../common/components/Buttons/PrimaryButton/primary-button";

// import utils
import { IconNames } from "../../../../../../../common/models/Icons/icon-names";
import { ActionTypes, IAction } from '../../hooks/useModalSettings';
import { CourseCategoryDto } from '../../../../../../../api-client';
import Input from '../../../../../../../common/components/Inputs/Input/input';

interface IDomainsProps {
  categories: CourseCategoryDto[];
  dispatch: Dispatch<IAction>;
  enableSaveButton: () => void;
}

export default function Categories({ 
  categories,
  dispatch,
  enableSaveButton
 }: IDomainsProps) {
  const classes = getClassNames();
  const theme = getTheme();

  const languageCategories = 
    categories.filter((category) => !category.archived && category.categoryTranslations?.some(categoryTranslation => categoryTranslation.language === 'it-IT'))
    .map((category) => ({
      id: category.id,
      value: category.categoryTranslations?.find(categoryTranslation => categoryTranslation.language === 'it-IT')?.value, 
    }));
      

  return (
    <div className={classes.collectionContainer}>
      <p>Add or delete categories</p>
        <div className={classes.languagesContainer}>
          {languageCategories?.map((category, index) => (
            <div key={index} className={classes.languageContainer}>
              {/* <div className={classes.languageItem}>
                <div className={classes.language}>{category.value}</div>
              </div> */}
              <Input
                id={category.id || ''}
                name={category.id || ''}
                onChange={(e) => {
                  dispatch({ type: ActionTypes.UPDATE_CATEGORY, payload: { id: category.id, value: e.target.value } });
                  enableSaveButton();
                }}
                value={category.value || ''}
                title=''
                fontSize={16}
                flex
              />
            <PrimaryButton
              type="button"
              onClick={() => {
                dispatch({ type: ActionTypes.DELETE_CATEGORY, payload: category.id });
                enableSaveButton();
              }}
              iconName={IconNames.DELETE}
              color={theme.palette.themePrimary}
              background={theme.palette.themeLighter}
              width={90} 
              padding={10}
              gap={5}
            >
              Delete
            </PrimaryButton>
            </div>
          ))}
        </div>
    </div>
  )
}