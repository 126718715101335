import { mergeStyleSets } from "@fluentui/react";

interface ITextLessonClassNames {
  textLessonContainer: string;
  textLessonScrollContainer: string;
}

export const getClassNames = (): ITextLessonClassNames => {
  return mergeStyleSets({
    textLessonContainer: {
      overflow: 'hidden',
      flex: 1,
      paddingBottom: 10,
      margin: '0px 20px',
    },

    textLessonScrollContainer: {
      overflow: 'auto',
      width: '100%',
      height: '100%',
      paddingRight: 10,
    }
  });
}