// import react
import { useState } from "react";

const useLearningsPage = () => {
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(true);
  const [selectedMenuItem, setSelectedMenuItems] = useState<number>(0);
  const [search, setSearch] = useState<string>('');

  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [courseIdToDelete, setCourseIdToDelete] = useState<string>('');

  const onMenuClick = () => setIsMenuOpened(!isMenuOpened);
  const onMenuItemClick = (index: number) => setSelectedMenuItems(index);

  const updateSearch = (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.currentTarget.value);

  const onShowDeleteDialog = (courseIdToDelete: string) => {
    setCourseIdToDelete(courseIdToDelete);
    setShowDeleteDialog(true);
  };
  const onCloseDeleteDialog = () => {
    setCourseIdToDelete('');
    setShowDeleteDialog(false);
  }

  return { 
    isMenuOpened,
    selectedMenuItem,
    onMenuClick,
    onMenuItemClick,
    search,
    updateSearch,
    deleteDialogActions: {
      showDeleteDialog,
      onShowDeleteDialog,
      onCloseDeleteDialog,
      courseIdToDelete
    }
  };
}

export default useLearningsPage;