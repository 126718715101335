import { CreateFormKeys, ICreateFromScratchForm, CreateFromScratchFormKeys } from "../../../common/models/Create/form-data";

const ErrorMessages = {
  [CreateFromScratchFormKeys.TITLE]: 'Please insert the title',
  [CreateFromScratchFormKeys.DESCRIPTION]: 'Please insert the description',
  [CreateFromScratchFormKeys.CATEGORY]: 'Please select the category',
  [CreateFromScratchFormKeys.DOCUMENT_LANGUAGE]: 'Please select the document language',
  [CreateFromScratchFormKeys.HAS_AUDIO]: '',
}

export class FromScratchValidation {
  private _formData: ICreateFromScratchForm;

  constructor(formData: ICreateFromScratchForm) {
    this._formData = formData;
  };

  public validate() {
    try {
      this._isNotEmpty();
    } catch (err) {
      throw err;
    }
  }

  private _isNotEmpty(): void {
    Object.keys(this._formData).forEach((key: string) => {
      if (key !== CreateFormKeys.GENERATE_AUDIO) {
        if (!this._formData[key as CreateFromScratchFormKeys]) {
          throw new Error(ErrorMessages[key as CreateFromScratchFormKeys]);
        };
      };
    });
  };
}
