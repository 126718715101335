import { mergeStyleSets, getTheme } from "@fluentui/react";
import { BorderRadius } from "../../../../../../../../utils/constants/styles";

interface IAddScheduleClassNames {
  addSchedule: string;
  title: string;
  addScheduleFormContainer: string;
  groupInputPeople: string;
  groupInput: string;
  titleLabel: string;
  frequencyContainer: string;
  frequencyInput: string;
  daysLabel: string;
  buttons: string;
}

export const getClassNames = (): IAddScheduleClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    addSchedule: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      gap: 20,
      paddingLeft: 1,
      borderBottom: `2px solid ${theme.palette.neutralLighter}`,
    },

    title: {
      fontWeight: 700,
      alignSelf: 'flex-start',
    },

    addScheduleFormContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 20,
    },

    groupInputPeople: {
      flex: 2,
      display: 'flex',
      flexDirection: 'column',
      gap: 5,
    },

    groupInput: {
      flex: 2,
      display: 'flex',
      flexDirection: 'column',
      gap: 5
    },

    titleLabel: {
      color: theme.palette.neutralSecondary,
      fontWeight: theme.fonts.medium.fontWeight
    },

    frequencyInput: {
      width: '100%',
      height: 30,
      border: 'none',
      background: theme.palette.neutralLighterAlt,
      borderRadius: BorderRadius,
      fontSize: theme.fonts.medium.fontSize,
      padding: '5px 10px',
      outline: 'none'
    },

    frequencyContainer: {
      position: 'relative',
      height: '100%',
      flex: 1,
    },

    daysLabel: {
      position: 'absolute',
      right: 10,
      bottom: 7.5,
      fontSize: theme.fonts.medium.fontSize,
      color: theme.palette.neutralTertiary
    },

    buttons: {
      display:'flex',
      alignItems: 'center',
      gap: 10,
    }
  });
}