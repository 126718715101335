import { mergeStyleSets } from "@fluentui/react";

interface IScormDetailsClassNames {
  form: string;
  formSection: string;
  languages: string;
}

export const getClassNames = (): IScormDetailsClassNames => {
  return mergeStyleSets({
    form: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: 40,
    },

    formSection: {
      width: 'fit-content',
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
    },

    languages: {
      display: 'flex',
      alignItems: 'center',
      gap: 20,
    }
  });
};