// import react
import { useReducer } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store/store';

export interface IOptionsForm {
  title: string;
  description: string;
}

export enum ActionTypes {
  UPDATE_INPUT = 'UPDATE_INPUT',
}

interface IInputPayload {
  name: string;
  value: string | string[] | number | boolean | undefined;
}

export interface IAction {
  type: ActionTypes;
  payload: IInputPayload;
}

function reducer(state: IOptionsForm, action: IAction): IOptionsForm {
  switch (action.type) {
    case (ActionTypes.UPDATE_INPUT): return {
      ...state,
      [(action.payload as IInputPayload).name]: (action.payload as IInputPayload).value,
    };

    default: return state;
  } 
}

const useOptionsForm = () => {
  const title = useSelector((state: RootState) => state.courses.course?.title);
  const description = useSelector((state: RootState) => state.courses.course?.description);

  const initialState: IOptionsForm = {
    title: title || '',
    description: description || '',
  };

  const [optionsForm, optionsFormDispatch] = useReducer(reducer, initialState);

  const isOptionsChanged = (): boolean => {
    return optionsForm.title !== initialState.title || optionsForm.description !== initialState.description;
  };

  return {
    optionsForm,
    optionsFormDispatch,
    isOptionsChanged
  }
};

export default useOptionsForm;