// import styles
import { getTheme } from "@fluentui/react";
import { BorderRadius } from "../../../../../../../../../../../utils/constants/styles";

// import models
import { IDatePickerStyleProps, IDatePickerStyles, IStyleFunctionOrObject } from "@fluentui/react";

// import components
import { DatePicker as FluentUIDatePicker } from '@fluentui/react';
import { DateTime } from "luxon";
import { ActionTypes, IAction } from "../../../../../hooks/useForm";
import { Dispatch } from "react";

export interface IDatePickerProps {
  value: DateTime | undefined;
  dispatch: Dispatch<IAction>;
  // setStartDate: (date: Date | null | undefined) => void;
};

const DatePicker = (props: IDatePickerProps) => {
  const styles: IStyleFunctionOrObject<IDatePickerStyleProps, IDatePickerStyles> = {
    root: {
      background: getTheme().palette.neutralLighterAlt,
      borderRadius: BorderRadius,
      height: 36,

      '*': {
        height: 36,
        borderRadius: BorderRadius,
      }
    },

    textField: {
      background: getTheme().palette.neutralLighterAlt,
      borderRadius: BorderRadius,
    },

    readOnlyTextField: {
      background: getTheme().palette.neutralLighterAlt,
      borderRadius: BorderRadius
    },

    wrapper: {
      background: getTheme().palette.neutralLighterAlt,
      borderRadius: BorderRadius,

      'div[class^="ms-TextField-field field-"]': {
        display: 'flex',
        alignItems: 'center'
      },

      '.ms-TextField-fieldGroup': {
        border: 'none',
        borderRadius: BorderRadius,

        '*': {
          background: getTheme().palette.neutralLighterAlt,
          borderRadius: BorderRadius,
        }
      },

      'div[class^="ms-TextField-fieldGroup"]::after': {
        borderRadius: BorderRadius,
      },
    },
  }

  return (
    <FluentUIDatePicker
      styles={styles}
      onSelectDate={(date: Date | null | undefined) => props.dispatch({ type: ActionTypes.SET_START_DATE, payload: { date } })}
      value={props.value?.toJSDate()} />
  )
};

export default DatePicker;