import { PropsWithChildren } from 'react'
import { BaseButton, Button } from '@fluentui/react';
import { getClassNames } from './primary-button.classNames';
import { PrimaryButton as FluentUIPrimaryButton, IButtonStyles, getTheme } from "@fluentui/react";
import { Icons } from '../../../../utils/constants/icons';
import { IconNames } from '../../../models/Icons/icon-names';

export interface IPrimaryButtonProps {
  width?: number;
  height?: number;
  onClick?: (e: React.MouseEvent<HTMLDivElement | HTMLAnchorElement | HTMLButtonElement | BaseButton | Button | HTMLSpanElement> | undefined) => void;
  type?: string;
  disabled?: boolean;
  disabledBackgroundColor?: string;
  iconName?: IconNames;
  background?: string;
  color?: string;
  padding?: number;
  gap?: number;
}

const PrimaryButton = (props: PropsWithChildren<IPrimaryButtonProps>) => {
  const classes = getClassNames(props);

  const theme = getTheme();
  const buttonStyles: IButtonStyles = {
    root: {
      backgroundColor: props.background && props.background,
      color: props.color && props.color,
      borderColor: props.background && props.background,
    },
    rootDisabled: {
      background: props.disabledBackgroundColor,
      color: theme.palette.neutralTertiary,
    },
    flexContainer: {
      justifyContent: 'space-evenly',
      gap: props.gap || 10
    }
  };

  const Icon = props.iconName && Icons[props.iconName];
  
  return (
    <FluentUIPrimaryButton className={classes['button']} styles={buttonStyles} onClick={props.onClick} type={props.type} disabled={props.disabled}>
      {Icon && <Icon color={props.disabled ? theme.palette.neutralTertiary : props.color} />}
      {props.children}
    </FluentUIPrimaryButton>
  )
}

export default PrimaryButton;