import { useEffect, useReducer } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store/store";
import { CourseCategoryDto } from "../../../../../../api-client";
import { v4 as uuidv4 } from 'uuid';

export interface ICourseSettingsForm {
  categories: CourseCategoryDto[] | undefined;
  categoryToAdd: string;
}

export enum ActionTypes {
  UPDATE_CATEGORIES = 'UPDATE_CATEGORIES',
  UPDATE_INPUT = 'UPDATE_INPUT',
  UPDATE_CATEGORY = 'UPDATE_CATEGORY',
  ADD_CATEGORY = 'ADD_CATEGORY',
  DELETE_CATEGORY = 'DELETE_CATEGORY',
}

interface ICategoryPayload {
  categoryToAdd: string;
}

export interface IAction {
  type: ActionTypes;
  payload?: ICategoryPayload | CourseCategoryDto[] | CourseCategoryDto | { id: string, value: string } | string | number;
}

function reducer(state: ICourseSettingsForm, action: IAction): ICourseSettingsForm {
  switch (action.type) {
    case (ActionTypes.UPDATE_CATEGORIES): 
      return { 
        ...state,
        categories: action.payload as CourseCategoryDto[],
      }

    case (ActionTypes.UPDATE_CATEGORY): {
      if (!state.categories) return state;

      const newCategories = [...state.categories];
      const categoryIndex = newCategories.findIndex((category) => category.id === ((action.payload as { id: string, value: string }).id));
      // const categoryToUpdate = {...newCategories[categoryIndex], categoryTranslations: [...newCategories[categoryIndex]]};
      const categoryToUpdateTranslations = [...newCategories[categoryIndex].categoryTranslations || []];
      const categoryToUpdateTranslation = { ...categoryToUpdateTranslations.find((categoryTranslation) => categoryTranslation.language === 'it-IT'), value: (action.payload as { id: string, value: string }).value };
      newCategories[categoryIndex] = { ...newCategories[categoryIndex], categoryTranslations: categoryToUpdateTranslations.map((categoryTranslation) => categoryTranslation.language === 'it-IT' ? categoryToUpdateTranslation : categoryTranslation) };

      return { 
        ...state,
        categories: newCategories
      }
    }

    case (ActionTypes.UPDATE_INPUT): 
      return { 
        ...state,
        categoryToAdd: action.payload as string,
      }

    case (ActionTypes.ADD_CATEGORY): {
      if (!state.categories) return state;

      const newCategories = [...state.categories];
      newCategories.push({
        id: uuidv4(),
        archived: false,
        categoryTranslations: [
          {
            language: 'it-IT',
            value: state.categoryToAdd,
          }
        ]
      });
      return { 
        ...state,
        categories: newCategories,
        categoryToAdd: '',
      }
    };

    case (ActionTypes.DELETE_CATEGORY): {
      if (!state.categories) return state;

      const newCategories = [...state.categories];
      const categoryIndex = newCategories.findIndex((category) => category.id === (action.payload as string));
      const categoryToDelete = {...newCategories[categoryIndex], archived: true};
      newCategories[categoryIndex] = categoryToDelete;

      return {
        ...state,
        categories: newCategories,
      }
    };

    default: return state;
  } 
}

export default function useModalSettings() {
  const categories = useSelector((state: RootState) => state.settings.categories);
  const initialState: ICourseSettingsForm = {
    categories: undefined,
    categoryToAdd: '',
  };

  const [courseSettingsForm, courseSettingsFormDispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (categories) 
      courseSettingsFormDispatch({ type: ActionTypes.UPDATE_CATEGORIES, payload: categories.filter((category) => category.archived === false)})
  }, [categories]);

  return {
    courseSettingsForm,
    courseSettingsFormDispatch,
  }
} 