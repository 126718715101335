// import styles
import { getClassNames } from './buttons.classNames';

// import components
import PrimaryButton from '../../../../../../../common/components/Buttons/PrimaryButton/primary-button';
import DefaultButton from '../../../../../../../common/components/Buttons/DefaultButton/default-button';

interface IButtonsProps {
  onCancelClick: () => void;
}

const Buttons = (props: IButtonsProps) => {
  const classes = getClassNames();

  return (
    <div className={classes.buttonsContainer}>
      <DefaultButton type='button' onClick={props.onCancelClick}>Cancel</DefaultButton>
      <PrimaryButton type='submit'>Generate</PrimaryButton>
    </div>
  )
}

export default Buttons;