import { useEffect, useRef, useState } from "react";
import Buttons from "./Buttons/Buttons";
import { getClassNames } from "./DeleteDialog.classNames";
import { CSSTransition } from 'react-transition-group';
import { ANIMATION_DURATION } from "../../../../../utils/constants/styles";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import Details from "./Details/Details";

interface IDeleteDialogProps {
  nodeRef: React.RefObject<HTMLDivElement>;
  animation: boolean;
  // type: 'Learning' | 'Lesson' | 'LearningError' | undefined;
  onCancelDeleteLearning: () => void;
  courseIdToDelete: string;
}

const DeleteDialog = (props: IDeleteDialogProps) => {
  const { courses } = useSelector((state: RootState) => state.courses);
  const course = courses?.find(el => el.id === props.courseIdToDelete);
  const [animation, setAnimation] = useState<boolean>(false);
  const classes = getClassNames();

  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setAnimation(props.animation);
  }, [props.animation]);

  return (
    <div ref={props.nodeRef} className={classes.dialogBackground}>
      <CSSTransition
        nodeRef={modalRef}
        timeout={ANIMATION_DURATION}
        in={animation}
        classNames={{
          enter: classes.enter,
          enterActive: classes.enterActive,
          exit: classes.exit,
          exitActive: classes.exitActive
        }}
        mountOnEnter
        unmountOnExit
      >
          <div className={classes.dialog}>
            <span>There was an error generating the course. Please generate the course again. Do you want to delete the course?</span>
            <Details errorDetails={course?.errors[course.errors.length - 1].description || ''} />
          <Buttons onCancelClick={props.onCancelDeleteLearning} courseIdToDelete={props.courseIdToDelete} />
        </div>
      </CSSTransition>
    </div>
  )
};

export default DeleteDialog;