import { getTheme } from "@fluentui/react";

export default function Play() {
  const theme = getTheme();

  return (
    <svg width="100%" height="100%" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.75013 0.413804L29.1672 13.1186C30.7595 13.9471 31.3786 15.9095 30.5501 17.5018C30.2421 18.0939 29.7592 18.5767 29.1672 18.8848L4.75013 31.5896C3.15786 32.4181 1.19543 31.7989 0.366929 30.2066C0.125863 29.7433 0 29.2288 0 28.7065V3.29688C0 1.50195 1.45507 0.046875 3.25 0.046875C3.69765 0.046875 4.13966 0.139346 4.54857 0.317575L4.75013 0.413804ZM3.59618 2.63155L3.4857 2.58487C3.40991 2.55978 3.33035 2.54688 3.25 2.54688C2.83579 2.54688 2.5 2.88266 2.5 3.29688V28.7065C2.5 28.827 2.52905 28.9458 2.58468 29.0527C2.77587 29.4201 3.22874 29.563 3.59618 29.3718L28.0132 16.667C28.1499 16.5959 28.2613 16.4845 28.3324 16.3479C28.5236 15.9804 28.3807 15.5276 28.0132 15.3364L3.59618 2.63155Z" fill={theme.palette.neutralLight}/>
    </svg>
  )
}