import { Dispatch, ChangeEvent } from 'react';
import { getClassNames } from "./FlashCardLesson.classNames";
import { FlashCardLessonDto } from "../../../../../../../../../api-client";
import useTextAreaRef from './hooks/useTextAreaRef';
import { ActionTypes, IAction, IFormData } from '../../../hooks/useForm';

interface IFlashCardLessonProps {
  formData: IFormData;
  dispatch: Dispatch<IAction>;
  disabled: boolean;
}

const FlashCardLesson = (props: IFlashCardLessonProps) => {
  const classes = getClassNames(props.disabled);
  const textAreaRefs = useTextAreaRef();

  const lessonData = props.formData.lessonData as FlashCardLessonDto;

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;

    props.dispatch({ type: ActionTypes.UPDATE_INPUT, payload: { name: e.target.name, value: e.target.value } });
  };

  return (
    <>
     <div className={classes.groupInput}>
        <h5 className={classes.label}>Title</h5>
        <textarea 
          className={classes.input} 
          id='title' 
          name='title'
          rows={1}
          value={lessonData.title || ''}
          onChange={onChange}
          ref={textAreaRefs.title}
          disabled={props.disabled}
        />
      </div>
      
      <div className={classes.groupInput}>
        <h5 className={classes.label}>Question</h5>
        <textarea 
          className={classes.input}
          id='question' 
          name='question'
          value={lessonData.question || ''}
          onChange={onChange}
          rows={1}
          ref={textAreaRefs.question}
          disabled={props.disabled}
        />
      </div>

      <div className={classes.groupInput}>
        <h5 className={classes.label}>Answer</h5>
        <textarea 
          className={classes.input}
          id='answer' 
          name='answer'
          value={lessonData.answer || ''}
          onChange={onChange}
          rows={1}
          ref={textAreaRefs.answer}
          disabled={props.disabled}
        />
      </div>

      <div className={classes.groupInput}>
        <h5 className={classes.label}>Explanation</h5>
        <textarea 
          className={classes.input}
          id='explanation' 
          name='explanation'
          value={lessonData.explanation || ''}
          onChange={onChange}
          rows={1}
          ref={textAreaRefs.explanation}
          disabled={props.disabled}
        />
      </div>
    </>
  )
};

export default FlashCardLesson;