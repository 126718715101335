import { IDropdownOption } from "@fluentui/react";
import Modal from "../../../../../../common/components/Modal/modal";
import { getClassNames } from "./generate-lesson-modal.classNames";
import useAddLesson from "./hooks/useAddLesson";
import { useMemo, useRef } from "react";
import Dropdown from "../../../../../../common/components/Dropdown/dropdown";
import { DropdownThemes } from "../../../../../../utils/constants/dropdown-themes";
import { lessonTypesOptions } from "../../../../../../utils/constants/lesson-types";
import { AddLessonSettingsDto, FileTypeDto } from "../../../../../../api-client";
import Pdf from "./components/Pdf/pdf";
import Docx from "./components/Docx/docx";
import Buttons from "./components/Buttons/buttons";
import { CSSTransition } from "react-transition-group";
import { ANIMATION_DURATION } from "../../../../../../utils/constants/styles";
import Loading from "../../../../../../common/components/Loading/Loading";

interface IGenerateLessonModalProps {
  onCloseGenerate: () => void;
  nodeRef: React.RefObject<HTMLDivElement>;
  animation: boolean;
  addLessonSettings?: AddLessonSettingsDto;
}

export default function GenerateLessonModal(props: IGenerateLessonModalProps) {
  const classes = getClassNames();

  const {
    lessonType,
    chapter,
    pages,
    changeChapter,
    changeLessonType,
    changePages,
    onGenerateClick,
    isLoading,
    loadingMessage
  } = useAddLesson();

  const loadingRef = useRef<HTMLDivElement>(null);

  const chapters: IDropdownOption[] = useMemo(() => 
    (props.addLessonSettings?.chapters && 
      props.addLessonSettings.chapters.map(e => ({ key: e, text: e }))) || [], [props.addLessonSettings]);

  if (!props.addLessonSettings) return null;

  const disabled = props.addLessonSettings.fileType === FileTypeDto.Pdf ? !pages : !chapter;

  return (
    <Modal
      nodeRef={props.nodeRef}
      animation={props.animation}
      width={450}
      height={360}
      title="Generate lesson"
      onModalClose={props.onCloseGenerate}
    >
    
      <form className={classes.form}>
        <div className={classes.bodyContent}>
          <Dropdown
            onChange={changeLessonType}
            options={lessonTypesOptions}
            theme={DropdownThemes.LIGHT}
            height={40}
            caretWidth="12%"
            title="Select lesson type"
            isGroup={true}
            selectedKey={lessonType}
          />

          {props.addLessonSettings.fileType === FileTypeDto.Pdf && (
            <Pdf 
              pages={pages}
              changePages={changePages}
            />
          )}

          {props.addLessonSettings.fileType === FileTypeDto.Docx && (
            <Docx
              chapters={chapters}
              chapter={chapter}
              changeChapter={changeChapter}
            />
          )}
        </div>

        <Buttons
          onCancelClick={props.onCloseGenerate}
          onSaveClick={onGenerateClick}
          disabled={disabled}
        />

        <CSSTransition
          nodeRef={loadingRef}
          timeout={ANIMATION_DURATION}
          in={isLoading}
          classNames={{
            enter: classes.enter,
            enterActive: classes.enterActive,
            exit: classes.exit,
            exitActive: classes.exitActive
          }}
          unmountOnExit
        >
          <Loading
            nodeRef={loadingRef}
            animation={isLoading}
            label={loadingMessage}  
          />
        </CSSTransition>

      </form>
    </Modal>
  );
}