// import react
import { useEffect, useCallback, useState, useRef, ChangeEvent } from "react";

// import models
import { IAttachment } from "../../../../../../../common/models/Learnings/attachment";
import { AxiosError } from "axios";

// import utils
import { fileToBase64, isFileTypeCorrect } from "../../../../../../../utils/helpers/helpers";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../../../../store/store";
import { useParams } from "react-router-dom";
import { editAttachment } from "../../../../../../../store/slices/courses";

const useAttachment = () => {
  const courseId = useSelector((state: RootState) => state.courses.course?.id);
  const lessonId = useSelector((state: RootState) => state.courses.lessonToEdit?.id);
  const documentLanguage = useSelector((state: RootState) => state.courses.course?.documentLanguage);
  const dispatch = useAppDispatch();

  const { lang: currentLanguage } = useParams();

  const { coursesClientApi } = useSelector((state: RootState) => state.clients.clients);

  const [attachment, setAttachment] = useState<IAttachment | null>(null);
  const [uploadAttachment, setUploadAttachment] = useState<boolean>(false);
  const [clearAttachment, setClearAttachment] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [file, setFile] = useState<File | null>();
  const inputFileRef = useRef<HTMLInputElement>(null);

  const loadAttachment = useCallback(async () => {
    if (!courseId || !documentLanguage || !lessonId || !coursesClientApi) return;
    setIsLoading(true);
    try {
      const attachment = await coursesClientApi.getLessonAttachment(courseId, currentLanguage || documentLanguage, lessonId);
      if (attachment.data.contentType === 'embedding') {
        if (!attachment.data.data || !attachment.data.contentType) return;
        setAttachment({data: attachment.data.data, src: ``, contentType: attachment.data.contentType});
      } else  {
        if (!attachment.data.data || !attachment.data.contentType) return;
        setAttachment({data: attachment.data.data, src: `data:${attachment.data.contentType};base64,${attachment.data.data}`, contentType: attachment.data.contentType});
      }
      setIsLoading(false);
    } catch (err) {
      const axiosError = err as AxiosError;
      setIsLoading(false);
      console.error(axiosError.response?.status);
    }
  }, [courseId, documentLanguage, currentLanguage, lessonId, coursesClientApi]);

  const onChooseFileClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (isFileTypeCorrect(e.target.files[0])) {
        const chosenFile = await fileToBase64(e.target.files[0]);
        if (chosenFile) {
          const blob = new Blob([e.target.files[0]]);
          const blobUrl = URL.createObjectURL(blob);
          setAttachment({ data: chosenFile.slice(chosenFile.indexOf(',') + 1), src: blobUrl, contentType: e.target.files[0].type });
          setUploadAttachment(true);
          if (clearAttachment) setClearAttachment(false);
        }
      }
    }
  };

  const onAttachmentClear = () => {
    if (inputFileRef.current && inputFileRef.current.files) {
      inputFileRef.current.files = null;
      inputFileRef.current.value = '';
    }
    setAttachment(null);
    if (uploadAttachment) setUploadAttachment(false);
    setClearAttachment(true);
  };

  const saveAttachment = async (lessonId: string) => {
    try {
      if (!courseId || !documentLanguage || !lessonId || !coursesClientApi) return
      if (uploadAttachment) {
        await dispatch(editAttachment({lessonId, lang: currentLanguage || documentLanguage, attachment: attachment}));
        // await coursesClientApi.editLessonAttachment(courseId, lessonId, currentLanguage || documentLanguage, {data: attachment?.data || '', contentType: attachment?.contentType || ''});
      }
    } catch(err) {
      console.error(err);
    }
  };

  const deleteAttachment = async (lessonId: string) => {
    try {
      if (!courseId || !documentLanguage || !lessonId || !coursesClientApi) return
      if (clearAttachment) {
        await coursesClientApi.deleteLessonAttachment(courseId, lessonId, currentLanguage || documentLanguage);
      }
    } catch(err) {
      console.error(err);
    }
  };

  const onAddEmbedContent = (data: string) => {
    setAttachment({
      contentType: 'embedding',
      data,
      src: '',
    });
    setUploadAttachment(true);
    if (clearAttachment) setClearAttachment(false);
  } 

  useEffect(() => {
    loadAttachment();
  }, [loadAttachment]);

  return {
    attachment,
    uploadAttachment,
    isLoading,
    // file,
    inputFileRef,
    onChooseFileClick,
    onFileChange,
    onAttachmentClear,
    saveAttachment,
    onAddEmbedContent,
    clearAttachment,
    deleteAttachment
  }

};

export default useAttachment;