import { IIconProps } from "../../../../common/models/Icons/icon-props";

const Clone = (props: IIconProps): JSX.Element => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 1C5.44772 1 5 1.44772 5 2V2.5C5 2.77614 4.77614 3 4.5 3C4.22386 3 4 2.77614 4 2.5V2C4 0.895431 4.89543 0 6 0H6.5C6.77614 0 7 0.223858 7 0.5C7 0.776142 6.77614 1 6.5 1H6ZM5 10C5 10.5523 5.44772 11 6 11H6.5C6.77614 11 7 11.2239 7 11.5C7 11.7761 6.77614 12 6.5 12H6C4.89543 12 4 11.1046 4 10V9.5C4 9.22386 4.22386 9 4.5 9C4.77614 9 5 9.22386 5 9.5V10ZM5 4.5C5 4.22386 4.77614 4 4.5 4C4.22386 4 4 4.22386 4 4.5V7.5C4 7.77614 4.22386 8 4.5 8C4.77614 8 5 7.77614 5 7.5V4.5ZM14 1C14.5523 1 15 1.44772 15 2V2.5C15 2.77614 15.2239 3 15.5 3C15.7761 3 16 2.77614 16 2.5V2C16 0.895431 15.1046 0 14 0H13.5C13.2239 0 13 0.223858 13 0.5C13 0.776142 13.2239 1 13.5 1H14ZM14 11C14.5523 11 15 10.5523 15 10V9.5C15 9.22386 15.2239 9 15.5 9C15.7761 9 16 9.22386 16 9.5V10C16 11.1046 15.1046 12 14 12H13.5C13.2239 12 13 11.7761 13 11.5C13 11.2239 13.2239 11 13.5 11H14ZM15.5 4C15.2239 4 15 4.22386 15 4.5V7.5C15 7.77614 15.2239 8 15.5 8C15.7761 8 16 7.77614 16 7.5V4.5C16 4.22386 15.7761 4 15.5 4ZM8.5 0C8.22386 0 8 0.223858 8 0.5C8 0.776142 8.22386 1 8.5 1H11.5C11.7761 1 12 0.776142 12 0.5C12 0.223858 11.7761 0 11.5 0H8.5ZM8 11.5C8 11.2239 8.22386 11 8.5 11H11.5C11.7761 11 12 11.2239 12 11.5C12 11.7761 11.7761 12 11.5 12H8.5C8.22386 12 8 11.7761 8 11.5ZM2 4H3V5H2C1.44772 5 1 5.44772 1 6V12.5C1 13.8807 2.11929 15 3.5 15H10C10.5523 15 11 14.5523 11 14V13H12V14C12 15.1046 11.1046 16 10 16H3.5C1.567 16 0 14.433 0 12.5V6C0 4.89543 0.895431 4 2 4Z" fill="#242424"/>
    </svg>
  )
};

export default Clone;