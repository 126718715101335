import { ChangeEvent } from "react";
import Input from "../../../../../../../../common/components/Inputs/Input/input";

interface IPdfProps {
  pages: string;
  changePages: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export default function Pdf(props: IPdfProps) {
  return (
    <Input
      id="pages"
      name="pages"
      onChange={props.changePages}
      title="Enter the pages to be used to generate the lesson"
      value={props.pages || ""}
    />
  );
}