// import react
import { ChangeEvent, Dispatch, useRef } from 'react';

// import styles
import { getClassNames } from "./ChooseFile.classNames";

// import models
import { getFileType } from '../../../../../../../utils/helpers/helpers';
import { FileTypeDto } from '../../../../../../../api-client';
import { MIMETypes } from '../../../../../../../utils/constants/file-types';

// import components
import { Icon } from '@fluentui/react';
import { ActionTypes, IAction } from '../../UploadModal';


interface IChooseFileProps {
  dispatch: Dispatch<IAction>;
}

const ChooseFile = ({ dispatch }: IChooseFileProps) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const classes = getClassNames();

  const getIcon = () => {
    if (fileRef.current?.files && fileRef.current.files[0]) {
      const fileType = getFileType(fileRef.current.files[0]);
      if (fileType) return fileType === FileTypeDto.Docx ? <img className={classes['icon']} src={require(`../../../../../../../assets/Icons/Others/word.png`)} alt="" /> : <img className={classes['icon']} src={require(`../../../../../../../assets/Icons/Others/pdf.png`)} alt="" />;
    }
    return null;
  }

  const onChooseFileClick = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  const onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) dispatch({ type: ActionTypes.UPDATE_FILE, payload: e.target.files[0]});
  }

  const onFileClear = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (fileRef.current && fileRef.current.files) fileRef.current.value = '';
    dispatch({ type: ActionTypes.UPDATE_FILE, payload: null });
  }

  return (
    <div className={classes.chooseFileContainer} onClick={onChooseFileClick}>

      {fileRef.current?.files && fileRef.current?.files[0] ? (
        <div className={classes.fileContainer}>
          <div className={classes.fileInfoContainer}>
            {getIcon()}
            <span className={classes.fileName}>{fileRef.current.files[0].name}</span>
          </div>
          <Icon iconName='ChromeClose' className={classes['close']} onClick={onFileClear} />
        </div>
      ) : (
        <span className={classes.uploadLabel}>Upload a file</span>
      )}

      <input type="file" accept={`.docx,.pdf,${MIMETypes.DOCX},${MIMETypes.PDF}`} ref={fileRef} style={{display: 'none'}} onChange={onFileChange}/>
    </div>
  )
};

export default ChooseFile;