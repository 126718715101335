import { useRef, useEffect } from "react";
import { QuizResponseDto } from "../../../../../../../../../../api-client";

const useTextAreaRef = (formOptions?: QuizResponseDto[] | null | undefined) => {
  const title = useRef<HTMLTextAreaElement>(null);
  const question = useRef<HTMLTextAreaElement>(null);
  const options = useRef<HTMLTextAreaElement[]>([]);
  const newOption = useRef<HTMLTextAreaElement>(null);
  const correctAnswerExplanation = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (title.current && question.current) {
      title.current.style.height = `${title.current.scrollHeight}px`;
      question.current.style.height = `${question.current.scrollHeight}px`;
    }

    if (options.current) {
      options.current.forEach(option => {
        option.style.height = `auto`;
        option.style.height = `${option.scrollHeight}px`;
      })
    }

    if (newOption.current) {
      newOption.current.style.height = `auto`;
      newOption.current.style.height = `${newOption.current.scrollHeight}px`;
    }

    if (correctAnswerExplanation.current) {
      correctAnswerExplanation.current.style.height = `${correctAnswerExplanation.current.scrollHeight}px`;
    }

  }, [title, question, options, correctAnswerExplanation, formOptions]);

  return {
    title,
    question,
    options,
    newOption,
    correctAnswerExplanation
  }
};

export default useTextAreaRef;