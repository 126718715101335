import { CreateFormKeys, ICreateFromUploadForm } from "../../../common/models/Create/form-data";
import { MIMETypes } from "../../constants/file-types";

const ErrorMessages = {
  [CreateFormKeys.TITLE]: 'Please insert the title',
  [CreateFormKeys.DESCRIPTION]: 'Please insert the description',
  [CreateFormKeys.CATEGORY]: 'Please select the category',
  [CreateFormKeys.LESSONS_NUMBER]: 'Please select the number of lessons',
  [CreateFormKeys.DOCUMENT_LANGUAGE]: 'Please select the document language',
  [CreateFormKeys.TYPE_OF_LESSONS]: 'Please select at least on type of lesson',
  [CreateFormKeys.PAGES]: '',
  [CreateFormKeys.CHAPTER_HEADER_PREFIX]: '',
  [CreateFormKeys.GENERATE_AUDIO]: '',
}

export class UploadValidation {
  private _formData: ICreateFromUploadForm;

  constructor(formData: ICreateFromUploadForm) {
    this._formData = formData;
  };

  public validate() {
    try {
      this._isNotEmpty();
      this._isFileTypeCorrect();
      this._validateTypeOfLessons();
    } catch (err) {
      throw err;
    }
  }

  private _isNotEmpty() {
    if (!this._formData.file) throw new Error('Please choose a file');

    Object.keys(this._formData.createForm).forEach((key: string) => {
      if (key !== CreateFormKeys.PAGES && key !== CreateFormKeys.GENERATE_AUDIO && key !== CreateFormKeys.CHAPTER_HEADER_PREFIX) {
        if (!this._formData.createForm[key as CreateFormKeys]) {
          throw new Error(ErrorMessages[key as CreateFormKeys]);
        };
      };
    });
  };

  private _isFileTypeCorrect (): void {
    const fileMIME = this._formData.file?.type;
    if (!(fileMIME === MIMETypes.DOCX || fileMIME === MIMETypes.PDF))
      throw new Error('Please choose a Word or PDF file');
  };

  private _validateTypeOfLessons (): void {
    if (this._formData.createForm[CreateFormKeys.TYPE_OF_LESSONS].length === 0)
      throw new Error(ErrorMessages[CreateFormKeys.TYPE_OF_LESSONS]);
  };
}