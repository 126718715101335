import { getClassNames } from "./Settings.classNames";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { ApiStatus } from "../../store/slices/settings";
import Loading from "../../common/components/Loading/Loading";
import CreateCard from "./components/CreateCard/CreateCard";
import ScormModal from "./components/modals/ScormModal/ScormModal";
import ScormIcon from "../../assets/Icons/settings/Scorm/Scorm";
import CourseModal from "./components/modals/CourseModal/CourseModal";
import CourseSettingsIcon from "../../assets/Icons/settings/Course/Course";

const Settings = () => {
  const roles = useSelector((state: RootState) => state.account.roles);
  const [selectedModal, setSelectedModal] = useState<string>('');
  const apiStatus = useSelector((state: RootState) => state.settings.apiStatus);
  const classes = getClassNames();

  return (
    <section className={classes.settingsPage}>

      {apiStatus === ApiStatus.LOADING && <Loading label="Loading settings" />}

      {apiStatus === ApiStatus.SUCCESS && (
        <>

          {roles.includes('Admin') && <CreateCard 
            cardInfo={{
              Icon: () => <ScormIcon />,
              action: () => setSelectedModal('scorm'),
              buttonLabel: "SCORM settings",
              description: "Manage your scorm domains and settings.",
            }}
          />}

          <CreateCard 
            cardInfo={{
              Icon: () => <CourseSettingsIcon />,
              action: () => setSelectedModal('course'),
              buttonLabel: "Course settings",
              description: "Customize and manage your course categories and preferences.",
            }}
          />

          <ScormModal
            isOpen={selectedModal === 'scorm'}
            onDismiss={() => setSelectedModal('')}
          />
          <CourseModal
            isOpen={selectedModal === 'course'}
            onDismiss={() => setSelectedModal('')}
          />
        </>

      )}
    </section>
  )
};

export default Settings;