import { getClassNames } from './buttons.classNames';
import DefaultButton from '../../../../../../common/components/Buttons/DefaultButton/default-button';
import PrimaryButton from '../../../../../../common/components/Buttons/PrimaryButton/primary-button';

interface IButtonsProps {
  type: 'Close' | 'Generate';
  onClose: () => void;
  onCancel: () => void;
}

const Buttons = (props: IButtonsProps) => {
  const classes = getClassNames();

  return (
    <div className={classes.buttonsContainer}>
      <DefaultButton onClick={props.onCancel}>Cancel</DefaultButton>
      <PrimaryButton onClick={props.onClose}>{props.type === 'Close' ? 'Close' : 'Generate'}</PrimaryButton>
    </div>
  )
}

export default Buttons;