import { mergeStyleSets, getTheme } from "@fluentui/react";
import { BorderRadius } from "../../../../../../../../utils/constants/styles";

interface IQuizClassNames {
  quizLessonContainer: string;
  quizContainer: string;
  quizScrollContainer: string;
  answerList: string;
  answer: string;
  correctAnswer: string;
  letterContainer: string;
  letter: string;
  dropdownAnswerContainer: string;
  hr: string;
}

export const getClassNames = (): IQuizClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    quizLessonContainer: {
      overflow: 'hidden',
      flex: 1,
      paddingBottom: 10,
      margin: '0px 20px',
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
    },

    quizContainer: {
      overflow: 'hidden',
      flex: 1,
      paddingBottom: 10,
    },

    quizScrollContainer: {
      overflow: 'auto',
      width: '100%',
      height: '100%',
      paddingRight: 10,
    },

    answerList: {
      flex: '0 0 50%',
      listStyleType: 'none',
      listStylePosition: 'inside',
    },

    answer: {
      padding: 10,
      background: theme.palette.themeLighterAlt,
      borderRadius: BorderRadius,
      display: 'flex',
      alignItems: 'center',
      gap: 10,

      ':not(:last-child)': {
        marginBottom: 10,
      }
    },

    correctAnswer: {
      padding: 10,
      background: theme.palette.themeLight,
      borderRadius: BorderRadius,
      display: 'flex',
      alignItems: 'center',
      gap: 10,

      ':not(:last-child)': {
        marginBottom: 10,
      }
    },

    letterContainer: {
      flex: '0 0 24px',
      display: 'flex',
      borderRight: `2px solid ${theme.palette.themeTertiary}`
    },

    letter: {
      textTransform: 'uppercase',
    },

    dropdownAnswerContainer: {
      flex: '0 0 40px',
      display: 'flex',
      alignItems: 'center',
    },

    hr: {
      borderTop: `1px solid ${theme.palette.neutralLight}`
    }
  });
}