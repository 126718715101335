import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CourseCategoryDto, LmsStatusInfoDto, SettingsDto, TimeZoneDto } from "../../api-client";
import { RootState } from "../store";

export enum ApiStatus {
  SUCCESS = 'SUCCESS',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  IDLE = 'IDLE',
}

export interface ISettingsState {
  apiStatus: ApiStatus;
  settings: SettingsDto | undefined;
  appSettings: {
    scormSettings: LmsStatusInfoDto | undefined;
  };
  categories: CourseCategoryDto[] | undefined;
  timeZones: TimeZoneDto[];
  error: any;
}

export const setSettings = createAsyncThunk<{settings: SettingsDto | undefined, scormSettings: LmsStatusInfoDto | undefined, categories: CourseCategoryDto[] | undefined} | undefined, void>('/setSettings', async (_, thunkAPI) => {
  try {
    const { settingsClientApi, lmsClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!settingsClientApi || !lmsClientApi) return undefined;

    const promiseResponse = await Promise.allSettled([settingsClientApi.apiSettingsGet(), lmsClientApi.getLmsStatus(), settingsClientApi.getAllCategories()]);

    return {
      settings: promiseResponse[0].status === 'fulfilled' ? promiseResponse[0].value.data : undefined,
      scormSettings: promiseResponse[1].status === 'fulfilled' ? promiseResponse[1].value.data : undefined,
      categories: promiseResponse[2].status === 'fulfilled' ? promiseResponse[2].value.data : undefined,
    };
  } catch (err) {
    console.error(err);
    return undefined;
  }
});

export const setTimeZones = createAsyncThunk<TimeZoneDto[], void>('/setTimeZones', async (_, thunkAPI) => {
  try {
    const { settingsClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!settingsClientApi) return [];

    const response = await settingsClientApi.getTimeZones();
    return response.data;
  } catch (err) {
    console.error(err);
    return [];
  }
});

export const updateDomains = createAsyncThunk<string[], string[]>('/updateDomains', async (domains, thunkAPI) => {
  try {
    const { lmsClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!lmsClientApi) return [];

    await lmsClientApi.setWhitelistedDomains(domains);
    return domains;
  } catch (err) {
    console.error(err);
    return [];
  }
});

export const updateCategories = createAsyncThunk<CourseCategoryDto[], CourseCategoryDto[]>('/updateCategories', async (categories, thunkAPI) => {
  try {
    const { settingsClientApi } = (thunkAPI.getState() as RootState).clients.clients;
    if (!settingsClientApi) return [];

    await settingsClientApi.overrideCategories(categories);
    return categories;
  } catch (err) {
    console.error(err);
    return [];
  }
});

const initialState: ISettingsState = {
  apiStatus: ApiStatus.IDLE,
  settings: undefined,
  appSettings: {
    scormSettings: undefined,
  },
  categories: [],
  timeZones: [],
  error: undefined,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setSettings.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.SUCCESS,
        settings: action.payload?.settings,
        appSettings: {
          scormSettings: action.payload?.scormSettings,
        },
        categories: action.payload?.categories || [],
      }
    });
    builder.addCase(setSettings.pending, (state, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.LOADING,
      }
    });
    builder.addCase(setTimeZones.fulfilled, (state, action) => {
      return {
        ...state,
        timeZones: action.payload
      }
    });

    // updateDomains
    builder.addCase(updateDomains.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.SUCCESS,
        appSettings: {
          ...state.appSettings,
          scormSettings: {
            ...state.appSettings.scormSettings,
            whitelistedDomains: action.payload,
          }
        }
      }
    });
    builder.addCase(updateDomains.pending, (state, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.LOADING,
      }
    });
    builder.addCase(updateDomains.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.ERROR,
      }
    });

    // updateCategories
    builder.addCase(updateCategories.fulfilled, (state, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.SUCCESS,
        categories: action.payload,
        settings: {
          ...state.settings,
          categories: action.payload,
        }
      }
    });
    builder.addCase(updateCategories.pending, (state, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.LOADING,
      }
    });
    builder.addCase(updateCategories.rejected, (state, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.ERROR,
      }
    });
  },
});

export default settingsSlice.reducer;