import { mergeStyleSets } from "@fluentui/react";
import { IFadeClassNames, FADE } from "../../../../../../utils/constants/styles";

interface IQuizModalClassNames extends IFadeClassNames {
  form: string;
  editFinalQuizContainer: string;
}

export const getClassNames = (): IQuizModalClassNames => {
  return mergeStyleSets({
    form: {
      overflow: 'hidden',
      width: '100%',
      flex: 1,
      display: 'flex',
      gap: 20,
    },

    editFinalQuizContainer: {
      overflow: 'hidden',
      flex: 55,
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
    },

    ...FADE.classNames,
  });
};