import { TimePicker as FluentUITimePicker, IButtonStyles, IComboBox, IComboBoxStyles } from "@fluentui/react";
import { getTheme } from "@fluentui/react";
import { BorderRadius } from "../../../../../../../../../../../utils/constants/styles";
import { DateTime } from "luxon";
import { ActionTypes, IAction } from "../../../../../hooks/useForm";
import { Dispatch } from "react";


interface ITimePickerProps {
  // setTime: (e: React.FormEvent<IComboBox>, time: Date) => void;
  time: Date | undefined;
  dispatch: Dispatch<IAction>;
}

const TimePicker = (props: ITimePickerProps) => {
  const theme = getTheme();

  const styles: Partial<IComboBoxStyles> = {
    root: {
      width: '100%',
      height: 35,
      borderRadius: BorderRadius,

      'div[class^="css"]': {
        '&::after': {
          border: `1px solid ${theme.palette.neutralQuaternary}`,
          borderRadius: BorderRadius,
        }
      },
    },

    rootPressed: {
      border: `2px solid ${theme.palette.themePrimary}`,
    },

    rootHovered: {
      'div[class^="css"]': {
        '&:hover::after': {
          border: `1px solid ${theme.palette.neutralQuaternary}`,
        }
      },
    },

    callout: {
      borderTop: `2px solid ${theme.palette.themePrimary}`,

      '*::-webkit-scrollbar': {
        width: 6,
      },
    }
  };

  const caretDownButtonStyles: Partial<IButtonStyles> = {
    root: { 
      width: 30, borderLeft: `1px solid ${theme.palette.neutralQuaternary}`,
    },
    
    rootHovered: {
      backgroundColor: 'white'
    },
    
    rootPressed: {
      backgroundColor: 'white'
    },
    
    rootChecked: {
      backgroundColor: 'white'
    },
    
    rootCheckedPressed: {
      backgroundColor: 'white'
    },
    
    rootCheckedHovered: {
      backgroundColor: 'white'
    },

    icon: {
      fontWeight: 700, 
      color: 'black', 
      marginTop: 3
    }
  }

  const now = DateTime.now();
  const minutes = now.minute;
  const time = now.set({hour: minutes < 31 ? now.hour : now.hour + 1, minute: minutes < 31 ? 30 : 0});

  return (
    <FluentUITimePicker 
      styles={styles}
      calloutProps={{ directionalHintFixed: true }}
      onChange={(event: React.FormEvent<IComboBox>, time: Date) => 
        props.dispatch({ type: ActionTypes.SET_TIME, payload: { time } })}
      value={props.time || time.toJSDate()}
      caretDownButtonStyles={caretDownButtonStyles}
      timeRange={{start: 0, end: 24}}
    />
  )
};

export default TimePicker;