// import react
import { useRef, useState } from "react";

// import styles
import { getClassNames } from "./Details.classNames";

// import models
import { CSSTransition } from "react-transition-group";
import { Icon } from "@fluentui/react";

export interface IDetailsProps{
  errorDetails: string;
};

const Details = (props: IDetailsProps) => {
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const classNames = getClassNames(showDetails);

    const inputsContainerRef = useRef<HTMLDivElement>(null);

    return (
      <div className={classNames.errorDetailsContainer}>
        <div className={classNames.showDetails} onClick={() => setShowDetails((prev) => !prev)}>
          <p>Error details</p>
          <Icon className={classNames.chevronDownMed} iconName="ChevronDownMed" />
        </div>

        <CSSTransition
          nodeRef={inputsContainerRef}
          timeout={500}
          in={showDetails}
          classNames={{
            enter: classNames.enter,
            enterActive: classNames.enterActive,
            enterDone: classNames.enterDone,
            exit: classNames.exit,
            exitActive: classNames.exitActive,
            exitDone: classNames.exitDone,
          }}
          unmountOnExit
        >
          <div ref={inputsContainerRef} className={classNames.errorContainer}>
            {props.errorDetails}
          </div>
        </CSSTransition>

    </div>
  );
};

export default Details;
