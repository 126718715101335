import { mergeStyleSets, getTheme } from "@fluentui/react";

interface IAudioPlayerClassNames {
  audioPlayerContainer: string;
  progressBarContainer: string;
  icon: string;
  statusContainer: string;
  controlsContainer: string;
  timeProgressContainer: string;
}

export const getClassNames = (timeProgress: number | undefined, duration: number | undefined): IAudioPlayerClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    audioPlayerContainer: {
      flex: '0 0 60px',
      margin: '0 20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: 5,
      cursor: 'default'
    },

    progressBarContainer: {
      'input[type="range"]': {
        '--range-progress': 0,
        '-webkit-appearance': 'none',
        position: 'relative',
        background: theme.palette.neutralQuaternary,
        width: '100%',
        height: 3,
        borderRadius: 2,
        cursor: 'pointer',
      },

      'input[type="range"]::before': {
        content: '""',
        height: 3,
        background: theme.palette.themePrimary,
        width: timeProgress && duration && `${(timeProgress / duration) * 100}%`,
        borderBottomLeftRadius: 2,
        borderTopLeftRadius: 2,
        position: 'absolute',
        top: 0,
        left: 0,
      },

      'input[type="range"]::-webkit-slider-thumb': {
        '-webkit-appearance': 'none',
        height: 8,
        width: 8,
        borderRadius: '50%',
        border: 'none',
        background: theme.palette.themePrimary,
        cursor: 'pointer',
        position: 'relative',
      },

      'input[type="range"]:active::-webkit-slider-thumb': {
        transform: 'scale(1.2)',
      },
    },

    icon: {
      fontSize: 16,
      cursor: 'pointer'
    },

    statusContainer: {
      height: 20,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 20px',
    },

    controlsContainer: {
      display: 'flex',
      gap: 15,
    },

    timeProgressContainer: {
      display: 'flex',
      fontSize: 14, 
    }
  });
}