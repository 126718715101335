export enum CreateFormKeys {
  TITLE = 'title',
  DESCRIPTION = 'description',
  CATEGORY = 'categoryId',
  LESSONS_NUMBER = 'lessonsNumber',
  DOCUMENT_LANGUAGE = 'documentLanguage',
  PAGES = 'pages',
  CHAPTER_HEADER_PREFIX = 'chapterHeaderPrefix',
  TYPE_OF_LESSONS = 'typeOfLessons',
  GENERATE_AUDIO = 'generateAudio',
}

export interface ICreateForm {
  [CreateFormKeys.TITLE]: string;
  [CreateFormKeys.DESCRIPTION]: string;
  [CreateFormKeys.CATEGORY]: string;
  [CreateFormKeys.LESSONS_NUMBER]: number;
  [CreateFormKeys.DOCUMENT_LANGUAGE]: string;
  [CreateFormKeys.PAGES]: string;
  [CreateFormKeys.CHAPTER_HEADER_PREFIX]: string;
  [CreateFormKeys.TYPE_OF_LESSONS]: string[];
  [CreateFormKeys.GENERATE_AUDIO]: boolean;
}

export interface ICreateFromUploadForm {
  createForm: ICreateForm;
  file: File | undefined;
}

export enum CreateFromScratchFormKeys {
  TITLE = 'title',
  DESCRIPTION = 'description',
  CATEGORY = 'categoryId',
  DOCUMENT_LANGUAGE = 'documentLanguage',
  HAS_AUDIO = 'hasAudio',
}

export interface ICreateFromScratchForm {
  [CreateFromScratchFormKeys.TITLE]: string;
  [CreateFromScratchFormKeys.DESCRIPTION]: string;
  [CreateFromScratchFormKeys.CATEGORY]: string;
  [CreateFromScratchFormKeys.DOCUMENT_LANGUAGE]: string;
  [CreateFromScratchFormKeys.HAS_AUDIO]: boolean;
}