// import react
import { ChangeEvent, Dispatch, useRef, useState } from "react";

// import styles
import { getClassNames } from "./FileDetails.classNames";

// import models
import { ActionTypes, IAction } from "../../UploadModal";
import { CreateFormKeys } from "../../../../../../../common/models/Create/form-data";
import { CSSTransition } from "react-transition-group";
import { Icon } from "@fluentui/react";

export interface IFileDetailsProps{
    pages: string;
    chapterHeaderPrefix: string;
    fileType: "Docx" | "Pdf" | null | undefined;
    dispatch: Dispatch<IAction>;
};

const FileDetails = ({pages, chapterHeaderPrefix, fileType, dispatch}: IFileDetailsProps) => {
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const classNames = getClassNames(fileType, showDetails);

    const onInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      dispatch({ type: ActionTypes.UPDATE_INPUT, payload: { name: e.currentTarget.name, value: e.currentTarget.value }, })
    };

    const inputsContainerRef = useRef<HTMLDivElement>(null);

    console.log(fileType);

    return (
      <div className={classNames.fileDetailsContainer}>
        <div className={classNames.showDetails} onClick={() => { if (!!fileType) setShowDetails((prev) => !prev)}}>
          <p>Advanced options</p>
          <Icon className={classNames.chevronDownMed} iconName="ChevronDownMed" />
        </div>

        <CSSTransition
          nodeRef={inputsContainerRef}
          timeout={400}
          in={showDetails}
          classNames={{
            enter: classNames.enter,
            enterActive: classNames.enterActive,
            enterDone: classNames.enterDone,
            exit: classNames.exit,
            exitActive: classNames.exitActive,
            exitDone: classNames.exitDone,
          }}
          unmountOnExit
        >
          <div ref={inputsContainerRef} className={classNames.inputsContainer}>
            
            {fileType === 'Pdf' && <div className={classNames.groupInput}>
              <h5 className={classNames.titleLabel}>Pages</h5>
              <input id="pages"
                name={CreateFormKeys.PAGES}
                type="text"
                className={classNames.textInput}
                placeholder="1;3-5;10"
                onChange={onInputChange}
                value={pages}
              />
            </div>}

            {fileType === "Docx" && (
              <div className={classNames.groupInput}>
                <h5 className={classNames.titleLabel}>Custom chapter style</h5>
                <input id="chapterHeaderPrefix"
                  placeholder="Style1;Style2;Style3"
                  name={CreateFormKeys.CHAPTER_HEADER_PREFIX}
                  type="text"
                  className={classNames.textInput}
                  onChange={onInputChange}
                  value={chapterHeaderPrefix}
                />
              </div>
            )}
          </div>
        </CSSTransition>

    </div>
  );
};

export default FileDetails;
