// import styles
import { getClassNames } from "./Header.classNames";

// import components
import Search from "../../../../../assets/Icons/Controls/Search/search";
import { Icon } from "@fluentui/react";
import { ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { ApiStatus } from "../../../../../store/slices/courses";
import { CourseStatusDto } from "../../../../../api-client";

interface IHeaderProps {
  onMenuClick: () => void;
  search: string;
  updateSearch: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Header = (props: IHeaderProps) => {
  const { startingStatus } = useSelector((state: RootState) => state.courses.apiStatus);
  const courses = useSelector((state: RootState) => state.courses.courses);
  const classes = getClassNames(startingStatus.api !== ApiStatus.SUCCESS);

  const showReload = !!(courses && courses.filter(course => course.status === CourseStatusDto.Generating).length > 0);

  return (
    <div className={classes.header}>
      {/* <Icon iconName="CollapseMenu" className={classes.menuIcon} onClick={props.onMenuClick} /> */}
      <div className={classes.pageTitleContainer}>
        <h1>Learnings</h1>
        {showReload && <Icon className={classes.reloadIcon} iconName='Refresh' onClick={() => window.location.reload()} />}
      </div>
      <div className={classes.searchBarContainer}>
        <input type="text" className={classes.searchBar} placeholder="Search learnings" value={props.search} onChange={props.updateSearch} />
        <Search />
      </div>
    </div>
  );
}

export default Header;