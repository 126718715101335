import { CreateFormKeys, ICreateForm } from "../../common/models/Create/form-data";
import { GenerateConfigurationDto } from "../../api-client";

// interface INumberOfLessons {
//   textLessonNumber: number;
//   bulletPointsLessonNumber: number;
//   quizLessonNumber: number;
// }

// const checkTotalLessons = (numberOfLessons: INumberOfLessons, totalNumber: number): void => {
//   if (numberOfLessons.textLessonNumber && numberOfLessons.bulletPointsLessonNumber && numberOfLessons.quizLessonNumber)
//     if (+numberOfLessons.textLessonNumber + +numberOfLessons.bulletPointsLessonNumber + +numberOfLessons.quizLessonNumber !== totalNumber)
//       throw new Error('Number of lessons error')
// };

// const getLessonsNumber = (formData: ICreateForm): INumberOfLessons => {
// if (formData.lessonsNumber === -1) return {
//   textLessonNumber: formData[CreateFormKeys.TYPE_OF_LESSONS].includes('textLessonNumber') ? 1 : 0,
//   bulletPointsLessonNumber: formData[CreateFormKeys.TYPE_OF_LESSONS].includes('bulletPointsLessonNumber') ? 1 : 0,
//   quizLessonNumber: formData[CreateFormKeys.TYPE_OF_LESSONS].includes('quizLessonNumber') ? 1 : 0,
// }; // let ai choose

// const numberOfLessons = {
//   textLessonNumber: 0,
//   bulletPointsLessonNumber: 0,
//   quizLessonNumber: 0,
// };

// let lessonsTypeLength: number = formData.typeOfLessons.length;
// let totalLessons: number = formData.lessonsNumber;
// const division = Math.floor(totalLessons / lessonsTypeLength);
// if(formData.typeOfLessons.includes('textLessonNumber')) numberOfLessons.textLessonNumber = division;
// if(formData.typeOfLessons.includes('bulletPointsLessonNumber')) numberOfLessons.bulletPointsLessonNumber = division;
// if(formData.typeOfLessons.includes('quizLessonNumber')) numberOfLessons.quizLessonNumber = division;
// let remainedLessons = totalLessons - division * lessonsTypeLength;
// if(formData.typeOfLessons.includes('quizLessonNumber') && numberOfLessons.quizLessonNumber) numberOfLessons.quizLessonNumber += remainedLessons;
// else if (formData.typeOfLessons.includes('bulletPointsLessonNumber') && numberOfLessons.bulletPointsLessonNumber) numberOfLessons.bulletPointsLessonNumber += remainedLessons;
// return numberOfLessons;
// };

export const configurationDataMapper = (formData: ICreateForm): GenerateConfigurationDto  => {
  try {
    // const numberOfLessons = getLessonsNumber(formData);
    // if (formData[CreateFormKeys.LESSONS_NUMBER] !== -1 ) checkTotalLessons(numberOfLessons, formData[CreateFormKeys.LESSONS_NUMBER]);

    const configData: GenerateConfigurationDto = {
      title: formData.title,
      description: formData.description,
      categoryId: formData.categoryId,
      lessonsNumber: formData.lessonsNumber,
      documentLanguage: formData.documentLanguage,
      textLessonNumber: formData[CreateFormKeys.TYPE_OF_LESSONS].includes('textLessonNumber') ? 1 : 0,
      bulletPointsLessonNumber: formData[CreateFormKeys.TYPE_OF_LESSONS].includes('bulletPointsLessonNumber') ? 1 : 0,
      quizLessonNumber: formData[CreateFormKeys.TYPE_OF_LESSONS].includes('quizLessonNumber') ? 1 : 0,
      flashCardLessonNumber: formData[CreateFormKeys.TYPE_OF_LESSONS].includes('flashCardLessonNumber') ? 1 : 0,  
      generateAudio: formData.generateAudio
    };
    
    return configData;
  } catch (err) {
    throw err;
  }
};
