import { getClassNames } from './buttons.classNames';
import PrimaryButton from '../../../../../../../../common/components/Buttons/PrimaryButton/primary-button';
import DefaultButton from '../../../../../../../../common/components/Buttons/DefaultButton/default-button';

interface IButtonsProps {
  onSaveClick: () => Promise<void>;
  onCancelClick: () => void;
  disabled: boolean;
}

const Buttons = (props: IButtonsProps) => {
  const classes = getClassNames();

  // const isSaveDisabled = (): boolean => {
  //   if (props.lessonType === LessonTypeDto.Quiz) {
  //     const quizLesson = (props.formData as QuizLessonDto);
  //     if (quizLesson.options && quizLesson.options.length < 2) return true;
  //   }
  //   if (props.lessonType === LessonTypeDto.BulletPoints) {
  //     const bulletPointLesson = (props.formData as BulletPointLessonDto);
  //     if (bulletPointLesson.bulletPoints && bulletPointLesson.bulletPoints.length < 2) return true;
  //   }
  //   return false;
  // }

  return (
    <div className={classes.buttonsContainer}>
      <DefaultButton type='button' onClick={props.onCancelClick}>Cancel</DefaultButton>
      <PrimaryButton type='button' onClick={props.onSaveClick} disabled={props.disabled}>Generate</PrimaryButton>
    </div>
  )
}

export default Buttons;