import { mergeStyleSets, keyframes, getTheme } from "@fluentui/react";
import { BorderRadius } from "../../../../../../../../utils/constants/styles";

interface ICourseOptionsNames {
  courseOptionsContainer: string;
  titleLabel: string;
  textInput: string;
  textArea: string;
}

export const getClassNames = (): ICourseOptionsNames => {
  const theme = getTheme();

  const fadeIn = keyframes({
    from : {
      opacity: 0,
    },
    to: {
      opacity: 1,
    }
  });

  return mergeStyleSets({
    courseOptionsContainer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: 20,

      animationName: fadeIn,
      animationFillMode: 'forwards',
      animationDuration: '500ms',
    },

    titleLabel: {
      color: theme.palette.neutralSecondary,
      fontWeight: theme.fonts.medium.fontWeight
    },

    textInput: {
      width: '100%',
      border: 'none',
      background: theme.palette.neutralLighterAlt,
      borderRadius: BorderRadius,
      fontSize: theme.fonts.large.fontSize,
      padding: '5px 10px',
      outline: 'none'
    },

    textArea: {
      resize: 'none',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderBottom: `3px solid ${theme.palette.themePrimary}`,
    },
  });
};