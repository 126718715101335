// import react
import { useReducer } from "react";
import { IUser } from "../../../../../../../common/models/LearningDetails/user";
import { DateTime } from "luxon";
import { getSelectedTimeZone, getTime } from "../../../../../../../utils/helpers/helpers";
import { IPersonaProps } from "@fluentui/react";
import { ScheduleDto } from "../../../../../../../api-client";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../store/store";

export interface IFormData {
  recipient: IUser | null | undefined;
  startDate: DateTime | undefined;
  time: Date | undefined;
  timeZone: string;
  frequency: number | undefined;
}

export enum ActionTypes {
  SET_RECIPIENT = 'SET_RECIPIENT',
  REMOVE_RECIPIENT = 'REMOVE_RECIPIENT',
  SET_START_DATE = 'SET_START_DATE',
  SET_TIME = 'SET_TIME',
  SET_TIME_ZONE = 'SET_TIME_ZONE',
  SET_FREQUENCY = 'SET_FREQUENCY',
  SET_INITIAL_STATE = 'SET_INITIAL_STATE',
  SET_EDIT_STATE = 'SET_EDIT_STATE',
}

interface ISetRecipientPayload {
  selectedItem?: IUser | undefined
}

interface IRemoveRecipientPayload {
  items: IPersonaProps[] | undefined;
}

interface ISetStartDatePayload {
  date: Date | null | undefined;
}

interface ISetTimePayload {
  time: Date;
}

interface ISetTimeZonePayload {
  option: string;
}

interface ISetFrequencyPayload {
  value: string;
}

interface ISetEditStatePayload {
  scheduleItem: ScheduleDto;
}

export interface IAction {
  type: ActionTypes;
  payload: 
    ISetRecipientPayload | 
    IRemoveRecipientPayload | 
    ISetStartDatePayload | 
    ISetTimePayload | 
    ISetFrequencyPayload |
    ISetTimeZonePayload | 
    ISetEditStatePayload |
    void;
}

function reducer(state: IFormData, action: IAction): IFormData {
  switch (action.type) {
    case (ActionTypes.SET_RECIPIENT): return {
      ...state,
      recipient: (action.payload as ISetRecipientPayload).selectedItem,
    };

    case (ActionTypes.REMOVE_RECIPIENT): return {
      ...state,
      recipient: (action.payload as IRemoveRecipientPayload).items?.length === 0 ? null : state.recipient,
    };

    case (ActionTypes.SET_START_DATE): {
      const date = (action.payload as ISetStartDatePayload).date;

      return {
        ...state,
        startDate: date ? DateTime.fromJSDate(date) : state.startDate,
      }
    };
    
    case (ActionTypes.SET_TIME): return {
      ...state,
      time: (action.payload as ISetTimePayload).time,
    };

    case (ActionTypes.SET_TIME_ZONE): return {
      ...state,
      timeZone: (action.payload as ISetTimeZonePayload).option,
    };

    case (ActionTypes.SET_FREQUENCY): {
      const value = +(action.payload as ISetFrequencyPayload).value;
      const isNotNumber = isNaN(value);

      return {
        ...state,
        frequency: isNotNumber ? state.frequency : (value ? value : 0) , 
      }
    };

    case (ActionTypes.SET_INITIAL_STATE): return {
      ...state,
      recipient: null,
      startDate: DateTime.now(),
      time: getTime().toJSDate(),
      frequency: 1,
    };

    case (ActionTypes.SET_EDIT_STATE): {
      const scheduleItem = (action.payload as ISetEditStatePayload).scheduleItem;

      return {
        ...state,
        recipient: {
          key: scheduleItem.recipient?.id || '',
          text: scheduleItem.recipient?.displayName || '',
          userType: scheduleItem.recipient.type,
          imageUrl: scheduleItem.recipient.profilePicture || '',
        },
        startDate: DateTime.fromISO(scheduleItem.startDate),
        frequency: scheduleItem.frequency,
        time: DateTime.fromISO(scheduleItem.startDate).toJSDate(),
        timeZone: scheduleItem.timeZoneId,
      } 
    };

    default: return state;
  }
}

function useForm() {
  const { timeZones } = useSelector((state: RootState) => state.settings);

  const initialState: IFormData = {
    recipient: null,
    startDate: DateTime.now(),
    time: getTime().toJSDate(),
    timeZone: getSelectedTimeZone(timeZones),
    frequency: 1,
  };

  const [formData, formDispatch] = useReducer(reducer, initialState);

  return {
    formData,
    formDispatch
  };
}

export default useForm;