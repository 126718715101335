// import styles
import { getClassNames } from "./ErrorMessage.classNames";

interface IErrorMessageProps {
  errorMessage: string;
}

const ErrorMessage = (props: IErrorMessageProps) => {
  const classes = getClassNames();

  return (
    <div className={classes.errorMessageContainer}>
      <p className={classes.errorMessage}>{props.errorMessage}</p>
    </div>
  )
}

export default ErrorMessage;