import { mergeStyleSets } from "@fluentui/react";

interface IListClassNames {
  listContainer: string;
}

export const getClassNames = (): IListClassNames => {
  return mergeStyleSets({
    listContainer: {
      overflow: 'auto',
      width: '100%',
      flex: 1,
      maxHeight: 335,
      marginBottom: 10,
    },
  });
}