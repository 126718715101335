import { mergeStyleSets, getTheme } from '@fluentui/react';
import { BorderRadius } from '../../../../../../../utils/constants/styles';

interface IChooseFileClassNames{
  chooseFileContainer: string;
  fileContainer: string;
  icon: string;
  fileInfoContainer: string;
  fileName: string;
  close: string;
  uploadLabel: string;
}

export const getClassNames = (): IChooseFileClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    chooseFileContainer: {
      width: '100%',
      flex: '0 0 100px',
      maxHeight: 100,
      padding: 20,
      color: theme.palette.themePrimary,
      background: getTheme().palette.neutralLighterAlt,
      borderRadius: BorderRadius,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },

    fileContainer: {
      width: '100%',
      height: '100%',
      padding: '0px 20px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: theme.palette.black,
      background: theme.palette.white,
      borderRadius: BorderRadius,
      boxShadow: '0 0 2px 2px rgba(0, 0, 0, 0.1)'
    },

    icon: {
      height: '75%',
    },

    fileInfoContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: 20,
    },

    fileName: {
      fontWeight: theme.fonts.xLarge.fontWeight,
    },

    close: {
      fontSize: 14,
    },

    uploadLabel: {
      textDecoration: 'underline',
    }
  })
}