// import react
import { ChangeEvent, useState, Dispatch } from 'react';

//import styles
import { getClassNames } from "./bullet-points-lesson.classNames";
import { getTheme } from '@fluentui/react';

// import models
import { BulletPointLessonDto } from "../../../../../../../../../api-client";
import { IconNames } from '../../../../../../../../../common/models/Icons/icon-names';

// import components
import PrimaryButton from '../../../../../../../../../common/components/Buttons/PrimaryButton/primary-button';
import Reorder from '../../../../../../../../../assets/Icons/Controls/Reorder/reorder';
import useTextArea from './hooks/useTextAreaRef';
import { ActionTypes, IAction, IFormData } from '../../../hooks/useForm';
import useDrag from './hooks/useDrag';


interface IBulletPointsLessonProps {
  formData: IFormData;
  dispatch: Dispatch<IAction>;
  disabled: boolean;
}

const BulletPointsLesson = (props: IBulletPointsLessonProps) => {
  const {
    onDragStart,
    onDragOver,
    onDragEnd,
    onDrop
  } = useDrag(props.dispatch);
  const classes = getClassNames(props.disabled);
  const theme = getTheme();

  const lessonData = props.formData.lessonData as BulletPointLessonDto;

  const [newItem, setNewItem] = useState<string>('');
  const textAreaRefs = useTextArea(lessonData.bulletPoints);

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;

    props.dispatch({ type: ActionTypes.UPDATE_INPUT, payload: { name: e.target.name, value: e.target.value } });
  };

  const onBulletPointsChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;

    const index = e.currentTarget.getAttribute('data-index');
    if (!index) return;
    props.dispatch({ type: ActionTypes.UPDATE_BULLET_POINT, payload: { name: e.target.name, value: e.target.value, index: +index } });
  };

  const onNewItemChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;

    setNewItem(e.target.value);
  };

  const onAddNewItem = () => {
    props.dispatch({type: ActionTypes.ADD_BULLET_POINT, payload: newItem});
    setNewItem('');
  };

  return (
    <>
     <div className={classes.titleGroupInput}>
        <h5 className={classes.titleLabel}>Title</h5>
        <textarea 
          className={classes.titleInput} 
          id='title' 
          name='title'
          value={lessonData.title || ''}
          onChange={onChange}
          rows={1}
          ref={textAreaRefs.title}
          disabled={props.disabled}
        />
      </div>
      <div className={classes.introductionGroupInput}>
        <h5 className={classes.titleLabel}>Introduction</h5>
        <textarea 
          className={classes.introductionInput}
          id='introduction' 
          name='introduction'
          value={lessonData.introduction || ''}
          onChange={onChange}
          rows={1}
          ref={textAreaRefs.introduction}
          disabled={props.disabled}
        />
      </div>
      <div className={classes.bulletPointsGroupInput}>
        <h5 className={classes.titleLabel}>Bullet points</h5>
        {lessonData.bulletPoints?.map((point: string, index: number) => (
          <div key={index} className={classes.bulletPointGroupInput}>
            <div
              data-index={index}
              className={classes.bulletPointContainer} 
              draggable={false}
              onDragStart={(e) => onDragStart(e, index)}
              onDragEnd={onDragEnd}
              onDragOver={onDragOver}
              onDrop={onDrop}
            >
              <Reorder disabled={false} />
              <textarea 
                className={classes.bulletPointInput}
                id={`bullet-point-${index}`} 
                name={`bullet-point-${index}`}
                data-index={index}
                value={point || ''}
                onChange={onBulletPointsChange}
                rows={1}
                ref={(el) => { if(el && textAreaRefs.bulletPoints.current) textAreaRefs.bulletPoints.current[index] = el}}
                disabled={props.disabled}
              />
              <PrimaryButton                
                onClick={() => props.dispatch({ type: ActionTypes.DELETE_BULLET_POINT, payload: index })}
                iconName={IconNames.DELETE} 
                width={90} 
                padding={10}
                gap={5} 
                background={theme.palette.themeLighter} 
                color={props.disabled ? theme.palette.neutralTertiary : theme.palette.themePrimary}
                disabled={props.disabled}
                >
                  Delete
                </PrimaryButton>
            </div>
          </div>
        ))}
        <div className={classes.newBulletPointContainer}>
          <textarea
            ref={textAreaRefs.newBulletPoint}
            name="new-item" 
            id="new-item" 
            className={classes.newBulletPointInput} 
            rows={1}
            value={newItem}
            onChange={onNewItemChange}
            // disabled={props.disabled}
          />
          <PrimaryButton
            onClick={onAddNewItem}
            iconName={IconNames.ADD}
            width={90}
            color={newItem ? theme.palette.white : theme.palette.neutralTertiary}
            disabled={!newItem || props.disabled}
          >
            Add
          </PrimaryButton>
        </div>
      </div>
    </>
  )
};

export default BulletPointsLesson;