import { mergeStyleSets, getTheme } from "@fluentui/react"

interface IEditLessonClassNames {
  editLessonContainer: string;
  dropdownContainer: string;
  dropdownLabel: string;
  lessonTypeContainer: string;
  checkbox: string;
}

export const getClassNames = (): IEditLessonClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    editLessonContainer: {
      overflow: 'auto',
      flex: 1,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
    },

    lessonTypeContainer: {
      display: 'flex',
      alignItems: 'flex-end',
      gap: 60,
    },

    dropdownContainer: {
      width: 200,
      display: 'flex',
      flexDirection: 'column',
      gap: 5,
    },

    checkbox: {
      marginBottom: 2,
    },

    dropdownLabel: {
      color: theme.palette.neutralSecondary,
      fontWeight: theme.fonts.medium.fontWeight,
    }
  });
};