import { mergeStyleSets } from "@fluentui/react";

interface IHeaderClassNames {
  header: string;
}

export const getClassNames = (): IHeaderClassNames => {
  return mergeStyleSets({
    header: {
      flex: '0 0 70px',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 40,
      padding: '20px 40px 0px',
    },
  });
};