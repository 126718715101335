import Modal from '../../../../../../common/components/Modal/modal';
import SideBar from './components/SideBar/sidebar';
import Question from './components/Question/question';
import { getClassNames } from './quiz-modal.classNames';
import useForm from './hooks/useForm';
import Navigation from './components/Navigation/navigation';
import Buttons from './components/Buttons/buttons';
import { RootState, useAppDispatch } from '../../../../../../store/store';
import { editFinalQuiz } from '../../../../../../store/slices/courses';
import { useParams } from 'react-router-dom';
import { FormEvent, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { ANIMATION_DURATION } from '../../../../../../utils/constants/styles';
import ConfirmationDialog from '../../ConfirmationDialog/confirmation-dialog';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { FinalQuizLessonDto, LessonTypeDto } from '../../../../../../api-client';

interface IQuizProps {
  onCloseQuizModal: () => void;
  nodeRef: React.RefObject<HTMLDivElement>;
  animation: boolean;
}

const QuizModal = (props: IQuizProps) => {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);

  const lesson = useSelector((state: RootState) => 
    state.courses.course?.lessons && state.courses.course.lessons.find(el => el.type === LessonTypeDto.FinalQuiz));
  const finalQuiz = lesson as FinalQuizLessonDto;

  const dispatch = useAppDispatch();
  const { formData, formDispatch } = useForm(finalQuiz);
  const classes = getClassNames();

  const { lang } = useParams();

  const dialogRef = useRef<HTMLDivElement>(null);

  async function onSaveQuiz() {
    try {
      if (!formData.quizes) return;
      await dispatch(editFinalQuiz({ quizes: formData.quizes, lang }));
      props.onCloseQuizModal();
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <Modal
      nodeRef={props.nodeRef}
      animation={props.animation}
      width={1200}
      height={700}
      title="Final Quiz"
      onModalClose={() => {
        if (_.isEqual(finalQuiz.quizes, formData.quizes)) props.onCloseQuizModal();
        else setShowConfirmationDialog(true);
      }}
    >
      <Navigation
        questionsLength={formData.quizes?.length || 0}
        selectedQuestionIndex={formData.selectedQuestionIndex + 1}
        dispatch={formDispatch}
      />
      <form className={classes.form} onSubmit={(e: FormEvent) => { e.preventDefault(); }}>
        <SideBar
          formData={formData}
          dispatch={formDispatch}
        />
        <div className={classes.editFinalQuizContainer}>
          {formData.quizes && formData.quizes.length > 0 && <Question
            key={formData.quizes[formData.selectedQuestionIndex].id}
            selectedQuestion={formData.quizes[formData.selectedQuestionIndex]}
            dispatch={formDispatch}
          />}
          <Buttons
            onSaveQuiz={onSaveQuiz}
            onCloseClick={() => {
              if (_.isEqual(finalQuiz.quizes, formData.quizes)) props.onCloseQuizModal();
              else setShowConfirmationDialog(true);
            }}
          />
        </div>
      </form>

      <CSSTransition
        nodeRef={dialogRef}
        timeout={ANIMATION_DURATION}
        in={showConfirmationDialog}
        classNames={{
          enter: classes.enter,
          enterActive: classes.enterActive,
          exit: classes.exit,
          exitActive: classes.exitActive
        }}
        unmountOnExit
      >
        <ConfirmationDialog
          nodeRef={dialogRef}
          animation={showConfirmationDialog}
          type='Close'
          onClose={props.onCloseQuizModal}
          onCancel={() => setShowConfirmationDialog(false)}
        />
      </CSSTransition>

    </Modal>
  );
};

export default QuizModal;