import { useCallback, useState } from "react";
import { AddLessonSettingsDto } from "../../../../api-client";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

export default function useAddLessonSettings() {
  const coursesClientApi = useSelector((state: RootState) => state.clients.clients.coursesClientApi);
  const courseId = useSelector((state: RootState) => state.courses.course?.id);
  const documentLanguage = useSelector((state: RootState) => state.courses.course?.documentLanguage);

  const [addLessonSettings, setAddLessonSettings] = useState<AddLessonSettingsDto>();

  const getAddLessonSettings = useCallback(async (): Promise<void> => {
    if (!coursesClientApi) return;

    if(!courseId || !documentLanguage) return;

    try {
      const response = await coursesClientApi.getAddLessonSettings(courseId);
      setAddLessonSettings(response.data);
    }catch(err){
      console.error(err);
    }
  }, [courseId, documentLanguage, coursesClientApi]);

  return {
    addLessonSettings,
    getAddLessonSettings
  };
}