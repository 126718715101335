// import react
import { useState } from 'react';

const useCreate = () => {
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const [showFromScratchModal, setShowFromScratchModal] = useState<boolean>(false);

  const onUploadClick = () => setShowUploadModal(true);
  const onUploadModalCloseClick = () => setShowUploadModal(false);

  const onFromScratchClick = () => setShowFromScratchModal(true);
  const onFromScratchModalCloseClick = () => setShowFromScratchModal(false);

  return {
    showUploadModal,
    onUploadClick,
    onUploadModalCloseClick,

    showFromScratchModal,
    onFromScratchClick,
    onFromScratchModalCloseClick
  }
};

export default useCreate;