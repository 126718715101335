export const BorderRadius = '5px';

export interface IFadeClassNames {
  enter: string;
  enterActive: string;
  exit: string;
  exitActive: string;
}

export const ANIMATION_DURATION = 250;

class Fade {
  constructor(private _enterDurationMS: number, private _exitDurationMS: number) {}

  get classNames () {
    return {
      enter: {
        opacity: 0,
      },
  
      enterActive: {
        opacity: 1,
        transition: `opacity ${this._enterDurationMS}ms`,
      },
  
      exit: {
        opacity: 1,
      },
  
      exitActive: {
        opacity: 0,
        transition: `opacity ${this._exitDurationMS}ms`,
      }
    };
  }
}

class FadeScale {
  constructor(private _enterDurationMS: number, private _exitDurationMS: number) {}

  get classNames () {
    return {
      enter: {
        opacity: 0,
        transform: 'scale(0.9)',
      },
  
      enterActive: {
        transform: 'scale(1)',
        opacity: 1,
        transition: `all ${this._enterDurationMS}ms`,
      },
  
      exit: {
        opacity: 1,
        transform: 'scale(1)',
      },
  
      exitActive: {
        opacity: 0,
        transform: 'scale(0.9)',
        transition: `all ${this._exitDurationMS}ms`,
      }
    };
  }
}

class FadeHeight {
  constructor(private _enterDurationMS: number, private _exitDurationMS: number) {}

  get classNames () {
    return {
      enter: {
        opacity: 0,
        maxHeight: 0,
        paddingBottom: 0,
        marginBottom: 0,
      },
  
      enterActive: {
        opacity: 1,
        maxHeight: 170,
        paddingBottom: 10,
        marginBottom: 10,
        transition: `all ${this._enterDurationMS}ms`,
      },
  
      exit: {
        opacity: 1,
        maxHeight: 170,
        paddingBottom: 10,
        marginBottom: 10,
      },
  
      exitActive: {
        opacity: 0,
        maxHeight: 0,
        paddingBottom: 0,
        marginBottom: 0,
        transition: `all ${this._exitDurationMS}ms`,
      },

      exitDone: {
        paddingBottom: 0,
        marginBottom: 0,
      },

      enterDone: {
        paddingBottom: 10,
        marginBottom: 10,
      },
    };
  }
}

class FadeHeightAdvancedProperties {
  constructor(private _enterDurationMS: number, private _exitDurationMS: number) {}

  get classNames () {
    return {
      enter: {
        opacity: 0,
        maxHeight: 0,
        paddingBottom: 0,
        marginBottom: 0,
        marginTop: 0,
      },
  
      enterActive: {
        opacity: 1,
        maxHeight: 70,
        paddingBottom: 10,
        marginBottom: 10,
        marginTop: 10,
        transition: `all ${this._enterDurationMS}ms`,
      },
  
      exit: {
        opacity: 1,
        maxHeight: 70,
        paddingBottom: 10,
        marginBottom: 10,
        marginTop: 10,
      },
  
      exitActive: {
        opacity: 0,
        maxHeight: 0,
        paddingBottom: 0,
        marginBottom: 0,
        marginTop: 0,
        transition: `all ${this._exitDurationMS}ms`,
      },

      exitDone: {
        paddingBottom: 0,
        marginBottom: 0,
        marginTop: 0,
      },

      enterDone: {
        paddingBottom: 10,
        marginBottom: 10,
        marginTop: 10,
      },
    };
  }
}

class FadeHeightErrorDetails {
  constructor(private _enterDurationMS: number, private _exitDurationMS: number) {}

  get classNames () {
    return {
      enter: {
        opacity: 0,
        maxHeight: 0,
        paddingBottom: 0,
        marginBottom: 0,
        marginTop: 0,
      },
  
      enterActive: {
        opacity: 1,
        maxHeight: 200,
        paddingBottom: 10,
        marginBottom: 10,
        marginTop: 10,
        transition: `all ${this._enterDurationMS}ms`,
      },
  
      exit: {
        opacity: 1,
        maxHeight: 200,
        paddingBottom: 10,
        marginBottom: 10,
        marginTop: 10,
      },
  
      exitActive: {
        opacity: 0,
        maxHeight: 0,
        paddingBottom: 0,
        marginBottom: 0,
        marginTop: 0,
        transition: `all ${this._exitDurationMS}ms`,
      },

      exitDone: {
        paddingBottom: 0,
        marginBottom: 0,
        marginTop: 0,
      },

      enterDone: {
        paddingBottom: 10,
        marginBottom: 10,
        marginTop: 10,
      },
    };
  }
}

export const FADE = new Fade(ANIMATION_DURATION, ANIMATION_DURATION);
export const SIDEBAR_ANIMATION = new Fade(750, 350);
export const FADE_SCALE = new FadeScale(ANIMATION_DURATION, ANIMATION_DURATION);
export const FADE_HEIGHT = new FadeHeight(ANIMATION_DURATION, ANIMATION_DURATION);
export const FADE_HEIGHT_ADVANCED_PROPERTIES = new FadeHeightAdvancedProperties(400, 400);
export const FADE_HEIGHT_ERROR_DETAILS = new FadeHeightErrorDetails(500, 500);