import { mergeStyleSets, getTheme } from "@fluentui/react";
import { IFadeClassNames, FADE } from "../../../../../../utils/constants/styles";

interface IOptionsClassNames extends IFadeClassNames {
  options: string;
  contextualMenuContainer: string;
  menuItemCustom: string;
  menuItemIconContainer: string;
  dropdownContainer: string;
  vr: string;
}

export const getClassNames = (): IOptionsClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    options: {
      height: 40,
      display: 'flex',
      alignItems: 'center',
      gap: 20,
    },

    contextualMenuContainer: {
    },

    menuItemCustom: {
      fontSize: 32,
      color: 'red',
    },

    menuItemIconContainer: {
      display: 'flex',
      margin: '0 6px',
    },

    dropdownContainer: {
      width: 190,
    },

    vr: {
      content: '""',
      height: '100%',
      border: 'none',
      borderRight: `1px solid ${theme.palette.neutralQuaternary}`,
      margin: '0 10px'
    },

    ...FADE.classNames,
  });
};