import Add from "../../assets/Icons/Buttons/Add/add";
import AddSchedule from "../../assets/Icons/Buttons/AddSchedule/add-schedule";
import Edit from "../../assets/Icons/Buttons/Edit/edit";
import Filter from "../../assets/Icons/Controls/Filter/filter";
import Analytics from "../../assets/Icons/Buttons/Analytics/analytics";
import ChangeLanguage from "../../assets/Icons/Dropdown/ChangeLanguage/change-language";
import Configure from "../../assets/Icons/Buttons/Configure/configure";
import Delete from "../../assets/Icons/Buttons/Delete/delete";
import Publish from "../../assets/Icons/Buttons/PublishIcon/publish";
import Schedule from "../../assets/Icons/Buttons/Schedule/Schedule";
import Unpublish from "../../assets/Icons/Buttons/Unpublish/unpublish";
import Reorder from "../../assets/Icons/Controls/Reorder/reorder";
import Send from "../../assets/Icons/Buttons/Send/Send";
import { IconNames } from "../../common/models/Icons/icon-names";
import { IIconProps } from "../../common/models/Icons/icon-props";
import AddLesson from "../../assets/Icons/Buttons/AddLesson/add-lesson";
import FinalQuiz from "../../assets/Icons/Buttons/FinalQuiz/final-quiz";
import Download from "../../assets/Icons/Buttons/Download/download";
import Clone from "../../assets/Icons/Buttons/Clone/Clone";
import ScormIcon from "../../assets/Icons/Buttons/Scorm/Scorm";
import Stop from "../../assets/Icons/Buttons/Stop/Stop";

export const Icons: { [key in IconNames]: (props: IIconProps) => JSX.Element } = {
  [IconNames.CONFIGURE]: Configure,
  [IconNames.ANALYTICS]: Analytics,
  [IconNames.SCHEDULE]: Schedule,
  [IconNames.PUBLISH]: Publish,
  [IconNames.UNPUBLISH]: Unpublish,
  [IconNames.DELETE]: Delete,
  [IconNames.CHANGE_LANGUAGE]: ChangeLanguage,
  [IconNames.ADD]: Add,
  [IconNames.REORDER]: Reorder,
  [IconNames.ADD_SCHEDULE]: AddSchedule,
  [IconNames.EDIT]: Edit,
  [IconNames.FILTER]: Filter,
  [IconNames.SEND]: Send,
  [IconNames.ADD_LESSON]: AddLesson,
  [IconNames.FINAL_QUIZ]: FinalQuiz,
  [IconNames.DOWNLOAD]: Download,
  [IconNames.CLONE]: Clone,
  [IconNames.SCORM]: ScormIcon,
  [IconNames.STOP]: Stop
};