import { Dispatch } from 'react';

// import styles
import { getClassNames } from "./collection.classNames";
import { getTheme } from "@fluentui/react";

// import components
import { Toggle } from "@fluentui/react";
import PrimaryButton from "../../../../../../../../../../common/components/Buttons/PrimaryButton/primary-button";

// import utils
import { getLanguageFromCode } from "../../../../../../../../../../utils/helpers/helpers";
import { IconNames } from "../../../../../../../../../../common/models/Icons/icon-names";
import { ActionTypes, IAction, ILanguagesForm } from "../../../../hooks/useLanguagesForm";
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../../../../store/store';

interface ICollectionProps {
  languagesForm: ILanguagesForm;
  dispatch: Dispatch<IAction>;
}

const Collection = (props: ICollectionProps) => {
  const classes = getClassNames();
  const theme = getTheme();

  const defaultLanguage = useSelector((state: RootState) => state.courses.course?.documentLanguage);

  const getLanguage = (language: string | null | undefined) => {
    if (!language) return '';

    return getLanguageFromCode(language);
  };

  return (
    <div className={classes.collectionContainer}>
      <p>Show, hide or delete a language from your collection</p>
        <div className={classes.languagesContainer}>
          {props.languagesForm.languageCollection.map((language, index) => (
            <div key={index} className={classes.languageContainer}>
              <div className={classes.languageItem}>
                <div className={classes.toggleContainer}>
                  <Toggle styles={{root: { margin: 0 }}}
                    defaultChecked={language.isPublishable}
                    onChange={() => props.dispatch({ type: ActionTypes.CHECK_TOGGLE, payload: index })}
                  />
                </div>
                <div className={classes.language}>{getLanguage(language.language)}</div>
              </div>
            <PrimaryButton
              type="button"
              onClick={() => props.dispatch({ type: ActionTypes.DELETE_LANGUAGE, payload: index })}
              disabled={props.languagesForm.languageCollection.length === 1 || defaultLanguage === language.language}
              iconName={IconNames.DELETE}
              background={theme.palette.themeLighter}
              width={90} 
              padding={10}
              gap={5}
              color={props.languagesForm.languageCollection.length === 1 || defaultLanguage === language.language ? theme.palette.neutralTertiary : theme.palette.themePrimary}
            >
              Delete
            </PrimaryButton>
            </div>
          ))}
        </div>
    </div>
  )
}

export default Collection;