import { useState } from "react";
import { getClassNames } from "./ScormModal.classes";
import Modal from "../../../../../common/components/Modal-copy/modal";
import useScormSettings, { ActionTypes } from "./hooks/useScormSettings";
import { getTheme } from "@fluentui/react";
import PrimaryButton from "../../../../../common/components/Buttons/PrimaryButton/primary-button";
import Domains from "./components/Domains/Domains";
import Input from "../../../../../common/components/Inputs/Input/input";
import { useAppDispatch } from "../../../../../store/store";
import { updateDomains } from "../../../../../store/slices/settings";

interface IScormModalProps {
  isOpen: boolean;
  onDismiss: () => void;
}

export default function ScormModal({ isOpen, onDismiss }: IScormModalProps) {
  const [isSettingsChanged, setIsSettingsChanged] = useState<boolean>(false);
  const { scormSettingsForm, scormSettingsFormDispatch } = useScormSettings();
  const dispatch = useAppDispatch();
  const classes = getClassNames();

  function enableSaveButton () {
    setIsSettingsChanged(true);
  }

  async function saveScormSettings() {
    try {
      await dispatch(updateDomains(scormSettingsForm.domains || [])).unwrap();
      setIsSettingsChanged(false);
    } catch (err) {
      console.error(err);
    }
  }

  return (
     <Modal
        isOpen={isOpen}
        width={580}
        title="SCORM settings"
        onSave={saveScormSettings}
        onDismiss={() => onDismiss()}
        saveButtonEnabled={isSettingsChanged}
     >
      <div className={classes.scormSettingsContainer}>
      <Domains form={scormSettingsForm} dispatch={scormSettingsFormDispatch} enableSaveButton={enableSaveButton} />
      <hr className={classes.divider} />
      <div className={classes.newDomainContainer}>
        <Input
          id=""
          name=""
          onChange={(e) => scormSettingsFormDispatch({ type: ActionTypes.UPDATE_INPUT, payload: e.target.value })}
          title="New domain"
          value={scormSettingsForm.domainToAdd}
          flex
        />
        <PrimaryButton
          // iconName={IconNames.ADD}
          disabled={scormSettingsForm.domainToAdd === ''}
          color={getTheme().palette.white}
          onClick={() => {
            scormSettingsFormDispatch({ type: ActionTypes.ADD_DOMAIN });
            enableSaveButton();
          }}
        >
          Add
        </PrimaryButton>
      </div>
    </div>
     </Modal>
  )
}
