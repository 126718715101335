import { mergeStyleSets } from "@fluentui/react";

interface ICourseOptionsNames {
  courseOptionsContainer: string;
  generateAudioContainer: string;
}

export const getClassNames = (): ICourseOptionsNames => {
  return mergeStyleSets({
    courseOptionsContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '1fr',
      gap: 10
    },

    generateAudioContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 10,
    }
  });
};