import Menu from "../../components/Menu/menu"
import { getClassNames } from "./app-layout.classNames";
import { Outlet } from "react-router-dom";
import { FiltersProvider } from "../../../contexts/filters-context";
import { InfoProvider } from "../../../contexts/info-context";

const AppLayout = (): JSX.Element => {
  const classes = getClassNames();

  return (
    <div className={classes.appLayout}>
      <InfoProvider>
        <FiltersProvider>
          <Menu />
          <main className={classes.main}>
            <Outlet />
          </main>
        </FiltersProvider>
      </InfoProvider>
    </div>
  )
};

export default AppLayout;