import { getClassNames } from './buttons.classNames';
import DefaultButton from '../../../../../../../../../../../../common/components/Buttons/DefaultButton/default-button';
import PrimaryButton from '../../../../../../../../../../../../common/components/Buttons/PrimaryButton/primary-button';

interface IButtonsProps {
  onSave: () => void;
  onCancel: () => void;
}

const Buttons = (props: IButtonsProps) => {
  const classes = getClassNames();

  return (
    <div className={classes.buttonsContainer}>
      <DefaultButton type='button' onClick={props.onCancel}>Cancel</DefaultButton>
      <PrimaryButton type='button' onClick={props.onSave}>Save</PrimaryButton>
    </div>
  )
}

export default Buttons;