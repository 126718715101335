import { useEffect, useReducer } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store/store";

export interface IScormSettingsForm {
  domains: string[] | undefined;
  domainToAdd: string;
}

export enum ActionTypes {
  UPDATE_DOMAINS = 'UPDATE_DOMAINS',
  UPDATE_INPUT = 'UPDATE_INPUT',
  ADD_DOMAIN = 'ADD_DOMAIN',
  DELETE_DOMAIN = 'DELETE_DOMAIN',
}

interface IDomainDropdownPayload {
  domainToAdd: string;
}

export interface IAction {
  type: ActionTypes;
  payload?: IDomainDropdownPayload | number | string | string[];
}

function reducer(state: IScormSettingsForm, action: IAction): IScormSettingsForm {
  switch (action.type) {
    case (ActionTypes.UPDATE_DOMAINS):
      return { 
        ...state,
        domains: action.payload as string[],
        domainToAdd: '',
      }

    case (ActionTypes.UPDATE_INPUT):
      return { 
        ...state,
        domainToAdd: action.payload as string,
      }

    case (ActionTypes.ADD_DOMAIN): {
      if (!state.domains) return state;

      const newDomains = [...state.domains];
      newDomains.push(state.domainToAdd);
      return { 
        ...state,
        domains: newDomains,
        domainToAdd: '',
      }
    };

    case (ActionTypes.DELETE_DOMAIN): {
      if (!state.domains) return state;

      const newDomains = [...state.domains];
      newDomains.splice((action.payload as number), 1);
      return {
        ...state,
        domains: newDomains,
      }
    };

    default: return state;
  } 
}

export default function useScormSettings() {
  const scormSettings = useSelector((state: RootState) => state.settings.appSettings.scormSettings);
  const initialState: IScormSettingsForm = {
    domains: undefined,
    domainToAdd: '',
  };

  const [scormSettingsForm, scormSettingsFormDispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (scormSettings) 
      scormSettingsFormDispatch({ type: ActionTypes.UPDATE_DOMAINS, payload: scormSettings?.whitelistedDomains || [] })
  }, [scormSettings]);

  return {
    scormSettingsForm,
    scormSettingsFormDispatch,
  }
} 