// import styles
import { getClassNames } from "./configure-modal.classNames";

// import components
import Modal from "../../../../../../common/components/Modal/modal";
import LanguageOptions from "./components/LanguageOptions/language-options";
import Nav from "./components/Nav/nav";

// import hooks
import useNav from "./hooks/useNav";
import CourseOptions from "./components/CourseOptions/course-options";
import DefaultButton from "../../../../../../common/components/Buttons/DefaultButton/default-button";
import PrimaryButton from "../../../../../../common/components/Buttons/PrimaryButton/primary-button";
import useLanguagesForm from "./hooks/useLanguagesForm";
import useOptionsForm from "./hooks/useOptionsForm";
import { useNavigate, useParams } from "react-router-dom";
import QuizOptions from "./components/QuizOptions/quiz-options";
import useQuizForm from "./hooks/useQuizForm";
import { useAppDispatch } from "../../../../../../store/store";
import { saveCourseOptions, saveLanguageCollection } from "../../../../../../store/slices/courses";

interface IConfigureModalProps {
  onCloseConfigure: () => void;
  nodeRef: React.RefObject<HTMLDivElement>;
  animation: boolean;
}

const ConfigureModal = (props: IConfigureModalProps) => {
  const classes = getClassNames();
  const dispatch = useAppDispatch();
  const { languagesForm, languagesFormDispatch, isLanguageCollectionChanged } = useLanguagesForm();
  const { optionsForm, optionsFormDispatch, isOptionsChanged } = useOptionsForm();
  const { quizForm, quizFormDispatch, isQuizChanged } = useQuizForm();
  const nav = useNav();
  const navigate = useNavigate();
  const { lang } = useParams();

  const onSave = async () => {
    const promiseArray = [];
    let reroute = false;

    if (isOptionsChanged() || isQuizChanged()) {
      promiseArray.push(dispatch(saveCourseOptions({optionsForm, quizForm, lang})));
    }
    if (isLanguageCollectionChanged()) {
      promiseArray.push(dispatch(saveLanguageCollection(languagesForm.languageCollection)));
      reroute = true;
    }

    await Promise.all(promiseArray);
    if (reroute) navigate('/learnings');
    props.onCloseConfigure();
  };

  function getModalHeight() {
    if (nav.selectedTab === 0) return 480;
    if (nav.selectedTab === 1) return 600;
    if (nav.selectedTab === 2) return 380;
  }

  return (
    <Modal
      nodeRef={props.nodeRef}
      animation={props.animation}
      width={700}
      height={getModalHeight()}
      title="Course configuration"
      onModalClose={props.onCloseConfigure}
    >
      <Nav selectedTab={nav.selectedTab} changeTab={nav.changeTab} />

      {nav.selectedTab === 0 && (
        <CourseOptions
          optionsForm={optionsForm}
          dispatch={optionsFormDispatch}
        />
      )}

      {nav.selectedTab === 1 && (
        <LanguageOptions
          languagesForm={languagesForm}
          dispatch={languagesFormDispatch}
        />
      )}

      {nav.selectedTab === 2 && (
        <QuizOptions
          quizForm={quizForm}
          dispatch={quizFormDispatch}
        />
      )}

      <div className={classes.buttons}>
        <DefaultButton type="button" onClick={props.onCloseConfigure}>Cancel</DefaultButton>
        <PrimaryButton type="button" onClick={onSave}>Save</PrimaryButton>
      </div>

    </Modal>
  )
}

export default ConfigureModal;