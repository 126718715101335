import { mergeStyleSets, getTheme } from "@fluentui/react";
import { IDropdownProps } from './dropdown';
import { DropdownThemes } from "../../../utils/constants/dropdown-themes";

interface IDropdownClassNames {
  'dropdown': string;
  'title-container': string;
  'placeholder-container': string;
  'placeholder': string;
  'icon': string;
  'option': string;
  'selected-option-container': string;
  'caret-down-container': string;
  groupInput: string;
  titleLabel: string;
}

export const getClassNames = (props: IDropdownProps): IDropdownClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    'dropdown': {
      // height: props.height
      ...(props.disabled && {
        pointerEvents: 'none',
      }),

      '*': {
        fill: props.disabled ? theme.palette.neutralTertiary : theme.palette.black,
      }
    },

    'title-container': {
      height: '100%',
      display: 'flex',
      alignItems: 'center'
    },

    'placeholder-container': {
      height: '100%',
      display: 'flex',
      gap: 6,
      alignItems: 'center',
    },

    'placeholder': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      maxWidth: '85%'
    },

    'icon': {
      marginRight: 10,
      fontSize: 18,
    },

    'option': {
      flex: 1,
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': '2',
      whiteSpace: 'pre-wrap',

    },

    'selected-option-container': {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      gap: 5,
    },

    'caret-down-container': {
      height: '100%',
      // width: 24,
      width: '100%',
      display: 'flex',
      // justifyContent: 'flex-end',
      justifyContent: 'center',
      alignItems: 'center',
      paddingRight: 2,
      borderLeft: props.theme === DropdownThemes.LIGHT ? `1px solid ${theme.palette.neutralQuaternary}` : props.theme === DropdownThemes.COLORED && `2px solid ${theme.palette.neutralPrimaryAlt}`
    },

    groupInput: {
      display: 'flex',
      flexDirection: 'column',
      gap: 5
    },

    titleLabel: {
      color: theme.palette.neutralSecondary,
      fontWeight: theme.fonts.medium.fontWeight
    },
  });
}