// import react
import { Dispatch } from 'react';

// import styles
import { getClassNames } from "./course-options.classNames";
import { ActionTypes, IAction, IOptionsForm } from '../../hooks/useOptionsForm';

interface ICourseOptionsProps {
  optionsForm: IOptionsForm;
  dispatch: Dispatch<IAction>;
}

const CourseOptions = (props: ICourseOptionsProps) => {
  const classes = getClassNames();

  return (
    <div className={classes.courseOptionsContainer}>
      <p>Edit course options</p>
      <div>
        <h5 className={classes.titleLabel}>Title</h5>
        <input
          name='title'
          className={classes.textInput}
          value={props.optionsForm.title}
          onChange={(e) => props.dispatch({ type: ActionTypes.UPDATE_INPUT, payload: { name: 'title', value: e.target.value } })}
        />
      </div>
      <div>
        <h5 className={classes.titleLabel}>Description</h5>
        <textarea
          name='description'
          className={`${classes.textInput} ${classes.textArea}`}
          rows={5}
          value={props.optionsForm.description}
          onChange={(e) => props.dispatch({ type: ActionTypes.UPDATE_INPUT, payload: { name: 'description', value: e.target.value } })}
        />
      </div>
    </div>
  )
};

export default CourseOptions;