// import styles
import { getClassNames } from "./error.classNames";

interface IErrorProps {
  message?: string;
}

const Error = (props: IErrorProps) => {
  const classes = getClassNames();

  return (
    <div className={classes.errorContainer}>
      <div className={classes.errorMessageContainer}>
        <span>{props.message || "Error"}</span>
      </div>
    </div>
  )
};

export default Error;