import { MouseEvent } from 'react';
import { getTheme } from "@fluentui/react";

interface IReorderProps {
  draggableElement?: HTMLDivElement;
  disabled?: boolean;
}

const Reorder = (props: IReorderProps) => {
  const preventDragging = (e: MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    if (props.draggableElement) props.draggableElement.draggable = false;
    else if (e.currentTarget.parentElement) e.currentTarget.parentElement.draggable = false;
  };
  
  const enableDragging = (e: MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    if (props.draggableElement) props.draggableElement.draggable = true;
    else if (e.currentTarget.parentElement) e.currentTarget.parentElement.draggable = true;
  };

  return (
    <svg 
      width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" 
      style={{cursor: 'move'}}
      onMouseDown={enableDragging}
      onMouseUp={preventDragging}
      onMouseLeave={preventDragging}
    >
      <path d="M2.75 13.25H21.25C21.6642 13.25 22 13.5858 22 14C22 14.3797 21.7178 14.6935 21.3518 14.7432L21.25 14.75H2.75C2.33579 14.75 2 14.4142 2 14C2 13.6203 2.28215 13.3065 2.64823 13.2568L2.75 13.25H21.25H2.75ZM2.75 9.25H21.25C21.6642 9.25 22 9.58579 22 10C22 10.3797 21.7178 10.6935 21.3518 10.7432L21.25 10.75H2.75C2.33579 10.75 2 10.4142 2 10C2 9.6203 2.28215 9.30651 2.64823 9.25685L2.75 9.25H21.25H2.75Z" fill={props.disabled ? getTheme().palette.neutralTertiary : getTheme().palette.black}/>
    </svg>
  )
};

export default Reorder;