import { mergeStyleSets } from '@fluentui/react';
import { getTheme } from '@fluentui/react';
import { BorderRadius } from '../../../../../utils/constants/styles';

interface IHeaderClassNames{
  header: string;
  pageTitleContainer: string;
  menuIcon: string;
  reloadIcon: string;
  searchBarContainer: string;
  searchBar: string;
}

export const getClassNames = (disabled: boolean): IHeaderClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    header: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    menuIcon: {
      fontSize: getTheme().fonts.xLarge.fontSize,
      cursor: 'pointer',
    },

    pageTitleContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 20,
    },

    reloadIcon: {
      marginTop: 6,
      fontWeight: 700,
      color: theme.palette.themePrimary,
      cursor: 'pointer',
    },

    searchBarContainer: {
      position: 'relative',
      ...(disabled && {
        pointerEvents: 'none',
      })
    },

    searchBar: {
      height: 35,
      padding: '0 10px',
      border: theme.palette.neutralQuaternary,
      backgroundColor: theme.palette.neutralLighter,
      borderRadius: BorderRadius,
      '::placeholder': {
        color: theme.palette.neutralQuaternary
      },

      ':focus': {
        outline: 'none',
      }
    }
  });
};