import { mergeStyleSets, getTheme, keyframes } from "@fluentui/react";
import { BorderRadius } from "../../../../../../../../../../utils/constants/styles";

interface ICollectionClassNames {
  collectionContainer: string;
  languagesContainer: string;
  languageContainer: string;
  languageItem: string;
  toggleContainer: string;
  language: string;
}

export const getClassNames = (): ICollectionClassNames => {
  const theme = getTheme();

  const addLanguage = keyframes({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    }
  });

  return mergeStyleSets({
    collectionContainer: {
      flex: '0 0 fit-content',
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
    },

    languagesContainer: {
      overflow: 'auto',
      maxHeight: 155,
      display: 'flex',
      flexDirection: 'column',
      gap: 5,
    },

    languageContainer: {
      flex: '0 0 35px',
      display: 'flex',
      alignItems: 'center',
      gap: 20,
      borderRadius: BorderRadius,

      animationName: addLanguage,
      animationDuration: '500ms',
    },

    languageItem: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      gap: 20,
      background: theme.palette.neutralLighterAlt,
      borderRadius: BorderRadius
    },

    toggleContainer: {
      flex: '0 0 60px',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: theme.palette.themeLight,
      borderRadius: BorderRadius
    },

    language: {
      flex: 1,
    }
  });
}