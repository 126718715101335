import { getClassNames } from "./FlashCardLesson.classNames";

interface IFlashCardLessonProps {
  question: string;
  answer: string;
  explanation: string;
}

const FlashCardLesson = (props: IFlashCardLessonProps) => {
  const classes = getClassNames();

  return (
    <div className={classes.flashCardLessonContainer}>
      <div className={classes.flashCardContainer}>
        <p>{props.question}</p>
        <p className={classes.answer}>{props.answer}</p>
        <p>{props.explanation}</p>
      </div>
    </div>
  );
}

export default FlashCardLesson;