import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import accountReducer from './slices/account';
import clientsReducer from './slices/clients';
import coursesReducer from './slices/courses';
import settingsReducer from './slices/settings';

export const store = configureStore({
  reducer: {
    account: accountReducer,
    clients: clientsReducer,
    settings: settingsReducer,
    courses: coursesReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  })
});

export type RootState = ReturnType<typeof store.getState>
type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>()