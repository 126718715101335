import { useEffect, useRef, useState } from "react";
import Buttons from "./Buttons/buttons";
import { getClassNames } from "./delete-dialog.classNames";
import { CSSTransition } from 'react-transition-group';
import { ANIMATION_DURATION } from "../../../../../utils/constants/styles";

interface IDeleteDialogProps {
  nodeRef: React.RefObject<HTMLDivElement>;
  animation: boolean;
  type: 'Learning' | 'Lesson' | undefined;
  onCancelDeleteLearning: () => void;
}

const DeleteDialog = (props: IDeleteDialogProps) => {
  const [animation, setAnimation] = useState<boolean>(false);
  const classes = getClassNames();

  const message = 
    props.type === 'Learning' ? 'Are you sure you want to delete the learning?' :
    'Are you sure you want to delete the lesson?';

  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setAnimation(props.animation);
  }, [props.animation]);

  return (
    <div ref={props.nodeRef} className={classes.dialogBackground}>
      <CSSTransition
        nodeRef={modalRef}
        timeout={ANIMATION_DURATION}
        in={animation}
        classNames={{
          enter: classes.enter,
          enterActive: classes.enterActive,
          exit: classes.exit,
          exitActive: classes.exitActive
        }}
        mountOnEnter
        unmountOnExit
      >
          <div className={classes.dialog}>
            <span>{message}</span>
          <Buttons onCancelClick={props.onCancelDeleteLearning} type={props.type} />
        </div>
      </CSSTransition>
    </div>
  )
};

export default DeleteDialog;