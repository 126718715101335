import { useState } from "react";

const usePagination = (numberOfPages: number) => {
  const [page, setPage] = useState<number>(0);

  const nextPage = () => { if (page < (numberOfPages - 1)) setPage(page + 1) };
  const previousPage = () => { if (page > 0) setPage(page - 1) };

  return {
    page,
    nextPage,
    previousPage
  };
};

export default usePagination;