// import react
import { FormEvent, useMemo, Dispatch, } from 'react';

// import styles
import { getClassNames } from "./CourseOptions.classNames";

// import models
import { IDropdownOption } from '@fluentui/react';
import { CreateFromScratchFormKeys } from '../../../../../../../../common/models/Create/form-data';
import { CourseCategoryDto } from '../../../../../../../../api-client';

// import utils
import { getLanguageFromCode, sortString } from '../../../../../../../../utils/helpers/helpers';
import Dropdown from '../../../../../../../../common/components/Dropdown/dropdown';
import { DropdownThemes } from '../../../../../../../../utils/constants/dropdown-themes';
import { ActionTypes, IAction } from '../../../FromScratchModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../../store/store';

interface ICourseOptionsProps {
  dispatch: Dispatch<IAction>;
  options: { 
    [CreateFromScratchFormKeys.CATEGORY]: string;
    [CreateFromScratchFormKeys.DOCUMENT_LANGUAGE]: string;
    [CreateFromScratchFormKeys.HAS_AUDIO]: boolean;
   };
}

const CourseOptions = ({ options, dispatch }: ICourseOptionsProps) => {
  const classes = getClassNames();
  const { settings, error } = useSelector((state: RootState) => state.settings);

  const categories = useMemo((): IDropdownOption[] => {
    if (!settings?.categories) return [];

    return settings.categories.filter(category => !category.archived).map((category: CourseCategoryDto): IDropdownOption => ({
      key: category.id || '',
      text: category.categoryTranslations?.find(categoryTranslation => categoryTranslation.language === 'it-IT')?.value || '',
    }));
  }, [settings?.categories]);

  const languages = useMemo((): IDropdownOption[] => {
    if (!settings?.supportedLanguages) return [];
    
    return settings.supportedLanguages.map((language: string): IDropdownOption => ({
      key: language,
      text: getLanguageFromCode(language),
    })).sort((a, b) => sortString(a.text, b.text));
  }, [settings?.supportedLanguages]);

  const onDropdownChange = (e: FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined, name?: CreateFromScratchFormKeys) => {
    if (!option || !name) return;
    
    dispatch({ type: ActionTypes.UPDATE_INPUT, payload: { name, value: option.key.toString() } });
  };

  return (
    <div className={classes.courseOptionsContainer}>
      <Dropdown
        options={categories}
        onChange={(event: FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) =>
          onDropdownChange(event, option, index, CreateFromScratchFormKeys.CATEGORY)}
        theme={DropdownThemes.DARK} 
        placeholder={error && 'Error...'}
        isGroup
        title="Category"
      />
      <Dropdown
        options={languages}
        onChange={(event: FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) =>
          onDropdownChange(event, option, index, CreateFromScratchFormKeys.DOCUMENT_LANGUAGE)}
        theme={DropdownThemes.DARK} 
        placeholder={error && 'Error...'}
        isGroup
        title="Document language"
      />
      {/* <div className={classes.generateAudioContainer} >
        <Checkbox onChange={(ev?: FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean | undefined) => 
          dispatch({ type: ActionTypes.UPDATE_INPUT, payload: { name: CreateFromScratchFormKeys.HAS_AUDIO, value: checked } })}
        />
        Generate Audio
      </div> */}
    </div>
  )
};

export default CourseOptions;