import { InteractionType } from "@azure/msal-browser";
import { useMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { PropsWithChildren, useCallback, useEffect } from "react";
import { authenticationRequest } from "../../utils/settings/msal";
import { RootState, useAppDispatch } from "../../store/store";
import { setAccount } from "../../store/slices/account";
import Login from "../../pages/Login/Login";
import { useSelector } from "react-redux";
import NoPermission from "../../common/components/NoPermission/NoPermission";

export default function Web({children}: PropsWithChildren) {
  const { token, roles } = useSelector((state: RootState) => state.account);
  const dispatch = useAppDispatch();
  const { accounts, instance } = useMsal();

  const getToken = useCallback(async () => {
    if (!accounts || accounts.length === 0) return;

    try {
      const result = await instance.acquireTokenSilent({
      ...authenticationRequest,
      account: accounts[0],
      });

      const roles = (result.idTokenClaims as any).roles;
      dispatch(setAccount({ username: accounts[0].username, token: result.accessToken, roles }));
    } catch (err) {
      console.error(err);
        // await instance.acquireTokenRedirect({
        // ...authenticationRequest,
        // account: accounts[0],
        // });
      }
  }, [accounts, instance, dispatch]);

  useEffect(() => {
    getToken();
  }, [getToken]);

  console.log('roles', roles);

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authenticationRequest}
      loadingComponent={Login}
    >
      {token && (roles?.includes('Manager') || roles?.includes('Admin')) ? children : <NoPermission />}
    </MsalAuthenticationTemplate>
  )
}