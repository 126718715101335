import { mergeStyleSets } from '@fluentui/react';

interface IButtonsClassNames{
  buttonsContainer: string;
}

export const getClassNames = (): IButtonsClassNames => {
  return mergeStyleSets({
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: 10,
    },
  })
}