// import react
import { ReactNode, useMemo, useState } from 'react';

// import styles
import { getClassNames } from './list.classNames';

// import models
import { CourseDto, CourseStatusDto } from '../../../../../api-client';
import { IBaseCourse } from '../../../../../common/models/Learnings/course';
import { CheckboxVisibility, IDetailsRowProps, IColumn, DetailsListLayoutMode } from "@fluentui/react";

// import components
import { DetailsList, buildColumns, DetailsRow } from "@fluentui/react";
import ListItem from './ListItem/list-item';

// import hooks
import { useNavigate } from 'react-router-dom';
import usePagination from './hooks/usePagination';
import Navigation from './Navigation/navigation';
import { useAppDispatch } from '../../../../../store/store';
import { setIDLE } from '../../../../../store/slices/courses';

interface IListProps {
  courses: IBaseCourse[] | undefined;
  onErrorItemClick: (courseIdToDelete: string) => void;
  filter: number;
}

const List = (props: IListProps) => {
  const dispatch = useAppDispatch();
  const classes = getClassNames();
  const navigate = useNavigate();

  const numberOfPages = props.courses?.length ? Math.ceil(props.courses?.length / 10) : 0;
  const pagination = usePagination(numberOfPages);

  const [selectedColumn, setSelectedColumn] = useState<string>('Created');
  const [isSortedDescending, setIsSortedDescending] = useState<boolean>(true);

  const items = useMemo(() => {
    if (!props.courses) return [];

      return props.courses.map((course): any => {
        return {
          Id: course.id,
          Title: course.title,
          Status: course.status,
          Category: course.category,
          Created: course.created,
          Author: course.createdBy
      }}).sort((a, b) => ((isSortedDescending ? a[selectedColumn] < b[selectedColumn] : a[selectedColumn] > b[selectedColumn]) ? 1 : -1))
        .slice(pagination.page * 10, (pagination.page * 10) + 10);
  }, [props.courses, pagination.page, selectedColumn, isSortedDescending]);

  const onColumnClick = (ev: React.MouseEvent<HTMLElement, MouseEvent>, column: IColumn) => {
    if (selectedColumn === column.key) {
      column.isSortedDescending = !column.isSortedDescending;
      setIsSortedDescending(column.isSortedDescending);
    } else {
      if (column.key === 'Created') {
        column.isSortedDescending = true;
        setIsSortedDescending(true);
      } else {
        column.isSortedDescending = false;
        setIsSortedDescending(false);
      }
    }
    setSelectedColumn(column.key);
  };

  const adjustColumns = (items: any) => {
    const columns = buildColumns(items, true, onColumnClick, selectedColumn, isSortedDescending);

    columns.forEach(col => {
      switch (col.key) {
        case 'Title': 
          col.minWidth = 400;
          col.maxWidth = 400;
          break;

        case 'Status':
          return col.maxWidth = 180;

        case 'Category':
          return col.maxWidth = 180;

        case 'Created':
          return col.maxWidth = 200;

        case 'Author':
          return col.maxWidth = 400;
      
        default: return columns;
      }
    });

    return columns.filter(col => col.name !== 'Id');
   };

  const columns = adjustColumns(items);

  const onItemClick = (_: any, index: number | undefined) => {
    if (props.courses && index !== undefined) {
      if (items[index].Status !== CourseStatusDto.Generating) {
        const course = props.courses.find(course => course.title === items[index].Title);

        // check if error critical
        if (items[index].Status === CourseStatusDto.GenError) {
          const errors = (course as CourseDto).errors;
          if (!errors) return;

          if (errors[errors?.length - 1].level === 'Critical') {
            props.onErrorItemClick(course?.id || '');
            return;
          }
        }        
        dispatch(setIDLE());
        navigate(`/learnings/${course?.id}`);
      }
    } 
  };

  const renderRows = (props: IDetailsRowProps | undefined): JSX.Element | null => {
    if (props) return <DetailsRow {...props} className={classes.row} />
    else return null;
  };

  const renderItem = (item?: any, index?: number | undefined, column?: IColumn | undefined): ReactNode => {
    if (!column) return;
    
    let generationProgress;
    if (item.Status === CourseStatusDto.Generating) {
      const course = props.courses?.find(course => course.id === item.Id);
      generationProgress = course?.generationProgress;
    }
   
    return (
      <ListItem
        item={item}
        columnKey={column.key}
        statusInfo={item.Status}
        generationProgress={generationProgress}
      />);
  };

  return (
    <div key={`${props.filter}-${pagination.page}`} className={classes.listContainer}>
      {/* <h1>Company learnings</h1> */}
      <DetailsList
        className={classes.list} 
        items={items}
        columns={columns}
        onRenderItemColumn={renderItem}
        onRenderRow={renderRows}
        checkboxVisibility={CheckboxVisibility.hidden}
        onActiveItemChanged={onItemClick}
        layoutMode={DetailsListLayoutMode.justified}
      />
      <Navigation
        numberOfPages={numberOfPages}
        page={pagination.page}
        nextPage={pagination.nextPage}
        previousPage={pagination.previousPage}
      />
    </div>
  )
}

export default List;