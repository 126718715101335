export enum NumberOfLessons {
  'id-1' = 'Let AI choose',
  'id5' = '5',
  'id10' = '10',
  'id15' = '15',
  'id20' = '20',
  'id25' = '25',
  'id30' = '30',
  'id35' = '35',
  'id40' = '40',
  'id45' = '45',
  'id50' = '50',
}