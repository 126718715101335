import { mergeStyleSets, getTheme } from "@fluentui/react";
import { BorderRadius } from "../../../../utils/constants/styles";
import { IInputProps } from "./input";

interface IInputClassNames {
  groupInput: string;
  titleLabel: string;
  textInput: string;
  textArea: string;
}

export const getClassNames = (props: IInputProps): IInputClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    groupInput: {
      ...(props.flex && {flex: 1}),
      display: 'flex',
      flexDirection: 'column',
      gap: 5
    },

    titleLabel: {
      color: theme.palette.neutralSecondary,
      fontWeight: theme.fonts.medium.fontWeight
    },

    textInput: {
      position: 'relative',
      width: props.width ? props.width : '100%',
      height: props.height,
      border: 'none',
      background: theme.palette.neutralLighterAlt,
      borderRadius: BorderRadius,
      fontSize: props.fontSize || theme.fonts.large.fontSize,
      padding: '5px 10px',
      outline: 'none',
    },

    textArea: {
      resize: 'none',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderBottom: `3px solid ${theme.palette.themePrimary}`,
    },
  });
};