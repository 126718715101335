import { mergeStyleSets, getTheme } from "@fluentui/react";
import { BorderRadius } from "../../../utils/constants/styles";

interface IErrorClassNames {
  errorContainer: string;
  errorMessageContainer: string;
}

export const getClassNames = (): IErrorClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    errorContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },

    errorMessageContainer: {
      width: 350,
      height: 150,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: theme.palette.neutralLighterAlt,
      borderRadius: `${BorderRadius} ${BorderRadius} 0 0`,
      borderBottom: `2px solid ${theme.palette.themePrimary}`
    }
  });
};