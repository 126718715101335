// import react
import { Dispatch, useState, ChangeEvent, FormEvent } from 'react';
import { QuizResponseDto, SafeQuizLessonDto } from '../../../../../../../../api-client';
import { getClassNames } from './question.classNames';
import useTextAreaRef from './hooks/useTextAreaRef';
import { IDropdownOption, getTheme } from '@fluentui/react';
import PrimaryButton from '../../../../../../../../common/components/Buttons/PrimaryButton/primary-button';
import { IconNames } from '../../../../../../../../common/models/Icons/icon-names';
import Reorder from '../../../../../../../../assets/Icons/Controls/Reorder/reorder';
import Dropdown from '../../../../../../../../common/components/Dropdown/dropdown';
import DropdownOptionsMapper from '../../../../../../../../utils/mappers/dropdown-options';
import { DropdownThemes } from '../../../../../../../../utils/constants/dropdown-themes';
import { ActionTypes, IAction } from '../../hooks/useForm';
import useDrag from './hooks/useDrag';

interface IQuestionProps {
  selectedQuestion: SafeQuizLessonDto | undefined | null;
  dispatch: Dispatch<IAction>;
}

const Question = (props: IQuestionProps) => {
  const {
    onDragStart,
    onDragOver,
    onDragEnd,
    onDrop
  } = useDrag(props.dispatch);

  const classes = getClassNames();
  const theme = getTheme();

  const textAreaRefs = useTextAreaRef(props.selectedQuestion?.options);

  const [newItem, setNewItem] = useState<string>('');

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;

    props.dispatch({ type: ActionTypes.UPDATE_INPUT, payload: { name: e.target.name, value: e.target.value } });
  };

  const onOptionsChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;

    const index = e.currentTarget.getAttribute('data-index');
    if (!index) return;
    props.dispatch({ type: ActionTypes.UPDATE_OPTION, payload: { name: e.target.name, value: e.target.value, index: +index } });
  };

  function onNewItemChange(e: ChangeEvent<HTMLTextAreaElement>) {
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;

    setNewItem(e.target.value);
  };

  const onCorrectAnswerChange = (e: FormEvent<HTMLDivElement>, selectedOption?: IDropdownOption) => {
    props.dispatch({ type: ActionTypes.UPDATE_INPUT, payload: { name: 'correctAnswerId', value: selectedOption?.key } });
  };

  return (
    <div className={classes.questionContainer}>
      <div className={classes.questionGroupInput}>
        <h5 className={classes.titleLabel}>Question</h5>
        <textarea 
          className={classes.questionInput}
          id='question' 
          name='question'
          value={props.selectedQuestion?.question || ''}
          onChange={onChange}
          rows={1}
          ref={textAreaRefs.question}
        />
      </div>
      <div className={classes.optionsGroupInput}>
      <h5 className={classes.titleLabel}>Answers</h5>
        {props.selectedQuestion?.options?.map((option: QuizResponseDto, index: number) => (
          <div key={index} className={classes.optionGroupInput}>
            <div
              data-index={index}
              className={classes.optionContainer} 
              draggable={false}
              onDragStart={(e) => onDragStart(e, index)}
              onDragEnd={onDragEnd}
              onDragOver={onDragOver}
              onDrop={onDrop}
            >
              <Reorder />
              <textarea 
                className={classes.optionInput}
                id={`option${index}`} 
                name={`option${index}`}
                data-index={index}
                value={option.response || ''}
                onChange={onOptionsChange}
                rows={1}
                ref={(el) => { if(el && textAreaRefs.options.current) textAreaRefs.options.current[index] = el}}
              />
                <PrimaryButton              
                  onClick={() => props.dispatch({ type: ActionTypes.DELETE_OPTION, payload: index })}
                  disabled={option.id === props.selectedQuestion?.correctAnswerId}
                  iconName={IconNames.DELETE} 
                  width={90} 
                  padding={10}
                  gap={5} 
                  background={theme.palette.themeLighter} 
                  color={option.id === props.selectedQuestion?.correctAnswerId ? theme.palette.neutralTertiary : theme.palette.themePrimary}
                >
                  Delete
                </PrimaryButton>
            </div>
          </div>
        ))}
        <div className={classes.newOptionContainer}>
          <textarea
            ref={textAreaRefs.newOption}
            name="new-item" 
            id="new-item" 
            className={classes.newOptionInput} 
            rows={1} 
            value={newItem}
            onChange={onNewItemChange}
          />
          <PrimaryButton
            onClick={() => {
              props.dispatch({ type: ActionTypes.ADD_OPTION, payload: newItem });
              setNewItem('');
            }}
            iconName={IconNames.ADD}
            width={90}
            color={newItem ? theme.palette.white : theme.palette.neutralTertiary}
            disabled={!newItem}
            type='button'
          >
            Add
          </PrimaryButton>
        </div>
      </div>
      <div className={classes.correctAnswerGroupInput}>
        <h5 className={classes.titleLabel}>Correct Answer</h5>
        <div className={classes.dropdownAnswerContainer}>
          <Dropdown
            height={40} 
            options={DropdownOptionsMapper.mapOptions(props.selectedQuestion?.options || [])}
            theme={DropdownThemes.COLORED}
            onChange={onCorrectAnswerChange}
            selectedKey={props.selectedQuestion?.correctAnswerId}
            placeholder={props.selectedQuestion?.options?.find(el => el.id === props.selectedQuestion?.correctAnswerId)?.response || ''}
            showAlwaysPlaceholder={true}
          />
        </div>
      </div>
    </div>
  );
};

export default Question;