import { IDropdownOption } from "@fluentui/react";
import { QuizResponseDto } from "../../api-client";

export default class DropdownOptionsMapper {
  static mapOptions(options: QuizResponseDto[]): IDropdownOption[] {
    return options.map((option: QuizResponseDto) => ({
      key: option.id !== undefined ? option.id : '',
      text: option.response || '',
    }));
  };
}