// import react
import { useState } from "react";

const useNav = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const changeTab = (tab: number) => setSelectedTab(tab);

  return {
    selectedTab,
    changeTab
  }
};

export default useNav;