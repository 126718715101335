import { mergeStyleSets, getTheme } from "@fluentui/react"
import { BorderRadius } from "../../../../../../../../../utils/constants/styles";

interface ITextLessonClassNames {
  editLessonContainer: string;
  dropdownContainer: string;
  groupInput: string;
  input: string;
  // descriptionGroupInput: string;
  // descriptionInput: string;
  label: string;
}

export const getClassNames = (disabled: boolean): ITextLessonClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    editLessonContainer: {
      overflow: 'auto',
      flex: 1,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      gap: 20,
    },

    dropdownContainer: {
      width: 190,
    },

    groupInput: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 5
    },

    input: {
      width: '100%',
      border: 'none',
      background: theme.palette.neutralLighterAlt,
      borderRadius: BorderRadius,
      fontSize: theme.fonts.large.fontSize,
      padding: '5px 10px',
      outline: 'none',
      resize: 'none',
      opacity: disabled ? 0.3 : 1,
    },

    // descriptionGroupInput: {
    //   width: '100%',
    //   flex: 1,
    //   display: 'flex',
    //   flexDirection: 'column',
    //   gap: 5
    // },

    // descriptionInput: {
    //   width: '100%',
    //   border: 'none',
    //   background: theme.palette.neutralLighterAlt,
    //   borderRadius: BorderRadius,
    //   fontSize: theme.fonts.large.fontSize,
    //   padding: '5px 10px',
    //   outline: 'none',
    //   resize: 'none',
    //   opacity: disabled ? 0.3 : 1,
    // },

    label: {
      color: theme.palette.neutralSecondary,
      fontWeight: theme.fonts.medium.fontWeight
    },
  });
};