import { mergeStyleSets, keyframes, getTheme } from "@fluentui/react"
import { BorderRadius } from "../../../../../../../../utils/constants/styles";

interface IQuestionClassNames {
  questionContainer: string;
  titleLabel: string;
  questionGroupInput: string;
  questionInput: string;
  optionsGroupInput: string;
  optionGroupInput: string;
  optionContainer: string;
  optionInput: string;
  newOptionContainer: string;
  newOptionInput: string;
  correctAnswerGroupInput: string;
  dropdownAnswerContainer: string;
}

export const getClassNames = (): IQuestionClassNames => {
  const theme = getTheme();

  const fadeIn = keyframes({
    from : {
      opacity: 0,
    },
    to: {
      opacity: 1,
    }
  });

  return mergeStyleSets({
    questionContainer: {
      overflow: 'auto',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: 20,

      animationName: fadeIn,
      animationFillMode: 'forwards',
      animationDuration: '500ms',
    },

    titleLabel: {
      color: theme.palette.neutralSecondary,
      fontWeight: theme.fonts.medium.fontWeight
    },

    questionGroupInput: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 5
    },

    questionInput: {
      width: '100%',
      border: 'none',
      background: theme.palette.neutralLighterAlt,
      borderRadius: BorderRadius,
      fontSize: theme.fonts.large.fontSize,
      padding: '5px 10px',
      outline: 'none',
      resize: 'none',
      opacity: 1,
    },

    optionsGroupInput: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 10
    },

    optionGroupInput: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 5
    },

    optionContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 10
    },

    optionInput: {
      flex: 1,
      border: 'none',
      background: theme.palette.neutralLighterAlt,
      borderRadius: BorderRadius,
      fontSize: theme.fonts.large.fontSize,
      padding: '5px 10px',
      outline: 'none',
      resize: 'none',
      opacity: 1,
    },

    newOptionContainer: {
      marginTop: 20,
      paddingLeft: 34,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: 10
    },

    newOptionInput: {
      flex: 1,
      border: 'none',
      background: theme.palette.neutralLighterAlt,
      borderRadius: BorderRadius,
      fontSize: theme.fonts.large.fontSize,
      padding: '5px 10px',
      outline: 'none',
      resize: 'none',
      opacity: 1,
    },

    correctAnswerGroupInput: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 5
    },

    dropdownAnswerContainer: {
      width: 300,
      flex: '0 0 40px',
      display: 'flex',
      alignItems: 'center',
      opacity: 1,
      // ...(disabled && {
      //   pointerEvents: 'none'
      // })
    },
  });
};