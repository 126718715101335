// import react
import * as React from 'react';

interface IChevronLeftProps {
  onClick: () => void;
}

const ChevronLeft = (props: IChevronLeftProps) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{cursor: "pointer"}}
      onClick={props.onClick}>
      <path d="M18.0303 22.7803C17.7374 23.0732 17.2626 23.0732 16.9697 22.7803L8.21967 14.0303C8.07902 13.8897 8 13.6989 8 13.5C8 13.3011 8.07902 13.1103 8.21967 12.9697L16.9697 4.21967C17.2626 3.92678 17.7374 3.92678 18.0303 4.21967C18.3232 4.51256 18.3232 4.98744 18.0303 5.28033L9.81066 13.5L18.0303 21.7197C18.3232 22.0126 18.3232 22.4874 18.0303 22.7803Z" fill="#242424"/>
    </svg>
  );
};

export default ChevronLeft;