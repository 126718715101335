// import react
import { useEffect, useRef } from "react";

// import styles
import { getClassNames } from "./Create.classNames";


// import utils
import { Logger } from "../../utils/logger";

//import models
import { CreateCardInfo } from "../../common/models/Create/create-card-info";

// import components
import CreateCard from "./components/CreateCard/CreateCard";
import UploadModal from "./components/Modals/UploadModal/UploadModal";
import FromScratchModal from "./components/Modals/FromScratchModal/FromScratchModal";

// import hooks
import useCreate from "./hooks/useCreate";
import UploadDocument from "../../assets/Icons/Others/UploadDocument/upload-document";
import CreateFromScratch from "../../assets/Icons/Others/CreateFromScratch/create-from-scratch";
import { RootState, useAppDispatch } from "../../store/store";
import { CSSTransition } from "react-transition-group";
import { ANIMATION_DURATION } from "../../utils/constants/styles";
import { useSelector } from "react-redux";
import { cleanCourses } from "../../store/slices/courses";

const Create = () => {
  Logger.render("Create");
  const courses = useSelector((state: RootState) => state.courses.courses);
  const dispatch = useAppDispatch();

  const classes = getClassNames();
  const {
    showUploadModal,
    onUploadClick,
    onUploadModalCloseClick,
    showFromScratchModal,
    onFromScratchClick,
    onFromScratchModalCloseClick
  } = useCreate();

  const getCards = (): CreateCardInfo[] => {
    return [
      {
        Icon: UploadDocument,
        buttonLabel: 'Upload a document',
        description: 'Choose a Word document or a PDF file to start generating your microlearning course',
        action: onUploadClick,
      },
      // {
      //   Icon: CreateFromWebsite,
      //   buttonLabel: 'Create from a website',
      //   description: 'Insert the link of a SharePoint site with documents that can be turned on a microlearning training for your collegues',
      // },
      {
        Icon: CreateFromScratch,
        buttonLabel: 'Create from scratch',
        description: 'Start creating your microlearning training, following our templates and with the help of AI',
        action: onFromScratchClick
      }
    ]
  }

  const uploadModalRef = useRef<HTMLDivElement>(null);
  const fromScratchModalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!!courses) dispatch(cleanCourses());
  }, [dispatch, courses]);

  return (
    <section className={classes.createPage}>
      {getCards().map((card, index) => <CreateCard key={index} cardInfo={card} />)}
      
      <CSSTransition
        nodeRef={uploadModalRef}
        timeout={ANIMATION_DURATION}
        in={showUploadModal}
        classNames={{
          enter: classes.enter,
          enterActive: classes.enterActive,
          exit: classes.exit,
          exitActive: classes.exitActive
        }}
        unmountOnExit
      >
        <UploadModal 
          nodeRef={uploadModalRef}
          onUploadModalCloseClick={onUploadModalCloseClick}
          animation={showUploadModal}
        />
      </CSSTransition>

      <CSSTransition
        nodeRef={fromScratchModalRef}
        timeout={ANIMATION_DURATION}
        in={showFromScratchModal}
        classNames={{
          enter: classes.enter,
          enterActive: classes.enterActive,
          exit: classes.exit,
          exitActive: classes.exitActive
        }}
        unmountOnExit
      >
        <FromScratchModal 
          nodeRef={fromScratchModalRef}
          onFromScratchModalCloseClick={onFromScratchModalCloseClick}
          animation={showFromScratchModal}
        />
      </CSSTransition>

    </section>
  )
};

export default Create;