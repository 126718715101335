import { mergeStyleSets, getTheme } from "@fluentui/react";

interface ICourseModalClassNames {
  scormSettingsContainer: string;
  divider: string;
  newDomainContainer: string;
}

export function getClassNames(): ICourseModalClassNames {
  const theme = getTheme();
  
  return mergeStyleSets({
    scormSettingsContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: 25,
    },

    divider: {
      borderColor: theme.palette.whiteTranslucent40,
      marginTop: 10,
    },

    newDomainContainer: {
      display: 'flex',
      alignItems: 'flex-end',
      gap: 20,
    }
  });
};