import { mergeStyleSets, getTheme } from "@fluentui/react";
import { IFooterProps } from "./footer";

interface IFooterClassNames {
  footer: string;
  actionEditContainer: string;
  actionHideContainer: string;
  editIcon: string;
  hideIcon: string;
}

export const getClassNames = (props: IFooterProps): IFooterClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    footer: {
      flex: '0 0 45px',
      margin: '0 20px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    actionEditContainer: {
      fontSize: 14,
      display: 'flex',
      alignItems: 'center',
      gap: 5,
      color: theme.palette.themePrimary,
      cursor: 'pointer',

      '&:hover': {
        color: theme.palette.themeDark,
      }
    },

    actionHideContainer: {
      fontSize: 14,
      display: 'flex',
      alignItems: 'center',
      gap: 5,
      color: theme.palette.themePrimary,
      cursor: 'pointer',

      '&:hover': {
        color: theme.palette.themeDark,
      }
    },

    editIcon: {
      fontSize: 16,
    },

    hideIcon: {
      fontSize: 16,
      marginTop: 4,
    },

  });
}