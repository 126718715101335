import { getTheme } from "@fluentui/react";
import { DropdownThemes } from "../../../../utils/constants/dropdown-themes";

interface ICaretDownProps {
  theme: DropdownThemes;
  size: number;
}

const CaretDown = (props: ICaretDownProps) => {
  const caretColor = props.theme === DropdownThemes.COLORED ? getTheme().palette.white : getTheme().palette.black;

  return (
    <svg width={props.size} height={props.size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.8527 7.64582C16.0484 7.84073 16.0489 8.15731 15.854 8.35292L10.389 13.8374C10.1741 14.0531 9.82477 14.0531 9.60982 13.8374L4.14484 8.35292C3.94993 8.15731 3.95049 7.84073 4.1461 7.64582C4.34171 7.4509 4.65829 7.45147 4.85321 7.64708L9.99942 12.8117L15.1456 7.64708C15.3406 7.45147 15.6571 7.4509 15.8527 7.64582Z" fill={caretColor}/>
    </svg>
  )
};

export default CaretDown;