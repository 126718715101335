// import react
import { Dispatch } from 'react';

// import styles
import { getClassNames } from './details.classNames';

// import models
import { CreateFromScratchFormKeys, ICreateFromScratchForm } from '../../../../../../../common/models/Create/form-data';

// import utils
import CourseDetails from './CourseDetails/CourseDetails';
import CourseOptions from './CourseOptions/CourseOptions';
import { IAction } from '../../FromScratchModal';

interface IDetailsProps {
  formData: ICreateFromScratchForm;
  dispatch: Dispatch<IAction>;
}

const Details = ({formData, dispatch}: IDetailsProps) => {
  const classes = getClassNames();
  
  return (
    <div className={classes.courseDetailsContainer}>
      <p>Course details</p>
      <CourseDetails
        details={{ title: formData.title, description: formData.description }}
        dispatch={dispatch}
      />
      <CourseOptions
        options={{
          [CreateFromScratchFormKeys.CATEGORY]: formData[CreateFromScratchFormKeys.CATEGORY],
          [CreateFromScratchFormKeys.DOCUMENT_LANGUAGE]: formData[CreateFromScratchFormKeys.DOCUMENT_LANGUAGE],
          [CreateFromScratchFormKeys.HAS_AUDIO]: formData[CreateFromScratchFormKeys.HAS_AUDIO],
        }}
        dispatch={dispatch}
      />
    </div>
  )
}

export default Details;