import { useState } from "react";
import Delete from "../../../../../../../assets/Icons/Buttons/Delete/delete";
import { getClassNames } from "./footer.classNames";
import { Icon } from "@fluentui/react";
import { getTheme } from "@fluentui/react";

export interface IFooterProps {
  onEditLessonClick: () => void;
  onDeleteClick: () => void;
}

const Footer = (props: IFooterProps) => {
  const [isHover, setIsHover] = useState(false);
  const classes = getClassNames(props);
  const theme = getTheme();

  return (
    <footer className={classes.footer}>
      <div className={classes.actionEditContainer} onClick={props.onEditLessonClick}>
        <Icon iconName="Edit" className={classes.editIcon} />
        <span>Edit</span>
      </div>
      <div
        className={classes.actionHideContainer}
        onClick={props.onDeleteClick}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}        
      >
        <Delete color={isHover ? theme.palette.themeDark : theme.palette.themePrimary} />
        <span>Delete</span>
      </div>
    </footer>
  )
};

export default Footer;