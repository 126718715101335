import { getClassNames } from "./create.classNames"

export default function CreateIcon() {
  const classes = getClassNames();

  return (
    <svg className={classes.icon} width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.75 0.5C19.4404 0.5 20 1.05964 20 1.75V17.5H35.75C36.4404 17.5 37 18.0596 37 18.75C37 19.4404 36.4404 20 35.75 20H20V35.75C20 36.4404 19.4404 37 18.75 37C18.0596 37 17.5 36.4404 17.5 35.75V20H1.75C1.05964 20 0.5 19.4404 0.5 18.75C0.5 18.0596 1.05964 17.5 1.75 17.5H17.5V1.75C17.5 1.05964 18.0596 0.5 18.75 0.5Z" fill="#242424"/>
    </svg>
  )
}