import { mergeStyleSets } from "@fluentui/react";

interface IConfigureModalClassNames {
  buttons: string;
}

export const getClassNames = (): IConfigureModalClassNames => {
  return mergeStyleSets({
    buttons: {
      width: '100%',
      display:'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: 10,
    }
  });
}